import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const CouponIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 48 48"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="invisible_box" data-name="invisible box">
        <rect width="48" height="48" fill="none" />
      </g>
      <g id="Layer_7" data-name="Layer 7">
        <path
          d="M43,7H38a2,2,0,0,0-1.4.6L34,10.2,31.4,7.6A2,2,0,0,0,30,7H5a2.9,2.9,0,0,0-3,3V38a3,3,0,0,0,3,3H30a2,2,0,0,0,1.4-.6L34,37.8l2.6,2.6A2,2,0,0,0,38,41h5a3,3,0,0,0,3-3V10A2.9,2.9,0,0,0,43,7ZM14,18a2,2,0,1,1-2,2A2,2,0,0,1,14,18Zm8,12a2,2,0,1,1,2-2A2,2,0,0,1,22,30Zm2.4-9.6-10,10a1.9,1.9,0,0,1-2.8,0,1.9,1.9,0,0,1,0-2.8l10-10a2,2,0,0,1,2.8,2.8ZM36,33a2,2,0,0,1-4,0V31a2,2,0,0,1,4,0Zm0-8a2,2,0,0,1-4,0V23a2,2,0,0,1,4,0Zm0-8a2,2,0,0,1-4,0V15a2,2,0,0,1,4,0Z"
          fill={fill || "#646965"}
        />
      </g>
    </g>
  </svg>
);
