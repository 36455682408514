// Lib
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import {
  useCreatePackageMutation,
  useGetPackageRulesQuery,
  useUpdatePackageMutation,
} from "rtkQuery/query/coinPackagesApi";
import { useGetExchangeRateQuery } from "rtkQuery/query/exchangeRateAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { PackageResponseDto } from "types/packages";
// Constants
import { NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Modal } from "components";
import { Input, InputNumber, Switch } from "components/Form";
// Styled
import { FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { resolver } from "./validation";

const initialValues = {
  name: null,
  price: null,
  expiryDays: null,
  bonusCoinsPercent: null,
  tag: null,
  active: false,
};

type PackagesForm = {
  name: string | null;
  price: number | null;
  expiryDays: number | null;
  bonusCoinsPercent: number | null;
  tag: string | null;
  active: boolean | null;
};

interface PackagesModalProps {
  modalData: boolean | PackageResponseDto;
  onClose: () => void;
}

export const PackagesModal: FC<PackagesModalProps> = ({
  modalData,
  onClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const isEdit = !!modalData && typeof modalData !== "boolean";

  const { data: exchangeRate } = useGetExchangeRateQuery(null);
  const { data: rules } = useGetPackageRulesQuery(null);
  const [create, { isLoading: isCreateLoading }] = useCreatePackageMutation();
  const [update, { isLoading: isUpdateLoading }] = useUpdatePackageMutation();

  const [amount, setAmount] = useState<null | number>(null);

  const { handleSubmit, control, reset, watch, setValue, getFieldState } =
    useForm<PackagesForm>({
      initialValues,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

  const price = watch("price");
  const bonusCoinsPercent = watch("bonusCoinsPercent");
  const name = watch("name");
  const expiryDays = watch("expiryDays");

  const handleCalucateFields = () => {
    const { isDirty: bonusManualyChanged } = getFieldState("bonusCoinsPercent");

    const bonusCoinsPercentValue =
      price === null
        ? null
        : bonusManualyChanged
        ? bonusCoinsPercent
        : rules?.find(rule => rule.from <= +price && rule.to >= +price)
            ?.bonusCoinsPercent || 0;

    const coinsAmount =
      price === null
        ? null
        : Math.round(
            (price / exchangeRate?.value) *
              (1 + bonusCoinsPercentValue / 100) *
              1000,
          ) / 1000;

    setAmount(coinsAmount);

    if (!isEdit && !bonusManualyChanged) {
      setValue("bonusCoinsPercent", bonusCoinsPercentValue);
    }
  };

  const calculateAmount = () => {
    if (!price) return;

    const coinsAmount = Math.round(
      (price / exchangeRate?.value) * (1 + bonusCoinsPercent / 100),
    );

    setAmount(coinsAmount);
  };

  useEffect(() => {
    calculateAmount();
  }, [bonusCoinsPercent]);

  useEffect(() => {
    handleCalucateFields();
  }, [price]);

  useEffect(() => {
    if (!modalData) {
      setAmount(null);
      reset(initialValues);
    }

    if (isEdit) {
      const { name, price, expiryDays, bonusCoinsPercent, tag, active } =
        modalData;
      reset({ name, price, expiryDays, tag, active });
      setValue("bonusCoinsPercent", bonusCoinsPercent, { shouldDirty: true });
      handleCalucateFields();
    }
  }, [modalData]);

  const onSubmit: SubmitHandler<PackagesForm> = async data => {
    try {
      if (isEdit) {
        await update({ id: modalData?.id, ...data }).unwrap();

        openNotification({ message: NOTIFICATIONS.PACKAGE_UPDATED });

        onClose();
      } else {
        await create(data).unwrap();

        openNotification({ message: NOTIFICATIONS.PACKAGE_CREATED });

        onClose();
      }
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const isDisabled =
    !name?.toString()?.length ||
    !price?.toString()?.length ||
    !bonusCoinsPercent?.toString()?.length ||
    !expiryDays?.toString()?.length;

  const isLoading = isCreateLoading || isUpdateLoading;

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  return (
    <Modal
      title={`${isEdit ? "Edit" : "Add"} Package`}
      width={560}
      open={!!modalData}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer $column $gap={16}>
          <Controller
            name={"name"}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                label="Package name"
                required
                disabled={isLoading}
                {...field}
                fieldState={fieldState}
              />
            )}
          />

          <InputsGridContainer>
            <InputNumber
              label="Amount coins"
              value={amount}
              disabled
              min={0}
              step={0.001}
              precision={3}
            />

            <Controller
              name="price"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Price (KD)"
                  required
                  min={0}
                  step={0.001}
                  precision={3}
                  {...field}
                  disabled={isLoading}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="expiryDays"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Expiry (days)"
                  required
                  min={0}
                  precision={0}
                  {...field}
                  disabled={isLoading}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="bonusCoinsPercent"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label="Bonus (%)"
                  required
                  min={0}
                  precision={3}
                  {...field}
                  disabled={isLoading}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>

          <Controller
            name={"tag"}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                label="Tag"
                disabled={isLoading}
                {...field}
                fieldState={fieldState}
              />
            )}
          />
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $align={isMobile ? "flex-start" : "center"}
          $justify="space-between"
          $gap={isMobile ? 16 : 8}
          $margin="24px 0 0"
          $column={isMobile}
        >
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <Switch
                label="Activate package"
                checked={field.value}
                {...field}
                disabled={isLoading}
              />
            )}
          />

          <FlexContainer
            $column={isMobile}
            $fullwidth={isMobile}
            $align="center"
            $justify="center"
            $gap={8}
          >
            <Button.Base
              $fullWidth={isMobile}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancel
            </Button.Base>

            <Button.Base
              $fullWidth={isMobile}
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isDisabled}
            >
              Save
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </form>
    </Modal>
  );
};
