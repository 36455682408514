// Lib
import { FC, useState } from "react";
// Api
import {
  useExtendBalanceExpirationDateMutation,
  useTopUpCustomerBalanceMutation,
} from "rtkQuery/query/customersPaymentAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
// Types
import {
  CustomerBalanceResponseDto,
  CustomerProfileResponseDto,
} from "types/customers";
// Constants
import {
  MONTH_DAY_FUll_YEAR_DATE,
  NOTIFICATIONS,
  TABLE_MONTH_DAY_YEAR_TIME_DATE,
} from "consts";
// Helpers
import { dateTransform, fixedDigitsValue } from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { CalendarTodayIcon, CurrencyIcon } from "icons";
// Components
import { SummaryField } from "components";
import {
  ExtendBalanceExpiredDateModal,
  TopUpBalanceModal,
} from "components/Modals";
import { History } from "../History";
// Styled
import { ContentBox, SummaryItemsGrid } from "styled/Box";
import { Typography } from "styled/Typography";
import { Wrapper, MainInfoWrapper } from "../styled";

interface SummaryTabProps {
  isCustomerDataLoading: boolean;
  isCustomerBalanceLoading: boolean;
  customerData: CustomerProfileResponseDto;
  customerBalance: CustomerBalanceResponseDto;
}

export const SummaryTab: FC<SummaryTabProps> = ({
  isCustomerDataLoading,
  isCustomerBalanceLoading,
  customerData,
  customerBalance,
}) => {
  const { canBalanceGet, canTransactionsCreate } = usePermissions();

  const { openNotification } = useNotification();

  const [topUpBalanceModal, setTopUpBalanceModal] = useState<boolean>(false);
  const [extendBalanceExpiredDateModal, setExtendBalanceExpiredDateModal] =
    useState<boolean>(false);

  const [topUpBalance, { isLoading: isTopUpBalanceLoading }] =
    useTopUpCustomerBalanceMutation();

  const [extendExpiredDate, { isLoading: isExtendExpiredDateLoading }] =
    useExtendBalanceExpirationDateMutation();

  const handleTopUpBalance = async (data: {
    fmcCentsAmount: number;
    note: string;
    purpose: string;
  }) => {
    const { fmcCentsAmount, note, purpose } = data || {};

    try {
      await topUpBalance({
        customerId: customerData?.id,
        fmcCentsAmount,
        purpose,
        ...(note && { note }),
      }).unwrap();

      setTopUpBalanceModal(false);

      openNotification({
        message: NOTIFICATIONS.BALANCE_UPDATED,
      });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleExtendExpiredDays = async (data: {
    days: number;
    note: string;
  }) => {
    const { days, note } = data || {};

    try {
      await extendExpiredDate({
        customerId: customerData?.id,
        days,
        note,
      }).unwrap();

      setExtendBalanceExpiredDateModal(false);

      openNotification({
        message: NOTIFICATIONS.BALANCE_EXPIRED_DATE_UPDATED,
      });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  return (
    <>
      <Wrapper>
        <MainInfoWrapper $column $gap={16}>
          {canBalanceGet && (
            <ContentBox $column $gap={20}>
              <SummaryItemsGrid>
                <SummaryField
                  isLoading={isCustomerBalanceLoading}
                  label="Customer balance (FMC)"
                  Icon={<CurrencyIcon />}
                  value={fixedDigitsValue(customerBalance?.fmcCentsAmount, 3)}
                  button={
                    canTransactionsCreate && {
                      title: "Top up balance",
                      onClick: () => setTopUpBalanceModal(true),
                    }
                  }
                />

                <SummaryField
                  isLoading={isCustomerBalanceLoading}
                  label="Balance expired at"
                  Icon={<CalendarTodayIcon />}
                  value={dateTransform({
                    date: customerBalance?.expiresAt,
                    format: TABLE_MONTH_DAY_YEAR_TIME_DATE,
                  })}
                  button={
                    canTransactionsCreate && {
                      title: "Extend expired date",
                      onClick: () => setExtendBalanceExpiredDateModal(true),
                    }
                  }
                />
              </SummaryItemsGrid>
            </ContentBox>
          )}

          <ContentBox $column $gap={20}>
            <Typography.H2>Customer Informations</Typography.H2>

            <SummaryItemsGrid>
              <SummaryField
                isLoading={isCustomerDataLoading}
                label="First name"
                value={customerData?.firstName}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Last name"
                value={customerData?.lastName}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Gender"
                value={customerData?.gender}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Birth date"
                value={dateTransform({
                  date: customerData?.birthDate,
                  format: MONTH_DAY_FUll_YEAR_DATE,
                })}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Phone number"
                value={customerData?.phoneNumber}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="E-mail"
                value={customerData?.email}
              />
            </SummaryItemsGrid>
          </ContentBox>
        </MainInfoWrapper>

        <History
          isCustomerDataLoading={isCustomerDataLoading}
          customerData={customerData}
        />
      </Wrapper>

      <TopUpBalanceModal
        open={topUpBalanceModal}
        isLoading={isTopUpBalanceLoading}
        onSave={handleTopUpBalance}
        onClose={() => setTopUpBalanceModal(false)}
      />

      <ExtendBalanceExpiredDateModal
        open={extendBalanceExpiredDateModal}
        isLoading={isExtendExpiredDateLoading}
        expiresAt={customerBalance?.expiresAt}
        onSave={handleExtendExpiredDays}
        onClose={() => setExtendBalanceExpiredDateModal(false)}
      />
    </>
  );
};
