// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useCreateUserMutation,
  useGetWaitingUsersQuery,
  useLazyGetWaitingUsersQuery,
  useUpdateUserMutation,
  useUpdateWaitingUserMutation,
} from "rtkQuery/query/usersAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import { useGetPackagesQuery } from "rtkQuery/query/coinPackagesApi";
// Hooks
import {
  useExport,
  useNotification,
  usePermissions,
  useTable,
  useViewport,
} from "hooks";
// Types
import { TablePaginationConfig } from "antd";
import { FilterValue } from "antd/es/table/interface";
import { TableAction } from "types/common";
import { WaitingUserResponseDto, WaitingUsersFilterTypes } from "types/users";
import { ETable, FilterOption } from "types/tableFilters";
import type { UserActivateModalForm } from "./components";
// Constants
import { NOTIFICATIONS, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon, RightBurgerMenuIcon } from "icons";
// Components
import { DropDown, Export, Pagination, Table } from "components";
import { UserActivateModal } from "./components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

import {
  columns,
  getSelectLocationFilter,
  getSelectPackageFilter,
  waitingUsersFilters,
} from "./config";

export const WaitingList: FC = () => {
  const { canUsersCreate, canUsersUpdate } = usePermissions();

  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();

  const { downloadCSV } = useExport();

  const [createUser, { isLoading: isUserCreateLoading }] =
    useCreateUserMutation();
  const [updateUser, { isLoading: isUserUpdateLoading }] =
    useUpdateUserMutation();
  const [patchUser, { isLoading: isPatchUserLoading }] =
    useUpdateWaitingUserMutation();

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    //TODO: remove comment if BE is ready
    // search,
    selectedFilters,
    debouncedSearch,
    debouncedFiltersQuery,
    setPage,
    setLimit,
    //TODO: remove comment if BE is ready
    // setSearch,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.WaitingUsers });

  const {
    data: usersData,
    isFetching,
    error: usersError,
  } = useGetWaitingUsersQuery(
    {
      query: {
        ...(debouncedFiltersQuery && debouncedFiltersQuery),
        page,
        limit,
        sortBy: sortBy,
        sortingOrder,
        search: debouncedSearch,
      },
    },
    rtkQueryParams,
  );

  const [getExportWaitingUsersData, { isFetching: isExportLoading }] =
    useLazyGetWaitingUsersQuery();

  const {
    data: locations,
    isLoading: isLocationsLoading,
    error: locationsError,
  } = useGetLocationsQuery(null, rtkQueryParams);

  const {
    data: packages,
    isLoading: isPackagesLoading,
    error: packagesError,
  } = useGetPackagesQuery(null, rtkQueryParams);

  useEffect(() => {
    if (usersError) {
      errorHandler({ error: usersError, openNotification });
    }
  }, [usersError]);

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  useEffect(() => {
    if (packagesError) {
      errorHandler({ error: packagesError, openNotification });
    }
  }, [packagesError]);

  const [userModal, setUserModal] = useState<false | WaitingUserResponseDto>(
    false,
  );

  const [filters, setFilters] = useState<FilterOption[]>(() => {
    const filters = waitingUsersFilters;

    if (locations?.length) {
      filters.concat(getSelectLocationFilter(locations));
    }

    if (packages?.length) {
      filters.concat(getSelectPackageFilter(packages));
    }
    return filters;
  });

  useEffect(() => {
    if (!locations?.length) return;

    setFilters(prev => {
      if (prev.find(el => el.key === WaitingUsersFilterTypes.locationId)) {
        return prev;
      }

      return [...prev, getSelectLocationFilter(locations)];
    });
  }, [locations]);

  useEffect(() => {
    if (!packages?.length) return;

    setFilters(prev => {
      if (prev.find(el => el.key === WaitingUsersFilterTypes.package)) {
        return prev;
      }

      return [...prev, getSelectPackageFilter(packages)];
    });
  }, [packages]);

  const onUserSave = async (data: UserActivateModalForm) => {
    if (!userModal) {
      return;
    }

    try {
      const {
        firstName,
        lastName,
        roleName,
        email,
        password,
        phoneNumber,
        customerActivated,
      } = data || {};

      if (userModal?.userId) {
        await updateUser({
          id: userModal?.userId,
          firstName,
          lastName,
          roleName,
          email,
          phoneNumber,
          customerActivated,
        }).unwrap();
      } else {
        await createUser({
          firstName,
          lastName,
          roleName,
          email,
          password,
          customerActivated,
        }).unwrap();
      }

      await patchUser({ id: userModal.id });

      openNotification({ message: NOTIFICATIONS.USER_UPDATE });

      setUserModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      const data = await getExportWaitingUsersData({
        query: {
          ...(debouncedFiltersQuery && debouncedFiltersQuery),
          limit: usersData?.totalCount,
          sortBy: sortBy,
          sortingOrder,
          search: debouncedSearch,
        },
      }).unwrap();

      return data.items;
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const tableActions: TableAction[] = [
    {
      title: "Activate",
      Icon: null,
      type: "Grey",
      disabledIfFieldIsTrue: "addedAsUser",
      disabled: !canUsersUpdate || !canUsersCreate,
      onClick: (row: WaitingUserResponseDto) => setUserModal(row),
    },
  ];

  const actions = usersData?.items?.find(u => !u.addedAsUser)
    ? tableActions
    : undefined;

  const dropDownMenuItems = [
    {
      key: "1",
      label: "Export",
      onClick: () =>
        downloadCSV({
          fileName: "waiting_list",
          isDataLoading: false,
          columns: columns,
          getExportData: getExportData,
        }),
    },
  ];

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };
  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>Waiting List</Typography.H1>

            {isDesktop ? (
              <Export
                fileName="waiting_list"
                isLoading={isExportLoading}
                isDataLoading={
                  isFetching || isLocationsLoading || isPackagesLoading
                }
                isDisabled={!usersData?.totalCount}
                columns={columns}
                getExportData={getExportData}
              />
            ) : (
              <DropDown items={dropDownMenuItems} trigger={["click"]}>
                <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
              </DropDown>
            )}
          </FlexContainer>

          <Table
            isLoading={isFetching || isLocationsLoading || isPackagesLoading}
            dataSource={usersData?.items || []}
            columns={columns}
            actions={actions}
            withPagination={!isDesktop}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No users to show.",
              description: "Try changing sections or change the filters",
            }}
            header={{
              //TODO: remove comment if BE is ready
              // search: {
              //   placeholder: "Search first name, last name, email or phone",
              //   value: search,
              //   setValue: setSearch,
              // },
              totalCount: usersData?.totalCount,
              filter: {
                filters: filters,
                selected: selectedFilters || [],
                setValue: handleSetTableFilterValues,
              },
            }}
            onChange={handleTableChange}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          totalItems={usersData?.totalCount}
        />
      </PageWrapper>

      <UserActivateModal
        userData={userModal}
        isLoading={
          isUserCreateLoading || isUserUpdateLoading || isPatchUserLoading
        }
        onSave={onUserSave}
        onClose={() => setUserModal(false)}
      />
    </>
  );
};
