import { DEFAULT_POLYGON_COLOR } from "Layers/config";
import styled, { keyframes } from "styled-components";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  z-index: 10000;
  display: flex;
  flex-direction: column;
  position: relative;

  opacity: 0;
  animation: ${fadeInAnimation} 1s ease-in-out forwards;
`;

export const Title = styled(Typography.Title)`
  color: ${DEFAULT_POLYGON_COLOR};
  position: absolute;
  top: -1rem;
  width: max-content;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

export const IconContainer = styled(FlexContainer)`
  width: 3rem;
  height: 3rem;
  position: relative;
  bottom: -6px;
`;
