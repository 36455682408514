import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const ManageAccountsIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    {...props}
  >
    <path
      d="M8.67 9.02C8.45 9.01 8.23 9 8 9C5.58 9 3.32 9.67 1.39 10.82C0.51 11.34 0 12.32 0 13.35V15C0 15.55 0.45 16 1 16H9.26C8.47 14.87 8 13.49 8 12C8 10.93 8.25 9.93 8.67 9.02Z"
      fill={fill || "#646965"}
    />
    <path
      d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
      fill={fill || "#646965"}
    />
    <path
      d="M18.75 12C18.75 11.78 18.72 11.58 18.69 11.37L19.53 10.64C19.71 10.48 19.75 10.22 19.63 10.01L19.04 8.99001C18.92 8.78001 18.67 8.69001 18.45 8.77001L17.39 9.13001C17.07 8.86001 16.71 8.65001 16.31 8.50001L16.09 7.41001C16.04 7.18001 15.84 7.01001 15.6 7.01001H14.42C14.18 7.01001 13.98 7.18001 13.93 7.41001L13.71 8.50001C13.31 8.65001 12.95 8.86001 12.63 9.13001L11.57 8.77001C11.34 8.69001 11.1 8.79001 10.98 8.99001L10.39 10.01C10.27 10.22 10.31 10.48 10.49 10.64L11.33 11.37C11.3 11.58 11.27 11.78 11.27 12C11.27 12.22 11.3 12.42 11.33 12.63L10.49 13.36C10.31 13.52 10.27 13.78 10.39 13.99L10.98 15.01C11.1 15.22 11.35 15.31 11.57 15.23L12.63 14.87C12.95 15.14 13.31 15.35 13.71 15.5L13.93 16.59C13.98 16.82 14.18 16.99 14.42 16.99H15.6C15.84 16.99 16.04 16.82 16.09 16.59L16.31 15.5C16.71 15.35 17.07 15.14 17.39 14.87L18.45 15.23C18.68 15.31 18.92 15.21 19.04 15.01L19.63 13.99C19.75 13.78 19.71 13.52 19.53 13.36L18.69 12.63C18.72 12.42 18.75 12.22 18.75 12ZM15 14C13.9 14 13 13.1 13 12C13 10.9 13.9 10 15 10C16.1 10 17 10.9 17 12C17 13.1 16.1 14 15 14Z"
      fill={fill || "#646965"}
    />
  </svg>
);
