import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const ordersUrlBuilder = {
  getOrders: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(endpoints.orders, query);
  },

  getOrder: ({ id }: { id: string }): string => {
    return `${endpoints.orders}/${id}`;
  },
  getTalabatOrder: ({ id }: { id: string }): string => {
    return `${endpoints.orders}/external/${id}`;
  },
  getScheduledOrders: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(`${endpoints.orders}/scheduled`, query);
  },
  getScheduledOrder: ({ id }: { id: string }): string => {
    return `${endpoints.orders}/scheduled/${id}`;
  },
  getTalabatOrders: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(`${endpoints.orders}/external`, query);
  },
};
