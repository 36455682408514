// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useDeleteLocationProvidersMutation,
  useGetLocationProvidersQuery,
  useGetLocationsQuery,
} from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification } from "hooks";
// Types
import { TableAction } from "types/common";
import { TableData } from "./types";
import { ConnectModalData } from "../ConnnectModal/types";
// Theme
import { theme } from "theme";
// Constants
import { rtkQueryParams } from "consts";
// Helpers
import { getLocationsProviderLists } from "./helpers";
import { DEFAULT_SETTINGS } from "../ConnnectModal/helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PersonIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { Table } from "components";
import { ConfirmDialog } from "components/Modals";
import { ConnnectModal } from "../ConnnectModal";
// Styled
import { Button } from "styled/Buttons";

import { columns } from "./config";

export const Locations: FC = () => {
  const { openNotification } = useNotification();

  const [connectModal, setConnectModal] = useState<ConnectModalData | boolean>(
    false,
  );

  const [confirmModal, setConfirmModal] = useState<TableData | false>(false);

  const [remove, { isLoading: isDeleteLoading }] =
    useDeleteLocationProvidersMutation();

  const { data: locationsData, error: locationsError } = useGetLocationsQuery(
    null,
    rtkQueryParams,
  );

  const { data: providersData } = useGetLocationProvidersQuery(
    null,
    rtkQueryParams,
  );

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  const handleOpenConnectModal = () => {
    setConnectModal(true);
  };

  const handleCloseConnectModal = () => {
    if (isDeleteLoading) {
      return;
    }
    setConnectModal(false);
  };

  const onCancel = () => {
    if (!confirmModal) return;

    setConfirmModal(false);
  };

  const handleConfirmDelete = async () => {
    if (!confirmModal) return;

    try {
      await remove({ id: confirmModal.id }).unwrap();
    } catch (error) {
      errorHandler({ error, openNotification });
    }

    openNotification({
      message: `Connection configuration successfuly deleted`,
    });

    setConfirmModal(false);
  };

  const handleOpenEditModal = (id: string) => {
    const data = providersData.find(el => el.id === id);

    const settings = data?.providers.reduce((acc, el) => {
      acc[el.provider] = {
        locationId: el.locationId,
        location: el.location,
        isActive: el.isActive,
      };
      return acc;
    }, DEFAULT_SETTINGS);
    setConnectModal({ id, settings });
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      type: "Grey",
      onClick: (row: TableData) => handleOpenEditModal(row.id),
    },
    {
      title: "",
      Icon: TrashIcon,
      type: "Grey",
      onClick: (row: TableData) => setConfirmModal(row),
    },
  ];

  const tableData: TableData[] = providersData?.reduce((acc, el) => {
    const data = { id: el.id, active: "" };

    el.providers.forEach(el => {
      data[el.provider] = {
        location: el.location,
        locationId: el.location,
      };

      if (el.isActive) {
        data.active = el.location;
      }
    });

    acc.push(data);

    return acc;
  }, []);

  const locationsProviderLists = getLocationsProviderLists(
    locationsData,
    providersData,
    connectModal,
  );

  return (
    <>
      <Table
        actions={actions}
        columns={columns}
        dataSource={tableData}
        header={{
          totalCount: tableData?.length,
          suffix: (
            <Button.Base
              type="primary"
              icon={<PlusIcon fill={theme.color.white} />}
              onClick={handleOpenConnectModal}
            >
              Add Connection
            </Button.Base>
          ),
        }}
      />

      <ConnnectModal
        open={!!connectModal}
        data={connectModal}
        locationsProviderLists={locationsProviderLists}
        onClose={handleCloseConnectModal}
      />

      <ConfirmDialog
        isLoading={isDeleteLoading}
        open={!!confirmModal}
        Icon={PersonIcon}
        message={
          confirmModal &&
          `Connection "${confirmModal.deliverect.location}" - "${confirmModal.loop.location}" will be deleted`
        }
        description="Are you sure to continue this action?"
        onCancel={onCancel}
        firstCTAButton={{
          title: "Accept",
          status: "danger",
          loading: isDeleteLoading,
          onClick: handleConfirmDelete,
        }}
      />
    </>
  );
};
