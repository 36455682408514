import { date, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DiscountType } from "types/coupons";

const schema = object().shape({
  startDate: date()
    .nullable()
    .required("Date is required")
    .test(
      "from-to",
      "The start date cannot be later than the end date",
      function (value) {
        const { expiryDate } = this.parent;
        return (
          !expiryDate ||
          (value !== null && value && value.getTime() < expiryDate.getTime())
        );
      },
    ),
  expiryDate: date()
    .nullable()
    .required("Date is required")
    .test(
      "from-to",
      "The end date cannot be earlier than the start date",
      function (value) {
        const { startDate } = this.parent;
        return (
          !startDate ||
          (value !== null && value && value.getTime() > startDate.getTime())
        );
      },
    ),
  discountOff: number()
    .required("Discount count is required")
    .test(
      "max-100",
      "Discount percentage must be less than or equal to 100",
      function (value) {
        const { discountType } = this.parent;
        return (
          discountType !== DiscountType.Percentage ||
          (value !== null && value <= 100)
        );
      },
    ),
  discountType: string().required("Discount type is required"),
  couponType: string().required("Coupon type is required"),
});

export const resolver = yupResolver(schema);
