// Lib
import { Children, cloneElement, FC, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { theme } from "theme";
import { styled } from "styled-components";
import { FlexContainer } from "styled/Box";
import { DragIndicatorIcon } from "icons";

// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled

const StyledRow = styled.tr<{ $selected: boolean }>`
  td {
    background: ${({ $selected }) =>
      $selected ? `#e9ede9 !important` : `${theme.color.white} !important`};
  }
`;

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

export const DragableRow: FC = ({ children, ...props }: RowProps) => {
  const [selected, setSelected] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,

    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  return (
    <StyledRow
      $selected={selected}
      onMouseOver={() => setSelected(true)}
      onMouseOut={() => setSelected(false)}
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {Children.map(children, child => {
        if ((child as React.ReactElement).key === "sort") {
          return cloneElement(child as React.ReactElement, {
            children: (
              <FlexContainer
                $margin="0 -18px 0 0"
                $align="center"
                $justify="flex-end"
                $width="24px"
              >
                <FlexContainer
                  ref={setActivatorNodeRef}
                  {...listeners}
                  $cursor="move"
                >
                  <DragIndicatorIcon />
                </FlexContainer>
              </FlexContainer>
            ),
          });
        }
        return child;
      })}
    </StyledRow>
  );
};
