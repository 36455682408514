// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useDeletePackageRuleMutation,
  useGetPackageRulesQuery,
} from "rtkQuery/query/coinPackagesApi";
// Hooks
import { useNotification, usePermissions, useViewport } from "hooks";
// Types
import { TableAction } from "types/common";
import { PackageRulesResponseDto } from "types/packages";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { Table } from "components";
import { ConfirmDialog } from "components/Modals";
import { RulesModal } from "../RulesModal";
// Styled
import { Button } from "styled/Buttons";

import { columns } from "./config";

export const Rules: FC = () => {
  const { isMobile } = useViewport();
  const { canPackagesCreate, canPackagesUpdate } = usePermissions();

  const { openNotification } = useNotification();

  const [deleteRule, { isLoading }] = useDeletePackageRuleMutation();

  const { data, isFetching, error } = useGetPackageRulesQuery(
    null,
    rtkQueryParams,
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const [rulesModal, setRulesModal] = useState<
    boolean | PackageRulesResponseDto
  >(false);

  const [rulesDeleteDialog, setRulesDeleteDialog] = useState<boolean | string>(
    false,
  );

  const onConfirmDelete = async () => {
    if (typeof rulesDeleteDialog !== "string") {
      return;
    }
    try {
      await deleteRule({ id: rulesDeleteDialog }).unwrap();

      openNotification({
        message: NOTIFICATIONS.PACKAGE_RULE_DELETED,
      });

      setRulesDeleteDialog(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      disabled: !canPackagesUpdate,
      type: "Grey",
      onClick: row => setRulesModal(row),
    },
    {
      title: "",
      Icon: TrashIcon,
      disabled: !canPackagesUpdate,
      type: "Grey",
      onClick: row => setRulesDeleteDialog(row?.id),
    },
  ];

  return (
    <>
      <Table
        header={{
          leftTitle: "Bonus coin",
          suffix:
            canPackagesCreate && !isMobile ? (
              <Button.Base
                type="primary"
                icon={<PlusIcon fill={theme.color.white} />}
                onClick={() => setRulesModal(true)}
              >
                Add Rule
              </Button.Base>
            ) : (
              <Button.SquaredIcon
                type="primary"
                icon={<PlusIcon fill={theme.color.white} />}
                onClick={() => setRulesModal(true)}
              />
            ),
        }}
        isLoading={isFetching}
        columns={columns}
        actions={actions}
        dataSource={data}
      />

      <RulesModal
        modalData={rulesModal}
        rules={data}
        onClose={() => setRulesModal(false)}
      />

      <ConfirmDialog
        open={!!rulesDeleteDialog}
        isLoading={isLoading}
        Icon={TrashIcon}
        message="Rule will be deleted"
        description="Are you sure you want to delete this rule?"
        onCancel={() => setRulesDeleteDialog(false)}
        firstCTAButton={{
          title: "Delete Rule",
          status: "danger",
          loading: isLoading,
          onClick: () => onConfirmDelete(),
        }}
      />
    </>
  );
};
