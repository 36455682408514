// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  GetTransactionsResponseDto,
  RefundTransactionRequestDto,
} from "types/transactions";
// Helpers
import { transactionsUrlBuilder } from "./urlBuilder/transactions";
// Utils
import { paymentQuery } from "utils/baseQuery";
import { transactionsMapper } from "utils/mappers";

export const transactionsAPI = createApi({
  reducerPath: "transactionsAPI",
  baseQuery: paymentQuery(),
  tagTypes: ["transactions"],

  endpoints: build => ({
    getTransactions: build.query<
      GetTransactionsResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => {
        return {
          url: transactionsUrlBuilder.getTransactions({ query }),
          method: "GET",
        };
      },
      transformResponse: (response: GetTransactionsResponseDto) =>
        transactionsMapper(response),
      providesTags: ["transactions"],
    }),
    refundTransaction: build.mutation<void, RefundTransactionRequestDto>({
      query: ({ id, customerId, ...data }) => ({
        url: transactionsUrlBuilder.refundTransaction({ id, customerId }),
        method: "POST",
        data,
      }),
      invalidatesTags: ["transactions"],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
  useRefundTransactionMutation,
} = transactionsAPI;
