import { DEFAULT_POLYGON_COLOR } from "../config";
import styled from "styled-components";
import { Typography } from "styled/Typography";

export const Title = styled(Typography.Title)<{ $isMounted: boolean }>`
  color: ${DEFAULT_POLYGON_COLOR};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;

  opacity: ${({ $isMounted }) => ($isMounted ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;
