// Lib
import { FC, memo, useCallback, useEffect, useMemo } from "react";
// Api
import {
  useGetCustomerCouponsQuery,
  useLazyGetCustomerCouponsQuery,
} from "rtkQuery/query/customersAPI";
// Hooks
import { useExport, useNotification, useTable, useViewport } from "hooks";
// Types
import { ETable } from "types/tableFilters";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon, RightBurgerMenuIcon } from "icons";
// Components
import { DropDown, Export, Modal, Pagination, Table } from "components";
// Styled
import { Button } from "styled/Buttons";
import { FlexContainer } from "styled/Box";

import { columns } from "./config";

interface UsedCouponsModalProps {
  usedCustomerCoupons: { id: string; name: string };
  setUsedCustomerCoupons: (value: { id: string; name: string }) => void;
}

export const UsedCouponsModal: FC<UsedCouponsModalProps> = memo(
  ({ usedCustomerCoupons, setUsedCustomerCoupons }) => {
    const { openNotification } = useNotification();
    const { isDesktop } = useViewport();
    const { downloadCSV } = useExport();

    const { page, limit, setPage, setLimit } = useTable({
      name: ETable.CustomerCouponsUsed,
      removeQueryParams: true,
    });

    const [getExportCouponsData, { isLoading: isExportLoading }] =
      useLazyGetCustomerCouponsQuery();

    const { data: tableData, isFetching: isTableFetching } =
      useGetCustomerCouponsQuery(
        {
          id: usedCustomerCoupons.id,
          query: { page, limit },
        },
        { refetchOnMountOrArgChange: true, skip: !usedCustomerCoupons.id },
      );

    useEffect(() => {
      if (!usedCustomerCoupons?.id) {
        setLimit(10);
      }
    }, [usedCustomerCoupons]);

    const getExportData = async (): Promise<Record<string, unknown>[]> => {
      try {
        const data = await getExportCouponsData({
          id: usedCustomerCoupons?.id,
          query: {
            limit: 1000,
          },
        }).unwrap();

        return data.items;
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    };

    const dropDownMenuItems = useMemo(
      () => [
        {
          key: "1",
          label: "Export",
          onClick: () =>
            downloadCSV({
              fileName: `${usedCustomerCoupons.name}_used_coupons_${usedCustomerCoupons?.id}`,
              isDataLoading: isExportLoading,
              columns: columns,
              getExportData: getExportData,
            }),
        },
      ],
      [usedCustomerCoupons],
    );

    const handleClose = useCallback(() => {
      setUsedCustomerCoupons({ id: "", name: "" });
    }, []);

    return (
      <Modal
        width={900}
        open={!!usedCustomerCoupons?.id}
        title={`${usedCustomerCoupons.name} used coupons`}
        onClose={handleClose}
      >
        <FlexContainer $fullwidth $column $gap={16}>
          <Table
            isLoading={isTableFetching}
            header={{
              totalCount: 6,
              suffix: isDesktop ? (
                <Export
                  type="Base"
                  fileName={`${usedCustomerCoupons.name}_used_coupons_${usedCustomerCoupons?.id}`}
                  isLoading={isExportLoading}
                  isDataLoading={isTableFetching}
                  isDisabled={!tableData?.totalCount}
                  columns={columns}
                  getExportData={getExportData}
                />
              ) : (
                <DropDown items={dropDownMenuItems} trigger={["click"]}>
                  <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
                </DropDown>
              ),
            }}
            columns={columns}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No coupons to show.",
            }}
            dataSource={tableData?.items || []}
          />

          <Pagination
            limit={limit}
            page={page}
            setLimit={setLimit}
            setPage={setPage}
            showSizeChanger={false}
            totalItems={tableData?.totalCount}
          />
        </FlexContainer>
      </Modal>
    );
  },
);

UsedCouponsModal.displayName = "UsedCouponsModal";
