import styled from "styled-components";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";

export const Title = styled(Typography.H2)`
  padding-left: 12px;
`;

export const InputsContainer = styled.div<{ $bordered?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: 8px 12px;

  ${({ $bordered }) => $bordered && `border-top: 1px solid #E0E0E0;`}
`;

export const EditButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  min-width: 100px;
`;

export const TimeSelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 400px;
  min-width: 209px;
`;

export const HideButtonContainer = styled.div`
  padding: 0px 12px 8px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HideButton = styled(Button.Notification)<{
  $collapsed: boolean;
  disabled: boolean;
}>`
  transition: all 0.25s;

  svg {
    ${({ $collapsed }) => !$collapsed && `transform: rotate(180deg);`};
    path {
      fill: ${({ disabled }) =>
        disabled ? "rgba(0, 0, 0, 0.25);" : "#3662b6;"};

      :hover {
        fill: #124ab7;
      }
    }
  }
`;

export const SlotFieldsContainer = styled.div<{
  $items: number;
  $transitions: boolean;
}>`
  transition: ${({ $transitions }) => $transitions && "max-height 0.25s"};
  max-height: ${({ $items }) => ($items ? `${$items * 57 - 1}px` : "auto")};
  overflow: hidden;
  overflow-x: auto;
`;
