import { GetLocationResponseDto } from "types/locations";
import { PackageResponseDto } from "types/packages";
import {
  WaitingUserStatus,
  WaitingUserStatusLabel,
  WaitingUsersFilterNames,
  WaitingUsersFilterTypes,
} from "types/users";
import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";

import { theme } from "theme";

import { TABLE_DATE } from "consts";

import { dateTransform } from "helpers/dataHelpers";

import { CallBlackIcon, TextFieldsIcon } from "icons";

import { Badge } from "components";

export const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Status",
    dataIndex: "addedAsUser",
    key: "addedAsUser",
    render: (active: boolean) => (
      <Badge
        title={active ? "Active" : "Inactive"}
        bgColor={active ? theme.color.status.success : theme.color.failed}
        textColor={theme.color.white}
      />
    ),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Referal Code",
    dataIndex: "referralCode",
    key: "referralCode",
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Selected package",
    dataIndex: "selectedPackage",
    key: "selectedPackage",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Area",
    dataIndex: "area",
    key: "area",
  },
  {
    title: "Share",
    dataIndex: "shares",
    key: "shares",
  },
  {
    title: "Refferal",
    dataIndex: "referrals",
    key: "referrals",
  },
  {
    title: "Registered at",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];

export const waitingUsersStatusDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: WaitingUserStatus.ACTIVE,
    label: WaitingUserStatusLabel[WaitingUserStatus.ACTIVE],
  },
  {
    type: WaitingUserStatus.INACTIVE,
    label: WaitingUserStatusLabel[WaitingUserStatus.INACTIVE],
  },
];

export const waitingUsersFilters: FilterOption[] = [
  {
    key: WaitingUsersFilterTypes.email,
    icon: <TextFieldsIcon />,
    label: WaitingUsersFilterNames[WaitingUsersFilterTypes.email],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: WaitingUsersFilterTypes.phoneNumber,
    icon: <CallBlackIcon />,
    label: WaitingUsersFilterNames[WaitingUsersFilterTypes.phoneNumber],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: WaitingUsersFilterTypes.city,
    icon: <TextFieldsIcon />,
    label: WaitingUsersFilterNames[WaitingUsersFilterTypes.city],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: WaitingUsersFilterTypes.area,
    icon: <TextFieldsIcon />,
    label: WaitingUsersFilterNames[WaitingUsersFilterTypes.area],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: WaitingUsersFilterTypes.activated,
    label: WaitingUsersFilterNames[WaitingUsersFilterTypes.activated],
    field: FilterField.SELECT,
    options: waitingUsersStatusDropdownFields,
  },
];

export const getSelectPackageFilter = (
  packages: PackageResponseDto[],
): FilterOption => {
  return {
    key: WaitingUsersFilterTypes.package,
    label: WaitingUsersFilterNames[WaitingUsersFilterTypes.package],
    field: FilterField.SELECT,
    options: packages.map(({ name }) => ({
      type: name,
      label: name,
    })),
  };
};

export const getSelectLocationFilter = (
  locations: GetLocationResponseDto[],
): FilterOption => {
  return {
    key: WaitingUsersFilterTypes.locationId,
    label: WaitingUsersFilterNames[WaitingUsersFilterTypes.locationId],
    field: FilterField.SELECT,
    options: locations.map(({ id, name }) => ({
      type: id,
      label: name,
    })),
  };
};
