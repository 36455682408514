export enum DeliveryProviders {
  deliverect = "deliverect",
  loop = "loop",
}

export enum DeliveryProviderLabels {
  deliverect = "Deliverect",
  loop = "Loop",
}

export type LocationProviderData = {
  locationId: string;
  location: string;
  provider: DeliveryProviders;
  isActive: boolean;
};

export type GetLocationProvidersResponseDto = {
  id: string;
  providers: LocationProviderData[];
};

export type CreateLocationProvidersRequestDto = {
  providers: LocationProviderData[];
};

export type UpdateLocationProvidersRequestDto = {
  id: string;
  providers: LocationProviderData[];
};
