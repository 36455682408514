export enum Permission {
  /* Dashboard */
  StatisticsGet = "statistics.get",

  /* Orders section */
  OrdersGet = "orders.get",
  OrdersCreate = "orders.create",

  /* Users section */
  UsersCreate = "users.create",
  UserAgentOnlyCreate = "user-agent-role-only.create",
  UsersGet = "users.get",
  UsersUpdate = "users.update",
  UsersDelete = "users.delete",

  /* Roles section */
  PermissionsGet = "permissions.get",
  RolesGet = "roles.get",
  RolesCreate = "roles.create",
  RolesUpdate = "roles.update",
  RolesDelete = "roles.delete",
  SuperAdminManagement = "super-admin-management",

  /* Transactions section */
  TransactionsCreate = "transactions.create",
  TransactionsGet = "transactions.get",

  /* Customers section */
  CustomerBase = "customer.base",
  CustomersVIPStatus = "customer-vip-status.update",
  CustomersGet = "customers.get",
  CustomersActivate = "customers.activate",
  BalanceGet = "balance.get",

  /* Delivery areas section */
  LocationsUpdate = "locations.update",
  LocationsGet = "locations.get",
  LocationsMenuGet = "locations-menu.get",

  /* Coin Packages section */
  PackagesGet = "packages.get",
  PackagesCreate = "packages.create",
  PackagesUpdate = "packages.update",

  /* Coupons section */
  CouponsGet = "coupons.get",
  CouponsCreate = "coupons.create",
  CouponsUpdate = "coupons.update",
  CouponsDelete = "coupons.delete",

  /* Settings section */
  WaitingListUpdate = "waiting-list-settings.update",
  ExchangeRateUpdate = "exchange-rate.update",
  BalanceExpirationUpdate = "balance-expiration.update",
  BalanceExpirationAgentLimitUpdate = "extend-balance-expiration-agent-limit.update",
  BalanceExpirationManagerLimitUpdate = "extend-balance-expiration-manager-limit.update",
  RefundAddAgentLimitUpdate = "refund-add-limit-agent.update",
  RefundAddManagerLimitUpdate = "refund-add-limit-manager.update",
  SettingsGet = "settings.get",
  SettingsUpdate = "settings.update",

  /* SettingsPurpose */
  SettingsPurposeGet = "settings-purpose.get",
  SettingsPurposeCreate = "settings-purpose.create",
  SettingsPurposeUpdate = "settings-purpose.update",
  SettingsPurposeDelete = "settings-purpose.delete",

  /* SettingsTimeSlots */
  SettingsTimeSlotsGet = "settings-time-slot.get",
  SettingsTimeSlotsCreate = "settings-time-slot.create",
  SettingsTimeSlotsUpdate = "settings-time-slot.update",
  SettingsTimeSlotsDelete = "settings-time-slot.delete",

  /* SettingsCashPayment */
  SettingsPaymentsGet = "settings-payment.get",
  SettingsPaymentsUpdate = "settings-payment.update",

  /* SettingsReferrals */
  SettingsReferralsGet = "settings-referrals.get",
  SettingsReferralsUpdate = "settings-referrals.update",

  /* SettingsScheduleOrder */
  SettingsScheduleOrderGet = "settings-schedule-order.get",
  SettingsScheduleOrderUpdate = "settings-schedule-order.update",

  /* SettingsCoupons */
  SettingsCouponsGet = "settings-coupons.get",
  SettingsCouponsUpdate = "settings-coupons.update",

  /* SettingsMenuGrid */
  SettingsMenuGridGet = "settings-menu-grid.get",
  SettingsMenuGridUpdate = "settings-menu-grid.update",

  /* other */
  EmployeesGet = "employees.get",
  EmployeesCreate = "employees.create",
  InvitationsCreate = "invitations.create",
  InvitationsGet = "invitations.get",

  DeliveriesAllOptions = "deliveries.all-options",

  LocationProvidersGet = "location-provider-management.get",
}

export const initialPermissions = {
  /* Orders */
  canOrdersGet: false,
  canOrdersCreate: false,

  /* Role */
  isUserAgent: false,
  isUserManager: false,

  isSuperAdminManagement: false,

  /* Dashboard */
  canStatisticsGet: false,

  /* Users section */
  canUsersCreate: false,
  canUserAgentOnlyCreate: false,
  canUsersGet: false,
  canUsersUpdate: false,
  canUsersDelete: false,

  /* Roles section */
  canPermissionsGet: false,
  canRolesGet: false,
  canRolesCreate: false,
  canRolesUpdate: false,
  canRolesDelete: false,

  /* Transactions section */
  canTransactionsCreate: false,
  canTransactionsGet: false,

  /* Customers section */
  canCustomerBase: false,
  canCustomerVIPStatusUpdate: false,
  canCustomersGet: false,
  canCustomersActivate: false,
  canBalanceGet: false,

  /* Delivery areas section */
  canLocationsUpdate: false,
  canLocationsGet: false,
  canLocationsMenuGet: false,

  /* Coin packages section */
  canPackagesGet: false,
  canPackagesCreate: false,
  canPackagesUpdate: false,

  /* Coupons section */
  canCouponsGet: false,
  canCouponsCreate: false,
  canCouponsUpdate: false,
  canCouponsDelete: false,

  /* Settings section */
  canUpdateWaitingListSettings: false,
  canExchangeRateUpdate: false,
  canBalanceExpirationUpdate: false,
  canSettingsGet: false,
  canSettingsUpdate: false,
  сanPurposeGet: false,
  canPurposeCreate: false,
  canPurposeUpdate: false,
  canPurposeDelete: false,
  canTimeSlotsGet: false,
  canTimeSlotsCreate: false,
  canTimeSlotsUpdate: false,
  canTimeSlotsDelete: false,
  canPaymentSettingsGet: false,
  canPaymentSettingsUpdate: false,
  canReferralsSettingsGet: false,
  canReferralsSettingsUpdate: false,
  canScheduleOrderSettingsGet: false,
  canScheduleOrderSettingsUpdate: false,
  canCouponsSettingsGet: false,
  canCouponsSettingsUpdate: false,
  canMenuGridSettingsGet: false,
  canMenuGridSettingsUpdate: false,
  isBalanceExpirationAgentLimitUpdate: false,
  isBalanceExpirationManagerLimitUpdate: false,
  isRefundAddAgentLimitUpdate: false,
  isAddManagerLimitUpdate: false,

  /* other */
  canEmployeesGet: false,
  canEmployeesCreate: false,
  canInvitationsCreate: false,
  canInvitationsGet: false,

  canDeliveriesAllOptions: false,

  canGetLocationProviders: false,
};

export const permissionLabel: Record<Permission, string> = {
  [Permission.SuperAdminManagement]: "Super Admin Management",
  [Permission.WaitingListUpdate]: "Edit Waiting List Settings",
  [Permission.BalanceExpirationUpdate]: "Edit Expiration balance",
  [Permission.BalanceGet]: "Check Expiration balance",
  [Permission.CustomerBase]: "Check Base Сustomer information",
  [Permission.CustomersActivate]: "Activate Customers",
  [Permission.CustomersVIPStatus]: "Edit Customers VIP status",
  [Permission.CustomersGet]: "Check Customers",
  [Permission.DeliveriesAllOptions]: "Create Deliveries",
  [Permission.EmployeesCreate]: "Create Employees",
  [Permission.EmployeesGet]: "Check Employees",
  [Permission.ExchangeRateUpdate]: "Edit Exchange rate",
  [Permission.BalanceExpirationAgentLimitUpdate]:
    "Limit extend expiration days (Agent role)",
  [Permission.BalanceExpirationManagerLimitUpdate]:
    "Limit extend expiration days (Manager role)",
  [Permission.InvitationsCreate]: "Create Invitations",
  [Permission.InvitationsGet]: "Check Invitations",
  [Permission.LocationsMenuGet]: "Check Zones menu",
  [Permission.LocationsGet]: "Check Zones",
  [Permission.LocationsUpdate]: "Edit Zones",
  [Permission.OrdersGet]: "Check Orders",
  [Permission.OrdersCreate]: "Create Orders",
  [Permission.PackagesGet]: "Check Coin Packages",
  [Permission.PackagesCreate]: "Create Coin Packages",
  [Permission.PackagesUpdate]: "Edit Coin Packages",
  [Permission.PermissionsGet]: "Check Permissions",
  [Permission.RefundAddAgentLimitUpdate]:
    "Limit Adding/Refunding FMCoins (Agent role)",
  [Permission.RefundAddManagerLimitUpdate]:
    "Limit Adding/Refunding FMCoins (Manager role)",
  [Permission.RolesCreate]: "Create Roles",
  [Permission.RolesDelete]: "Delete Roles",
  [Permission.RolesGet]: "Check Roles",
  [Permission.RolesUpdate]: "Edit Roles",
  [Permission.SettingsGet]: "Check Settings",
  [Permission.SettingsUpdate]: "Edit Settings",
  [Permission.SettingsPurposeGet]: "Check Purpose settings",
  [Permission.SettingsPurposeCreate]: "Create Purpose settings",
  [Permission.SettingsPurposeUpdate]: "Edit Purpose settings",
  [Permission.SettingsPurposeDelete]: "Delete Purpose settings",
  [Permission.SettingsTimeSlotsGet]: "Check Time Slots settings",
  [Permission.SettingsTimeSlotsCreate]: "Create Time Slots settings",
  [Permission.SettingsTimeSlotsUpdate]: "Edit Time Slots settings",
  [Permission.SettingsTimeSlotsDelete]: "Delete Time Slots settings",
  [Permission.SettingsPaymentsGet]: "Check Payment settings",
  [Permission.SettingsPaymentsUpdate]: "Edit Payment settings",
  [Permission.SettingsReferralsGet]: "Check Referrals settings",
  [Permission.SettingsReferralsUpdate]: "Edit Referrals settings",
  [Permission.SettingsScheduleOrderGet]: "Check Schedule Order settings",
  [Permission.SettingsScheduleOrderUpdate]: "Edit Schedule Order settings",
  [Permission.SettingsCouponsGet]: "Check Coupons settings",
  [Permission.SettingsCouponsUpdate]: "Edit Coupons settings",
  [Permission.SettingsMenuGridGet]: "Check Menu Grid settings",
  [Permission.SettingsMenuGridUpdate]: "Edit Menu Grid settings",
  [Permission.StatisticsGet]: "Check Statistics",
  [Permission.TransactionsCreate]: "Create Transactions",
  [Permission.TransactionsGet]: "Check Transactions",
  [Permission.UserAgentOnlyCreate]: "Create only Agent users",
  [Permission.UsersCreate]: "Create Users",
  [Permission.UsersDelete]: "Delete Users",
  [Permission.UsersGet]: "Check Users",
  [Permission.UsersUpdate]: "Edit Users",
  [Permission.CouponsGet]: "Check Coupons",
  [Permission.CouponsCreate]: "Create Coupons",
  [Permission.CouponsUpdate]: "Edit Coupons",
  [Permission.CouponsDelete]: "Delete Coupons",
  [Permission.LocationProvidersGet]: "Location Providers management",
};
