import dayjs from "dayjs";
import { CouponType, DiscountType } from "types/coupons";
import { AllPaymentTypes, OrderPaymentType } from "types/orders";

export type OrderCouponForm = {
  name: string;
  discountOff: number;
  discountType: DiscountType | null;
  startDate: dayjs.Dayjs | null;
  expiryDate: dayjs.Dayjs | null;
  numberOfUses: number;
  isActive: boolean;
  orderPaymentType: OrderPaymentType | AllPaymentTypes | null;
  minimumOrderFiatCentsPrice: number;
  minimumOrderFmcCentsPrice: number;
  allowMultipleUsage: boolean;
  couponType: CouponType;
  numberOfCouponsToGenerate: number;
};

export type TopUpCouponForm = {
  name: string;
  discountOff: number;
  discountType: DiscountType | null;
  startDate: dayjs.Dayjs | null;
  expiryDate: dayjs.Dayjs | null;
  numberOfUses: number;
  numberOfUniqueUses: number;
  isActive: boolean;
  orderPaymentType: OrderPaymentType | AllPaymentTypes | null;
  minimumOrderFiatCentsPrice: number;
  minimumOrderFmcCentsPrice: number;
  allowMultipleUsage: boolean;
  couponType: CouponType;
  numberOfCouponsToGenerate: number;
};

export enum UseType {
  OneTime = "One-time use",
  Multiple = "Multiple-use",
}

export enum UsersType {
  All = "All users",
  List = "Specific selected ones",
  UniqueUsers = "Number of unique users",
}

export enum PurchaseType {
  WithoutPurchase = "Without minimum",
  MinimumPurchase = "Minimum purchase",
}

export enum TopUpType {
  WithoutMimimunTopUp = "Without minimum",
  MinimumTopUp = "Minimum top-up",
  WithoutTopUp = "Without top-up",
}

export enum TabKey {
  Main = "Main",
  Users = "Users",
}
