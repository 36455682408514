// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
// Types
import { FilterValue } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd/lib/table";
import { ETable } from "types/tableFilters";
import type { UserModalForm } from "components/Modals/UserModal";
import { TableAction } from "types/common";
import { UserResponseDto } from "types/users";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS, Permission } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import {
  DescriptionBlackIcon,
  PencilIcon,
  PlusIcon,
  RightBurgerMenuIcon,
  TrashIcon,
} from "icons";
// Layouts
// Components
import { DropDown, Pagination, Table } from "components";
import { ConfirmDialog, UserModal } from "components/Modals";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

import { columns, filtersList, getUsersSortBy } from "./config";

export const Users: FC = () => {
  const {
    canUsersCreate,
    canUsersUpdate,
    canUsersDelete,
    isSuperAdminManagement,
  } = usePermissions();

  const navigate = useNavigate();
  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();

  const [createUser, { isLoading: isUserCreateLoading }] =
    useCreateUserMutation();
  const [updateUser, { isLoading: isUserUpdateLoading }] =
    useUpdateUserMutation();
  const [deleteUser, { isLoading: isUserDeleteLoading }] =
    useDeleteUserMutation();

  const [userModal, setUserModal] = useState<boolean | UserResponseDto>(false);
  const [deleteUserModal, setDeleteUserModal] = useState<
    false | UserResponseDto
  >(false);

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    search,
    selectedFilters,
    debouncedSearch,
    debouncedFiltersQuery,
    setPage,
    setLimit,
    setSearch,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.Users });

  const {
    data: usersData,
    isFetching,
    error: usersError,
  } = useGetUsersQuery(
    {
      query: {
        ...(debouncedFiltersQuery && debouncedFiltersQuery),
        page,
        limit,
        sortBy: getUsersSortBy(sortBy),
        sortingOrder,
        search: debouncedSearch,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (usersError) {
      errorHandler({ error: usersError, openNotification });
    }
  }, [usersError]);

  const handleAddUser = () => {
    setUserModal(true);
  };

  const onConfirmDelete = async () => {
    if (!deleteUserModal) {
      return;
    }

    try {
      await deleteUser({ id: deleteUserModal.id }).unwrap();

      openNotification({
        message: NOTIFICATIONS.USER_DELETE,
      });

      setDeleteUserModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onUserSave = async (data: UserModalForm) => {
    const isEdit = !!userModal && typeof userModal !== "boolean";

    try {
      const {
        id,
        firstName,
        lastName,
        roleName,
        email,
        password,
        newPassword,
        phoneNumber,
        customerActivated,
      } = data || {};

      if (!isEdit) {
        await createUser({
          firstName,
          lastName,
          roleName,
          password,
          customerActivated,
          email: email || null,
        }).unwrap();

        openNotification({ message: NOTIFICATIONS.USER_CREATE });
      } else {
        await updateUser({
          id,
          firstName,
          lastName,
          roleName,
          email: email || null,
          phoneNumber,
          newPassword,
          customerActivated,
        }).unwrap();

        openNotification({ message: NOTIFICATIONS.USER_UPDATE });
      }

      setUserModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const validateRowActions = (row: UserResponseDto) => {
    if (isSuperAdminManagement) {
      return false;
    }

    return !!row.role.permissions.find(
      p => p.name === Permission.SuperAdminManagement,
    );
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      type: "Grey",
      disabled: !canUsersUpdate,
      disableFunction: (row: UserResponseDto) => validateRowActions(row),
      onClick: (row: UserResponseDto) => setUserModal(row),
    },
    {
      title: "",
      Icon: TrashIcon,
      type: "Grey",
      disabled: !canUsersDelete,
      disableFunction: (row: UserResponseDto) => validateRowActions(row),
      onClick: (row: UserResponseDto) => setDeleteUserModal(row),
    },
  ];

  const dropDownMenuItems = [
    {
      key: "1",
      label: "Add User",
      onClick: handleAddUser,
    },
  ];

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const onRow = record => {
    return {
      onClick: () => {
        navigate(`/users/${record.id}`);
      },
    };
  };

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>All Users</Typography.H1>

            {canUsersCreate && isDesktop ? (
              <Button.Heading
                type="primary"
                icon={<PlusIcon fill={theme.color.white} />}
                onClick={handleAddUser}
              >
                Add User
              </Button.Heading>
            ) : (
              <DropDown items={dropDownMenuItems} trigger={["click"]}>
                <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
              </DropDown>
            )}
          </FlexContainer>

          <Table
            isLoading={isFetching}
            dataSource={usersData?.items || []}
            columns={columns}
            withPagination={!isDesktop}
            actions={actions}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No users to show.",
              description: "Try changing sections or change the filters",
            }}
            header={{
              search: {
                placeholder: "Search first name or last name",
                value: search,
                setValue: setSearch,
              },
              totalCount: usersData?.totalCount,
              filter: {
                filters: filtersList,
                selected: selectedFilters || [],
                setValue: handleSetTableFilterValues,
              },
            }}
            onChange={handleTableChange}
            onRow={onRow}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          showSizeChanger={!isDesktop}
          totalItems={usersData?.totalCount}
        />
      </PageWrapper>

      <UserModal
        userData={userModal}
        isLoading={isUserCreateLoading || isUserUpdateLoading}
        onSave={onUserSave}
        onClose={() => setUserModal(false)}
      />

      <ConfirmDialog
        open={!!deleteUserModal}
        isLoading={isUserDeleteLoading}
        Icon={TrashIcon}
        message={`"${
          (deleteUserModal as UserResponseDto)?.fullName
        }" will be deleted`}
        description="Are you sure to continue this action?"
        onCancel={() => setDeleteUserModal(false)}
        firstCTAButton={{
          title: "Delete User",
          status: "danger",
          loading: isUserDeleteLoading,
          onClick: onConfirmDelete,
        }}
      />
    </>
  );
};
