import Fill from "ol/style/Fill";
import Circle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";

export const color = {
  white: "#FFFFFF",
  beigeDark: "#FACEAB",
  beigeMedium: "#FCEBDD",
  beigeLight: "#FEF8F4",
  greenDark: "#002E07",
  greenMedium: "#335839",
  greenMediumLight: "#66826A",
  greenLight: "#D6DED7",
  redDark: "#BF4F4C",
  redMedium: "#EF635F",
  redLight: "#F2827F",

  mapGrey: "#031306",
  mapBlue: "#189AB4",
  mapRed: "#FF0000",
  mapGreen: "#00D100",
};

export const DEFAULT_MAP_CENTER = [47.99342697048772, 29.391263469722716];
export const DEFAULT_MAP_ZOOM = 12;
export const DEFAULT_POLYGON_COLOR = color.mapGrey;
export const ACTIVE_POLYGON_COLOR = color.mapBlue;
export const REMOVED_POLYGON_COLOR = color.mapRed;
export const NEW_POLYGON_COLOR = color.mapGreen;

export const setTransparency = (color: string) => `${color}53`;

export const mapStyles = {
  Point: new Style({
    image: new Circle({
      radius: 4,
      fill: new Fill({ color: NEW_POLYGON_COLOR }),
      stroke: new Stroke({
        color: NEW_POLYGON_COLOR,
      }),
    }),
  }),
  Line: new Style({
    stroke: new Stroke({
      color: NEW_POLYGON_COLOR,
      width: 3,
    }),
  }),
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: DEFAULT_POLYGON_COLOR,
      width: 1,
    }),
    fill: new Fill({
      color: setTransparency(DEFAULT_POLYGON_COLOR),
    }),
  }),
  NewPolygon: new Style({
    stroke: new Stroke({
      color: NEW_POLYGON_COLOR,
      width: 1,
    }),
    fill: new Fill({
      color: setTransparency(NEW_POLYGON_COLOR),
    }),
  }),
  ActivePolygon: new Style({
    stroke: new Stroke({
      color: ACTIVE_POLYGON_COLOR,
      width: 1,
    }),
    fill: new Fill({
      color: setTransparency(ACTIVE_POLYGON_COLOR),
    }),
  }),
  RemovedPolygon: new Style({
    stroke: new Stroke({
      color: REMOVED_POLYGON_COLOR,
      width: 1,
    }),
    fill: new Fill({
      color: setTransparency(REMOVED_POLYGON_COLOR),
    }),
  }),
};
