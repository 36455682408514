import { Badge } from "components";
import { fixedDigitsValue } from "helpers/dataHelpers";
import { CurrencyIcon } from "icons";
import { FlexContainer } from "styled/Box";
import { theme } from "theme";

export const columns = [
  {
    key: "sort",
    align: "left" as const,
    width: 24,
  },
  {
    title: "Package name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Amount coins",
    dataIndex: "coinsAmount",
    key: "coinsAmount",
    align: "right" as const,
    render: (value: number) =>
      value || value === 0 ? (
        <FlexContainer $align="center" $gap={8} $justify="flex-end">
          {fixedDigitsValue(value, 3)} <CurrencyIcon />
        </FlexContainer>
      ) : null,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    align: "right" as const,
    render: (value: number) => `${fixedDigitsValue(value, 3)} KD`,
  },
  {
    title: "Expiry (days)",
    dataIndex: "expiryDays",
    key: "expiryDays",
    align: "right" as const,
  },
  {
    title: "Bonus coins",
    dataIndex: "bonusCoinsPercent",
    key: "bonusCoinsPercent",
    align: "right" as const,
    render: (value: number) => `${value}%`,
  },
  {
    title: "Tag",
    dataIndex: "tag",
    key: "tag",
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    render: (active: boolean) => (
      <Badge
        title={active ? "Active" : "Inactive"}
        bgColor={active ? theme.color.status.success : theme.color.failed}
        textColor={theme.color.white}
      />
    ),
  },
];

export const mobileColumns = columns.filter(column => column.key !== "sort");
