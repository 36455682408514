import { styled } from "styled-components";
import { theme } from "theme";

export const MapWrapper = styled.div`
  width: 100%;
  background: ${theme.color.white};
  box-shadow: ${theme.shadow.table};
  border-radius: ${theme.borderRadius.base};
  height: 100%;
  height: 600px;
  height: calc(100vh - 195px);
  overflow: auto;
`;
