// Lib
import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useGetReviewsQuery } from "rtkQuery/query/reviewsAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { ReviewResponseDto } from "types/reviews";
import { AdditionalFilter, ETable } from "types/tableFilters";
// Constants
import { ADMIN_ROUTES, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Pagination, Table } from "components";
// Styled
import { PageWrapper, FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

import { columns, filtersList } from "./config";

export const Reviews: FC = () => {
  const { isDesktop } = useViewport();

  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    selectedFilters,
    debouncedFiltersQuery,
    setPage,
    setLimit,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.Reviews });

  const { data, isFetching, error } = useGetReviewsQuery(
    {
      query: {
        ...(debouncedFiltersQuery && debouncedFiltersQuery),
        page,
        limit,
        sortBy,
        sortingOrder,
      },
    },
    rtkQueryParams,
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const handleTableChange = useCallback(
    (
      _pagination: TablePaginationConfig,
      _filters: Record<string, FilterValue>,
      sorter: SorterResult,
    ) => {
      handleSort(sorter?.field as string, sorter?.order);
    },
    [],
  );

  const onRow = useCallback((record: ReviewResponseDto) => {
    return {
      onClick: () => navigate(`${ADMIN_ROUTES.REVIEWS.path}/${record.id}`),
    };
  }, []);

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>Rates and Reviews</Typography.H1>
          </FlexContainer>

          <Table
            isLoading={isFetching}
            dataSource={data?.items || []}
            columns={columns}
            withPagination={!isDesktop}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No reviews to show.",
              description: "Try changing filters",
            }}
            header={{
              totalCount: data?.totalCount,
              filter: {
                include: [AdditionalFilter.Customer, AdditionalFilter.Rating],
                filters: filtersList,
                selected: selectedFilters,
                setValue: handleSetTableFilterValues,
              },
            }}
            onRow={onRow}
            onChange={handleTableChange}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          showSizeChanger={!isDesktop}
          totalItems={data?.totalCount}
        />
      </PageWrapper>
    </>
  );
};
