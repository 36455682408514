import { ThemeConfig } from "antd";

export const theme = {
  color: {
    brand: "#002E07",
    brandBackground: "#EBF3EB",
    brandDisabled: "#ABB2AC",
    borderLight: "#EBEBEB",
    borderMedium: "#E0E0E0",
    lightBlue: "#57BCDC",
    blue: "#3662B6",
    yellow: "#FEBF2B",
    yellowLight: "rgba(255, 220, 139, 1)",
    red: "#FA5045",
    white: "#fff",
    black: "#000000",
    error: "#ff4d4f",
    failed: "#EC6240",
    disabled: "#FCD5D3",
    active: "#D7DED7",
    lightGreen: "#F7FAF8",
    gray: "#ADADAD",
    darkGray: "#4E4E4E",
    pink: "rgba(251, 180, 175, 1)",

    background: {
      primary: "#F5F5F5",
      gray: "#F0F2F1",
      brand: "#EBF3EB",
      lightgray: "#F9FAF9",
      neutral: "#ECEDEE",
      secondary: "#E0E2E1",
      secondary2: "#A8A8A8",
    },

    text: {
      primaty1: "#EFF7EE",
      primary2: "#031306",
      secondary: "#363D37",
      tertiary: "#646965",
      disabled: "#87898E",
    },
    status: {
      success: "#5BAA5C",
      warning: "#FEBF2B",
      neutral: "#3EB8EE",
      danger: "#FA5045",
    },
  },

  shadow: {
    table: "0px 0px 6px rgba(0, 0, 0, 0.12)",
    light: "0px 1px 4px rgba(0, 0, 0, 0.06)",
    bold: "0px 4px 32px rgba(0, 0, 0, 0.2)",
    none: "none",
  },

  borderRadius: {
    base: "8px",
  },

  fontSize: {
    base: "14px",
    md: "16px",
    heading: "28px",
    subheading: "20px",
    blockheading: "18px",
  },

  lineHeight: {
    base: "20px",
    md: "24px",
    subheading: "28px",
    heading: "32px",
  },

  fontWeight: {
    thin: 100,
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },

  breakpoints: {
    xxl: "1440px",
    xl: "1280px",
    l: "1024px",
    ml: "991px",
    m: "768px",
    s: "512px",
    xs: "480px",
    desktop: "1440px",
    tabletLandscape: "1024px",
  },
};

export const config: ThemeConfig = {
  token: {
    colorLink: theme.color.blue,
    colorLinkActive: "#124AB7",
    colorLinkHover: "#124AB7",
    colorPrimary: theme.color.brand,
    colorPrimaryBg: "#fff",
    colorInfo: theme.color.blue,
    fontSize: 16,
    colorPrimaryBorderHover: theme.color.brand,
    colorBorder: theme.color.borderMedium,
    colorBorderSecondary: theme.color.borderMedium,
    borderRadius: 8,
    colorErrorHover: "rgb(253 106 96)",
  },
  components: {
    Input: {
      activeShadow: "inset 0px 0px 0px 0.5px",
      errorActiveShadow: "inset 0px 0px 0px 0.5px #E13A2F",
      colorErrorBorderHover: "rgb(225, 58, 47)",
    },
    DatePicker: {
      activeShadow: "inset 0px 0px 0px 0.5px",
      errorActiveShadow: "inset 0px 0px 0px 0.5px #E13A2F",
      colorErrorBorderHover: "rgb(225, 58, 47)",
    },
    Select: {
      colorErrorBorderHover: "rgb(225, 58, 47)",
    },
  },
};
