// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useUpdateExchangeRateMutation } from "rtkQuery/query/exchangeRateAPI";
// Hooks
import { useDebounce, useNotification, usePermissions } from "hooks";
// Types
import { ExchangeRateResponseDto } from "types/exchangeRate";
// Constants
import { NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { InputNumber } from "components/Form";
// Styled
import { ContentBox, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";

interface ConversionProps {
  isExchangeRateLoading: boolean;
  exchangeRate: ExchangeRateResponseDto;
}

export const Conversion: FC<ConversionProps> = ({
  isExchangeRateLoading,
  exchangeRate,
}) => {
  const { canExchangeRateUpdate } = usePermissions();

  const { openNotification } = useNotification();

  const [updateExchangeRate] = useUpdateExchangeRateMutation();

  const [errors, setErrors] = useState(false);

  const [exchangeRateValue, setExchangeRateValue] = useState<number | null>(
    null,
  );

  const debouncedExchangeRateValue = useDebounce(exchangeRateValue, 1000);

  useEffect(() => {
    if (exchangeRate) {
      setExchangeRateValue(exchangeRate?.value);
    }
  }, [exchangeRate]);

  const handleUpdateExchangeRate = async () => {
    try {
      await updateExchangeRate({ value: debouncedExchangeRateValue }).unwrap();

      setErrors(false);

      openNotification({ message: NOTIFICATIONS.COIN_CONVERSION_UPDATED });
    } catch (error) {
      errorHandler({ error, openNotification });

      if (error?.data?.description?.length) {
        setErrors(error?.data?.description[0]);
      }
    }
  };

  useEffect(() => {
    if (!debouncedExchangeRateValue) return;
    if (debouncedExchangeRateValue === exchangeRate?.value) {
      setErrors(false);
      return;
    }

    handleUpdateExchangeRate();
  }, [debouncedExchangeRateValue]);

  return (
    <ContentBox $column>
      <Typography.H2>Coin conversion</Typography.H2>

      <InputsGridContainer>
        <InputNumber label="Coin" value={1} disabled />

        <InputNumber
          label="KD"
          step={0.001}
          precision={3}
          disabled={!canExchangeRateUpdate || isExchangeRateLoading}
          fieldError={errors}
          value={exchangeRateValue}
          onChange={(value: number) => setExchangeRateValue(value)}
        />
      </InputsGridContainer>
    </ContentBox>
  );
};
