import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const transactionsUrlBuilder = {
  getTransactions: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(endpoints.transactions, query);
  },
  refundTransaction: ({
    id,
    customerId,
  }: {
    id: string;
    customerId: string;
  }): string => {
    return `${endpoints.customers}/${customerId}/payments/refund/${id}`;
  },
};
