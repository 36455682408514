// Lib
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CollapseProps } from "antd";
// Hooks
import { usePermissions } from "hooks";
// Types
import { GetLocationResponseDto } from "types/locations";
// Theme
import { theme } from "theme";
// Constants
import { ADMIN_ROUTES } from "consts";
// Helpers
import {
  channelLinkStatusTitle,
  getOperatingStatusColor,
  operatingStatusTitle,
} from "../../../helpers";
// Icons
import { EyeVisibleIcon, LocationPointIcon, PencilIcon } from "icons";
// Components
import { Status } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  Collapse,
  Description,
  DownArrow,
  Grid,
  IconRoundedContainer,
  ListWrapper,
  Wrapper,
} from "./styled";

interface LocationsListProps {
  isLoading: boolean;
  isSaveButtonDisabled: boolean;
  locationsData: GetLocationResponseDto[];
  activeKitchenId: string;
  updateActiveKitchen: (id: string) => void;
  handleSaveChanges: () => void;
}

export const LocationsList: FC<LocationsListProps> = ({
  isLoading,
  isSaveButtonDisabled,
  locationsData,
  activeKitchenId,
  updateActiveKitchen,
  handleSaveChanges,
}) => {
  const navigate = useNavigate();

  const { canLocationsUpdate, canLocationsGet } = usePermissions();

  const handleEdit = (id: string) => {
    navigate(`${ADMIN_ROUTES.DELIVERY_AREAS.path}/edit/${id}`);
  };

  const handleShow = (id: string) => {
    navigate(`${ADMIN_ROUTES.DELIVERY_AREAS.path}/${id}`);
  };

  const createItems = (): CollapseProps["items"] => {
    return locationsData?.map(
      ({ id, name, operatingZones, operatingStatus, channelLinkStatus }) => ({
        key: id,
        label: (
          <FlexContainer $align="center" $gap={16}>
            <IconRoundedContainer>
              <LocationPointIcon />
            </IconRoundedContainer>

            <FlexContainer $column $gap={4}>
              <Typography.Title>{name}</Typography.Title>

              <Description>
                {operatingZones?.length} operating zones
              </Description>
            </FlexContainer>
          </FlexContainer>
        ),
        children: (
          <FlexContainer $fullwidth $column $gap={16}>
            <Grid>
              <FlexContainer $column $gap={4}>
                <Description>Operating status</Description>

                {operatingStatus && (
                  <Status
                    type="outlined"
                    text={operatingStatusTitle[operatingStatus]}
                    status={getOperatingStatusColor(operatingStatus)}
                  />
                )}
              </FlexContainer>
              <FlexContainer $column $gap={4}>
                <Description>Channel link status</Description>

                {channelLinkStatus && (
                  <Status
                    type="outlined"
                    text={channelLinkStatusTitle[channelLinkStatus]}
                    status={getOperatingStatusColor(channelLinkStatus)}
                  />
                )}
              </FlexContainer>
            </Grid>

            <div>
              <Description>Zone priorities</Description>

              {!!operatingZones?.length &&
                operatingZones.map((z, i) => (
                  <FlexContainer
                    key={z.name}
                    $fullwidth
                    $align="center"
                    $justify="space-between"
                    $padding="4px 0"
                    $gap={4}
                  >
                    <Typography.Title>
                      {z?.name || `ZONE ${i}`}
                    </Typography.Title>

                    <Typography.Title>{z.priority}</Typography.Title>
                  </FlexContainer>
                ))}
            </div>

            <Grid>
              {canLocationsUpdate && (
                <Button.Base
                  icon={<PencilIcon />}
                  onClick={() => handleEdit(id)}
                >
                  Edit area
                </Button.Base>
              )}

              {canLocationsGet && (
                <Button.Base
                  icon={<EyeVisibleIcon fill={theme.color.text.tertiary} />}
                  onClick={() => handleShow(id)}
                >
                  Show detail
                </Button.Base>
              )}
            </Grid>
          </FlexContainer>
        ),
      }),
    );
  };

  const items = locationsData?.length ? createItems() : [];

  return (
    <Wrapper>
      <FlexContainer $fullwidth $column $justify="space-between" $height="100%">
        <ListWrapper $canLocationsUpdate={canLocationsUpdate}>
          <Collapse
            accordion
            expandIconPosition="end"
            items={items}
            activeKey={activeKitchenId}
            onChange={(keys: string[]) => updateActiveKitchen(keys[0])}
            expandIcon={({ isActive }) => (
              <DownArrow $rotate={isActive ? 180 : 0} />
            )}
          />
        </ListWrapper>

        {canLocationsUpdate && (
          <Button.Base
            loading={isLoading}
            disabled={isSaveButtonDisabled}
            onClick={handleSaveChanges}
          >
            Save
          </Button.Base>
        )}
      </FlexContainer>
    </Wrapper>
  );
};
