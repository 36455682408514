import { Rate } from "antd";
import styled from "styled-components";

export const RateStars = styled(Rate)`
  .ant-rate-star {
    cursor: pointer !important;
  }
`;

export const ReviewText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 250px;
`;
