// Lib
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import { useGetRolesQuery } from "rtkQuery/query/rolesAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { WaitingUserResponseDto } from "types/users";
// Helpers
import { roleDisabledCheck } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Modal } from "components";
import { Input, PasswordInput, Select, Switch } from "components/Form";
// Styled
import { FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { createResolver, editResolver } from "./validation";

export type UserActivateModalForm = {
  customerActivated: boolean;
  email: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  roleName: string;
  phoneNumber?: string;
};

interface UserActivateModalProps {
  userData: false | WaitingUserResponseDto;
  isLoading: boolean;
  onSave: (args: UserActivateModalForm) => void;
  onClose: () => void;
}

export const UserActivateModal: FC<UserActivateModalProps> = ({
  userData,
  isLoading,
  onSave,
  onClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const isEdit = !!userData && userData?.userId !== null;

  const {
    data: rolesData,
    isFetching: isRolesDataFetching,
    error: rolesDataError,
  } = useGetRolesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (rolesDataError) {
      errorHandler({ error: rolesDataError, openNotification });
    }
  }, [rolesDataError]);

  const { handleSubmit, control, reset, watch } =
    useForm<UserActivateModalForm>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver: isEdit ? editResolver : createResolver,
    });

  const email = watch("email");
  const roleName = watch("roleName");
  const password = watch("password");
  const customerActivated = watch("customerActivated");

  useEffect(() => {
    if (!userData) {
      reset({
        customerActivated: false,
        email: null,
        firstName: null,
        lastName: null,
        password: null,
        roleName: null,
        phoneNumber: null,
      });

      return;
    }

    const { email, firstName, lastName, phoneNumber } = userData;

    reset({
      email,
      firstName,
      lastName,
      phoneNumber,
    });
  }, [userData]);

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const onSubmit: SubmitHandler<UserActivateModalForm> = data => {
    onSave(data);
  };

  const roles = rolesData?.items
    ?.filter(({ name }) => roleDisabledCheck(name))
    .map(({ name }) => ({
      value: name,
      label: name,
    }));

  const isSubmitDisabled = isEdit
    ? !email || !roleName || !customerActivated
    : !email || !roleName || !password || !customerActivated;

  return (
    <Modal
      title="Activate User"
      width={560}
      open={!!userData}
      onClose={handleClose}
    >
      <FlexContainer
        $fullwidth
        $column
        $gap={10}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FlexContainer $fullwidth $column $gap={16}>
          <InputsGridContainer>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="First name"
                  isDisabled={isLoading}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Last name"
                  isDisabled={isLoading}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>

          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                required
                label="E-mail"
                isDisabled={isLoading}
                {...field}
                fieldState={fieldState}
              />
            )}
          />

          {isEdit && (
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Phone number"
                  isDisabled={isLoading}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          )}

          <Controller
            name="roleName"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                required
                label="Role"
                placeholder="Select role"
                loading={isRolesDataFetching}
                {...field}
                fieldState={fieldState}
                options={roles}
                disabled={isLoading}
              />
            )}
          />

          {!isEdit && (
            <Controller
              name={"password"}
              control={control}
              render={({ field, fieldState }) => (
                <PasswordInput
                  required
                  label="Password"
                  isDisabled={isLoading}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          )}
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $column={isMobile}
          $align={isMobile ? "flex-start" : "center"}
          $justify={isMobile ? "flex-start" : "space-between"}
          $gap={isMobile ? 16 : 8}
          $margin="24px 0 0"
        >
          <Controller
            name="customerActivated"
            control={control}
            render={({ field }) => (
              <Switch
                label="Activate user"
                checked={field.value}
                {...field}
                disabled={isLoading}
              />
            )}
          />

          <FlexContainer
            $fullwidth={isMobile}
            $column={isMobile}
            $align="center"
            $justify="center"
            $gap={8}
          >
            <Button.Base
              $fullWidth={isMobile}
              disabled={isLoading}
              onClick={handleClose}
            >
              Close
            </Button.Base>

            <Button.Base
              $fullWidth={isMobile}
              loading={isLoading}
              type="primary"
              htmlType="submit"
              disabled={isSubmitDisabled}
            >
              Save
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
