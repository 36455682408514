// Lib
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
} from "rtkQuery/query/customersAPI";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "rtkQuery/query/usersAPI";
// Hooks
import {
  useDebounce,
  useExport,
  useNotification,
  usePermissions,
  useTable,
  useViewport,
} from "hooks";
// Types
import { TablePaginationConfig } from "antd/es/table";
import { FilterValue } from "antd/es/table/interface";
import { TableAction } from "types/common";
import { ETable } from "types/tableFilters";
import { UserResponseDto } from "types/users";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS, rtkQueryParams } from "consts";
// Icons
import {
  AddIcon,
  DescriptionBlackIcon,
  PencilIcon,
  RightBurgerMenuIcon,
} from "icons";
// Helpers
import { getFiltersQuery } from "helpers/tableFiltersHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import {
  DropDown,
  Export,
  //TODO: remove comment if BE is ready
  // Export,
  Pagination,
  Table,
} from "components";
import { TopUpBalanceModal, UserModal, UserModalForm } from "components/Modals";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { SelectedItems, Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

import { columns, filtersList } from "./config";
import { CustomerProfileResponseDto } from "types/customers";
import { UsedCouponsModal } from "./components";

export const Customers: FC = () => {
  const navigate = useNavigate();
  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();
  const { downloadCSV } = useExport();
  const { canUsersUpdate } = usePermissions();

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    //TODO: remove comment if BE is ready
    // search,
    debouncedSearch,
    selectedFilters,
    setPage,
    setLimit,
    //TODO: remove comment if BE is ready
    // setSearch,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.Customers });

  const filtersQuery = getFiltersQuery(selectedFilters);

  const query = useDebounce<Record<string, string | string[]>>(
    filtersQuery,
    500,
  );

  const {
    data: customersData,
    isFetching: isLoading,
    error: customersError,
    refetch,
  } = useGetCustomersQuery(
    {
      query: {
        ...(query && query),
        page,
        limit,
        sortBy,
        sortingOrder,
        search: debouncedSearch,
      },
    },
    rtkQueryParams,
  );

  const [getExportCustomersData, { isFetching: isExportLoading }] =
    useLazyGetCustomersQuery();

  const [getUserData] = useLazyGetUserQuery();

  const [updateUser, { isLoading: isUserUpdateLoading }] =
    useUpdateUserMutation();

  useEffect(() => {
    if (customersError) {
      errorHandler({ error: customersError, openNotification });
    }
  }, [customersError]);

  const [
    selected,
    //TODO: remove comment if BE is ready
    // , setSelected
  ] = useState<string[]>([]);
  const [topUpBalanceModal, setTopUpBalanceModal] = useState<boolean>(false);
  const [userModal, setUserModal] = useState<boolean | UserResponseDto>(false);
  const [usedCustomerCoupons, setUsedCustomerCoupons] = useState({
    id: "",
    name: "",
  });
  const [loadingId, setLoadingId] = useState<string | null>(null);

  const onCounterClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    count: number,
    id: string,
    name: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!count) {
      return;
    }
    setUsedCustomerCoupons({
      id,
      name,
    });
  };

  const tableColumns = useMemo(() => {
    return columns.map(col => {
      if (col.key === "couponUsageCount") {
        return {
          ...col,
          render: (count: number, record: CustomerProfileResponseDto) => (
            <Button.Notification
              type="link"
              onClick={e => {
                onCounterClick(e, count, record.id, record?.fullName);
              }}
            >
              {count}
            </Button.Notification>
          ),
        };
      }

      return col;
    });
  }, []);

  const cleanColumns = useMemo(() => {
    return tableColumns.map((col, i) => ({
      ...col,
      title:
        i === 0 ? (
          <FlexContainer $align="center" style={{ position: "relative" }}>
            <SelectedItems>{`${selected.length} data selected`}</SelectedItems>

            <Button.SubHeading
              onClick={() => setTopUpBalanceModal(true)}
              style={{
                marginLeft: 24,
                marginRight: -30,
                marginTop: -4,
                marginBottom: -4,
              }}
              icon={<AddIcon fill={theme.color.black} />}
            >
              Bulk Top up Balance
            </Button.SubHeading>
          </FlexContainer>
        ) : null,
      sorter: false,
    }));
  }, [selected.length]);

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const onRow = record => {
    return {
      onClick: () => {
        navigate(`/customers/${record.id}`);
      },
    };
  };

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      const data = await getExportCustomersData({
        query: {
          ...(query && query),
          limit: customersData?.totalCount,
          sortBy,
          sortingOrder,
          search: debouncedSearch,
        },
      }).unwrap();

      return data.items;
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleOpenUserModal = async (id: string) => {
    try {
      setLoadingId(id);
      const userData = await getUserData({ id }).unwrap();
      setUserModal(userData);
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setLoadingId(null);
    }
  };

  const onUserSave = async (data: UserModalForm) => {
    const isEdit = !!userModal && typeof userModal !== "boolean";

    if (!isEdit) {
      return;
    }

    try {
      const {
        id,
        firstName,
        lastName,
        roleName,
        email,
        newPassword,
        phoneNumber,
        customerActivated,
      } = data || {};

      await updateUser({
        id,
        firstName,
        lastName,
        roleName,
        email,
        phoneNumber,
        newPassword,
        customerActivated,
      }).unwrap();

      openNotification({ message: NOTIFICATIONS.CUSTOMER_UPDATE });

      refetch();

      setUserModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  //TODO: remove comment if BE is ready
  // const rowSelection = {
  //   onChange: (_: React.Key[], selectedRows: any[]) => {
  //     setSelected(selectedRows.map(row => row.id));
  //   },
  // };

  // TODO: add this action after client aprove
  const actions: TableAction[] = [
    // {
    //   title: "",
    //   Icon: PencilIcon,
    //   loadingId: loadingId,
    //   type: "Grey",
    //   disabled: !canUsersUpdate,
    //   onClick: (row: CustomerProfileResponseDto) => handleOpenUserModal(row.id),
    // },
  ];

  const DropDownMenuItems = [
    {
      key: "1",
      label: "Export",
      onClick: () =>
        downloadCSV({
          fileName: "customers",
          isDataLoading: false,
          columns: columns,
          getExportData: getExportData,
        }),
    },
  ];

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>Customers</Typography.H1>

            {isDesktop ? (
              <Export
                fileName="customers"
                isLoading={isExportLoading}
                isDataLoading={isLoading}
                isDisabled={!customersData?.totalCount}
                columns={columns}
                getExportData={getExportData}
              />
            ) : (
              <DropDown items={DropDownMenuItems} trigger={["click"]}>
                <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
              </DropDown>
            )}
          </FlexContainer>

          <Table
            isLoading={isLoading}
            withPagination={!isDesktop}
            rowKey={record => record.id}
            dataSource={customersData?.items || []}
            actions={actions}
            columns={!selected?.length ? tableColumns : cleanColumns}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No customer to show.",
              description: "Try changing sections or change the filters",
            }}
            header={{
              //TODO: remove comment if BE is ready
              // search: {
              //   placeholder: "Search name or phone number",
              //   value: search,
              //   setValue: setSearch,
              // },
              totalCount: customersData?.totalCount,
              filter: {
                filters: filtersList,
                selected: selectedFilters,
                setValue: handleSetTableFilterValues,
              },
            }}
            //TODO: remove and add request if BE is finished
            // rowSelection={{
            //   type: "checkbox",
            //   selectedRowKeys: selected,
            //   ...rowSelection,
            // }}
            onChange={handleTableChange}
            onRow={onRow}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          showSizeChanger={!isDesktop}
          totalItems={customersData?.totalCount}
        />
      </PageWrapper>

      <UserModal
        customerModal
        userData={userModal}
        isLoading={isUserUpdateLoading}
        onSave={onUserSave}
        onClose={() => setUserModal(false)}
      />

      <TopUpBalanceModal
        onSave={data => console.log(data)}
        open={topUpBalanceModal}
        isLoading={false}
        onClose={() => setTopUpBalanceModal(false)}
      />

      <UsedCouponsModal
        usedCustomerCoupons={usedCustomerCoupons}
        setUsedCustomerCoupons={setUsedCustomerCoupons}
      />
    </>
  );
};
