// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import {
  useLazyGetAllMenuProductsListQuery,
  useLazyGetRecommendedProductsListQuery,
  useRemoveProductMutation,
  useUpdateRecommendedProductsOrderMutation,
} from "rtkQuery/query/recommendedAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { TableAction } from "types/common";
import { ETable } from "types/tableFilters";
import {
  MenuItem,
  MenuProductResponseDto,
  RecommendedMenuItem,
} from "types/recommended";
// Theme
// Constants
import { NOTIFICATIONS, rtkQueryParams } from "consts";
// Helpers
import {
  addChildrenItems,
  createTableData,
  getFiltered,
  removeChild,
} from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { MenuIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { Pagination } from "components";
import { Select } from "components/Form";
import { ConfirmDialog } from "components/Modals";
import { AddItemModal, CustomDragTable } from "./components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";

import { columns, expandedRowColumns } from "./config";

export const Recommended: FC = () => {
  const { openNotification } = useNotification();

  const { isMobile, isDesktop } = useViewport();

  const { page, limit, search, setPage, setLimit, setSearch } = useTable({
    name: ETable.Recommended,
    removeQueryParams: true,
  });

  const [productItemsList, setProductItemsList] = useState<
    MenuProductResponseDto[]
  >([]);
  const [tableData, setTableData] = useState<MenuItem[]>([]);

  const [locationId, setLocationId] = useState<string | null>(null);

  const [addItemsModal, setAddItemsModal] = useState<MenuItem | false>(false);
  const [itemDeleteConfirm, setItemDeleteConfirm] = useState<
    RecommendedMenuItem | false
  >(false);

  const [getRecommendedProducts, { isFetching: isRecommendedProductsLoading }] =
    useLazyGetRecommendedProductsListQuery();

  const [getAllMenuProducts, { isFetching: isAllMenuProductsLoading }] =
    useLazyGetAllMenuProductsListQuery();

  const [updateOrder] = useUpdateRecommendedProductsOrderMutation();

  const [remove, { isLoading: isRemoveLoading }] = useRemoveProductMutation();

  const {
    data: locations,
    isFetching: isLocationsLoading,
    error: locationsError,
  } = useGetLocationsQuery(null, rtkQueryParams);

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  const getProductsData = async () => {
    try {
      const recommendedProducts = await getRecommendedProducts({
        locationId,
      }).unwrap();

      const allMenuProducts = await getAllMenuProducts({ locationId }).unwrap();

      setPage(1);
      setSearch("");

      setTableData(
        createTableData({
          allMenuProducts,
          recommendedProducts,
        }),
      );
      setProductItemsList(allMenuProducts);
    } catch (error) {
      errorHandler({ error, openNotification });
      setTableData([]);
    }
  };

  useEffect(() => {
    if (!locationId) return;

    getProductsData();
  }, [locationId]);

  const onDeleteConfirm = async () => {
    if (!itemDeleteConfirm) {
      return;
    }

    const name = itemDeleteConfirm?.name || "Item";

    try {
      await remove({ productId: itemDeleteConfirm.removeId }).unwrap();

      setTableData(prev => removeChild({ prev, item: itemDeleteConfirm }));

      openNotification({ message: `${name} successfully deleted` });

      setItemDeleteConfirm(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const orderItemsChange = async (
    id: string,
    newItemsData: MenuItem[],
    prevData: MenuItem[],
  ) => {
    setTableData(newItemsData);

    try {
      const data = {
        items: newItemsData
          .find(el => el.id === id)
          .recommendedItems.map((el, i) => ({ id: el.itemId, order: i })),
      };

      await updateOrder({ locationId, data }).unwrap();

      openNotification({ message: NOTIFICATIONS.ORDER_UPDATE });
    } catch (error) {
      errorHandler({ error, openNotification });

      setTableData(prevData);
    }
  };

  const handleAddItems = (
    parentId: string,
    items: MenuProductResponseDto[],
  ) => {
    setTableData(prev => addChildrenItems({ prev, parentId, items }));

    getProductsData();
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PlusIcon,
      type: "Grey",
      onClick: (record: MenuItem) => setAddItemsModal(record),
    },
  ];

  const expandedRowActions: TableAction[] = [
    {
      title: "",
      Icon: TrashIcon,
      type: "Grey",
      onClick: (record: RecommendedMenuItem) => setItemDeleteConfirm(record),
    },
  ];

  const locationOptions = locations?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const isLoading =
    isLocationsLoading ||
    isRecommendedProductsLoading ||
    isAllMenuProductsLoading;

  const { itemsToShow, totalCount } = getFiltered({
    page,
    limit,
    search,
    tableData,
  });

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $column={isMobile}
            $padding="0 0 24px"
            $align={isMobile ? "flex-start" : "center"}
            $justify="space-between"
            $gap={16}
          >
            <Typography.H1>Recommended Items</Typography.H1>

            <Select
              placeholder="Location"
              style={{ width: "200px" }}
              value={locationId}
              onChange={(value: string) => setLocationId(value)}
              options={locationOptions || []}
            />
          </FlexContainer>

          <CustomDragTable
            isLoading={isLoading}
            withPagination={!isDesktop}
            childrenDataSource="recommendedItems"
            rowKey={record => record.id}
            header={{
              totalCount: totalCount,
              search: {
                placeholder: "Search item",
                value: search,
                setValue: setSearch,
              },
            }}
            columns={columns}
            expandedRowColumns={expandedRowColumns}
            expandedRowActions={expandedRowActions}
            actions={actions}
            dataSource={itemsToShow}
            orderItemsChange={orderItemsChange}
            empty={{
              icon: MenuIcon,
              title: "No recommended items to show.",
              description: "Try to change location",
            }}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          showSizeChanger={!isDesktop}
          totalItems={totalCount}
        />
      </PageWrapper>

      <ConfirmDialog
        open={!!itemDeleteConfirm}
        isLoading={isRemoveLoading}
        Icon={TrashIcon}
        message={
          !!itemDeleteConfirm &&
          `${itemDeleteConfirm.name} will be deleted from ${itemDeleteConfirm.parentName}`
        }
        description="Are you sure to continue this action?"
        onCancel={() => setItemDeleteConfirm(false)}
        firstCTAButton={{
          title: "Delete item",
          status: "danger",
          loading: isRemoveLoading,
          onClick: onDeleteConfirm,
        }}
      />

      <AddItemModal
        locationId={locationId}
        parentProduct={addItemsModal}
        productItemsList={productItemsList}
        handleAddItems={handleAddItems}
        handleClose={() => setAddItemsModal(false)}
      />
    </>
  );
};
