// Lib
import React, {
  FC,
  useEffect,
  useRef,
  useState,
  createContext,
  CSSProperties,
} from "react";
import { Map, View } from "ol";
import { Coordinate } from "ol/coordinate";
import { fromLonLat, toLonLat } from "ol/proj";
// Api
import {
  useLazyGetLocationsQuery,
  useUpdateZoneLocationMutation,
} from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import {
  GetLocationResponseDto,
  InteractionMode,
  MapInputData,
  TMappedZone,
  TZone,
} from "types/locations";
// Helpers
import { inputDataMapper } from "pages/DeliveryAreas/helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { LocationsList } from "pages/DeliveryAreas/Main/components";
// Styled
import { MapContextStyledWrapper } from "styled/Box";

import { DEFAULT_MAP_ZOOM } from "Layers/config";

export const DEFAULT_ZONE_PRIORITY = 0;

const coordsMapper = (coordinates: Coordinate[][]) =>
  coordinates.map(coordinate => coordinate.map(el => toLonLat(el)));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const focusMapView = (point: Coordinate, map: any) => {
  if (!(point && map)) return;

  point && map.getView().setCenter(fromLonLat(point));
  map.getView().setZoom(DEFAULT_MAP_ZOOM);
};

export const coordsToLatLng = (coordinates?: Coordinate) => {
  if (!coordinates) return;

  const [lng, lat] = coordinates;

  return { lat, lng };
};

export const payloadMapper = (
  data: TMappedZone[],
  kitchenAddress?: Coordinate,
) => {
  return {
    kitchenAddress: coordsToLatLng(kitchenAddress),
    operatingZones: data
      .filter(({ toRemove }) => !toRemove)
      .map(({ coordinates, priority, name }) => ({
        name,
        priority: isNaN(priority) ? DEFAULT_ZONE_PRIORITY : priority,
        geometry: {
          coordinates,
          type: "Polygon",
        },
      })),
  };
};

export const MapInteractionContext = createContext<{
  map: Map | null;
  editedKitchen: TMappedZone[];
  setEditedKitchen: React.Dispatch<React.SetStateAction<TMappedZone[]>>;
  addNewZone: (coordinates: Coordinate[][]) => void;
  editZone: (zoneId: string, coordinates: Coordinate[][]) => void;
  toggleRemoveZone: (zoneId: string) => void;
  kitchens: MapInputData[] | null;
  setKitchens: React.Dispatch<React.SetStateAction<MapInputData[]>>;
  isEdited: boolean;
  activeKitchenId: string;
  setActiveKitchenId: React.Dispatch<React.SetStateAction<string>>;
  removeNewZones: () => void;
  interactionMode: InteractionMode | undefined;
  setInteractionEditMode: React.Dispatch<React.SetStateAction<InteractionMode>>;
  addNewKitchenPoint: (coordinate: Coordinate) => void;
  kitchenAddress: Coordinate;
}>(null);

type MapProviderProps = {
  isInteractive?: boolean;
  zoom: number;
  center: number[];
  sx?: CSSProperties;
  rawKitchens?: GetLocationResponseDto[] | null;
  children: React.ReactNode;
};

export const MapInteractionProvider: FC<MapProviderProps> = ({
  isInteractive,
  zoom,
  center,
  rawKitchens,
  sx,
  children,
}) => {
  const { isDesktop } = useViewport();
  const { openNotification } = useNotification();
  const [getLocationsList, { data: updatedData }] = useLazyGetLocationsQuery();
  const [updateLocation, { isLoading }] = useUpdateZoneLocationMutation();

  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);

  useEffect(() => {
    const options = {
      view: new View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };

    const mapObject = new Map(options);
    mapObject.setTarget(mapRef.current as HTMLDivElement);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, [center, zoom]);

  useEffect(() => {
    if (!map) return;

    map.getView().setZoom(zoom);
  }, [map, zoom]);

  useEffect(() => {
    if (!map) return;

    map.getView().setCenter(center);
  }, [center, map]);

  const [kitchens, setKitchens] = useState<MapInputData[] | null>(null);
  const [activeKitchenId, setActiveKitchenId] = useState("");
  const [editedKitchen, setEditedKitchen] = useState<TMappedZone[]>(
    [] as TMappedZone[],
  );
  const [isEdited, setIsEdited] = useState(false);
  const [interactionMode, setInteractionEditMode] = useState<
    InteractionMode | undefined
  >();
  const [kitchenAddress, setKitchenAddress] = useState<Coordinate>();

  useEffect(() => {
    if (!rawKitchens) return;

    setKitchens(inputDataMapper(rawKitchens));
  }, [rawKitchens]);

  useEffect(() => {
    if (!updatedData) return;

    setKitchens(inputDataMapper(updatedData));
  }, [updatedData]);

  useEffect(() => {
    setKitchenAddress(undefined);
  }, [interactionMode]);

  useEffect(() => {
    setIsEdited(false);
    setKitchenAddress(undefined);
    setInteractionEditMode(undefined);

    if (!activeKitchenId) return;

    const currentKitchen = kitchens?.find(
      kitchen => kitchen.id === activeKitchenId,
    );

    if (!currentKitchen) return;

    const point = currentKitchen?.zones[0]?.coordinates[0][0];

    setEditedKitchen(currentKitchen.zones);

    focusMapView(point, map);
  }, [activeKitchenId]);

  const addNewKitchenPoint = (coordinate: Coordinate) => {
    if (!coordinate) return;

    setIsEdited(true);

    setKitchenAddress(coordinate);
  };

  const addNewZone = (coordinates: Coordinate[][]) => {
    if (!coordinates) return;

    setIsEdited(true);

    return setEditedKitchen(
      prevKitchen =>
        [
          ...prevKitchen,
          {
            coordinates: coordsMapper(coordinates),
          },
        ] as TMappedZone[],
    );
  };

  const editZone = (zoneId: string, coordinates: Coordinate[][]) => {
    if (!(zoneId && coordinates)) return;

    setIsEdited(true);

    return setEditedKitchen(prevKitchen =>
      prevKitchen.map(kitchen =>
        kitchen.zoneId === zoneId
          ? {
              ...kitchen,
              coordinates: coordsMapper(coordinates),
            }
          : kitchen,
      ),
    );
  };

  const toggleRemoveZone = (zoneId: string) => {
    if (!zoneId) return;

    setIsEdited(true);

    return setEditedKitchen(prevKitchen =>
      prevKitchen.map(kitchen =>
        kitchen.zoneId === zoneId
          ? {
              ...kitchen,
              toRemove: !kitchen.toRemove,
            }
          : kitchen,
      ),
    );
  };

  const removeNewZones = () => {
    return setEditedKitchen(prevKitchen =>
      prevKitchen.map(kitchen =>
        !kitchen.zoneId
          ? {
              ...kitchen,
              toRemove: true,
            }
          : kitchen,
      ),
    );
  };

  const resetMap = async () => {
    if (isInteractive) {
      const locations = await getLocationsList().unwrap();

      setKitchens(inputDataMapper(locations));

      return;
    }
    setKitchens(inputDataMapper(rawKitchens));
  };

  const updateActiveKitchen = (id: string) => {
    setActiveKitchenId(id);
    resetMap();
  };

  const sendData = async (data: { operatingZones: TZone[] }) => {
    try {
      await updateLocation({
        id: activeKitchenId,
        ...data,
      }).unwrap();

      openNotification({ message: "Zone updated" });

      const locations = await getLocationsList().unwrap();

      setKitchens(inputDataMapper(locations));

      setActiveKitchenId("");
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleSaveChanges = () => {
    editedKitchen && sendData(payloadMapper(editedKitchen, kitchenAddress));
  };

  return (
    <MapInteractionContext.Provider
      value={{
        map,
        editedKitchen,
        setEditedKitchen,
        addNewZone,
        editZone,
        toggleRemoveZone,
        kitchens,
        setKitchens,
        isEdited,
        activeKitchenId,
        setActiveKitchenId,
        removeNewZones,
        interactionMode,
        setInteractionEditMode,
        addNewKitchenPoint,
        kitchenAddress,
      }}
    >
      <MapContextStyledWrapper
        $isSidebar={updatedData ? !!updatedData?.length : !!rawKitchens?.length}
      >
        {!!rawKitchens?.length && (
          <LocationsList
            locationsData={updatedData || rawKitchens}
            isLoading={isLoading}
            isSaveButtonDisabled={!isEdited}
            activeKitchenId={activeKitchenId}
            updateActiveKitchen={updateActiveKitchen}
            handleSaveChanges={handleSaveChanges}
          />
        )}
        <div
          ref={mapRef}
          style={{
            position: "relative",
            height: !isDesktop ? "300px" : "100%",
            width: "100%",
            ...(sx && sx),
          }}
        >
          {children}
        </div>
      </MapContextStyledWrapper>
    </MapInteractionContext.Provider>
  );
};
