// Lib
import { FC } from "react";
import { Radio, RadioChangeEvent } from "antd";
import { Control, Controller } from "react-hook-form";
// Types
import { DiscountType } from "types/coupons";
import { AllPaymentTypes, OrderPaymentType } from "types/orders";
import {
  TopUpCouponForm,
  UsersType,
  UseType,
  TopUpType,
} from "../../../../types";
// Constants
import { DAY_MONTH_YEAR_DATE, TABLE_MONTH_DAY_YEAR_TIME_DATE } from "consts";
// Helpers
import {
  //temporary removed
  // paymentTypeOptions,
  topUpDiscountTypeOptions,
  getBonusLabel,
} from "../../../../helpers";
// Components
import { Tooltip } from "components";
import {
  DatePicker,
  Input,
  InputNumber,
  Select,
  Switch,
} from "components/Form";
// Styled
import {
  ContentBox,
  EmptyInputsGridItem,
  FlexContainer,
  InputsGridContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";

interface MainTabProps {
  isEdit: boolean;
  isEditMode: boolean;
  isCouponNameDisabled: boolean;
  isLoading: boolean;
  calculatedCoinsAmount: number;
  control: Control<TopUpCouponForm, any>;
  useType: UseType;
  usersType: UsersType;
  discountType: DiscountType;
  topUpType: TopUpType;
  orderPaymentType: OrderPaymentType | AllPaymentTypes;
  handleSetUseType: (value: UseType) => void;
  handleSetUsersType: (value: UsersType) => void;
  handleSetDiscountType: (value: DiscountType) => void;
  handleSetTopUpType: (value: TopUpType) => void;
}

export const MainTab: FC<MainTabProps> = ({
  isEdit,
  isEditMode,
  isCouponNameDisabled,
  isLoading,
  calculatedCoinsAmount,
  control,
  useType,
  usersType,
  discountType,
  topUpType,
  handleSetUseType,
  handleSetUsersType,
  handleSetDiscountType,
  handleSetTopUpType,
}) => {
  const onChangeDiscountType = (value: DiscountType) => {
    handleSetDiscountType(value);
  };

  const onChangeUseType = (e: RadioChangeEvent) => {
    handleSetUseType(e.target.value as UseType);
  };
  const onChangeUsersType = (e: RadioChangeEvent) => {
    handleSetUsersType(e.target.value as UsersType);
  };
  const onChangePurchaseType = (e: RadioChangeEvent) => {
    handleSetTopUpType(e.target.value as TopUpType);
  };

  const isFmcCentsTypeSelected = discountType === DiscountType.FmcCents;

  return (
    <>
      <FlexContainer $column $gap={16} $fullwidth>
        <ContentBox $column $gap={20}>
          <FlexContainer $fullwidth $align="center" $justify="space-between">
            <Typography.H2>Settings</Typography.H2>

            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <Switch
                  label="Activate Coupon"
                  checked={field.value}
                  {...field}
                  disabled={!isEditMode || isLoading}
                />
              )}
            />
          </FlexContainer>

          <InputsGridContainer>
            {!isEdit && (
              <>
                <Tooltip
                  title={
                    isCouponNameDisabled &&
                    "Not available for bulk coupons creating"
                  }
                  trigger={["hover"]}
                >
                  <div>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input
                          label="Coupon Code"
                          placeholder="Coupon Code"
                          {...field}
                          fieldState={fieldState}
                          disabled={isCouponNameDisabled || isEdit || isLoading}
                        />
                      )}
                    />
                  </div>
                </Tooltip>

                <Controller
                  name="numberOfCouponsToGenerate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      label="Quantity"
                      placeholder="Quantity"
                      required
                      min={1}
                      step={1}
                      precision={0}
                      {...field}
                      disabled={isEdit || isLoading}
                      fieldState={fieldState}
                    />
                  )}
                />
              </>
            )}

            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  required
                  showTime
                  label="Start date"
                  placeholder={DAY_MONTH_YEAR_DATE}
                  format={TABLE_MONTH_DAY_YEAR_TIME_DATE}
                  isDisabled={!isEditMode || isLoading}
                  {...field}
                  fieldState={fieldState}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={e => field.onChange(e) as any}
                />
              )}
            />

            <Controller
              name="expiryDate"
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  required
                  showTime
                  label="Expiration date"
                  placeholder={DAY_MONTH_YEAR_DATE}
                  format={TABLE_MONTH_DAY_YEAR_TIME_DATE}
                  isDisabled={!isEditMode || isLoading}
                  {...field}
                  fieldState={fieldState}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={e => field.onChange(e) as any}
                />
              )}
            />

            <Controller
              name="discountType"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  required
                  label="Bonus Type"
                  placeholder="Select type"
                  loading={false}
                  {...field}
                  fieldState={fieldState}
                  options={topUpDiscountTypeOptions}
                  disabled={!isEditMode || isLoading}
                  onChange={onChangeDiscountType}
                />
              )}
            />

            {isFmcCentsTypeSelected ? (
              <>
                <Controller
                  name="discountOff"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      label="Bonus (KWD)"
                      placeholder="Set Bonus (KWD)"
                      required
                      min={0}
                      step={1}
                      precision={0}
                      {...field}
                      disabled={!isEditMode || isLoading}
                      fieldState={fieldState}
                    />
                  )}
                />

                <EmptyInputsGridItem />

                <InputNumber
                  label="Amount of coins"
                  placeholder="Amount of coins"
                  min={0}
                  step={1}
                  precision={0}
                  disabled
                  value={calculatedCoinsAmount}
                />
              </>
            ) : (
              <Controller
                name="discountOff"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label={getBonusLabel(discountType)}
                    placeholder={`Set ${getBonusLabel(discountType)}`}
                    required
                    min={0}
                    step={1}
                    precision={0}
                    {...field}
                    disabled={!isEditMode || isLoading}
                    fieldState={fieldState}
                  />
                )}
              />
            )}
          </InputsGridContainer>
        </ContentBox>

        <ContentBox $column $gap={20}>
          <Typography.H2>Users</Typography.H2>

          <Radio.Group onChange={onChangeUsersType} value={usersType}>
            <FlexContainer>
              <Radio value={UsersType.All} disabled={!isEditMode || isLoading}>
                {UsersType.All}
              </Radio>

              <Radio value={UsersType.List} disabled={!isEditMode || isLoading}>
                {UsersType.List}
              </Radio>

              {isFmcCentsTypeSelected && (
                <Radio
                  value={UsersType.UniqueUsers}
                  disabled={!isEditMode || isLoading}
                >
                  {UsersType.UniqueUsers}
                </Radio>
              )}
            </FlexContainer>
          </Radio.Group>

          {usersType === UsersType.UniqueUsers && (
            <InputsGridContainer>
              <Controller
                name="numberOfUniqueUses"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="Number of unique uses"
                    required
                    min={1}
                    step={1}
                    precision={0}
                    {...field}
                    disabled={!isEditMode || isLoading}
                    fieldState={fieldState}
                  />
                )}
              />
            </InputsGridContainer>
          )}
        </ContentBox>

        <ContentBox $column $gap={20}>
          <Typography.H2>Usage Type</Typography.H2>

          <Radio.Group onChange={onChangeUseType} value={useType}>
            <FlexContainer>
              <Radio
                value={UseType.OneTime}
                disabled={!isEditMode || isLoading}
              >
                {UseType.OneTime}
              </Radio>

              <Radio
                value={UseType.Multiple}
                disabled={!isEditMode || isLoading}
              >
                {UseType.Multiple}
              </Radio>
            </FlexContainer>
          </Radio.Group>

          {useType === UseType.Multiple && (
            <InputsGridContainer>
              <Controller
                name="numberOfUses"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="Count of uses"
                    required
                    min={2}
                    step={1}
                    precision={0}
                    {...field}
                    disabled={!isEditMode || isLoading}
                    fieldState={fieldState}
                  />
                )}
              />
            </InputsGridContainer>
          )}
        </ContentBox>

        <ContentBox $column $gap={20}>
          <Typography.H2>Minimum top-up</Typography.H2>

          <Radio.Group onChange={onChangePurchaseType} value={topUpType}>
            <FlexContainer>
              <Radio
                value={TopUpType.WithoutMimimunTopUp}
                disabled={isFmcCentsTypeSelected || !isEditMode || isLoading}
              >
                {TopUpType.WithoutMimimunTopUp}
              </Radio>

              <Radio
                value={TopUpType.MinimumTopUp}
                disabled={isFmcCentsTypeSelected || !isEditMode || isLoading}
              >
                {TopUpType.MinimumTopUp}
              </Radio>

              {isFmcCentsTypeSelected && (
                <Radio
                  value={TopUpType.WithoutTopUp}
                  disabled={!isEditMode || isLoading}
                >
                  {TopUpType.WithoutTopUp}
                </Radio>
              )}
            </FlexContainer>
          </Radio.Group>

          {topUpType === TopUpType.MinimumTopUp && (
            <InputsGridContainer>
              <Controller
                name="minimumOrderFiatCentsPrice"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="Minimum Top-up (KWD)"
                    required
                    min={0}
                    step={0.01}
                    precision={2}
                    {...field}
                    disabled={!isEditMode || isLoading}
                    fieldState={fieldState}
                  />
                )}
              />
            </InputsGridContainer>
          )}
        </ContentBox>
      </FlexContainer>
    </>
  );
};
