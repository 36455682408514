// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { SetCoreSettingsRequestDto } from "types/coreSettings";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";
import { coreSettingsPayloadMapper } from "utils/mappers";

export const coreSettingsAPI = createApi({
  reducerPath: "coreSettingsAPI",
  baseQuery: coreQuery(),
  tagTypes: [],

  endpoints: build => ({
    updateCoreSettings: build.mutation<void, SetCoreSettingsRequestDto>({
      query: data => ({
        url: endpoints.coreSettings,
        method: "PUT",
        data: coreSettingsPayloadMapper(data),
      }),
    }),
  }),
});

export const { useUpdateCoreSettingsMutation } = coreSettingsAPI;
