// Lib
import { FC, useEffect } from "react";
// Hooks
import {
  useAppDispatch,
  useAppSelector,
  useExport,
  useNotification,
  useViewport,
} from "hooks";
// Actions
import { setTableFilters } from "rtkQuery/slices/tableFilters";
// Selectors
import { getCustomerTransactionsFilters } from "rtkQuery/selectors/tableFilters";
// Types
import { TablePaginationConfig } from "antd";
import { FilterValue } from "antd/es/table/interface";
import { AdditionalFilter, ETable, FilterStateItem } from "types/tableFilters";
import {
  GetTransactionsResponseDto,
  TransactionFilterTypes,
} from "types/transactions";
// Icons
import { DescriptionBlackIcon, RightBurgerMenuIcon } from "icons";
// Components
import { DropDown, Export, Table } from "components";

import { columns, filtersList, mobileColumns } from "./config";
import { useGetPurposeOptionsQuery } from "rtkQuery/query/authSettingsAPI";
import { rtkQueryParams } from "consts";
import { errorHandler } from "utils/errorHandler";
import { Button } from "styled/Buttons";

interface TransactionsTabProps {
  isLoading: boolean;
  isExportLoading: boolean;
  transactionsData: GetTransactionsResponseDto;
  //TODO: remove comment if BE is ready
  // search: string;
  // setSearch: React.Dispatch<React.SetStateAction<string>>;
  handleSort: (field: string, direction: string) => void;
  getExportData: () => Promise<Record<string, unknown>[]>;
}

export const TransactionsTab: FC<TransactionsTabProps> = ({
  isLoading,
  isExportLoading,
  transactionsData,
  //TODO: remove comment if BE is ready
  // search,
  // setSearch,
  handleSort,
  getExportData,
}) => {
  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();
  const { downloadCSV } = useExport();

  const filters = useAppSelector(getCustomerTransactionsFilters);
  const dispatch = useAppDispatch();

  const { data: optionsData, error: optionsError } = useGetPurposeOptionsQuery(
    null,
    rtkQueryParams,
  );

  useEffect(() => {
    if (optionsError) {
      errorHandler({ error: optionsError, openNotification });
    }
  }, [optionsError]);

  const setTableFilterValues = (filters: FilterStateItem[]) => {
    dispatch(setTableFilters({ name: ETable.CustomerTransactions, filters }));
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const filtersDataList = optionsData?.length
    ? filtersList.map(f =>
        f.key === TransactionFilterTypes.purpose
          ? {
              ...f,
              options: optionsData.map(({ name }) => ({
                type: name,
                label: name,
              })),
            }
          : f,
      )
    : filtersList;

  const dropDownMenuItems = [
    {
      key: "1",
      label: "Export",
      onClick: () =>
        downloadCSV({
          fileName: "transactions",
          isDataLoading: isLoading,
          columns: columns,
          getExportData: getExportData,
        }),
    },
  ];

  return (
    <Table
      isLoading={isLoading}
      dataSource={transactionsData?.items || []}
      columns={isDesktop ? columns : mobileColumns}
      empty={{
        icon: DescriptionBlackIcon,
        title: "No transactions to show.",
        description: "Try changing sections or change the filters",
      }}
      header={{
        //TODO: remove comment if BE is ready
        // search: {
        //   placeholder: "Search Location,  Order ID, Delivery status",
        //   value: search,
        //   setValue: setSearch,
        // },
        totalCount: transactionsData?.totalCount,
        filter: {
          include: [AdditionalFilter.UserInitiated],
          filters: filtersDataList,
          selected: filters || [],
          setValue: setTableFilterValues,
        },
        suffix: isDesktop ? (
          <Export
            type="Base"
            fileName="transactions"
            isLoading={isExportLoading}
            isDataLoading={isLoading}
            isDisabled={!transactionsData?.totalCount}
            columns={columns}
            getExportData={getExportData}
          />
        ) : (
          <DropDown items={dropDownMenuItems} trigger={["click"]}>
            <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
          </DropDown>
        ),
      }}
      onChange={handleTableChange}
      //TODO: change scroll.x when table will be finished
      scroll={{ x: 3040 }}
    />
  );
};
