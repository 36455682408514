import { MenuProductResponseDto } from "types/recommended";

export const getFiltered = ({
  page,
  limit,
  search,
  productItemsList,
}: {
  page: number;
  limit: number;
  search: string;
  productItemsList: MenuProductResponseDto[];
}): { itemsToShow: MenuProductResponseDto[]; totalCount: number } => {
  let data = productItemsList;

  if (search) {
    data = productItemsList.filter(p =>
      p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  }

  const indexOfLastItem = page * limit;
  const indexOfFirstItem = indexOfLastItem - limit;
  const itemsToShow = data.slice(indexOfFirstItem, indexOfLastItem);

  return { itemsToShow, totalCount: data.length };
};
