// Lib
import { FC, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks/redux";
// Actions
import { setIsUserSideBarOpen, userLogout } from "rtkQuery/slices";
// Selectors
import { getUserEmail } from "rtkQuery/selectors";
// Types
import { MenuProps } from "antd";
import { SidebarMenuItemType } from "types/common";
// Icons
import { AvatarIcon, LogoutIcon } from "icons";
// Components
import Logo from "components/Logo";
// Styled
import { FlexContainer } from "styled/Box";
import {
  BurgerButton,
  HeaderContainer,
  UserTitle,
  Avatar,
  Menu,
  Drawer,
  LogoutButton,
} from "./styled";

type TMenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: TMenuItem[],
  type?: "group",
): TMenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as TMenuItem;
}

interface MobileHeaderProps {
  collapsed: boolean;
  sidebarMenuItems: SidebarMenuItemType[];
}

export const MobileHeader: FC<MobileHeaderProps> = ({
  collapsed,
  sidebarMenuItems,
}) => {
  const userEmail = useAppSelector(getUserEmail);

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  //scroll to top after change route
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsUserSideBarOpen(true));
  }, [pathname]);

  const handleLogOut = () => {
    dispatch(userLogout());
  };

  const handleCollapse = () => {
    dispatch(setIsUserSideBarOpen(!collapsed));
  };

  const items: TMenuItem[] = sidebarMenuItems.map(
    ({ Icon, label, path, type, list, key }) => {
      if (type === "link") {
        return getItem(<NavLink to={path}>{label}</NavLink>, key, <Icon />);
      }
      if (type === "list") {
        return getItem(
          label,
          key,
          <Icon />,
          list.map(({ label, path, key }) =>
            getItem(<NavLink to={path}>{label}</NavLink>, key),
          ),
        );
      }
    },
  );

  const selected = pathname.split("/");

  return (
    <HeaderContainer>
      <BurgerButton
        $collapsed={false}
        $color={"white"}
        onClick={handleCollapse}
      >
        <i />
        <i />
        <i />
      </BurgerButton>

      <Logo />

      <div style={{ width: 40 }}></div>

      <Drawer
        open={!collapsed}
        placement="left"
        closable={false}
        onClose={handleCollapse}
      >
        <div>
          <FlexContainer $fullwidth $justify="flex-end">
            <BurgerButton
              $collapsed={true}
              $color={"black"}
              onClick={handleCollapse}
            >
              <i />
              <i />
              <i />
            </BurgerButton>
          </FlexContainer>

          <FlexContainer $align="center" $margin="0 0 12px 16px">
            <Avatar size={42} icon={<AvatarIcon width="40" height="40" />} />

            <UserTitle>{userEmail || "user-test@gmail.com"}</UserTitle>
          </FlexContainer>

          <Menu
            selectedKeys={selected}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={items}
          />

          <LogoutButton onClick={handleLogOut}>
            <LogoutIcon />

            <span>Logout</span>
          </LogoutButton>
        </div>
      </Drawer>
    </HeaderContainer>
  );
};
