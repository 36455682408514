// Lib
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
// Api
import { useGetTalabatOrderQuery } from "rtkQuery/query/ordersAPI";
// Hooks
import { useNotification } from "hooks";
// Constants
import { ADMIN_ROUTES, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink } from "components";

import { OrderDetail, Summary } from "./components";
// Styled
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";
import { MainInfoWrapper } from "./styled";

export const TalabatOrderDetails: FC = () => {
  const { id } = useParams();

  const { openNotification } = useNotification();

  const { data, isFetching, error } = useGetTalabatOrderQuery(
    { id },
    rtkQueryParams,
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  return (
    <FlexContainer $fullwidth $column $gap={24}>
      <PageHeadingContainer>
        <PageTitleContainer $column $gap={16}>
          <ArrowBackLink
            title="Back to Orders"
            path={`${ADMIN_ROUTES.ORDERS.path}?type=Talabat`}
          />

          <Typography.H1>Order Detail</Typography.H1>
        </PageTitleContainer>
      </PageHeadingContainer>

      <MainInfoWrapper>
        <Summary isLoading={isFetching} data={data} />

        <OrderDetail isLoading={isFetching} data={data?.items || []} />
      </MainInfoWrapper>
    </FlexContainer>
  );
};
