import { generateRequestUrl } from "helpers/dataHelpers";
// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  CreateOrderDeliveryManualRequestDto,
  CreateOrderDeliveryManualResponseDto,
  CreateOrderRequestDto,
  CreateOrderResponseDto,
  CreateTalabatOrderRequestDto,
  GetOrdersResponseDto,
  GetScheduledOrdersResponseDto,
  GetTalabatOrdersResponseDto,
  OrderResponseDto,
  ScheduledOrderResponseDto,
  ScheduleOrdersSettingsDto,
  TalabatOrderResponseDto,
} from "types/orders";
// Helpers
import { ordersUrlBuilder } from "./urlBuilder/orders";
// Utils
import { coreQuery } from "utils/baseQuery";
import {
  createOrderPayloadMapper,
  createTalabatOrderPayloadMapper,
  orderMapper,
  ordersMapper,
  scheduledOrderMapper,
  scheduledOrdersMapper,
  talabatOrderMapper,
  talabatOrdersMapper,
} from "utils/mappers";
import { endpoints } from "consts";

export const ordersAPI = createApi({
  reducerPath: "ordersAPI",
  baseQuery: coreQuery(),
  tagTypes: ["orders", "order", "schedule-orders-settings", "talabat-orders"],

  endpoints: build => ({
    getOrders: build.query<GetOrdersResponseDto, { query: UrlQueryParams }>({
      query: ({ query }) => {
        return {
          url: ordersUrlBuilder.getOrders({ query }),
          method: "GET",
        };
      },
      transformResponse: (response: GetOrdersResponseDto) =>
        ordersMapper(response),
      providesTags: ["orders"],
    }),
    getTalabatOrders: build.query<
      GetTalabatOrdersResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => {
        return {
          url: ordersUrlBuilder.getTalabatOrders({ query }),
          method: "GET",
        };
      },
      transformResponse: (response: GetTalabatOrdersResponseDto) =>
        talabatOrdersMapper(response),
      providesTags: ["talabat-orders"],
    }),
    getScheduledOrders: build.query<
      GetScheduledOrdersResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => {
        return {
          url: ordersUrlBuilder.getScheduledOrders({ query }),
          method: "GET",
        };
      },
      transformResponse: (response: GetScheduledOrdersResponseDto) =>
        scheduledOrdersMapper(response),
      providesTags: ["orders"],
    }),
    getOrder: build.query<OrderResponseDto, { id: string }>({
      query: ({ id }) => {
        return {
          url: ordersUrlBuilder.getOrder({ id }),
          method: "GET",
        };
      },
      transformResponse: (response: OrderResponseDto) => orderMapper(response),
      providesTags: ["order"],
    }),
    getTalabatOrder: build.query<TalabatOrderResponseDto, { id: string }>({
      query: ({ id }) => {
        return {
          url: ordersUrlBuilder.getTalabatOrder({ id }),
          method: "GET",
        };
      },
      transformResponse: (response: TalabatOrderResponseDto) =>
        talabatOrderMapper(response),
      providesTags: ["order"],
    }),
    getScheduledOrder: build.query<ScheduledOrderResponseDto, { id: string }>({
      query: ({ id }) => {
        return {
          url: ordersUrlBuilder.getScheduledOrder({ id }),
          method: "GET",
        };
      },
      transformResponse: (response: ScheduledOrderResponseDto) =>
        scheduledOrderMapper(response),
      providesTags: ["order"],
    }),
    getScheduleOrdersSettings: build.query<ScheduleOrdersSettingsDto, void>({
      query: () => ({
        url: `${endpoints.scheduleOrders}/settings`,
        method: "GET",
      }),
      providesTags: ["schedule-orders-settings"],
    }),
    updateScheduleOrdersSettings: build.mutation<
      ScheduleOrdersSettingsDto,
      ScheduleOrdersSettingsDto
    >({
      query: data => ({
        url: `${endpoints.scheduleOrders}/settings`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["schedule-orders-settings"],
    }),
    createOrderDeliveryManual: build.mutation<
      CreateOrderDeliveryManualResponseDto,
      CreateOrderDeliveryManualRequestDto
    >({
      query: ({ customerId, ...data }) => ({
        url: generateRequestUrl(endpoints.deliveriesManual, { customerId }),
        method: "POST",
        data,
      }),
    }),
    createOrder: build.mutation<CreateOrderResponseDto, CreateOrderRequestDto>({
      query: ({ customerId, ...data }) => ({
        url: generateRequestUrl(endpoints.orders, { customerId }),
        method: "POST",
        data: createOrderPayloadMapper(data),
      }),
    }),
    createTalabatOrder: build.mutation<
      CreateOrderResponseDto,
      CreateTalabatOrderRequestDto
    >({
      query: data => ({
        url: `${endpoints.orders}/external`,
        method: "POST",
        data: createTalabatOrderPayloadMapper(data),
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetTalabatOrdersQuery,
  useLazyGetOrdersQuery,
  useGetScheduledOrdersQuery,
  useLazyGetScheduledOrdersQuery,
  useGetOrderQuery,
  useGetTalabatOrderQuery,
  useGetScheduledOrderQuery,
  useLazyGetScheduleOrdersSettingsQuery,
  useUpdateScheduleOrdersSettingsMutation,
  useCreateOrderMutation,
  useCreateOrderDeliveryManualMutation,
  useCreateTalabatOrderMutation,
} = ordersAPI;
