import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const DoneBlackIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="done_black_24dp 1" clipPath="url(#clip0_3534_4458)">
      <path
        id="Vector"
        d="M8.9999 16.2001L4.7999 12.0001L3.3999 13.4001L8.9999 19.0001L20.9999 7.0001L19.5999 5.6001L8.9999 16.2001Z"
        fill={fill || "#3A834D"}
      />
    </g>
    <defs>
      <clipPath id="clip0_3534_4458">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
