// Lib
import { FC, useState } from "react";
// Types
import { TableAction } from "types/common";
import { CouponAllowedUser } from "types/coupons";
import { UsersType } from "../../types";
// Theme
import { theme } from "theme";
// Icons
import { DescriptionBlackIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { Table } from "components";
import { ConfirmDialog } from "components/Modals";
import { AddCustomersModal } from "../AddCustomersModal";
// Styled
import { Button } from "styled/Buttons";

import { columns } from "./config";

interface UsersTabProps {
  isEditMode: boolean;
  isLoading: boolean;
  allowedUsers: CouponAllowedUser[];
  handleAddCustomers: (customers: CouponAllowedUser[]) => void;
}

export const UsersTab: FC<UsersTabProps> = ({
  isEditMode,
  isLoading,
  allowedUsers,
  handleAddCustomers,
}) => {
  const [removeModal, setRemoveModal] = useState<false | CouponAllowedUser>(
    false,
  );
  const [addCustomersModal, setAddCustomersModal] = useState(false);

  const getModalTitle = (): string => {
    if (!!removeModal && removeModal?.fullName) {
      return `${removeModal?.fullName} will be removed`;
    }

    return "This user will be removed";
  };

  const onConfirmDelete = () => {
    if (!removeModal) {
      return;
    }
    handleAddCustomers(allowedUsers.filter(u => u?.id !== removeModal?.id));
    setRemoveModal(false);
  };

  // TODO: add this action after client aprove
  const actions: TableAction[] = [
    {
      title: "",
      Icon: TrashIcon,
      type: "Grey",
      loadingId: null,
      disabled: !isEditMode || isLoading,
      onClick: (row: CouponAllowedUser) => setRemoveModal(row),
    },
  ];

  return (
    <>
      <Table
        isLoading={false}
        dataSource={allowedUsers || []}
        actions={actions}
        columns={columns}
        empty={{
          icon: DescriptionBlackIcon,
          title: "Must contain at least one user.",
          description: `Add users or change Coupon Users type to "${UsersType.All}".`,
        }}
        header={{
          totalCount: allowedUsers?.length || 0,
          suffix: isEditMode ? (
            <Button.Base
              type="primary"
              icon={<PlusIcon fill={theme.color.white} />}
              disabled={isLoading}
              onClick={() => setAddCustomersModal(true)}
            >
              Add User
            </Button.Base>
          ) : (
            <div style={{ height: 40 }} />
          ),
        }}
      />

      <AddCustomersModal
        open={addCustomersModal}
        allowedUsers={allowedUsers}
        handleAddCustomers={handleAddCustomers}
        handleClose={() => setAddCustomersModal(false)}
      />

      <ConfirmDialog
        open={!!removeModal}
        Icon={TrashIcon}
        message={getModalTitle()}
        description="Are you sure to continue this action?"
        onCancel={() => setRemoveModal(false)}
        firstCTAButton={{
          title: "Remove User",
          status: "danger",
          loading: false,
          onClick: onConfirmDelete,
        }}
        isLoading={false}
      />
    </>
  );
};
