import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const ShoppingCartIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_8329_6442)">
      <path
        d="M4.66669 11.9997C3.93335 11.9997 3.34002 12.5997 3.34002 13.333C3.34002 14.0663 3.93335 14.6663 4.66669 14.6663C5.40002 14.6663 6.00002 14.0663 6.00002 13.333C6.00002 12.5997 5.40002 11.9997 4.66669 11.9997ZM0.666687 1.99967C0.666687 2.36634 0.966687 2.66634 1.33335 2.66634H2.00002L4.40002 7.72634L3.50002 9.35301C3.01335 10.2463 3.65335 11.333 4.66669 11.333H12C12.3667 11.333 12.6667 11.033 12.6667 10.6663C12.6667 10.2997 12.3667 9.99967 12 9.99967H4.66669L5.40002 8.66634H10.3667C10.8667 8.66634 11.3067 8.39301 11.5334 7.97967L13.92 3.65301C14.1667 3.21301 13.8467 2.66634 13.34 2.66634H3.47335L3.02669 1.71301C2.92002 1.47967 2.68002 1.33301 2.42669 1.33301H1.33335C0.966687 1.33301 0.666687 1.63301 0.666687 1.99967ZM11.3334 11.9997C10.6 11.9997 10.0067 12.5997 10.0067 13.333C10.0067 14.0663 10.6 14.6663 11.3334 14.6663C12.0667 14.6663 12.6667 14.0663 12.6667 13.333C12.6667 12.5997 12.0667 11.9997 11.3334 11.9997Z"
        fill={fill || "black"}
      />
    </g>
    <defs>
      <clipPath id="clip0_8329_6442">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
