// Lib
import { FC } from "react";
// Types
import { TabKey } from "../../types";
// Icons
import { InfoRedIcon } from "icons";
//Components
import { Tooltip } from "components";
// Styled
import { LabelContainer } from "./styled";

interface TabLabelProps {
  label: TabKey | string;
  isError?: boolean;
}

export const TabLabel: FC<TabLabelProps> = ({ label, isError }) => {
  return (
    <LabelContainer $error={isError}>
      {label}
      {isError && (
        <Tooltip title="Must contain at least one user">
          <InfoRedIcon />
        </Tooltip>
      )}
    </LabelContainer>
  );
};
