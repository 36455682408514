// Lib
import { FC, useState } from "react";
// Api
import { useUpdateRecommendedProductsMutation } from "rtkQuery/query/recommendedAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { ETable } from "types/tableFilters";
import {
  MenuItem,
  MenuProductResponseDto,
  RecommendedMenuItem,
} from "types/recommended";
// Helpers
import { getFiltered } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { SearchBlackIcon } from "icons";
// Components
import { Pagination, Table } from "components";
import { Modal } from "components/Modal";
import { Input } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";

import { columns, mobileColumns } from "./config";

interface AddItemsModalProps {
  locationId: string | null;
  parentProduct: MenuItem | false;
  productItemsList: MenuProductResponseDto[];
  handleAddItems: (parentId: string, items: MenuProductResponseDto[]) => void;
  handleClose: () => void;
}

export const AddItemModal: FC<AddItemsModalProps> = ({
  locationId,
  productItemsList,
  parentProduct,
  handleAddItems,
  handleClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile, isDesktop } = useViewport();

  const [update, { isLoading }] = useUpdateRecommendedProductsMutation();

  const { page, limit, search, setPage, setLimit, setSearch, handleSort } =
    useTable({ name: ETable.Recommended, removeQueryParams: true });

  const [selected, setSelected] = useState<MenuProductResponseDto[]>([]);

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: MenuProductResponseDto[]) => {
      setSelected(selectedRows);
    },
  };

  const onClose = () => {
    if (isLoading) return;

    setSelected([]);
    handleClose();
  };

  const addSelectedItems = async () => {
    if (!locationId) return;

    if (typeof parentProduct === "boolean") return;

    const isAlreadyContainItem = (): RecommendedMenuItem | undefined => {
      const newItemsIds = selected.map(i => i.id);
      return parentProduct?.recommendedItems?.find(r =>
        newItemsIds.includes(r.itemId),
      );
    };

    const itemContain = isAlreadyContainItem();

    if (itemContain) {
      openNotification({
        message: `${itemContain.name} is already added to the ${parentProduct.name}`,
        type: "error",
      });
      return;
    }

    try {
      const items = [
        {
          productId: parentProduct.id,
          name: parentProduct.name,
          order: 0,
          recommendedItems: parentProduct.recommendedItems
            .map(p => ({
              productId: p.itemId,
              name: p.name,
              order: p.order,
            }))
            .concat(
              selected.map(p => ({
                productId: p.id,
                name: p.name,
                order: 0,
              })),
            ),
        },
      ];

      await update({ locationId, data: { items } }).unwrap();

      handleAddItems(parentProduct.id, selected);

      openNotification({
        message: `${selected?.length} recommended items added to ${
          parentProduct?.name || "menu"
        }`,
      });

      onClose();
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const { itemsToShow, totalCount } = getFiltered({
    page,
    limit,
    search,
    productItemsList,
  });

  const title = parentProduct
    ? `Add item to ${parentProduct?.name || "menu"}`
    : "Add item";

  const submitButtonTitle = !selected.length
    ? "Add Product"
    : `Add ${selected.length} items`;

  return (
    <Modal title={title} open={!!parentProduct} width={1150} onClose={onClose}>
      <FlexContainer $fullwidth $column $gap={20}>
        <FlexContainer
          $fullwidth={isMobile}
          $column={isMobile}
          $align={isMobile ? "flex-start" : "center"}
          $justify="space-between"
          $gap={16}
        >
          <Typography.TitleThin>
            Showing {itemsToShow?.length || 0} of {totalCount} items
          </Typography.TitleThin>

          <Input
            fullWidth={isMobile}
            prefix={<SearchBlackIcon />}
            placeholder="Search menu"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </FlexContainer>

        <Table
          rowKey={record => record.id}
          isLoading={false}
          accentColumn={3}
          columns={!isDesktop ? mobileColumns : columns}
          dataSource={itemsToShow}
          scroll={{ y: "55vh" }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selected.map(m => m.id),
            ...rowSelection,
          }}
          onChange={handleTableChange}
        />

        <Pagination
          padding="12px 0"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          totalItems={totalCount}
        />
      </FlexContainer>

      <FlexContainer
        $fullwidth
        $column={isMobile}
        $justify="flex-end"
        $gap={8}
        $padding="24px 0 0"
      >
        <Button.Heading
          $fullWidth={isMobile}
          disabled={isLoading}
          onClick={onClose}
        >
          Cancel
        </Button.Heading>

        <Button.Heading
          $fullWidth={isMobile}
          type="primary"
          loading={isLoading}
          disabled={!selected.length}
          onClick={addSelectedItems}
        >
          {submitButtonTitle}
        </Button.Heading>
      </FlexContainer>
    </Modal>
  );
};
