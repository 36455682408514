import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const CurrencyIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66833 8.68867C1.66833 7.14103 5.39935 5.11719 10.0018 5.11719C14.6043 5.11719 18.3353 7.14103 18.3353 8.68867V12.2602C18.3353 13.8078 14.6043 15.8316 10.0018 15.8316C5.39935 15.8316 1.66833 13.8078 1.66833 12.2602V8.68867Z"
      fill={fill || "#66826A"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0018 12.2411C14.6043 12.2411 18.3353 10.3292 18.3353 8.68629C18.3353 7.04222 14.6043 5.11719 10.0018 5.11719C5.39935 5.11719 1.66833 7.04222 1.66833 8.68629C1.66833 10.3292 5.39935 12.2411 10.0018 12.2411Z"
      fill={fill || "#002E07"}
    />
  </svg>
);
