// Lib
import { FC, memo, useEffect, useState } from "react";
// Hooks
import { useViewport } from "hooks";
// Components
import { Modal } from "components";
import { TextArea } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

interface RejectModalProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSave: (reason: string) => void;
}

export const RejectModal: FC<RejectModalProps> = memo(
  ({ open, isLoading, onClose, onSave }) => {
    const { isMobile } = useViewport();

    const [rejectReason, setRejectReason] = useState("");

    useEffect(() => {
      if (!open) {
        setRejectReason("");
      }
    }, [open]);

    const handleClose = () => {
      if (isLoading) return;

      onClose();
    };

    const handleSave = () => {
      onSave(rejectReason);
    };

    return (
      <Modal
        open={open}
        title="Reject review"
        width={560}
        onClose={handleClose}
      >
        <FlexContainer $fullwidth $column $gap={10}>
          <TextArea
            label="Reason"
            placeholder="Enter Reason"
            value={rejectReason}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setRejectReason(e.target.value)
            }
          />

          <FlexContainer
            $fullwidth
            $align="center"
            $justify="flex-end"
            $gap={8}
            $margin="16px 0 0"
          >
            <FlexContainer
              $align="center"
              $justify="center"
              $gap={8}
              $column={isMobile}
              $fullwidth={isMobile}
            >
              <Button.Heading
                $fullWidth={isMobile}
                disabled={isLoading}
                onClick={handleClose}
              >
                Close
              </Button.Heading>

              <Button.Heading
                $fullWidth={isMobile}
                loading={isLoading}
                status="danger"
                onClick={handleSave}
              >
                Reject
              </Button.Heading>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    );
  },
);

RejectModal.displayName = "RejectModal";
