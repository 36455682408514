import { fixedDigitsValue } from "helpers/dataHelpers";

export const columns = [
  {
    title: "From",
    dataIndex: "from",
    key: "from",
    render: (value: number) => `${fixedDigitsValue(value, 3)} KD`,
  },
  {
    title: "To",
    dataIndex: "to",
    key: "to",
    render: (value: number) => `${fixedDigitsValue(value, 3)} KD`,
  },
  {
    title: "Bonus (%)",
    dataIndex: "bonusCoinsPercent",
    key: "bonusCoinsPercent",
    align: "right" as const,
  },
];
