// Lib
import { FC, useEffect } from "react";
// Api
import { useGetExchangeRateQuery } from "rtkQuery/query/exchangeRateAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Constants
import { rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Conversion, Packages, Rules, TrialPackage } from "./components";
// Styled
import { FlexContainer } from "styled/Box";
import { Grid } from "./styled";

export const CoinPackages: FC = () => {
  const { isMobile } = useViewport();
  const { openNotification } = useNotification();

  const {
    data: exchangeRate,
    isLoading: isExchangeRateLoading,
    error: exchangeRateError,
  } = useGetExchangeRateQuery(null, rtkQueryParams);

  useEffect(() => {
    if (exchangeRateError) {
      errorHandler({ error: exchangeRateError, openNotification });
    }
  }, [exchangeRateError]);

  return (
    <FlexContainer $column $gap={16}>
      <Grid>
        <FlexContainer $column $gap={16} $fullwidth>
          <Conversion
            isExchangeRateLoading={isExchangeRateLoading}
            exchangeRate={exchangeRate}
          />

          <TrialPackage exchangeRateValue={exchangeRate?.value} />
        </FlexContainer>

        {isMobile ? (
          <Rules />
        ) : (
          <div>
            <Rules />
          </div>
        )}
      </Grid>

      <Packages />
    </FlexContainer>
  );
};
