// Lib
import { FC } from "react";
import { Skeleton } from "antd";
// Types
import { UserResponseDto } from "types/users";
// Theme
import { theme } from "theme";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Icons
import { AddCircleIcon, EditIcon } from "icons";
// Styled
import { ContentBox } from "styled/Box";
import { Typography } from "styled/Typography";
import {
  Description,
  Devider,
  HistoryItem,
  HistoryItemsWrapper,
  IconWrapper,
} from "./styled";

enum UserHistory {
  Created = "created",
  Updated = "updated",
  OrderMade = "orderMade",
  TopUpBalanceMade = "topUpBalanceMade",
}

const icon = {
  [UserHistory.Created]: AddCircleIcon,
  [UserHistory.Updated]: EditIcon,
};

const title = {
  [UserHistory.Created]: "User data created",
  [UserHistory.Updated]: "User update data",
};

interface HistoryProps {
  isLoading: boolean;
  userData: UserResponseDto;
}

export const History: FC<HistoryProps> = ({ isLoading, userData }) => {
  const data = [
    {
      Icon: icon[UserHistory.Created],
      title: title[UserHistory.Created],
      date: dateTransform({ date: userData?.createdAt }),
    },
    {
      Icon: icon[UserHistory.Updated],
      title: title[UserHistory.Updated],
      date: dateTransform({ date: userData?.updatedAt }),
    },
  ];

  return (
    <ContentBox $column $gap={20}>
      <Typography.H2>History</Typography.H2>

      <HistoryItemsWrapper>
        {isLoading ? (
          <Skeleton active />
        ) : data?.length ? (
          data.map(({ Icon, title, date }, i) => (
            <HistoryItem key={i}>
              <IconWrapper>
                {i !== 0 && <Devider />}
                <Icon fill={theme.color.text.tertiary} />
              </IconWrapper>

              <div>
                <Typography.Title>{title}</Typography.Title>

                <Description>{date}</Description>
              </div>
            </HistoryItem>
          ))
        ) : null}
      </HistoryItemsWrapper>
    </ContentBox>
  );
};
