// Lib
import { FC } from "react";
import VectorSource from "ol/source/Vector";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
// Hooks
import { useMapInteractionContext, usePermissions } from "hooks";
// Types
import { InteractionMode } from "types/locations";
// Layers
import {
  DrawLayer,
  DrawPointLayer,
  PointLayer,
  VectorLayerWrapper,
} from "Layers";
// Components
import { ToggleButtons } from "../ToggleButtons";

import { mapStyles } from "Layers/config";

export const InteractiveMap: FC = () => {
  const { canLocationsUpdate } = usePermissions();

  const {
    editedKitchen,
    addNewZone,
    editZone,
    kitchens,
    toggleRemoveZone,
    activeKitchenId,
    removeNewZones,
    interactionMode,
    setInteractionEditMode,
    addNewKitchenPoint,
  } = useMapInteractionContext();

  const isPolygonActive = interactionMode === InteractionMode.Polygon;
  const isPointActive = interactionMode === InteractionMode.Point;

  return (
    <div>
      {!!activeKitchenId && isPolygonActive && (
        <DrawLayer
          style={mapStyles.NewPolygon}
          {...{ addNewZone, activeKitchenId, removeNewZones }}
        />
      )}
      {canLocationsUpdate && !!activeKitchenId && (
        <ToggleButtons
          value={interactionMode}
          onChange={setInteractionEditMode}
        />
      )}

      {!!isPointActive && (
        <DrawPointLayer addNewKitchenPoint={addNewKitchenPoint} />
      )}

      {kitchens?.map(({ zones, id, kitchenAddress, name }) => (
        <div key={id}>
          {zones.map(({ source, zoneId, name }) => (
            <VectorLayerWrapper
              key={zoneId}
              isPolygonMode={interactionMode === InteractionMode.Polygon}
              {...{
                editedKitchen,
                zoneId,
                name,
                activeKitchenId,
                kitchenId: id,
                source: source as VectorSource<Feature<Geometry>>,
                editZone,
                toggleRemoveZone,
                interactionMode,
              }}
            />
          ))}
          <PointLayer
            name={name}
            coords={kitchenAddress}
            isActive={id === activeKitchenId}
          />
        </div>
      ))}
    </div>
  );
};
