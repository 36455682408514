import { GetLocationResponseDto } from "types/locations";
import { ConnectModalData, ProvidersList } from "../ConnnectModal/types";
import {
  DeliveryProviders,
  GetLocationProvidersResponseDto,
} from "types/deliveryProviders";

const getEditRowId = (
  connectModal: boolean | ConnectModalData,
): string | undefined => {
  if (typeof connectModal !== "boolean") {
    return connectModal.id;
  }

  return;
};

export const getLocationsProviderLists = (
  locationsData: GetLocationResponseDto[],
  providersData: GetLocationProvidersResponseDto[],
  connectModal: boolean | ConnectModalData,
): ProvidersList => {
  const defaultLists: ProvidersList = {
    loopLocations: [],
    deliverectLocations: [],
  };

  if (!locationsData?.length) {
    return defaultLists;
  }

  const editRowId = getEditRowId(connectModal);

  const disabledOptionIds: string[] =
    providersData?.reduce((acc, el) => {
      if (!!editRowId && el.id === editRowId) {
        return acc;
      }

      el.providers.forEach(provider => acc.push(provider.locationId));

      return acc;
    }, []) || [];

  const options = locationsData?.reduce(
    (acc: ProvidersList, el: GetLocationResponseDto) => {
      if (el.deliveryProvider === DeliveryProviders.deliverect) {
        acc.deliverectLocations.push({
          label: el.name,
          value: el.id,
          disabled: disabledOptionIds.includes(el.id),
        });
      }

      if (el.deliveryProvider === DeliveryProviders.loop) {
        acc.loopLocations.push({
          label: el.name,
          value: el.id,
          disabled: disabledOptionIds.includes(el.id),
        });
      }

      return acc;
    },
    defaultLists,
  );

  return options;
};
