export const TABLE_DATE = "M/D/YYYY h:mm A";
export const TABLE_MONTH_DAY_YEAR_TIME_DATE = "MM/DD/YYYY, h:mm:ss A";
export const TABLE_DAY_MONTH_YEAR_TIME_DATE = "DD/MM/YYYY, h:mm:ss A";
export const MONTH_DAY_YEAR_DATE = "MMM D, YYYY";
export const MONTH_DAY_YEAR_HOURS_MINUTES_DATE = "MMM DD, YYYY HH:mm A";
export const DAY_MONTH_DATE = "DD, MMM";
export const DAY_MONTH_YEAR_TIME_DATE = "DD MMM YYYY, HH:mm";
export const FULL_MONTH_DAY_YEAR_HOURS_MINUTES_DATE = "MMMM DD, YYYY HH:mm";
export const HOURS_MINUTES_DATE = "HH:mm";
export const DAY_MONTH_YEAR_DATE = "DD/MM/YYYY";
export const MONTH_DAY_FUll_YEAR_DATE = "DD/MM/YYYY";
