// Lib
import { FC } from "react";
import { Skeleton } from "antd";
// Types
import { GetLocationResponseDto } from "types/locations";
// Theme
import { theme } from "theme";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Icons
import {
  AccountBalanceWalletIcon,
  AddCircleIcon,
  EditIcon,
  ShoppingCartIcon,
} from "icons";
// Styled
import { Typography } from "styled/Typography";
import { ContentBox } from "styled/Box";
import {
  Description,
  Devider,
  HistoryItem,
  HistoryItemsWrapper,
  IconWrapper,
} from "./styled";

export enum DeliveryAreaHistory {
  Created = "created",
  Updated = "updated",
  OrderMade = "orderMade",
  TopUpBalanceMade = "topUpBalanceMade",
}

const icon = {
  [DeliveryAreaHistory.Created]: AddCircleIcon,
  [DeliveryAreaHistory.Updated]: EditIcon,
  [DeliveryAreaHistory.OrderMade]: ShoppingCartIcon,
  [DeliveryAreaHistory.TopUpBalanceMade]: AccountBalanceWalletIcon,
};

const title = {
  [DeliveryAreaHistory.Created]: "Delivery Area data created",
  [DeliveryAreaHistory.Updated]: "Delivery Area update data",
  [DeliveryAreaHistory.OrderMade]: "Delivery Area make an order",
  [DeliveryAreaHistory.TopUpBalanceMade]:
    "Delivery Area make an top up balance",
};

interface HistoryProps {
  isCustomerDataLoading: boolean;
  locationData: GetLocationResponseDto;
}

export const History: FC<HistoryProps> = ({
  isCustomerDataLoading,
  locationData,
}) => {
  const data = [
    {
      Icon: icon[DeliveryAreaHistory.Created],
      title: title[DeliveryAreaHistory.Created],
      date: dateTransform({ date: locationData?.createdAt }),
    },
    {
      Icon: icon[DeliveryAreaHistory.Updated],
      title: title[DeliveryAreaHistory.Updated],
      date: dateTransform({ date: locationData?.updatedAt }),
    },
  ];
  return (
    <ContentBox $fullwidth $column $gap={20} $height="fit-content">
      <Typography.H2>History</Typography.H2>

      <HistoryItemsWrapper>
        {isCustomerDataLoading ? (
          <Skeleton active />
        ) : data?.length ? (
          data.map(({ Icon, title, date }, i) => (
            <HistoryItem key={i}>
              <IconWrapper>
                {i !== 0 && <Devider />}
                <Icon fill={theme.color.text.tertiary} />
              </IconWrapper>

              <div>
                <Typography.Title>{title}</Typography.Title>
                <Description>{date}</Description>
              </div>
            </HistoryItem>
          ))
        ) : null}
      </HistoryItemsWrapper>
    </ContentBox>
  );
};
