// Lib
import { useState, useEffect, FC } from "react";
import { useParams } from "react-router-dom";
import { Skeleton, TabsProps } from "antd";
// Api
import { useGetCustomerQuery } from "rtkQuery/query/customersAPI";
import { useGetCustomerBalanceQuery } from "rtkQuery/query/customersPaymentAPI";
import { useGetOrdersQuery } from "rtkQuery/query/ordersAPI";
import { useGetReferralsQuery } from "rtkQuery/query/referralsAPI";
import {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
} from "rtkQuery/query/transactionsAPI";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
// Types
//TODO: remove comment if BE is ready
// import { ELoyaltyLevelValue } from "types/common";
import { ETable } from "types/tableFilters";
import { UserResponseDto } from "types/users";
// Icons
import { PencilIcon } from "icons";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Helpers
import { getFullName } from "helpers/dataHelpers";
import { getCsvMappedData } from "./helpers";
// Components
import {
  ArrowBackLink,
  Avatar,
  //TODO: remove comment if BE is ready
  // LoyaltyLevel,
  Pagination,
  Tabs,
} from "components";
import { UserModal, UserModalForm } from "components/Modals";
import {
  SummaryTab,
  HealthDataTab,
  OrdersTab,
  TransactionsTab,
  CustomerStatus,
  ReferralsTab,
} from "../components";
// Styled
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
  PageWrapper,
} from "styled/Box";
import { Typography } from "styled/Typography";

import { TabKey } from "./config";

export const CustomerDetails: FC = () => {
  const { id } = useParams();

  const { isMobile } = useViewport();

  const {
    canTransactionsGet,
    canOrdersGet,
    canBalanceGet,
    canCustomerVIPStatusUpdate,
    canUsersUpdate,
  } = usePermissions();

  const { openNotification } = useNotification();

  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.Summary);
  const [userModal, setUserModal] = useState<boolean | UserResponseDto>(false);

  const {
    page: referralsPage,
    limit: referralsLimit,
    setPage: setReferralsPage,
    setLimit: setReferralsLimit,
  } = useTable({ name: ETable.Referrals, removeQueryParams: true });

  const {
    sortingOrder: ordersSortingOrder,
    sortBy: ordersSortBy,
    page: ordersPage,
    limit: ordersLimit,
    //TODO: remove comment if BE is ready
    // search: ordersSearch,
    debouncedSearch: debouncedOrdersSearch,
    debouncedFiltersQuery: debouncedOrdersFiltersQuery,
    setPage: setOrdersPage,
    setLimit: setOrdersLimit,
    //TODO: remove comment if BE is ready
    // setSearch: setOrdersSearch,
    handleSort: handleOrdersSort,
  } = useTable({ name: ETable.CustomerOrders, removeQueryParams: true });

  const {
    sortingOrder: transactionsSortingOrder,
    sortBy: transactionsSortBy,
    page: transactionsPage,
    limit: transactionsLimit,
    //TODO: remove comment if BE is ready
    // search: transactionsSearch,
    debouncedSearch: debouncedTransactionsSearch,
    debouncedFiltersQuery: debouncedTransactionsFiltersQuery,
    setPage: setTransactionsPage,
    setLimit: setTransactionsLimit,
    //TODO: remove comment if BE is ready
    // setSearch: setTransactionsSearch,
    handleSort: handleTransactionsSort,
  } = useTable({
    name: ETable.CustomerTransactions,
    removeQueryParams: true,
  });

  const {
    data: ordersData,
    isFetching: isOrdersLoading,
    error: ordersError,
  } = useGetOrdersQuery(
    {
      query: {
        ...(debouncedOrdersFiltersQuery && debouncedOrdersFiltersQuery),
        customerId: id,
        page: ordersPage,
        limit: ordersLimit,
        sortBy: ordersSortBy,
        sortingOrder: ordersSortingOrder,
        search: debouncedOrdersSearch,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: activeTab !== TabKey.Orders,
    },
  );

  const {
    data: transactionsData,
    isFetching: isTransactionsLoading,
    error: transactionsError,
  } = useGetTransactionsQuery(
    {
      query: {
        ...(debouncedTransactionsFiltersQuery &&
          debouncedTransactionsFiltersQuery),
        customerId: id,
        page: transactionsPage,
        limit: transactionsLimit,
        sortBy: transactionsSortBy,
        sortingOrder: transactionsSortingOrder,
        search: debouncedTransactionsSearch,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: activeTab !== TabKey.Transactions,
    },
  );
  const {
    data: referralsData,
    isFetching: isReferralsLoading,
    error: referralsError,
  } = useGetReferralsQuery(
    {
      id,
      query: {
        page: referralsPage,
        limit: referralsLimit,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: activeTab !== TabKey.Referrals,
    },
  );

  const [getExportTransactionsData, { isFetching: isExportLoading }] =
    useLazyGetTransactionsQuery();

  useEffect(() => {
    if (referralsError) {
      errorHandler({ error: referralsError, openNotification });
    }
  }, [referralsError]);

  useEffect(() => {
    if (ordersError) {
      errorHandler({ error: ordersError, openNotification });
    }
  }, [ordersError]);

  useEffect(() => {
    if (transactionsError) {
      errorHandler({ error: transactionsError, openNotification });
    }
  }, [transactionsError]);

  const {
    data: customerData,
    isFetching: isCustomerDataLoading,
    error: customerError,
    refetch,
  } = useGetCustomerQuery({ id }, rtkQueryParams);

  const {
    data: customerBalance,
    isFetching: isCustomerBalanceLoading,
    error: customerBalanceError,
  } = useGetCustomerBalanceQuery(
    { id },
    { ...rtkQueryParams, skip: !canBalanceGet },
  );

  useEffect(() => {
    if (customerError) {
      errorHandler({ error: customerError, openNotification });
    }
  }, [customerError]);

  useEffect(() => {
    if (customerBalanceError) {
      errorHandler({ error: customerBalanceError, openNotification });
    }
  }, [customerBalanceError]);

  const [getUserData, { isFetching: isFetchingUserData }] =
    useLazyGetUserQuery();

  const [updateUser, { isLoading: isUserUpdateLoading }] =
    useUpdateUserMutation();

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      const data = await getExportTransactionsData({
        query: {
          ...(debouncedTransactionsFiltersQuery &&
            debouncedTransactionsFiltersQuery),
          customerId: id,
          limit: transactionsData?.totalCount,
          sortBy: transactionsSortBy,
          sortingOrder: transactionsSortingOrder,
          search: debouncedTransactionsSearch,
        },
      }).unwrap();

      return getCsvMappedData(data.items);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: TabKey.Summary,
      label: TabKey.Summary,
      children: (
        <SummaryTab
          isCustomerDataLoading={isCustomerDataLoading}
          isCustomerBalanceLoading={isCustomerBalanceLoading}
          customerData={customerData}
          customerBalance={customerBalance}
        />
      ),
    },
    {
      key: TabKey.HealthData,
      label: TabKey.HealthData,
      children: (
        <HealthDataTab
          isCustomerDataLoading={isCustomerDataLoading}
          customerData={customerData}
        />
      ),
    },
    canOrdersGet && {
      key: TabKey.Orders,
      label: TabKey.Orders,
      children: (
        <OrdersTab
          isLoading={isOrdersLoading}
          ordersData={ordersData}
          //TODO: remove comment if BE is ready
          // search={ordersSearch}
          // setSearch={setOrdersSearch}
          handleSort={handleOrdersSort}
        />
      ),
    },
    canTransactionsGet && {
      key: TabKey.Transactions,
      label: TabKey.Transactions,
      children: (
        <TransactionsTab
          isLoading={isTransactionsLoading}
          transactionsData={transactionsData}
          isExportLoading={isExportLoading}
          getExportData={getExportData}
          //TODO: remove comment if BE is ready
          // search={transactionsSearch}
          // setSearch={setTransactionsSearch}
          handleSort={handleTransactionsSort}
        />
      ),
    },
    {
      key: TabKey.Referrals,
      label: TabKey.Referrals,
      children: (
        <ReferralsTab
          isLoading={isReferralsLoading}
          referralsData={referralsData}
        />
      ),
    },
  ];

  const { firstName, lastName, isVip } = customerData || {};

  const name = getFullName({ firstName, lastName });

  const isPagination =
    activeTab === TabKey.Orders ||
    activeTab === TabKey.Transactions ||
    activeTab === TabKey.Referrals;

  const limit =
    activeTab === TabKey.Orders
      ? ordersLimit
      : activeTab === TabKey.Transactions
      ? transactionsLimit
      : activeTab === TabKey.Referrals
      ? referralsLimit
      : undefined;

  const page =
    activeTab === TabKey.Orders
      ? ordersPage
      : activeTab === TabKey.Transactions
      ? transactionsPage
      : activeTab === TabKey.Referrals
      ? referralsPage
      : undefined;

  const totalItems =
    activeTab === TabKey.Orders
      ? ordersData?.totalCount
      : activeTab === TabKey.Transactions
      ? transactionsData?.totalCount
      : activeTab === TabKey.Referrals
      ? referralsData?.totalCount
      : undefined;

  const setLimit = (limit: number) => {
    switch (activeTab) {
      case TabKey.Orders:
        setOrdersLimit(limit);
        break;
      case TabKey.Transactions:
        setTransactionsLimit(limit);
        break;
      case TabKey.Referrals:
        setReferralsLimit(limit);
        break;

      default:
        break;
    }
  };

  const setPage = (page: number) => {
    switch (activeTab) {
      case TabKey.Orders:
        setOrdersPage(page);
        break;
      case TabKey.Transactions:
        setTransactionsPage(page);
        break;
      case TabKey.Referrals:
        setReferralsPage(page);
        break;

      default:
        break;
    }
  };

  const handleOpenUserModal = async () => {
    try {
      const userData = await getUserData({ id }).unwrap();
      setUserModal(userData);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onUserSave = async (data: UserModalForm) => {
    const isEdit = !!userModal && typeof userModal !== "boolean";

    if (!isEdit) {
      return;
    }

    try {
      const {
        id,
        firstName,
        lastName,
        roleName,
        email,
        newPassword,
        phoneNumber,
        customerActivated,
      } = data || {};

      await updateUser({
        id,
        firstName,
        lastName,
        roleName,
        email,
        phoneNumber,
        newPassword,
        customerActivated,
      }).unwrap();

      openNotification({ message: NOTIFICATIONS.CUSTOMER_UPDATE });

      refetch();

      setUserModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer $fullwidth $column $gap={24} $grow={1}>
          <PageHeadingContainer
            $column={isMobile}
            $fullwidth={isMobile}
            $align={isMobile ? "flex-start" : undefined}
          >
            <PageTitleContainer
              $column
              $fullwidth={isMobile}
              $align={isMobile ? "flex-start" : undefined}
            >
              <ArrowBackLink
                title="Back to Customers"
                path={ADMIN_ROUTES.CUSTOMERS.path}
              />

              <FlexContainer
                $gap={12}
                $align="center"
                $justify="center"
                $margin="20px 0 0 0"
              >
                {isCustomerDataLoading ? (
                  <>
                    <Skeleton.Avatar active size={"default"} shape={"circle"} />
                    <Skeleton.Input active size={"default"} />
                  </>
                ) : name ? (
                  <>
                    <Avatar name={name} size="xl" />{" "}
                    <Typography.H1>{name}</Typography.H1>{" "}
                  </>
                ) : null}
                {/* //TODO: add correct data if BE is ready */}
                {/* <LoyaltyLevel level={ELoyaltyLevelValue.BRONZE} size="xl" /> */}
              </FlexContainer>
            </PageTitleContainer>

            {canCustomerVIPStatusUpdate &&
              customerData &&
              !isCustomerDataLoading && (
                <CustomerStatus
                  id={customerData?.id}
                  isLoading={isCustomerDataLoading}
                  status={isVip}
                />
              )}

            {/* // TODO: add this action after client aprove */}
            {/* {!!canUsersUpdate && (
              <Button.Heading
                icon={<PencilIcon />}
                loading={isFetchingUserData}
                onClick={handleOpenUserModal}
              >
                Edit
              </Button.Heading>
            )} */}
          </PageHeadingContainer>

          <Tabs
            activeKey={activeTab}
            items={items}
            onChange={(activeKey: TabKey) => setActiveTab(activeKey)}
          />
        </FlexContainer>

        {isPagination && (
          <Pagination
            onPage
            padding="12px 16px"
            limit={limit}
            page={page}
            setLimit={setLimit}
            setPage={setPage}
            totalItems={totalItems}
          />
        )}
      </PageWrapper>

      <UserModal
        customerModal
        userData={userModal}
        isLoading={isUserUpdateLoading}
        onSave={onUserSave}
        onClose={() => setUserModal(false)}
      />
    </>
  );
};
