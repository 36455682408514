import { styled } from "styled-components";
import { theme } from "theme";

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 384px 1fr;

  background: ${theme.color.white};
  box-shadow: ${theme.shadow.table};
  border-radius: ${theme.borderRadius.base};

  overflow: auto;
  min-height: calc(100vh - 225px);

  @media screen and (max-width: 1130px) {
    min-height: auto;
    grid-template-columns: 1fr;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 360px;
  grid-gap: 24px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr;
  }
`;
