import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const ShareIcon: FC<SvgIconConstituentValues> = ({ fill, ...props }) => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="24"
  //   height="24"
  //   viewBox="0 0 100 100"
  //   id="share"
  //   {...props}
  // >
  //   <g>
  //     <path d="M75 64c-4.8 0-9.1 2.3-11.8 5.8L38.9 55.7c1.5-3.7 1.5-7.8 0-11.5l24.3-14.1c2.7 3.5 7 5.8 11.8 5.8 8.3 0 15-6.7 15-15s-6.7-15-15-15-15 6.7-15 15c0 2 .4 4 1.1 5.7L36.8 40.8C34 37.3 29.8 35 25 35c-8.3 0-15 6.7-15 15s6.7 15 15 15c4.8 0 9.1-2.3 11.8-5.8l24.3 14.1C60.4 75 60 77 60 79c0 8.3 6.7 15 15 15s15-6.7 15-15-6.7-15-15-15zm0-54c6.1 0 11 4.9 11 11s-4.9 11-11 11-11-4.9-11-11 4.9-11 11-11zM25 61c-6.1 0-11-4.9-11-11s4.9-11 11-11 11 4.9 11 11-4.9 11-11 11zm50 29c-6.1 0-11-4.9-11-11s4.9-11 11-11 11 4.9 11 11-4.9 11-11 11z"></path>
  //   </g>
  //   <g>
  //     <path
  //       fill={fill || "#00F"}
  //       d="M1644-510v1684H-140V-510h1784m8-8H-148v1700h1800V-518z"
  //     ></path>
  //   </g>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
    {...props}
  >
    <path
      d="M20 0C18.55 0 17.74 1.44 18.07 2.51L14.52 6.07C14.22 5.98 13.78 5.98 13.48 6.07L10.93 3.52C11.27 2.45 10.46 1 9 1C7.55 1 6.73 2.44 7.07 3.52L2.51 8.07C1.44 7.74 0 8.55 0 10C0 11.1 0.9 12 2 12C3.45 12 4.26 10.56 3.93 9.49L8.48 4.93C8.78 5.02 9.22 5.02 9.52 4.93L12.07 7.48C11.73 8.55 12.54 10 14 10C15.45 10 16.27 8.56 15.93 7.48L19.49 3.93C20.56 4.26 22 3.45 22 2C22 0.9 21.1 0 20 0Z"
      fill={fill || "black"}
    />
  </svg>
);
