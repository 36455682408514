// Lib
import { FC, useEffect, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import { useGetRolesQuery } from "rtkQuery/query/rolesAPI";
// Hooks
import { useNotification, usePermissions, useViewport } from "hooks";
// Types
import { UserResponseDto } from "types/users";
// Consts
import { Permission } from "consts";
// Helpers
import { roleDisabledCheck, roleLimitationDisabledCheck } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Modal } from "components";
import { Input, PasswordInput, Select, Switch } from "components/Form";
// Styled
import { FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { createResolver, editResolver } from "./validation";

export type UserModalForm = {
  id?: string;
  customerActivated: boolean;
  email: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  newPassword?: string;
  roleName: string;
  phoneNumber?: string;
};

interface UserModalProps {
  userData: boolean | UserResponseDto;
  isLoading: boolean;
  customerModal?: boolean;
  onSave: (args: UserModalForm) => void;
  onClose: () => void;
}

export const UserModal: FC<UserModalProps> = ({
  userData,
  isLoading,
  customerModal,
  onSave,
  onClose,
}) => {
  const {
    canUserAgentOnlyCreate,
    isUserAgent,
    isUserManager,
    isSuperAdminManagement,
  } = usePermissions();

  const { isMobile } = useViewport();

  const { openNotification } = useNotification();

  const isEdit = !!userData && typeof userData !== "boolean";

  const {
    data: rolesData,
    isFetching: isRolesDataFetching,
    error: rolesDataError,
  } = useGetRolesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (rolesDataError) {
      errorHandler({ error: rolesDataError, openNotification });
    }
  }, [rolesDataError]);

  const { handleSubmit, control, reset, watch } = useForm<UserModalForm>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: isEdit ? editResolver : createResolver,
  });

  const roleName = watch("roleName");
  const password = watch("password");

  useEffect(() => {
    if (!userData) {
      reset({
        id: null,
        customerActivated: false,
        email: null,
        firstName: null,
        lastName: null,
        password: null,
        newPassword: null,
        roleName: null,
        phoneNumber: null,
      });
    }

    if (isEdit) {
      const {
        id,
        customerActivated,
        email,
        firstName,
        lastName,
        role,
        phoneNumber,
      } = userData;

      reset({
        id,
        customerActivated,
        email,
        firstName,
        lastName,
        phoneNumber,
        roleName: role?.name,
      });
    }
  }, [userData]);

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const onSubmit: SubmitHandler<UserModalForm> = data => {
    onSave(data);
  };

  const rolesList = useMemo(
    () =>
      isSuperAdminManagement
        ? rolesData?.items
        : rolesData?.items.filter(
            r =>
              !r.permissions.find(
                p => p.name === Permission.SuperAdminManagement,
              ),
          ),
    [rolesData, isSuperAdminManagement],
  );

  const roles = rolesList?.map(({ name }) => ({
    value: name,
    label: name,
    disabled: canUserAgentOnlyCreate
      ? roleLimitationDisabledCheck(name)
      : isEdit
      ? false
      : roleDisabledCheck(name),
  }));

  const cantEdit = isEdit ? isUserAgent || isUserManager : false;

  const isSubmitDisabled = isEdit ? !roleName : !roleName || !password;

  return (
    <Modal
      title={`${isEdit ? "Edit" : "Add"} ${
        customerModal ? "Customer" : "User"
      }`}
      width={560}
      open={!!userData}
      onClose={handleClose}
    >
      <FlexContainer
        $fullwidth
        $column
        $gap={10}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FlexContainer $fullwidth $column $gap={16}>
          <InputsGridContainer>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="First name"
                  isDisabled={isLoading || cantEdit}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Last name"
                  isDisabled={isLoading || cantEdit}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>

          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                label="E-mail"
                isDisabled={isLoading || cantEdit}
                {...field}
                fieldState={fieldState}
              />
            )}
          />

          {isEdit && (
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Phone number"
                  isDisabled={isLoading || cantEdit}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          )}

          {!customerModal && (
            <Controller
              name="roleName"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  required
                  label="Role"
                  placeholder="Select role"
                  loading={isRolesDataFetching}
                  {...field}
                  fieldState={fieldState}
                  options={roles}
                  disabled={isLoading || cantEdit}
                />
              )}
            />
          )}

          {!isEdit && (
            <Controller
              name={"password"}
              control={control}
              render={({ field, fieldState }) => (
                <PasswordInput
                  required
                  label="Password"
                  isDisabled={isLoading || cantEdit}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          )}

          {isEdit && (
            <Controller
              name={"newPassword"}
              control={control}
              render={({ field, fieldState }) => (
                <PasswordInput
                  label="Password"
                  isDisabled={isLoading || cantEdit}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          )}
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $column={isMobile}
          $align={isMobile ? "flex-start" : "center"}
          $justify={isMobile ? "flex-start" : "space-between"}
          $gap={isMobile ? 16 : 8}
          $margin="24px 0 0"
        >
          <Controller
            name="customerActivated"
            control={control}
            render={({ field }) => (
              <Switch
                label="Activate user"
                checked={field.value}
                {...field}
                disabled={isLoading}
              />
            )}
          />

          <FlexContainer
            $fullwidth={isMobile}
            $column={isMobile}
            $align="center"
            $justify="center"
            $gap={8}
          >
            <Button.Base
              $fullWidth={isMobile}
              disabled={isLoading}
              onClick={handleClose}
            >
              Close
            </Button.Base>

            <Button.Base
              $fullWidth={isMobile}
              loading={isLoading}
              type="primary"
              htmlType="submit"
              disabled={isSubmitDisabled}
            >
              Save
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
