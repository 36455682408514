// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  Coupon,
  CreateCouponRequestDto,
  GetCouponsResponseDto,
  GetCouponsUsedByUsersListResponseDto,
  UpdateCouponRequestDto,
} from "types/coupons";
// Helpers
import { couponsUrlBuilder } from "./urlBuilder/coupons";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";
import {
  couponMapper,
  couponPayloadMapper,
  couponsMapper,
} from "utils/mappers";
import { generateRequestUrl } from "helpers/dataHelpers";

export const couponsAPI = createApi({
  reducerPath: "couponsAPI",
  baseQuery: coreQuery(),
  tagTypes: ["coupons"],

  endpoints: build => ({
    getCoupons: build.query<GetCouponsResponseDto, { query: UrlQueryParams }>({
      query: ({ query }) => ({
        url: couponsUrlBuilder.getCoupons({ query }),
        method: "GET",
      }),
      transformResponse: (response: GetCouponsResponseDto) =>
        couponsMapper(response),
      providesTags: ["coupons"],
    }),
    getCoupon: build.query<Coupon, { id: string }>({
      query: ({ id }) => ({
        url: couponsUrlBuilder.getCoupon({ id }),
        method: "GET",
      }),
      transformResponse: (response: Coupon) => couponMapper(response),
      providesTags: ["coupons"],
    }),
    getCouponUsedByUserList: build.query<
      GetCouponsUsedByUsersListResponseDto,
      { id: string; query: UrlQueryParams }
    >({
      query: ({ id, query }) => ({
        url: couponsUrlBuilder.getCouponUsedByUserList({ id, query }),
        method: "GET",
      }),
      providesTags: ["coupons"],
    }),
    createCoupon: build.mutation<Coupon[], CreateCouponRequestDto>({
      query: data => ({
        url: endpoints.coupons,
        method: "POST",
        data: couponPayloadMapper(data),
      }),
      invalidatesTags: ["coupons"],
    }),
    updateCoupon: build.mutation<Coupon, UpdateCouponRequestDto>({
      query: ({ id, data }) => ({
        url: `${endpoints.coupons}/${id}`,
        method: "PATCH",
        data: couponPayloadMapper(data),
      }),
      invalidatesTags: ["coupons"],
    }),
    deleteCoupon: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.coupons}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["coupons"],
    }),
    validateCoupon: build.query<
      Coupon,
      { customerId: string; couponCode: string }
    >({
      query: ({ customerId, couponCode }) => ({
        url: generateRequestUrl(`${endpoints.coupons}/validate/${customerId}`, {
          couponCode,
        }),
        method: "GET",
      }),
      transformResponse: (response: Coupon) => couponMapper(response),
    }),
  }),
});

export const {
  useLazyValidateCouponQuery,
  useGetCouponsQuery,
  useGetCouponQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
  useGetCouponUsedByUserListQuery,
  useLazyGetCouponUsedByUserListQuery,
} = couponsAPI;
