// Lib
import { FC } from "react";
// Icons
import { LockIcon } from "icons";
// Styled
import { BlockedIconContainer, Wrapper, TitleThin } from "./styled";

interface BlockedStateProps {
  isClosed: boolean;
}

export const BlockedState: FC<BlockedStateProps> = ({ isClosed }) => {
  const title = isClosed ? "The store is closed" : "Select store location";
  return (
    <Wrapper>
      <BlockedIconContainer>
        <LockIcon />
      </BlockedIconContainer>

      <TitleThin>{title}</TitleThin>
    </Wrapper>
  );
};
