// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { CustomerBalanceResponseDto } from "types/customers";
// Helpers
import { customersPaymentUrlBuilder } from "./urlBuilder/customersPayment";
// Utils
import { paymentQuery } from "utils/baseQuery";
import { balanceMapper, topUpMapper } from "utils/mappers";
import {
  CreateCustomerTransactionRequestDto,
  ExtendCustomerBalanceExpirationRequestDto,
  TransactionResponseDto,
} from "types/transactions";
import { endpoints } from "consts";

export const customersPaymentAPI = createApi({
  reducerPath: "customersPaymentAPI",
  baseQuery: paymentQuery(),
  tagTypes: ["customerBalance"],

  endpoints: build => ({
    getCustomerBalance: build.query<CustomerBalanceResponseDto, { id: string }>(
      {
        query: ({ id }) => ({
          url: customersPaymentUrlBuilder.getCustomerBalance({ id }),
          method: "GET",
        }),
        transformResponse: (response: CustomerBalanceResponseDto) =>
          balanceMapper(response),
        providesTags: ["customerBalance"],
      },
    ),
    topUpCustomerBalance: build.mutation<
      void,
      CreateCustomerTransactionRequestDto
    >({
      query: data => ({
        url: endpoints.transactions,
        method: "POST",
        data: topUpMapper(data),
      }),
      invalidatesTags: ["customerBalance"],
    }),
    extendBalanceExpirationDate: build.mutation<
      TransactionResponseDto,
      ExtendCustomerBalanceExpirationRequestDto
    >({
      query: data => ({
        url: endpoints.extendBalanceExpirationDate,
        method: "POST",
        data,
      }),
      invalidatesTags: ["customerBalance"],
    }),
  }),
});

export const {
  useGetCustomerBalanceQuery,
  useTopUpCustomerBalanceMutation,
  useExtendBalanceExpirationDateMutation,
} = customersPaymentAPI;
