// Lib
import { Dispatch, FC, SetStateAction } from "react";
// Types
import { InteractionMode } from "types/locations";
// Theme
import { theme } from "theme";
// Icons
import { LocationsIcon, ShareIcon } from "icons";
// Styled
import { SwitchButton, Wrapper } from "./styled";

type ToggleButtonsProps = {
  onChange: Dispatch<SetStateAction<InteractionMode | undefined>>;
  value: InteractionMode | undefined;
};

export const ToggleButtons: FC<ToggleButtonsProps> = ({ value, onChange }) => {
  const handleChange = (newValue: InteractionMode) => {
    if (value === newValue) {
      onChange(undefined);

      return;
    }
    onChange(newValue);
  };

  const isPrimary = (buttonValue: InteractionMode): "primary" | "default" => {
    if (value === buttonValue) {
      return "primary";
    }

    return "default";
  };

  const buttons = [
    {
      Icon: ShareIcon,
      mode: InteractionMode.Polygon,
    },
    {
      Icon: LocationsIcon,
      mode: InteractionMode.Point,
    },
  ];

  return (
    <Wrapper>
      {buttons.map(({ Icon, mode }, i) => (
        <SwitchButton
          key={mode}
          type={isPrimary(mode)}
          onClick={() => handleChange(mode)}
          $isFirst={i === 0}
          $isLast={i === buttons.length - 1}
        >
          {
            <Icon
              fill={
                isPrimary(mode) === "primary"
                  ? theme.color.white
                  : theme.color.text.tertiary
              }
            />
          }
        </SwitchButton>
      ))}
    </Wrapper>
  );
};
