// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  CreateOrUpdatePackageRequestDto,
  CreateOrUpdatePackageRulesRequestDto,
  PackageResponseDto,
  PackageRulesResponseDto,
  SetTrialPackageRequestDto,
  TrialPackageResponseDto,
  UpdatePackagesOrderRequestDto,
} from "types/packages";
// Utils
import { paymentQuery } from "utils/baseQuery";
import {
  CoinPackageRulesMapper,
  trialPackagePayloadMapper,
  trialPackageMapper,
  CoinPackagesMapper,
  PackageRulesPayloadMapper,
  CoinPackagesPayloadMapper,
} from "utils/mappers";

import { endpoints } from "consts";

export const coinPackagesApi = createApi({
  reducerPath: "coinPackagesApi",
  baseQuery: paymentQuery(),
  tagTypes: ["coin-packages", "coin-packages-trial", "coin-packages-rules"],

  endpoints: build => ({
    getTrialPackage: build.query<TrialPackageResponseDto, void>({
      query: () => ({
        url: endpoints.packageTrial,
        method: "GET",
      }),
      providesTags: ["coin-packages-trial"],
      transformResponse: (response: TrialPackageResponseDto) =>
        trialPackageMapper(response),
    }),
    updateTrialPackage: build.mutation<
      TrialPackageResponseDto,
      SetTrialPackageRequestDto
    >({
      query: ({ id, ...data }) => ({
        url: `${endpoints.packageTrial}/${id}`,
        method: "PUT",
        data: trialPackagePayloadMapper(data),
      }),
      invalidatesTags: ["coin-packages-trial"],
    }),
    getPackageRules: build.query<PackageRulesResponseDto[], void>({
      query: () => ({
        url: endpoints.packageRules,
        method: "GET",
      }),
      providesTags: ["coin-packages-rules"],
      transformResponse: (response: PackageRulesResponseDto[]) =>
        CoinPackageRulesMapper(response),
    }),
    createPackageRule: build.mutation<
      PackageRulesResponseDto,
      CreateOrUpdatePackageRulesRequestDto
    >({
      query: data => ({
        url: endpoints.packageRules,
        method: "POST",
        data: PackageRulesPayloadMapper(data),
      }),
      invalidatesTags: ["coin-packages-rules"],
    }),
    updatePackageRule: build.mutation<
      PackageRulesResponseDto,
      CreateOrUpdatePackageRulesRequestDto
    >({
      query: ({ id, ...data }) => ({
        url: `${endpoints.packageRules}/${id}`,
        method: "PUT",
        data: PackageRulesPayloadMapper(data),
      }),
      invalidatesTags: ["coin-packages-rules"],
    }),
    deletePackageRule: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.packageRules}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["coin-packages-rules"],
    }),
    getPackages: build.query<PackageResponseDto[], void>({
      query: () => ({
        url: endpoints.packages,
        method: "GET",
      }),
      providesTags: ["coin-packages"],
      transformResponse: (response: PackageResponseDto[]) =>
        CoinPackagesMapper(response),
    }),
    createPackage: build.mutation<
      PackageResponseDto,
      CreateOrUpdatePackageRequestDto
    >({
      query: data => ({
        url: endpoints.packages,
        method: "POST",
        data: CoinPackagesPayloadMapper(data),
      }),
      invalidatesTags: ["coin-packages"],
    }),
    updatePackage: build.mutation<
      PackageResponseDto,
      CreateOrUpdatePackageRequestDto
    >({
      query: ({ id, ...data }) => ({
        url: `${endpoints.packages}/${id}`,
        method: "PUT",
        data: CoinPackagesPayloadMapper(data),
      }),
      invalidatesTags: ["coin-packages"],
    }),
    deletePackage: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.packages}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["coin-packages"],
    }),
    updatePackagesOrder: build.mutation<void, UpdatePackagesOrderRequestDto>({
      query: data => ({
        url: `${endpoints.packages}/update-orders`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["coin-packages"],
    }),
  }),
});

export const {
  useGetTrialPackageQuery,
  useUpdateTrialPackageMutation,
  useGetPackageRulesQuery,
  useCreatePackageRuleMutation,
  useUpdatePackageRuleMutation,
  useDeletePackageRuleMutation,
  useGetPackagesQuery,
  useCreatePackageMutation,
  useUpdatePackageMutation,
  useDeletePackageMutation,
  useUpdatePackagesOrderMutation,
} = coinPackagesApi;
