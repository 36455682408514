import { GetLocationResponseDto } from "types/locations";
import { number, object, string } from "yup";

export const getValidation = (data: GetLocationResponseDto) => {
  if (!data) {
    return undefined;
  }
  const { operatingZones } = data || {};

  const zonesValidation = operatingZones.reduce((acc, _el, i) => {
    acc[`zone${i}`] = object().shape({
      name: string().trim().required("Name is required"),
      priority: number().required("Priority is required"),
    });
    return acc;
  }, {});

  const schema = object().shape({
    name: string().trim().required("Name is required"),
    zones: object().shape(zonesValidation),
  });

  return schema;
};
