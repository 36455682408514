// Lib
import { FC, memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
// Hooks
import { useTable } from "hooks";
// Types
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { AdditionalFilter, ETable } from "types/tableFilters";
import {
  GetScheduledOrdersResponseDto,
  OrderFilterTypes,
  ScheduledOrderResponseDto,
} from "types/orders";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Table } from "components";
// Styled

import { columns, filtersList } from "./config";

interface ScheduledTabProps {
  isLoading: boolean;
  isFetching: boolean;
  isDesktop: boolean;
  ordersData: GetScheduledOrdersResponseDto;
  locationOptions: {
    label: string;
    type: string;
  }[];
  handleOrdersSort: (field: string, direction: string) => void;
}

export const ScheduledTab: FC<ScheduledTabProps> = memo(
  ({ isLoading, isDesktop, ordersData, locationOptions, handleOrdersSort }) => {
    const navigate = useNavigate();

    const { selectedFilters, handleSetTableFilterValues } = useTable({
      name: ETable.ScheduledOrders,
      removeQueryParams: true,
    });

    const handleTableChange = useCallback(
      (
        _pagination: TablePaginationConfig,
        _filters: Record<string, FilterValue>,
        sorter: SorterResult<GetScheduledOrdersResponseDto>,
      ) => {
        handleOrdersSort(sorter?.field as string, sorter?.order);
      },
      [],
    );

    const onRow = useCallback(record => {
      return {
        onClick: () => navigate(`/orders/scheduled/${record.id}`),
      };
    }, []);

    const filters = useMemo(
      () =>
        !locationOptions?.length
          ? filtersList
          : filtersList?.map(filter => {
              if (filter.key === OrderFilterTypes.locationId) {
                return { ...filter, options: locationOptions };
              }

              return filter;
            }),
      [filtersList, locationOptions],
    );

    return (
      <Table
        isLoading={isLoading}
        dataSource={ordersData?.items || []}
        withPagination={!isDesktop}
        columns={columns}
        empty={{
          icon: DescriptionBlackIcon,
          title: "No order to show.",
          description: "Try changing sections or change the filters",
        }}
        header={{
          totalCount: ordersData?.totalCount,
          filter: {
            include: [AdditionalFilter.Customer],
            filters: filters,
            selected: selectedFilters,
            setValue: handleSetTableFilterValues,
          },
        }}
        rowClassName={(record: ScheduledOrderResponseDto) =>
          record.isOutOfStock && "alert-row"
        }
        onChange={handleTableChange}
        onRow={onRow}
      />
    );
  },
);

ScheduledTab.displayName = "ScheduledTab";
