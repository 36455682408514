import { Link } from "react-router-dom";
import { TABLE_DATE } from "consts";
import { CallBlackIcon, CurrencyIcon, TextFieldsIcon } from "icons";
import {
  convertStatusToText,
  dateTransform,
  fixedDigitsValue,
} from "helpers/dataHelpers";

import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";
import { PaymentResponseDto } from "types/payments";
import {
  TransactionFilterNames,
  TransactionFilterTypes,
  TransactionResponseDto,
  TransactionType,
  TransactionTypeLabel,
} from "types/transactions";

import { Badge } from "components";

import { FlexContainer } from "styled/Box";

export const columns = [
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    fixed: "left" as const,
    width: 210,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },

  {
    title: "Transaction number",
    dataIndex: "id",
    key: "id",
    fixed: "left" as const,
    width: 250,
  },
  {
    title: "Order value",
    dataIndex: "payment",
    key: "orderValue",
    width: 120,
    align: "right" as const,
    render: (payment: PaymentResponseDto) =>
      payment?.amount || payment?.amount === 0
        ? `${fixedDigitsValue(payment?.amount, 3)} ${payment?.currency || null}`
        : null,
  },
  {
    title: "FM Coin",
    dataIndex: "fmcCentsAmount",
    key: "fmcCentsAmount",
    sorter: true,
    width: 150,
    render: (value: string) => (
      <FlexContainer $align="center" $justify="flex-end" $gap={8}>
        {fixedDigitsValue(value, 3)} <CurrencyIcon />
      </FlexContainer>
    ),
  },
  {
    title: "Balance",
    dataIndex: "customerBalance",
    key: "customerBalance",
    width: 120,
    render: (value: string) => (
      <FlexContainer $align="center" $justify="flex-end" $gap={8}>
        {fixedDigitsValue(value, 3)} <CurrencyIcon />
      </FlexContainer>
    ),
  },
  {
    title: "Exchange rate",
    dataIndex: "payment",
    key: "exchangeRate",
    align: "right" as const,
    width: 120,
    render: (payment: PaymentResponseDto) =>
      payment?.metadata?.exchangeRate || null,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: true,
    width: 150,
    render: (type: TransactionType) => (
      <Badge title={TransactionTypeLabel[type]} />
    ),
  },
  {
    title: "Purpose",
    dataIndex: "purpose",
    key: "purpose",
    render: (purpose: TransactionType) => <Badge title={purpose} />,
  },
  {
    title: "Note",
    dataIndex: "note",
    key: "note",
    render: (text: string) => (
      <p
        style={{
          maxWidth: "300px",
          whiteSpace: "pre-wrap",
          overflow: "auto",
        }}
      >
        {text}
      </p>
    ),
  },
  {
    title: "Payment Status",
    dataIndex: "payment",
    key: "status",
    render: (payment: PaymentResponseDto) =>
      payment?.status ? (
        <Badge
          capitalize
          title={convertStatusToText(payment?.status)}
          style={{ cursor: "pointer" }}
        />
      ) : null,
  },
  {
    title: "Checkout URL",
    dataIndex: "payment",
    key: "status",
    render: (payment: PaymentResponseDto) =>
      payment?.metadata?.referenceNo ? (
        <Link
          target="_blank"
          rel="noreferrer"
          to={`${process.env.REACT_APP_CHECKOUT_URL}${payment.metadata.referenceNo}`}
        >
          {payment.metadata.referenceNo}
        </Link>
      ) : null,
  },
  {
    title: "Location",
    dataIndex: "locationName",
    key: "locationName",
  },
  {
    title: "Order ID",
    dataIndex: "orderId",
    key: "orderId",
    width: 250,
  },
  {
    title: "User initiated",
    dataIndex: "initiatorName",
    key: "initiatorName",
    render: (name: string, record: TransactionResponseDto) => (
      <Link to={`/users/${record.initiatorId}`}>
        {name || record.initiatorId}
      </Link>
    ),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sorter: true,
    width: 210,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];

export const mobileColumns = columns.map(column => {
  if (column.key === "createdAt" || column.key === "id") {
    return { ...column, fixed: false };
  }

  return column;
});

export const transactionsTypeDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: TransactionType.Manual,
    label: TransactionTypeLabel[TransactionType.Manual],
  },
  {
    type: TransactionType.OrderCharge,
    label: TransactionTypeLabel[TransactionType.OrderCharge],
  },
  {
    type: TransactionType.OrderRefund,
    label: TransactionTypeLabel[TransactionType.OrderRefund],
  },
  {
    type: TransactionType.PaymentRefund,
    label: TransactionTypeLabel[TransactionType.PaymentRefund],
  },
  {
    type: TransactionType.Refund,
    label: TransactionTypeLabel[TransactionType.Refund],
  },
  {
    type: TransactionType.TopUp,
    label: TransactionTypeLabel[TransactionType.TopUp],
  },
  {
    type: TransactionType.Voucher,
    label: TransactionTypeLabel[TransactionType.Voucher],
  },
  {
    type: TransactionType.BalanceExtension,
    label: TransactionTypeLabel[TransactionType.BalanceExtension],
  },
];

export const filtersList: FilterOption[] = [
  {
    key: TransactionFilterTypes.id,
    label: TransactionFilterNames[TransactionFilterTypes.id],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: TransactionFilterTypes.phoneNumber,
    label: TransactionFilterNames[TransactionFilterTypes.phoneNumber],
    icon: <CallBlackIcon />,
    field: FilterField.INPUT_SELECT,
  },
  {
    key: TransactionFilterTypes.note,
    label: TransactionFilterNames[TransactionFilterTypes.note],
    icon: <TextFieldsIcon />,
    field: FilterField.INPUT_SELECT,
  },
  {
    key: TransactionFilterTypes.purpose,
    label: TransactionFilterNames[TransactionFilterTypes.purpose],
    field: FilterField.SELECT,
    options: [],
  },
  {
    key: TransactionFilterTypes.type,
    label: TransactionFilterNames[TransactionFilterTypes.type],
    field: FilterField.SELECT,
    options: transactionsTypeDropdownFields,
  },
  {
    key: TransactionFilterTypes.createdAtAfter,
    label: TransactionFilterNames[TransactionFilterTypes.createdAtAfter],
    field: FilterField.DATE,
  },
  {
    key: TransactionFilterTypes.updatedAtAfter,
    label: TransactionFilterNames[TransactionFilterTypes.updatedAtAfter],
    field: FilterField.DATE,
  },
];
