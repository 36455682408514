// Components
import { Logo, LoginForm } from "./components";
// Styled
import { ContentContainer, PageWrapper } from "./styled";

export const Login = () => {
  return (
    <PageWrapper>
      <ContentContainer>
        <Logo />

        <LoginForm />
      </ContentContainer>
    </PageWrapper>
  );
};
