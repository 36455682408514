// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  CustomerProfileResponseDto,
  GetCustomerCouponsResponseDto,
  GetCustomerMenuResponseDto,
  GetCustomersAddressResponseDto,
  GetCustomersResponseDto,
} from "types/customers";
// Helpers
import { customersUrlBuilder } from "./urlBuilder/customers";
// Utils
import { coreQuery } from "utils/baseQuery";
import {
  couponsMapper,
  customerMenuMapper,
  customersMapper,
} from "utils/mappers";
import { endpoints } from "consts";

export const customersAPI = createApi({
  reducerPath: "customersAPI",
  baseQuery: coreQuery(),
  tagTypes: ["customers", "customer"],

  endpoints: build => ({
    getCustomers: build.query<
      GetCustomersResponseDto,
      { query: UrlQueryParams }
    >({
      query: ({ query }) => ({
        url: customersUrlBuilder.getCustomers({ query }),
        method: "GET",
      }),
      transformResponse: (response: GetCustomersResponseDto) =>
        customersMapper(response),
      providesTags: ["customers"],
    }),
    getCustomer: build.query<CustomerProfileResponseDto, { id: string }>({
      query: ({ id }) => ({
        url: customersUrlBuilder.getCustomer({ id }),
        method: "GET",
      }),
      providesTags: ["customer"],
    }),
    updateCustomerStatus: build.mutation<void, { id: string; isVip: boolean }>({
      query: ({ id, ...data }) => ({
        url: `${endpoints.users}/${id}`,
        method: "PATCH",
        data,
      }),
    }),
    getCustomerAddresses: build.query<
      GetCustomersAddressResponseDto[],
      { id: string }
    >({
      query: ({ id }) => ({
        url: customersUrlBuilder.getCustomerAddresses({ id }),
        method: "GET",
      }),
    }),
    getCustomerCoupons: build.query<
      GetCustomerCouponsResponseDto,
      { id: string; query: UrlQueryParams }
    >({
      query: ({ id, query }) => ({
        url: customersUrlBuilder.getCustomerCoupons({ id, query }),
        method: "GET",
      }),
      transformResponse: (response: GetCustomerCouponsResponseDto) =>
        couponsMapper(response),
    }),
    getCustomerMenu: build.query<
      GetCustomerMenuResponseDto,
      { id: string; query: UrlQueryParams }
    >({
      query: ({ id, query }) => ({
        url: customersUrlBuilder.getCustomerMenu({ id, query }),
        method: "GET",
      }),
      transformResponse: (response: GetCustomerMenuResponseDto) =>
        customerMenuMapper(response),
    }),
    getCustomerMenuByLocation: build.query<
      GetCustomerMenuResponseDto,
      { id: string }
    >({
      query: ({ id }) => ({
        url: customersUrlBuilder.getCustomerMenuByLocation({ id }),
        method: "GET",
      }),
      transformResponse: (response: GetCustomerMenuResponseDto) =>
        customerMenuMapper(response),
    }),
  }),
});

export const {
  useGetCustomerMenuQuery,
  useGetCustomerMenuByLocationQuery,
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useGetCustomerQuery,
  useGetCustomerCouponsQuery,
  useLazyGetCustomerCouponsQuery,
  useLazyGetCustomerQuery,
  useLazyGetCustomerAddressesQuery,
  useUpdateCustomerStatusMutation,
} = customersAPI;
