import { styled } from "styled-components";
import { Collapse as AntDCollapse } from "antd";

import { DownArrowIcon } from "icons";

import { theme } from "theme";

import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";

export const Wrapper = styled.div`
  padding: 8px;
  overflow: auto;

  @media screen and (max-width: ${theme.breakpoints.l}) {
    overflow: visible;
  }
`;

export const ListWrapper = styled.div<{ $canLocationsUpdate: boolean }>`
  height: ${({ $canLocationsUpdate }) =>
    $canLocationsUpdate ? "calc(100% - 48px)" : "100%"};
  overflow: auto;

  @media screen and (max-width: ${theme.breakpoints.l}) {
    overflow: visible;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const Description = styled(Typography.Description)`
  color: ${theme.color.text.tertiary};
`;

export const IconRoundedContainer = styled(FlexContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${theme.color.background.gray};
  padding: 8px;
`;

export const DownArrow = styled(DownArrowIcon)<{ $rotate: number }>`
  transform: ${({ $rotate }) => `rotate(${$rotate}deg);`};
  transition: All 0.2s;
`;

export const Collapse = styled(AntDCollapse)`
  border-radius: 8px;
  box-shadow: none;
  filter: none;

  background: ${theme.color.white};
  border: 0;

  .ant-collapse-item {
    border-color: ${theme.color.borderLight};

    .ant-collapse-header {
      align-items: center;
      padding: 16px;
    }

    .ant-collapse-content {
      padding: 0 16px;

      .ant-collapse-content-box {
        padding: 16px 0;
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      background: ${theme.color.borderLight};
      border-radius: 0px !important;
    }
  }

  .ant-collapse-item:last-child {
    border-radius: 0;
  }

  .ant-collapse-content {
    border-top: 0;
  }
`;
