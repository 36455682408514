// Lib
import { FC } from "react";
import { Skeleton } from "antd";
// Types
import { OrderDetailProps } from "./types";
// Icons
import { DiningIcon } from "icons";
// Helpers
import { fixedDigitsValue } from "helpers/dataHelpers";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { MenuItem, Image, ImagePlaceholder } from "../../../components/styled";

export const OrderDetail: FC<OrderDetailProps> = ({ isLoading, data }) => {
  return (
    <ContentBox $column $gap={20}>
      <Typography.H2>Order detail</Typography.H2>

      {isLoading && <Skeleton active />}

      {!isLoading && (
        <FlexContainer $fullwidth $column $gap={12}>
          {data?.map(
            ({
              productId,
              productName,
              quantity,
              fiatCentsPrice,
              subItems,
              imageURL,
            }) => {
              return (
                <MenuItem key={productId} $fullwidth $padding="8px" $gap={8}>
                  <div>
                    {imageURL ? (
                      <Image src={imageURL} />
                    ) : (
                      <ImagePlaceholder>
                        <DiningIcon height={"64px"} width={"64px"} />
                      </ImagePlaceholder>
                    )}
                  </div>

                  <FlexContainer
                    $fullwidth
                    $column
                    $gap={8}
                    $padding="4px 0 0 0"
                  >
                    <FlexContainer $gap={8} $wrap>
                      <Typography.Title>
                        {productName?.en || productName?.ar}
                      </Typography.Title>
                    </FlexContainer>

                    <Typography.DescriptionThin>
                      KWD: {fixedDigitsValue(fiatCentsPrice, 3)} · Quantity:{" "}
                      {quantity} items
                    </Typography.DescriptionThin>

                    {!!subItems?.length && (
                      <FlexContainer $fullwidth $column $padding="5px 0 0 0 ">
                        {subItems.map((item, i) => (
                          <Typography.DescriptionThin key={item.productId + i}>
                            {item?.quantity || 1} x {item?.productName}
                          </Typography.DescriptionThin>
                        ))}
                      </FlexContainer>
                    )}
                  </FlexContainer>
                </MenuItem>
              );
            },
          )}
        </FlexContainer>
      )}
    </ContentBox>
  );
};
