import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 360px;
  gap: 24px;

  @media screen and (max-width: 1110px) {
    grid-template-columns: 1fr;
  }
`;
