import { yupResolver } from "@hookform/resolvers/yup";
import { bool, object, string } from "yup";

const editSchema = object({
  id: string().nullable(),
  newPassword: string().nullable(),
  customerActivated: bool(),
  email: string().nullable().email("Email is not valid"),
  firstName: string().nullable(),
  lastName: string().nullable(),
  roleName: string().nullable().required("Role is required"),
  phoneNumber: string().nullable(),
}).required();

const createSchema = object({
  customerActivated: bool(),
  email: string().nullable().email("Email is not valid"),
  firstName: string().nullable(),
  lastName: string().nullable(),
  password: string().nullable().required("Password required"),
  roleName: string().nullable().required("Role is required"),
}).required();

export const editResolver = yupResolver(editSchema);

export const createResolver = yupResolver(createSchema);
