export enum ReviewFilterTypes {
  status = "status",
  rate = "rate",
  createdAfter = "createdAtAfter",
  updatedAfter = "updatedAtAfter",
}

export enum ReviewFilterNames {
  status = "Status",
  rate = "Rating",
  createdAtAfter = "Created after",
  updatedAtAfter = "Updated after",
}

export enum ReviewStatus {
  REVIEW = "review",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum ReviewStatusLabels {
  approved = "Approved",
  rejected = "Rejected",
  review = "On review",
}

export type ReviewResponseDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  customerId: string;
  status: string;
  rating: number;
  comment: string;
  providerId: string;
  firstName: string;
  adminFirstName: string;
  initiatorId: string;
  reason: string;
};

export type GetReviewsResponseDto = {
  items: ReviewResponseDto[];
  totalCount: number;
};

export type ReviewDetailsResponseDto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  customerId: string;
  initiatorId: string;
  status: ReviewStatus;
  rating: string;
  comment: string;
  providerId: string;
  firstName: string;
  adminFirstName: string;
  reason: string;
  productName: string;
};

export type UpdateReviewRequestDto = {
  id: string;
  status: ReviewStatus;
  reason: string | null;
};
