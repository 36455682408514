// Lib
import { FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
// Hooks
import { useViewport } from "hooks";
// Types
import { ExtendBalanceExpiredDateForm } from "./types";
// Constants
import { DAY_MONTH_YEAR_DATE, TABLE_DAY_MONTH_YEAR_TIME_DATE } from "consts";
// Components
import { Modal } from "components";
import { DatePicker, InputNumber, TextArea } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

import { resolver } from "./validation";

interface ExtendBalanceExpiredDateModalProps {
  open: boolean;
  isLoading: boolean;
  expiresAt: string | null;
  onSave: (args: { days: number; note: string }) => void;
  onClose: () => void;
}

export const ExtendBalanceExpiredDateModal: FC<
  ExtendBalanceExpiredDateModalProps
> = ({ open, isLoading, expiresAt, onSave, onClose }) => {
  const { isMobile } = useViewport();

  const { handleSubmit, control, setValue, reset } =
    useForm<ExtendBalanceExpiredDateForm>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
      defaultValues: {
        expiresAt: expiresAt ? dayjs(expiresAt) : undefined,
      },
    });

  useEffect(() => {
    if (!open) {
      reset({ days: undefined, newDate: undefined });
      return;
    }

    setValue("expiresAt", dayjs(expiresAt) || undefined);
  }, [open]);

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const handleDaysChange = (days: number | null) => {
    if (!days) {
      setValue("days", null);
      setValue("newDate", null);
      return;
    }
    const startDate = expiresAt ? dayjs(expiresAt) : dayjs();

    const calculatedDate = startDate.add(+days, "days");

    setValue("days", days);
    setValue("newDate", calculatedDate);
  };

  const handleDateChange = (date: dayjs.Dayjs) => {
    if (!date) {
      setValue("days", null);
      setValue("newDate", date);
      return;
    }
    const startDate = expiresAt ? dayjs(expiresAt) : dayjs().startOf("day");

    const calculatedDays = date.diff(startDate, "day");

    setValue("days", calculatedDays);
    setValue("newDate", date);
  };

  const onSubmit: SubmitHandler<ExtendBalanceExpiredDateForm> = async data => {
    const { days, note } = data || {};

    onSave({ days, note });
  };

  //temporary removed
  // const disabledDate = (current: dayjs.Dayjs) => {
  //   // Can not select days before today and today
  //   const startFrom = expiresAt ? dayjs(expiresAt) : dayjs();
  //   return current && current < startFrom.endOf("day");
  // };

  return (
    <Modal
      title="Extend balance expired date"
      width={560}
      open={open}
      onClose={handleClose}
    >
      <FlexContainer
        $fullwidth
        $column
        $gap={10}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FlexContainer $fullwidth $column $gap={16}>
          <Controller
            name="days"
            control={control}
            render={({ field, fieldState }) => (
              <InputNumber
                required
                label="Number of additional days"
                placeholder="Enter number of days"
                precision={0}
                {...field}
                disabled={isLoading}
                fieldState={fieldState}
                onChange={str => handleDaysChange(str as number)}
              />
            )}
          />

          <Controller
            name="expiresAt"
            control={control}
            render={({ field, fieldState }) => (
              <DatePicker
                isDisabled
                label="Current balance expired date"
                placeholder={DAY_MONTH_YEAR_DATE}
                picker="date"
                format={TABLE_DAY_MONTH_YEAR_TIME_DATE}
                {...field}
                fieldState={fieldState}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={field.onChange as any}
              />
            )}
          />

          <Controller
            name="newDate"
            control={control}
            render={({ field, fieldState }) => (
              <DatePicker
                required
                label="New balance expired date"
                placeholder={DAY_MONTH_YEAR_DATE}
                picker="date"
                format={DAY_MONTH_YEAR_DATE}
                isDisabled={isLoading}
                //temporary removed
                // disabledDate={disabledDate}
                {...field}
                fieldState={fieldState}
                onChange={date => handleDateChange(date)}
              />
            )}
          />

          <Controller
            name="note"
            control={control}
            render={({ field, fieldState }) => (
              <TextArea
                required
                label="Note"
                placeholder="Enter note"
                isDisabled={isLoading}
                {...field}
                fieldState={fieldState}
              />
            )}
          />
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $align="center"
          $justify="flex-end"
          $gap={8}
          $margin="24px 0 0"
        >
          <FlexContainer
            $column={isMobile}
            $fullwidth={isMobile}
            $align="center"
            $justify="center"
            $gap={8}
          >
            <Button.Base
              $fullWidth={isMobile}
              disabled={isLoading}
              onClick={handleClose}
            >
              Close
            </Button.Base>

            <Button.Base
              $fullWidth={isMobile}
              loading={isLoading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
