// Lib
import { FC, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// Hooks
import { useTable } from "hooks";
// Types
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { AdditionalFilter, ETable } from "types/tableFilters";
import {
  GetOrdersResponseDto,
  OrderResponseDto,
  OrderType,
} from "types/orders";
// Constants
import { ADMIN_ROUTES } from "consts";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Table } from "components";

import { columns, filtersList } from "./config";

interface GeneralTabProps {
  isLoading: boolean;
  isDesktop: boolean;
  ordersData: GetOrdersResponseDto;
  handleOrdersSort: (field: string, direction: string) => void;
}

export const GeneralTab: FC<GeneralTabProps> = memo(
  ({ isLoading, isDesktop, ordersData, handleOrdersSort }) => {
    const navigate = useNavigate();

    const { selectedFilters, handleSetTableFilterValues } = useTable({
      name: ETable.Orders,
      removeQueryParams: true,
    });

    const handleTableChange = useCallback(
      (
        _pagination: TablePaginationConfig,
        _filters: Record<string, FilterValue>,
        sorter: SorterResult<GetOrdersResponseDto>,
      ) => {
        handleOrdersSort(sorter?.field as string, sorter?.order);
      },
      [],
    );

    const onRow = useCallback((record: OrderResponseDto) => {
      if (record.type === OrderType.SCHEDULE_ORDER) {
        return {
          onClick: () => navigate(`/orders/scheduled/${record.id}`),
        };
      }
      return {
        onClick: () => navigate(`${ADMIN_ROUTES.ORDERS.path}/${record.id}`),
      };
    }, []);

    return (
      <Table
        isLoading={isLoading}
        dataSource={ordersData?.items || []}
        withPagination={!isDesktop}
        columns={columns}
        empty={{
          icon: DescriptionBlackIcon,
          title: "No order to show.",
          description: "Try changing sections or change the filters",
        }}
        header={{
          totalCount: ordersData?.totalCount,
          filter: {
            include: [AdditionalFilter.Customer],
            filters: filtersList,
            selected: selectedFilters,
            setValue: handleSetTableFilterValues,
          },
        }}
        onChange={handleTableChange}
        onRow={onRow}
      />
    );
  },
);

GeneralTab.displayName = "GeneralTab";
