// Lib
import { FC, useEffect, useRef, useState } from "react";
import { Overlay } from "ol";
import Feature, { FeatureLike } from "ol/Feature";
import { getCenter } from "ol/extent";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { Vector } from "ol/source";
import VectorSource from "ol/source/Vector";
// Hooks
import { useMapInteractionContext } from "hooks";
// Helpers
import { getZoneLabel } from "../helpers";
// Styled
import { Title } from "./styled";

import { mapStyles } from "../config";

interface PolygonLayerProps {
  source: Vector<FeatureLike>;
  zoneId: string;
  name?: string;
}

export const PolygonLayer: FC<PolygonLayerProps> = ({
  source,
  zoneId,
  name,
}) => {
  const { map } = useMapInteractionContext();

  //fix blinking
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const zoneLabelRef = useRef(null);
  const zoneLabelOverlay = new Overlay({
    positioning: "center-center",
    stopEvent: true,
  });
  const vectorLayer = new VectorLayer({
    source: source as VectorSource<Feature<Geometry>>,
    style: mapStyles.MultiPolygon,
    zIndex: 2,
  });

  const zoneLabel = name || getZoneLabel(zoneId.split("-")[1]);

  useEffect(() => {
    if (!map) return;

    map.addLayer(vectorLayer);

    if (zoneLabelRef.current) {
      zoneLabelOverlay.setElement(zoneLabelRef.current);

      const extent = source.getFeatures()[0].getGeometry()?.getExtent();
      if (!extent) return;

      const centerPos = getCenter(extent);
      zoneLabelOverlay.setPosition(centerPos);
    }

    map.addOverlay(zoneLabelOverlay);

    return () => {
      map.removeLayer(vectorLayer);
      map.removeOverlay(zoneLabelOverlay);
    };
  }, [map]);

  return (
    <Title ref={zoneLabelRef} $isMounted={isMounted}>
      {zoneLabel}
    </Title>
  );
};
