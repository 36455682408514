// Lib
import { FC } from "react";
// Types
import { GetLocationResponseDto } from "types/locations";
// Helpers
import {
  channelLinkStatusTitle,
  getOperatingStatusColor,
  operatingStatusTitle,
} from "../../../../helpers";
// Components
import { Status } from "components";
// Styled
import { FlexContainer, HorizontalDevider } from "styled/Box";
import { Typography } from "styled/Typography";
import { Description, IdsContainer, StatusesContainer } from "./styled";

interface InfoProps {
  locationData: GetLocationResponseDto;
}

export const Info: FC<InfoProps> = ({ locationData }) => {
  if (!locationData) return null;

  return (
    <FlexContainer $fullwidth $column>
      <StatusesContainer>
        {!!locationData?.operatingStatus && (
          <FlexContainer $column $gap={4}>
            <Description>Operating status</Description>

            <Status
              type="outlined"
              text={operatingStatusTitle[locationData?.operatingStatus]}
              status={getOperatingStatusColor(locationData?.operatingStatus)}
            />
          </FlexContainer>
        )}

        {!!locationData?.channelLinkStatus && (
          <FlexContainer $column $gap={4}>
            <Description>Channel link status</Description>

            <Status
              type="outlined"
              text={channelLinkStatusTitle[locationData?.channelLinkStatus]}
              status={getOperatingStatusColor(locationData?.channelLinkStatus)}
            />
          </FlexContainer>
        )}
      </StatusesContainer>

      <HorizontalDevider />

      <IdsContainer>
        <FlexContainer $fullwidth $column $gap={4}>
          <Description>ID</Description>

          <Typography.Title>{locationData?.id}</Typography.Title>
        </FlexContainer>

        <FlexContainer $fullwidth $column $gap={4}>
          <Description>Deliverect location</Description>

          <Typography.Title>
            {locationData?.deliverectLocationId}
          </Typography.Title>
        </FlexContainer>

        <FlexContainer $fullwidth $column $gap={4}>
          <Description>Channel link</Description>

          <Typography.Title>{locationData?.channelLinkId}</Typography.Title>
        </FlexContainer>
      </IdsContainer>

      <HorizontalDevider />

      <FlexContainer $fullwidth $column $gap={4} $padding="24px">
        <Description>Zone priorities</Description>

        {locationData?.operatingZones.map(z => (
          <FlexContainer
            key={z.name}
            $fullwidth
            $align="center"
            $justify="space-between"
          >
            <Typography.Title>{z.name}</Typography.Title>

            <Typography.Title>{z.priority}</Typography.Title>
          </FlexContainer>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};
