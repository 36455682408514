// Lib
import { FC, ReactNode } from "react";
// Hooks
import { useViewport } from "hooks";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Description } from "./styled";

interface SettingsItemProps {
  title: string;
  description?: string;
  width?: string;
  children: ReactNode;
}

export const SettingsItem: FC<SettingsItemProps> = ({
  title,
  description,
  children,
}) => {
  const { isMobile } = useViewport();

  return (
    <FlexContainer
      $column={isMobile}
      $fullwidth
      $gap={4}
      $align="center"
      $justify="space-between"
      $padding="12px 0"
    >
      <FlexContainer
        $column
        $align="flex-start"
        $width={isMobile ? "100%" : "calc(100% - 210px)"}
      >
        <Typography.Title>{title}</Typography.Title>

        {!!description && <Description>{description}</Description>}
      </FlexContainer>

      <FlexContainer
        $width={isMobile ? "100%" : "200px"}
        $align="center"
        $justify={isMobile ? "flex-start" : "flex-end"}
      >
        {children}
      </FlexContainer>
    </FlexContainer>
  );
};

export default SettingsItem;
