import { yupResolver } from "@hookform/resolvers/yup";
import { number, string, bool, object } from "yup";

const schema = object({
  bonusCoinsPercent: number().nullable().required("Bonus is required"),
  name: string().trim().nullable().required("Name is required"),
  price: number().nullable().required("Price is required"),
  expiryDays: number().nullable().required("Expiry (days) is required"),
  tag: string().nullable(),
  active: bool(),
}).required();

export const resolver = yupResolver(schema);
