import { yupResolver } from "@hookform/resolvers/yup";
import { date, number, object, string } from "yup";

const schema = object({
  days: number().nullable().required("Number of additional days is required"),
  expiresAt: date().nullable(),
  newDate: date().nullable().required("New balance expired date is required"),
  note: string().trim().required("Note is required"),
}).required();

export const resolver = yupResolver(schema);
