import styled from "styled-components";
import { theme } from "theme";

export const LabelContainer = styled.div<{ $error: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ $error }) => $error && `color: ${theme.color.status.danger} !important`}
`;
