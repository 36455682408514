import { FC } from "react";

import { Permission } from "./permissions";

import {
  // Analytics,
  CoinPackages,
  Customers,
  DeliveryAreas,
  LocationProviders,
  NotFound,
  OrderCoupons,
  Orders,
  Recommended,
  Roles,
  Settings,
  TopUpCoupons,
  Transactions,
  Users,
  WaitingList,
} from "pages";

import { CustomerDetails } from "pages/Customers/View/CustomerDetails";
import { OrderCreate } from "pages/Orders/View/OrderCreate";
import { ScheduledOrderDetails } from "pages/Orders/View/ScheduledOrderDetails";
import { EditCreateRoles } from "pages/Roles/View/EditCreateRoles";
// import { TransactionDetails } from "pages/Transactions/View/TransactionDetails";
import { EditArea } from "pages/DeliveryAreas/View/EditArea";
import { AreaDetails } from "pages/DeliveryAreas/View/AreaDetails";
import { UserDetails } from "pages/Users/View/UserDetails";
import { OrderCouponEdit } from "pages/Coupons/OrderCoupons/View/OrderCouponEdit";
import { TopUpCouponEdit } from "pages/Coupons/TopUpCoupons/View/TopUpCouponEdit";
import { OrderDetails } from "pages/Orders/View/OrderDetails";
import { TalabatOrderDetails } from "pages/Orders/View/TalabatOrderDetails";
import { TalabatOrderCreate } from "pages/Orders/View/TalabatOrderCreate";
import { Reviews } from "pages/Reviews";
import { ReviewDetails } from "pages/Reviews/View";

export interface Route {
  type: "main" | "additional";
  path: string;
  Component: FC;
  access?: boolean | Permission[];
  props?: object;
}

export const LOGIN = "/login";

export const NOT_FOUND_ROUTES: Record<string, Route> = {
  NOT_FOUND: { type: "main", path: "/404", Component: NotFound, access: true },
};

export const ADMIN_ROUTES: Record<string, Route> = {
  ORDERS: {
    type: "main",
    path: "/orders",
    Component: Orders,
    access: [Permission.OrdersGet],
  },
  ORDER_DETAILS: {
    type: "additional",
    path: "/orders/:id",
    Component: OrderDetails,
    access: [Permission.OrdersGet],
  },
  SCHEDULED_ORDER_DETAILS: {
    type: "additional",
    path: "/orders/scheduled/:id",
    Component: ScheduledOrderDetails,
    access: [Permission.OrdersGet],
  },
  TALABAT_ORDER_DETAILS: {
    type: "additional",
    path: "/orders/talabat/:id",
    Component: TalabatOrderDetails,
    access: [Permission.OrdersGet],
  },
  ORDER_CREATE: {
    type: "additional",
    path: "/orders/create",
    Component: OrderCreate,
    access: [Permission.OrdersCreate],
  },
  TALABAT_ORDER_CREATE: {
    type: "additional",
    path: "/orders/talabat/create",
    Component: TalabatOrderCreate,
    access: [Permission.OrdersCreate],
  },
  MENU_RECOMMENDED: {
    type: "main",
    path: "/menu/recommended",
    Component: Recommended,
    access: [Permission.LocationsMenuGet],
  },
  // ANALYTICS: {
  // type: 'main',
  //   path: "/analytics",
  //   Component: Analytics,
  //   access: [Permission.StatisticsGet],
  // },
  CUSTOMERS: {
    type: "main",
    path: "/customers",
    Component: Customers,
    access: [Permission.CustomersGet],
  },
  CUSTOMERS_DETAILS: {
    type: "additional",
    path: "/customers/:id",
    Component: CustomerDetails,
    access: [Permission.CustomersGet, Permission.CustomerBase],
  },
  COIN_PACKAGES: {
    type: "main",
    path: "/coin-packages",
    Component: CoinPackages,
    access: [Permission.PackagesGet],
  },
  REVIEWS: {
    type: "main",
    path: "/reviews",
    Component: Reviews,
    access: true,
  },
  REVIEW_DETAILS: {
    type: "additional",
    path: "/reviews/:id",
    Component: ReviewDetails,
    access: true,
  },
  ORDER_COUPONS: {
    type: "main",
    path: "/coupons/order-coupons",
    Component: OrderCoupons,
    access: [Permission.CouponsGet],
  },
  ORDER_COUPON_EDIT: {
    type: "additional",
    path: "/coupons/order-coupons/:id",
    Component: OrderCouponEdit,
    access: [Permission.CouponsGet],
  },
  ORDER_COUPON_CREATE: {
    type: "additional",
    path: "/coupons/order-coupons/create",
    Component: OrderCouponEdit,
    access: [Permission.CouponsCreate],
  },
  TOP_UP_COUPONS: {
    type: "main",
    path: "/coupons/top-up-coupons",
    Component: TopUpCoupons,
    access: [Permission.CouponsGet],
  },
  TOP_UP_COUPON_EDIT: {
    type: "additional",
    path: "/coupons/top-up-coupons/:id",
    Component: TopUpCouponEdit,
    access: [Permission.CouponsGet],
  },
  TOP_UP_COUPON_CREATE: {
    type: "additional",
    path: "/coupons/top-up-coupons/create",
    Component: TopUpCouponEdit,
    access: [Permission.CouponsCreate],
  },
  TRANSACTIONS: {
    type: "main",
    path: "/transactions",
    Component: Transactions,
    access: [Permission.TransactionsGet],
  },
  //TODO: remove comment if BE is ready
  // TRANSACTIONS_DETAILS: {
  //   path: "/transactions/:id",
  //   Component: TransactionDetails,
  //   access: [Permission.TransactionsGet],
  // },
  USERS: {
    type: "main",
    path: "/users/all-users",
    Component: Users,
    access: [Permission.UsersGet],
  },
  USER_DETAILS: {
    type: "additional",
    path: "/users/:id",
    Component: UserDetails,
    access: [Permission.UsersGet],
  },
  WAITING_LIST: {
    type: "main",
    path: "/users/waiting-list",
    Component: WaitingList,
    access: [Permission.UsersGet],
  },
  ROLES: {
    type: "main",
    path: "/roles",
    Component: Roles,
    access: [Permission.RolesGet],
  },
  ROLES_CREATE: {
    type: "additional",
    path: "/roles/create",
    Component: EditCreateRoles,
    access: [Permission.RolesCreate],
  },
  ROLES_EDIT: {
    type: "additional",
    path: "/roles/:id",
    Component: EditCreateRoles,
    access: [Permission.RolesUpdate],
  },
  DELIVERY_AREAS: {
    type: "main",
    path: "/delivery-areas",
    Component: DeliveryAreas,
    access: [Permission.LocationsGet],
  },
  DELIVERY_AREAS_EDIT: {
    type: "additional",
    path: "/delivery-areas/edit/:id",
    Component: EditArea,
    access: [Permission.LocationsGet],
  },
  DELIVERY_AREAS_SHOW: {
    type: "additional",
    path: "/delivery-areas/:id",
    Component: AreaDetails,
    access: [Permission.LocationsGet],
  },
  SETTINGS: {
    type: "main",
    path: "/settings",
    Component: Settings,
    access: [Permission.SettingsGet],
  },
  PROVIDERS: {
    type: "main",
    path: "/location-providers",
    Component: LocationProviders,
    access: [Permission.LocationProvidersGet],
  },
};
