// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useCreateLocationProvidersMutation,
  useUpdateLocationProvidersMutation,
} from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { DefaultOptionType } from "antd/es/select";
import { ConnnectModalProps, Settings } from "./types";
// Constants
import { DeliveryProviders } from "types/deliveryProviders";
// Helpers
import { getDefaultSettings } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Modal } from "components";
import { Select, Switch } from "components/Form";
// Styled
import { FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";

export const ConnnectModal: FC<ConnnectModalProps> = ({
  locationsProviderLists,
  open,
  data,
  onClose,
}) => {
  const { isMobile } = useViewport();

  const { openNotification } = useNotification();

  const isEdit = typeof data !== "boolean" && !!data;

  const [selectedSettings, setSelectedSettings] = useState<Settings>(() =>
    getDefaultSettings(),
  );

  const [create, { isLoading: isCreateLoading }] =
    useCreateLocationProvidersMutation();

  const [update, { isLoading: isUpdateLoading }] =
    useUpdateLocationProvidersMutation();

  const { loopLocations, deliverectLocations } = locationsProviderLists;

  useEffect(() => {
    if (!!data && typeof data !== "boolean") {
      setSelectedSettings(data.settings);
      return;
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      return;
    }

    setSelectedSettings(getDefaultSettings());
  }, [open]);

  const onIsActiveChange = (key: DeliveryProviders, value: boolean) => {
    setSelectedSettings(prev => {
      const updatedSettings = Object.keys(prev).reduce(
        (acc, currentKey: DeliveryProviders) => {
          acc[currentKey] = {
            ...prev[currentKey],
            isActive: currentKey === key ? value : !value,
          };
          return acc;
        },
        {} as typeof prev,
      );

      return updatedSettings;
    });
  };

  const onLocationChange = (
    key: DeliveryProviders,
    value: string,
    location: string,
  ) => {
    setSelectedSettings(prev => ({
      ...prev,
      [key]: { ...prev[key], locationId: value, location },
    }));
  };

  const handleClose = () => {
    if (isCreateLoading || isUpdateLoading) {
      return;
    }

    onClose();
  };

  const onConfirm = async () => {
    const providers = Object.entries(selectedSettings).map(
      ([provider, config]) => ({
        locationId: config.locationId,
        location: config.location,
        provider: provider as DeliveryProviders,
        isActive: config.isActive,
      }),
    );

    try {
      if (isEdit) {
        await update({ id: data.id, providers }).unwrap();

        openNotification({ message: "Connection successfuly updated" });
      } else {
        await create({ providers }).unwrap();

        openNotification({ message: "Connection successfuly created" });
      }

      onClose();
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const isSubmitDisabled =
    !selectedSettings[DeliveryProviders.deliverect].locationId ||
    !selectedSettings[DeliveryProviders.loop].locationId;

  return (
    <Modal
      open={!!data}
      title={`${isEdit ? "Edit" : "Add"} Connection`}
      onClose={handleClose}
    >
      <InputsGridContainer>
        <FlexContainer $fullwidth $column $gap={8}>
          <Select
            label="Deliverect"
            placeholder="Select Location"
            value={selectedSettings[DeliveryProviders.deliverect].locationId}
            options={deliverectLocations}
            onChange={(value: string, option: DefaultOptionType) =>
              onLocationChange(
                DeliveryProviders.deliverect,
                value,
                option.label as string,
              )
            }
          />

          <Switch
            label="Active"
            checked={selectedSettings[DeliveryProviders.deliverect].isActive}
            onChange={(checked: boolean) =>
              onIsActiveChange(DeliveryProviders.deliverect, checked)
            }
          />
        </FlexContainer>

        <FlexContainer $fullwidth $column $gap={8}>
          <Select
            label="Loop"
            placeholder="Select Location"
            value={selectedSettings[DeliveryProviders.loop].locationId}
            options={loopLocations}
            onChange={(value: string, option: DefaultOptionType) =>
              onLocationChange(
                DeliveryProviders.loop,
                value,
                option.label as string,
              )
            }
          />
          <Switch
            label="Active"
            checked={selectedSettings[DeliveryProviders.loop].isActive}
            onChange={(checked: boolean) =>
              onIsActiveChange(DeliveryProviders.loop, checked)
            }
          />
        </FlexContainer>
      </InputsGridContainer>

      <FlexContainer
        $fullwidth
        $align="center"
        $justify="flex-end"
        $gap={8}
        $padding="24px 0 0"
        $column={isMobile}
      >
        <Button.Heading
          $fullWidth={isMobile}
          disabled={isCreateLoading || isUpdateLoading}
          onClick={handleClose}
        >
          Cancel
        </Button.Heading>

        <Button.Heading
          $fullWidth={isMobile}
          type="primary"
          loading={isCreateLoading || isUpdateLoading}
          disabled={isSubmitDisabled}
          onClick={onConfirm}
        >
          Confirm
        </Button.Heading>
      </FlexContainer>
    </Modal>
  );
};
