import {
  TransactionResponseDto,
  TransactionTypeLabel,
} from "types/transactions";

export const getCsvMappedData = (
  data: TransactionResponseDto[],
): Record<string, unknown>[] => {
  return data.map(d => ({
    ...d,
    status: d?.payment?.status || null,
    exchangeRate: d?.payment?.metadata?.exchangeRate || null,
    payment: d?.payment?.amount,
    type: TransactionTypeLabel[d?.type] || null,
  }));
};
