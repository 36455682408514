import { fromLonLat } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import { Vector } from "ol/source";
import {
  ChannelLinkStatusEnum,
  GetLocationResponseDto,
  KitchenAddressResponseDto,
  MapInputData,
  OperatingStatusEnum,
  TZone,
} from "types/locations";
import { getZoneLabel } from "Layers/helpers";

const gj = new GeoJSON();

export const operatingStatusTitle = {
  [OperatingStatusEnum.Online]: "Online",
  [OperatingStatusEnum.Paused]: "Paused",
};

export const operatingStatusColor = {
  [OperatingStatusEnum.Online]: "success",
  [OperatingStatusEnum.Paused]: "danger",
};

export const channelLinkStatusTitle = {
  [ChannelLinkStatusEnum.Active]: "Active",
  [ChannelLinkStatusEnum.Register]: "Register",
  [ChannelLinkStatusEnum.Inactive]: "Inactive",
};

export const channelLinkStatusColor = {
  [ChannelLinkStatusEnum.Active]: "success",
  [ChannelLinkStatusEnum.Register]: "warning",
  [ChannelLinkStatusEnum.Inactive]: "danger",
};

export const getOperatingStatusColor = (
  status: OperatingStatusEnum | ChannelLinkStatusEnum,
) => {
  switch (status) {
    case OperatingStatusEnum.Online:
    case ChannelLinkStatusEnum.Active:
      return "success";
    case ChannelLinkStatusEnum.Register:
      return "warning";
    case OperatingStatusEnum.Paused:
    case ChannelLinkStatusEnum.Inactive:
      return "danger";

    default:
      return "neutral";
  }
};

export const geoToMercatorMapper = (zone: TZone) => {
  const source = new Vector({
    features: gj.readFeatures(
      {
        type: "Feature",
        properties: {
          id: "shape",
        },
        ...zone,
      },
      {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      },
    ),
  });

  return source;
};

export const latLngToCoords = (latLng?: KitchenAddressResponseDto) => {
  if (!latLng) return [];

  return [latLng.lng, latLng.lat];
};

export const inputDataMapper = (
  locations: GetLocationResponseDto[],
): MapInputData[] => {
  if (!locations[0]) return [];
  if (!locations) return [];

  return locations.map(({ id, operatingZones, kitchenAddress, name }) => {
    const kitchenCoords = latLngToCoords(kitchenAddress);

    return {
      id,
      name: name || "",
      kitchenAddress: fromLonLat(kitchenCoords),
      zones: operatingZones.map((zone, i) => ({
        zoneId: `${id}-${i}`,
        name: zone.name || getZoneLabel(i),
        source: geoToMercatorMapper(zone),
        priority: zone.priority,
        coordinates: zone.geometry.coordinates,
      })),
    };
  });
};
