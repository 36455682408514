// Lib
import { FC } from "react";
// Types
import { GetReferralsResponseDto } from "types/referrals";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Table } from "components";

import { columns } from "./config";

interface ReferralsTabProps {
  isLoading: boolean;
  referralsData: GetReferralsResponseDto;
}

export const ReferralsTab: FC<ReferralsTabProps> = ({
  isLoading,
  referralsData,
}) => {
  return (
    <Table
      isLoading={isLoading}
      dataSource={referralsData?.items || []}
      columns={columns}
      empty={{
        icon: DescriptionBlackIcon,
        title: "No referrals to show.",
      }}
      header={{
        totalCount: referralsData?.totalCount,
      }}
    />
  );
};
