import {
  OrderItemResponseDto,
  OrderResponseDto,
  ScheduledOrderItemResponseDto,
  ScheduledOrderResponseDto,
} from "types/orders";
import { OrderDetailsDataType } from "./components/OrderDetail/types";
import { SummaryDataType } from "./components/Summary/types";

export const transformToOrderDetails = (
  data: OrderItemResponseDto[] | ScheduledOrderItemResponseDto[],
): OrderDetailsDataType[] => {
  return data.map(el => ({
    productId: el.productId,
    productName: el?.productName?.en || el?.productName,
    quantity: el.quantity,
    fmcCentsPrice: el.fmcCentsPrice,
    fiatCentsPrice: el.fiatCentsPrice,
    subItems: el.subItems,
    isSnoozed: el?.isSnoozed,
    imageURL: el?.imageURL,
  }));
};

export const transformToSummary = (
  data: OrderResponseDto | ScheduledOrderResponseDto,
): SummaryDataType => {
  return {
    coupons: data?.coupons || [],
    paymentType: data?.paymentType,
    fullName: data?.fullName,
    sid: data?.sid,
    chargeStatus: data?.chargeStatus,
    orderStatus: data?.orderStatus,
    chargeFailureReason: data?.chargeFailureReason,
    delivery: data?.delivery,
    totalFmcCentsPrice: data?.totalFmcCentsPrice,
    totalFiatCentsPrice: data?.totalFiatCentsPrice,
    locationId: data?.locationId,
    locationName: data?.locationName,
    timeslot: data?.timeslot,
    customerId: data?.customerId,
    expectedPreorderDeliveryTime:
      (data as ScheduledOrderResponseDto)?.expectedPreorderDeliveryTime || "",
    deliveredAt: (data as ScheduledOrderResponseDto)?.deliveredAt || "",
  };
};
