// Lib
import { FC, useEffect, useMemo, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
// Api
import {
  useDeletePackageMutation,
  useGetPackagesQuery,
  useUpdatePackagesOrderMutation,
} from "rtkQuery/query/coinPackagesApi";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
// Hooks
import { useNotification, usePermissions, useViewport } from "hooks";
// Types
import { TableAction } from "types/common";
import { PackageResponseDto } from "types/packages";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS, rtkQueryParams } from "consts";
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PlusIcon, TrashIcon } from "icons";
// Layouts
// Components
import { Table } from "components";
import { ConfirmDialog } from "components/Modals";
import { DragableRow } from "components/Table/components";
import { PackagesModal } from "../PackagesModal";
// Styled
import { Button } from "styled/Buttons";

import { columns, mobileColumns } from "./config";

export const Packages: FC = () => {
  const { canPackagesCreate, canPackagesUpdate } = usePermissions();

  const { isMobile, isDesktop } = useViewport();

  const { openNotification } = useNotification();

  const [deletePackage, { isLoading }] = useDeletePackageMutation();
  const [updateOrder] = useUpdatePackagesOrderMutation();

  const {
    data,
    isLoading: isFetching,
    error,
  } = useGetPackagesQuery(null, rtkQueryParams);

  const [dataSource, setDataSource] = useState<PackageResponseDto[]>([]);

  useEffect(() => {
    if (data) {
      setDataSource(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const [packagesModal, setPackagesModal] = useState<
    boolean | PackageResponseDto
  >(false);

  const [packageDeleteDialog, setPackageDeleteDialog] = useState<
    false | PackageResponseDto
  >(false);

  const onConfirmDelete = async () => {
    if (typeof packageDeleteDialog === "boolean") {
      return;
    }
    try {
      await deletePackage({ id: packageDeleteDialog?.id }).unwrap();

      openNotification({
        message: NOTIFICATIONS.PACKAGE_DELETED,
      });

      setPackageDeleteDialog(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      let newArray: PackageResponseDto[] = [];
      setDataSource(prev => {
        const activeIndex = prev.findIndex(i => i.id === active.id);
        const overIndex = prev.findIndex(i => i.id === over?.id);

        newArray = arrayMove(prev, activeIndex, overIndex);
        return newArray;
      });

      try {
        await updateOrder({
          items: newArray.map((el, i) => ({ id: el.id, order: i })),
        }).unwrap();

        openNotification({ message: "Packages order successfully updated" });
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    }
  };

  const actions: TableAction[] = useMemo(
    () => [
      {
        title: "",
        Icon: PencilIcon,
        disabled: !canPackagesUpdate,
        type: "Grey",
        onClick: row => setPackagesModal(row),
      },
      {
        title: "",
        Icon: TrashIcon,
        disabled: !canPackagesUpdate,
        type: "Grey",
        onClick: row => setPackageDeleteDialog(row),
      },
    ],
    [],
  );

  return (
    <>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={dataSource?.map(i => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            header={{
              leftTitle: "Coin packages",
              suffix:
                canPackagesCreate && !isMobile ? (
                  <Button.Base
                    type="primary"
                    icon={<PlusIcon fill={theme.color.white} />}
                    onClick={() => setPackagesModal(true)}
                  >
                    Add Package
                  </Button.Base>
                ) : (
                  <Button.SquaredIcon
                    type="primary"
                    icon={<PlusIcon fill={theme.color.white} />}
                    onClick={() => setPackagesModal(true)}
                  />
                ),
            }}
            components={{
              body: !isMobile && {
                row: DragableRow,
              },
            }}
            dragable
            isLoading={isFetching}
            columns={isDesktop ? columns : mobileColumns}
            actions={actions}
            dataSource={dataSource}
          />
        </SortableContext>
      </DndContext>

      <PackagesModal
        modalData={packagesModal}
        onClose={() => setPackagesModal(false)}
      />

      <ConfirmDialog
        open={!!packageDeleteDialog}
        isLoading={isLoading}
        Icon={TrashIcon}
        message={`${
          (packageDeleteDialog as PackageResponseDto)?.name
        } will be deleted`}
        description="Are you sure you want to delete this package?"
        onCancel={() => setPackageDeleteDialog(false)}
        firstCTAButton={{
          title: "Delete Package",
          status: "danger",
          loading: isLoading,
          onClick: () => onConfirmDelete(),
        }}
      />
    </>
  );
};
