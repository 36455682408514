import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const AvatarIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="21" cy="21" r="20.5" fill="#99AB9C" stroke="#BEC9BD" />
    <mask
      id="mask0_9842_11127"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="40"
      height="40"
    >
      <circle cx="21" cy="21" r="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_9842_11127)">
      <path
        d="M21.0001 26.7166C18.9048 26.7166 17.1112 25.9706 15.6191 24.4785C14.127 22.9865 13.381 21.1928 13.381 19.0976C13.381 17.0023 14.127 15.2087 15.6191 13.7166C17.1112 12.2245 18.9048 11.4785 21.0001 11.4785C23.0953 11.4785 24.8889 12.2245 26.381 13.7166C27.8731 15.2087 28.6191 17.0023 28.6191 19.0976C28.6191 21.1928 27.8731 22.9865 26.381 24.4785C24.8889 25.9706 23.0953 26.7166 21.0001 26.7166ZM5.76196 41.9547V36.6214C5.76196 35.542 6.04006 34.5496 6.59625 33.6442C7.15117 32.7401 7.88895 32.0499 8.80958 31.5738C10.7778 30.5896 12.7778 29.8512 14.8096 29.3585C16.8413 28.8671 18.9048 28.6214 21.0001 28.6214C23.0953 28.6214 25.1588 28.8671 27.1905 29.3585C29.2223 29.8512 31.2223 30.5896 33.1905 31.5738C34.1112 32.0499 34.8489 32.7401 35.4039 33.6442C35.9601 34.5496 36.2382 35.542 36.2382 36.6214V41.9547H5.76196Z"
        fill="#002E07"
      />
    </g>
  </svg>
);
