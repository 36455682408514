export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Deliverect",
    dataIndex: ["deliverect", "location"],
    key: "deliverect",
  },
  {
    title: "Loop",
    dataIndex: ["loop", "location"],
    key: "loop",
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
  },
];
