import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const couponsUrlBuilder = {
  getCoupons: ({ query }: { query: UrlQueryParams }): string => {
    return generateRequestUrl(endpoints.coupons, query);
  },
  getCoupon: ({ id }: { id: string }): string => {
    return `${endpoints.coupons}/${id}`;
  },
  getCouponUsedByUserList: ({
    id,
    query,
  }: {
    id: string;
    query: UrlQueryParams;
  }): string => {
    return generateRequestUrl(`${endpoints.coupons}/${id}/customers`, query);
  },
};
