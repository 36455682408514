// Lib
import { FC, memo, useCallback, useEffect, useMemo } from "react";
// Api
import {
  useGetCouponUsedByUserListQuery,
  useLazyGetCouponUsedByUserListQuery,
} from "rtkQuery/query/couponsAPI";
// Hooks
import { useExport, useNotification, useTable, useViewport } from "hooks";
// Types
import { ETable } from "types/tableFilters";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon, RightBurgerMenuIcon } from "icons";
// Components
import { DropDown, Export, Modal, Pagination, Table } from "components";
// Styled
import { Button } from "styled/Buttons";
import { FlexContainer } from "styled/Box";

import { columns } from "./config";

interface CouponUsersModalProps {
  usedCouponUsers: { id: string; name: string };
  setUsedCouponUsers: (value: { id: string; name: string }) => void;
}

export const CouponUsersModal: FC<CouponUsersModalProps> = memo(
  ({ usedCouponUsers, setUsedCouponUsers }) => {
    const { openNotification } = useNotification();
    const { isDesktop } = useViewport();
    const { downloadCSV } = useExport();

    const { page, limit, setPage, setLimit } = useTable({
      name: ETable.CouponUsedByUserList,
      removeQueryParams: true,
    });

    const [getExportCouponUsersData, { isLoading: isExportLoading }] =
      useLazyGetCouponUsedByUserListQuery();

    const { data: tableData, isFetching: isTableFetching } =
      useGetCouponUsedByUserListQuery(
        {
          id: usedCouponUsers.id,
          query: { page, limit },
        },
        { refetchOnMountOrArgChange: true, skip: !usedCouponUsers.id },
      );

    useEffect(() => {
      if (!usedCouponUsers?.id) {
        setLimit(10);
      }
    }, [usedCouponUsers]);

    const getExportData = async (): Promise<Record<string, unknown>[]> => {
      try {
        const data = await getExportCouponUsersData({
          id: usedCouponUsers?.id,
          query: {
            limit: tableData.totalCount,
          },
        }).unwrap();

        return data.items;
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    };

    const dropDownMenuItems = useMemo(
      () => [
        {
          key: "1",
          label: "Export",
          onClick: () =>
            downloadCSV({
              fileName: usedCouponUsers.name,
              isDataLoading: isExportLoading,
              columns: columns,
              getExportData: getExportData,
            }),
        },
      ],
      [usedCouponUsers],
    );

    const handleClose = useCallback(() => {
      setUsedCouponUsers({ id: "", name: "" });
    }, []);

    return (
      <Modal
        width={900}
        open={!!usedCouponUsers?.id}
        title={usedCouponUsers.name}
        onClose={handleClose}
      >
        <FlexContainer $fullwidth $column $gap={16}>
          <Table
            isLoading={isTableFetching}
            header={{
              totalCount: tableData?.totalCount,
              suffix: isDesktop ? (
                <Export
                  type="Base"
                  fileName={usedCouponUsers.name}
                  isLoading={isExportLoading}
                  isDataLoading={isTableFetching}
                  isDisabled={!tableData?.totalCount}
                  columns={columns}
                  getExportData={getExportData}
                />
              ) : (
                <DropDown items={dropDownMenuItems} trigger={["click"]}>
                  <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
                </DropDown>
              ),
            }}
            columns={columns}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No users to show.",
            }}
            dataSource={tableData?.items || []}
          />

          <Pagination
            limit={limit}
            page={page}
            setLimit={setLimit}
            setPage={setPage}
            showSizeChanger={false}
            totalItems={tableData?.totalCount}
          />
        </FlexContainer>
      </Modal>
    );
  },
);

CouponUsersModal.displayName = "CouponUsersModal";
