// Lib
import { FC } from "react";
// Types
import { FilterField } from "types/tableFilters";
// Theme
import { theme } from "theme";
// Icons
import { ArrowDropDownBlackIcon } from "icons";
// Styled
import { FlexContainer } from "styled/Box";
import { CloseIcon, FilterSelect } from "../styled";
import { RateStars } from "./styled";

const RATE_OPTIONS = [
  {
    value: "0",
  },
  {
    value: "1",
  },
  {
    value: "2",
  },
  {
    value: "3",
  },
  {
    value: "4",
  },
  {
    value: "5",
  },
];

interface RatingFilterProps {
  name: string;
  isOpen: boolean;
  field: FilterField;
  value: string;
  setIsOpen: (arg: boolean) => void;
  setFilterValue: (value: string, name: string) => void;
}

export const RatingFilter: FC<RatingFilterProps> = ({
  name,
  isOpen,
  field,
  value,
  setIsOpen,
  setFilterValue,
}) => {
  return (
    <FilterSelect
      $inputType={field}
      suffixIcon={
        <FlexContainer $align="center">
          <ArrowDropDownBlackIcon />
          <div
            style={{
              width: 20,
              height: 30,
              borderLeft: `1px solid ${theme.color.borderMedium}`,
            }}
          />
        </FlexContainer>
      }
      removeIcon={<CloseIcon />}
      open={isOpen}
      popupMatchSelectWidth={253}
      onMouseDown={() => setIsOpen(true)}
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      onChange={v => setFilterValue(v, name)}
      value={value}
      options={RATE_OPTIONS}
      optionRender={option => {
        return (
          <FlexContainer $height="24px" $align="center" $gap={4}>
            <RateStars disabled value={Number(option?.data?.value)} />
          </FlexContainer>
        );
      }}
      labelRender={label => (
        <FlexContainer $align="center" $gap={4}>
          <span>Rating:</span>
          <RateStars disabled value={Number(label?.value)} />
        </FlexContainer>
      )}
    />
  );
};
