// Lib
import { FC, memo } from "react";
// Types
import { TalabalPaymentType, TalabalPaymentTypeLabel } from "types/orders";
import { MenuStatuses, SummaryData } from "../../types";
// Helpers
import { fixedDigitsValue } from "helpers/dataHelpers";
// Components
import { SegmentedButtons } from "components";
import { BlockedState } from "../BlockedState";
// Styled
import { ContentBox, FlexContainer, HorizontalDashedDevider } from "styled/Box";
import { Typography } from "styled/Typography";

interface OrderSummaryProps {
  isBlocked: boolean;
  menuStatuses: MenuStatuses;
  paymentType: TalabalPaymentType;
  summary: SummaryData;
  setPaymentType: React.Dispatch<React.SetStateAction<TalabalPaymentType>>;
}

const OrderSummaryComponent: FC<OrderSummaryProps> = ({
  isBlocked,
  menuStatuses,
  paymentType,
  summary,
  setPaymentType,
}) => {
  const { quantity, subTotal, total } = summary;

  const { isClosed } = menuStatuses;

  const options = [
    {
      value: TalabalPaymentType.Cash,
      label: TalabalPaymentTypeLabel[TalabalPaymentType.Cash],
    },
    {
      value: TalabalPaymentType.Knet,
      label: TalabalPaymentTypeLabel[TalabalPaymentType.Knet],
    },
  ];

  const isUnAvailable = isBlocked || isClosed;

  return (
    <ContentBox $column $gap={16} $position="relative">
      <Typography.H2>Order Summary</Typography.H2>

      <FlexContainer $fullwidth $column $gap={12}>
        <FlexContainer $fullwidth $gap={8} $justify="space-between">
          <FlexContainer $column $gap={12}>
            <Typography.Title>
              Subtotal items ({quantity} items)
            </Typography.Title>
          </FlexContainer>

          <FlexContainer $column $gap={12}>
            <Typography.Title>
              {fixedDigitsValue(subTotal.fiat, 3)} KD
            </Typography.Title>
          </FlexContainer>
        </FlexContainer>

        <HorizontalDashedDevider />

        <FlexContainer $fullwidth $gap={8} $justify="space-between">
          <Typography.H3>Total</Typography.H3>

          <Typography.H3>{fixedDigitsValue(total.fiat, 3)} KD</Typography.H3>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer $fullwidth $align="center" $justify="space-between">
        <Typography.H3>Payment type</Typography.H3>

        <SegmentedButtons
          value={paymentType}
          setValue={(value: TalabalPaymentType) => setPaymentType(value)}
          options={options}
        />
      </FlexContainer>

      {isUnAvailable && <BlockedState isClosed={isClosed} />}
    </ContentBox>
  );
};

export const OrderSummary = memo(OrderSummaryComponent);
