// Lib
import { FC, memo } from "react";
// Types
import { OrderPaymentType, OrderPaymentTypeLabel } from "types/orders";
import { MenuStatuses, SummaryData } from "../../types";
// Helpers
import { fixedDigitsValue } from "helpers/dataHelpers";
// Icons
import { CurrencyIcon } from "icons";
// Components
import {
  SegmentedButtons,
  // Tooltip
} from "components";
import { ErrorState } from "../ErrorState";
import { BlockedState } from "../BlockedState";
// Styled
import { ContentBox, FlexContainer, HorizontalDashedDevider } from "styled/Box";
import { Typography } from "styled/Typography";

interface OrderSummaryProps {
  isBlocked: boolean;
  isCashPayment: boolean;
  menuStatuses: MenuStatuses;
  isExpiredCoinsError: boolean;
  isBalanceError: boolean;
  paymentType: OrderPaymentType;
  summary: SummaryData;
  customerCoinsBalance: number | undefined;
  customerCoinsExpireDate: string | undefined;
  setPaymentType: React.Dispatch<React.SetStateAction<OrderPaymentType>>;
}

const OrderSummaryComponent: FC<OrderSummaryProps> = ({
  isBlocked,
  // isCashPayment,
  menuStatuses,
  isExpiredCoinsError,
  isBalanceError,
  paymentType,
  summary,
  customerCoinsBalance,
  customerCoinsExpireDate,
  setPaymentType,
}) => {
  const { quantity, subTotal, total, deliveryFee } = summary;

  const {
    isClosed,
    //  isBusy
  } = menuStatuses;

  // const isCashAvailable = isCashPayment && !isBusy;

  // const disabledTooltipTitle = !isCashPayment
  //   ? "Cash payments is not available"
  //   : isBusy
  //   ? "Kitchen is busy"
  //   : "";

  const options = [
    {
      value: OrderPaymentType.FmcCents,
      label: OrderPaymentTypeLabel[OrderPaymentType.FmcCents],
    },
    {
      value: OrderPaymentType.FiatCents,
      label: OrderPaymentTypeLabel[OrderPaymentType.FiatCents],
      disabled: true,
    },

    //temporary removed. ability to create cashpayment orders on hold
    // {
    //   value: OrderPaymentType.FiatCents,
    //   label: isCashAvailable ? (
    //     OrderPaymentTypeLabel[OrderPaymentType.FiatCents]
    //   ) : (
    //     <Tooltip title={disabledTooltipTitle} trigger={"hover"}>
    //       {OrderPaymentTypeLabel[OrderPaymentType.FiatCents]}
    //     </Tooltip>
    //   ),
    //   disabled: !isCashPayment || isBusy,
    // },
  ];

  const isUnAvailable = isBlocked || isClosed;

  return (
    <ContentBox $column $gap={16} $position="relative">
      <Typography.H2>Order Summary</Typography.H2>

      <FlexContainer $fullwidth $column $gap={12}>
        <FlexContainer $fullwidth $gap={8} $justify="space-between">
          <FlexContainer $column $gap={12}>
            <Typography.Title>
              Subtotal items ({quantity} items)
            </Typography.Title>

            <Typography.Title>Delivery fee</Typography.Title>
          </FlexContainer>

          <FlexContainer $column $gap={12}>
            <FlexContainer $gap={4} $align="center">
              <CurrencyIcon width="24" height="24" />

              <Typography.Title>
                {fixedDigitsValue(subTotal.coins, 1)} | KD{" "}
                {fixedDigitsValue(subTotal.fiat, 3)}
              </Typography.Title>
            </FlexContainer>

            <FlexContainer $gap={4} $align="center">
              <CurrencyIcon width="24" height="24" />

              <Typography.Title>
                {fixedDigitsValue(deliveryFee.coins, 1)} | KD{" "}
                {fixedDigitsValue(deliveryFee.fiat, 3)}
              </Typography.Title>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <HorizontalDashedDevider />

        <FlexContainer $fullwidth $gap={8} $justify="space-between">
          <Typography.H3>Total</Typography.H3>

          <FlexContainer $gap={4} $align="center">
            <CurrencyIcon width="24" height="24" />

            <Typography.H3>
              {fixedDigitsValue(total.coins, 1)} | KD{" "}
              {fixedDigitsValue(total.fiat, 3)}
            </Typography.H3>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer $fullwidth $align="center" $justify="space-between">
        <Typography.H3>Payment type</Typography.H3>

        <SegmentedButtons
          value={paymentType}
          setValue={(value: OrderPaymentType) => setPaymentType(value)}
          options={options}
        />
      </FlexContainer>

      {isUnAvailable && <BlockedState isClosed={isClosed} />}

      {isBalanceError && (
        <ErrorState customerCoinsBalance={customerCoinsBalance} />
      )}

      {isExpiredCoinsError && (
        <ErrorState customerCoinsExpireDate={customerCoinsExpireDate} />
      )}
    </ContentBox>
  );
};

export const OrderSummary = memo(OrderSummaryComponent);
