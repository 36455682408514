// Lib
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import { useGetLocationQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification } from "hooks";
// Constants
import { ADMIN_ROUTES, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon } from "icons";
// Components
import { ArrowBackLink, Loader } from "components";
import { History, Info, Map } from "./components";
// Styled
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { GridContainer, Wrapper } from "./styled";

export const AreaDetails: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const {
    data: locationData,
    isFetching,
    error,
  } = useGetLocationQuery({ id }, rtkQueryParams);

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const handleEdit = () => {
    navigate(`${ADMIN_ROUTES.DELIVERY_AREAS.path}/edit/${id}`);
  };

  return (
    <>
      {isFetching && <Loader />}

      <FlexContainer $fullwidth $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column $gap={16}>
            <ArrowBackLink
              title="Back to Delivery Areas"
              path={ADMIN_ROUTES.DELIVERY_AREAS.path}
            />

            <Typography.H1>{locationData?.name}</Typography.H1>
          </PageTitleContainer>

          <Button.Heading icon={<PencilIcon />} onClick={handleEdit}>
            Edit
          </Button.Heading>
        </PageHeadingContainer>

        <GridContainer>
          <Wrapper>
            <Info locationData={locationData} />

            {!isFetching && !!locationData && (
              <Map locationData={locationData} />
            )}
          </Wrapper>

          <History isCustomerDataLoading={false} locationData={locationData} />
        </GridContainer>
      </FlexContainer>
    </>
  );
};
