import { theme } from "theme";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  @media screen and (max-width: ${theme.breakpoints.l}) {
    grid-template-columns: 1fr;
  }
`;
