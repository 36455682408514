// Lib
import { Children, FC, cloneElement, useState } from "react";
import { TableProps } from "antd";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
// Types
import type { DragEndEvent } from "@dnd-kit/core";
import type { ColumnsType } from "antd/es/table";
import { TableAction } from "types/common";
// Icons
import { DragIndicatorIcon, MenuIcon } from "icons";
// Components
import { ITableProps, Table } from "components/Table";
// Styled
import { FlexContainer } from "styled/Box";
import { DownArrow } from "./styled";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

interface CustomDragTableProps {
  childrenDataSource: string;
  expandedRowColumns: ColumnsType<any>;
  expandedRowActions: TableAction[];
  orderItemsChange: (id: string, newArr: unknown[], oldArr: unknown[]) => void;
}

export const CustomDragTable: FC<
  ITableProps & TableProps<any> & CustomDragTableProps
> = ({
  expandedRowColumns,
  expandedRowActions,
  childrenDataSource,
  orderItemsChange,
  ...props
}) => {
  const [expandedRows, setExpandedRows] = useState<React.Key[]>([]);
  const [dragRowId, setDragRowsId] = useState<string | null>(null);

  const onExpandedRowsChange = (expandedKeys: React.Key[]) => {
    setExpandedRows(expandedKeys);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
      setActivatorNodeRef,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 },
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 999,
          }
        : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {Children.map(children, child => {
          if ((child as React.ReactElement).key === "sort") {
            return cloneElement(child as React.ReactElement, {
              children: (
                <FlexContainer
                  $margin="0 -18px 0 0"
                  $align="center"
                  $justify="flex-end"
                  $width="68px"
                >
                  <FlexContainer
                    ref={setActivatorNodeRef}
                    {...listeners}
                    $cursor="move"
                  >
                    <DragIndicatorIcon />
                  </FlexContainer>
                </FlexContainer>
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const expandedRowRender = record => {
    const onDragEnd = ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const prevArray = [...props.dataSource];
        const newArr = [...props.dataSource];

        const activeRowIndex = newArr.findIndex(el => el.id === record?.id);

        const activeIndex = newArr[activeRowIndex][
          childrenDataSource
        ].findIndex(i => i.id === active.id);

        const overIndex = newArr[activeRowIndex][childrenDataSource].findIndex(
          i => i.id === over?.id,
        );

        const newItemsArray = arrayMove(
          newArr[activeRowIndex][childrenDataSource],
          activeIndex,
          overIndex,
        );

        newArr[activeRowIndex][childrenDataSource] = newItemsArray;

        orderItemsChange(record.id, newArr, prevArray);
      }
      setDragRowsId(null);
    };

    const onDragStart = () => {
      setDragRowsId(record.id);
    };

    return (
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        <SortableContext
          items={record[childrenDataSource].map(i => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            id={record?.id}
            nested
            shadow={false}
            rowKey={record => record.id}
            components={{
              body: {
                row,
              },
            }}
            columns={[
              {
                key: "sort",
                align: "left",
                width: 72,
              },
              ...expandedRowColumns,
            ]}
            actions={expandedRowActions}
            dataSource={record[childrenDataSource]}
            empty={{
              icon: MenuIcon,
              title: "No recommended items to show.",
            }}
          />
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <Table
      rowKey={record => record.id}
      wrapTitles={false}
      minWidth={1100}
      dragRowId={dragRowId}
      expandedRows={expandedRows}
      expandable={{
        onExpandedRowsChange,
        expandedRowRender,
        expandIcon: ({ expanded, onExpand, record }) => (
          <FlexContainer onClick={e => onExpand(record, e)} $cursor="pointer">
            <DownArrow $rotate={expanded ? -180 : 0} />
          </FlexContainer>
        ),
      }}
      {...props}
    />
  );
};
