// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnyObject, ObjectSchema } from "yup";
import { Spin } from "antd";
// Api
import {
  useGetLocationQuery,
  useUpdateLocationMutation,
} from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { GeometryData } from "types/locations";
// Theme
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, rtkQueryParams } from "consts";
// Helpers
import { getValidation } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { LoaderIcon } from "icons";
// Components
import { ArrowBackLink } from "components";
import { Input, InputNumber } from "components/Form";
// Styled
import {
  ContentBox,
  FlexContainer,
  InputsGridContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { InputsGrid } from "./styled";

type ValidationType = ObjectSchema<
  {
    name: string;
    zones: object;
  },
  AnyObject,
  {
    name: undefined;
    zones: object;
  },
  ""
>;

type AreaForm = {
  name: string;
  zones: { [x: string]: { name: string; priority: number } };
};

export const EditArea: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const [geometry, setGeometry] = useState<
    Record<string, { geometry: GeometryData }>
  >({});

  const [validation, setValidation] = useState<ValidationType>();

  const [update, { isLoading: isUpdateLoading }] = useUpdateLocationMutation();
  const { data: locationData, isFetching: isDataLoading } = useGetLocationQuery(
    { id },
    rtkQueryParams,
  );

  const { control, handleSubmit, reset } = useForm<AreaForm>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: validation ? yupResolver(validation) : null,
  });

  const { operatingZones } = locationData || {};

  useEffect(() => {
    if (!locationData) {
      return;
    }

    const { name, operatingZones } = locationData || {};

    const fields = operatingZones.reduce(
      (acc, el, i) => {
        acc.zones[`zone${i}`] = { name: el.name, priority: el.priority };
        acc.geometry[`zone${i}`] = { geometry: el.geometry };
        return acc;
      },

      { zones: {}, geometry: {} },
    );

    reset({ name, zones: fields.zones });
    setGeometry(fields.geometry);

    setValidation(getValidation(locationData));
    // const validation = getValidation(data);
  }, [locationData]);

  const onSubmit: SubmitHandler<AreaForm> = async data => {
    const { name, zones } = data;

    const operatingZones = Object.entries(zones).map(el => ({
      name: el[1].name,
      priority: el[1].priority,
      geometry: geometry[el[0]].geometry,
    }));

    try {
      await update({ ...locationData, name, operatingZones }).unwrap();

      openNotification({ message: NOTIFICATIONS.LOCATION_UPDATE });

      navigate(ADMIN_ROUTES.DELIVERY_AREAS.path);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  return (
    <FlexContainer
      $fullwidth
      $column
      $gap={24}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <PageHeadingContainer>
        <PageTitleContainer $column $gap={16}>
          <ArrowBackLink
            title="Back to Delivery Areas"
            path={ADMIN_ROUTES.DELIVERY_AREAS.path}
          />

          <Typography.H1>Delivery Area</Typography.H1>
        </PageTitleContainer>

        <Button.Heading
          type="primary"
          htmlType="submit"
          loading={isUpdateLoading}
        >
          Save
        </Button.Heading>
      </PageHeadingContainer>

      <FlexContainer $fullwidth $column $gap={16}>
        <ContentBox>
          <InputsGridContainer>
            <Input label="ID" value={id} isDisabled />

            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Name"
                  required
                  isDisabled={isUpdateLoading || isDataLoading}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>

        <ContentBox $column>
          <Typography.H2>Operating zones</Typography.H2>

          <Spin indicator={<LoaderIcon />} spinning={isDataLoading}>
            <InputsGrid $gridRows={Math.ceil(operatingZones?.length / 2 || 0)}>
              {!!operatingZones?.length &&
                operatingZones.map((zone, i) => (
                  <FlexContainer
                    key={zone.name + i}
                    $fullwidth
                    $column={isMobile}
                    $align="center"
                    $justify="space-between"
                    $gap={8}
                  >
                    <FlexContainer>
                      <Typography.Title>{`Zone ${i}`}</Typography.Title>
                    </FlexContainer>

                    <FlexContainer
                      $column={isMobile}
                      $width={isMobile ? "100%" : "272px"}
                      $gap={16}
                    >
                      <Controller
                        name={`zones.zone${i}.name`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            label="Name"
                            required
                            disabled={isUpdateLoading}
                            {...field}
                            fieldState={fieldState}
                          />
                        )}
                      />

                      <Controller
                        name={`zones.zone${i}.priority`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputNumber
                            label="Priority"
                            required
                            min={0}
                            precision={0}
                            {...field}
                            disabled={isUpdateLoading}
                            fieldState={fieldState}
                          />
                        )}
                      />
                    </FlexContainer>
                  </FlexContainer>
                ))}
            </InputsGrid>
          </Spin>
        </ContentBox>
      </FlexContainer>
    </FlexContainer>
  );
};
