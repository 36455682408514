// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useUpdateUserMutation } from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
// Layouts
// Components
import { Switch } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

interface CustomerStatusProps {
  id: string;
  isLoading: boolean;
  status: boolean | undefined;
}

export const CustomerStatus: FC<CustomerStatusProps> = ({
  id,
  isLoading,
  status,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const [update, { isLoading: isUpdateLoading }] = useUpdateUserMutation();

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (typeof status === "boolean") {
      setIsChecked(status);
    }
  }, [status]);

  const handleStatusChange = async (value: boolean) => {
    try {
      setIsChecked(value);

      await update({ id, isVip: value }).unwrap();
    } catch (error) {
      errorHandler({ error, openNotification });
      setIsChecked(!value);
    }
  };

  return (
    <FlexContainer
      $align="center"
      $fullwidth={isMobile}
      $margin={isMobile && "24px 0 0"}
      $justify={isMobile ? "flex-start" : "center"}
      $gap={10}
    >
      <Typography.Title>VIP Status</Typography.Title>
      <Switch
        loading={isLoading || isUpdateLoading}
        checked={isChecked}
        onChange={value => handleStatusChange(value)}
      />
    </FlexContainer>
  );
};
