import { dateTransform } from "helpers/dataHelpers";

export const columns = [
  {
    title: "Name",
    dataIndex: "firstName",
    key: "firstName",
  },

  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Date of usage",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
];
