import { ViewportContext, ViewportContextType } from "context/ViewportContext";
import { useContext } from "react";

export const useViewport = () => {
  const { width } = useContext<ViewportContextType>(ViewportContext);

  return {
    width,
    isMobile: width < 480,
    isLargeMobile: width < 680,
    isTablet: width < 1024 && width > 479,
    isMinTablet: width < 1024,
    isDesktop: width > 1023,
  };
};
