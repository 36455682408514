// Lib
import { FC, useEffect, useRef } from "react";
import { Overlay } from "ol";
// Hooks
import { useMapInteractionContext } from "hooks";
// Icons
import { LocationsIcon } from "icons";
// Styled
import { IconContainer, Title, Wrapper } from "./styled";

import { ACTIVE_POLYGON_COLOR, DEFAULT_POLYGON_COLOR } from "../config";

interface PointLayerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coords: any;
  isActive: boolean;
  name?: string;
}

export const PointLayer: FC<PointLayerProps> = ({ coords, isActive, name }) => {
  const { map } = useMapInteractionContext();

  const markerRef = useRef(null);
  const markerOverlay = new Overlay({
    positioning: "bottom-center",
    stopEvent: false,
    position: coords,
  });

  useEffect(() => {
    if (!map) return;

    if (markerRef.current) {
      markerOverlay.setElement(markerRef.current);
    }

    map.addOverlay(markerOverlay);

    return () => {
      map.removeOverlay(markerOverlay);
    };
  }, [map, isActive, coords]);

  if (!coords.length) return null;

  return (
    <Wrapper>
      <div ref={markerRef}>
        <Title>{name}</Title>

        <IconContainer>
          <LocationsIcon
            fill={isActive ? ACTIVE_POLYGON_COLOR : DEFAULT_POLYGON_COLOR}
            width="3rem"
            height="3rem"
          />
        </IconContainer>
      </div>
    </Wrapper>
  );
};
