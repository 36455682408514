import { styled } from "styled-components";
import { InputsGridContainer } from "styled/Box";

export const InputsGrid = styled(InputsGridContainer)<{
  $gridRows: number;
}>`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${({ $gridRows }) =>
    $gridRows && `repeat(${$gridRows}, minmax(10px, 1fr));`};
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 48px;
  grid-row-gap: 12px;

  @media (max-width: 1124px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;
