import { AllPaymentTypes, OrderPaymentType } from "./orders";

export enum CouponKindType {
  Order = "order",
  TopUp = "topUp",
}

export enum CouponType {
  GiftVoucher = "giftVoucher",
  DiscountCode = "discountCode",
}

export enum CouponTypeLabel {
  giftVoucher = "Gift Voucher",
  discountCode = "Discount Code",
}

export enum DiscountType {
  Percentage = "percentage",
  FiatCents = "fiatCents",
  FmcCents = "fmcCents",
}

export enum DiscountTypeLabel {
  percentage = "Percentage",
  fiatCents = "KWD",
  fmcCents = "FM Coins",
}

export enum CouponPaymentLabel {
  all = "All",
  fiatCents = "KWD",
  fmcCents = "FM Coins",
}

export type CouponAllowedUser = {
  id: string;
  email: string | null;
  phoneNumber: string | null;
  firstName?: string;
  lastName?: string;
  fullName?: string;
};

export type Coupon = {
  id: string;
  createdAt: string;
  updatedAt: string;
  discountOff: number;
  discountType: DiscountType;
  startDate: string;
  expiryDate: string;
  numberOfUses: number;
  couponCode: string;
  isActive: boolean;
  allowMultipleUsage: boolean;
  allowedUsers: CouponAllowedUser[];
  minimumOrderFiatCentsPrice: number;
  minimumOrderFmcCentsPrice: number;
  orderPaymentType: OrderPaymentType | AllPaymentTypes;
  couponType: CouponType;
  type: CouponKindType;
  couponUsed?: string;
  orderSid?: string;
  exchangeRate?: number;
  discountFiatCents?: number;
  numberOfUniqueUses?: number;
  couponUsageCount: number;
};

export type GetCouponsResponseDto = {
  items: Coupon[];
  totalCount: number;
};

export type CreateCouponRequestData = {
  discountOff: number;
  discountType: DiscountType;
  expiryDate: string;
  startDate: string;
  numberOfUses: number;
  allowedUsers: string[];
  allowMultipleUsage: boolean;
  isActive: boolean;
  minimumOrderFiatCentsPrice: number;
  minimumOrderFmcCentsPrice: number;
  orderPaymentType: OrderPaymentType | AllPaymentTypes;
  type: CouponKindType;
  numberOfCouponsToGenerate?: number;
  name?: string;
  exchangeRate?: number;
  discountFiatCents?: number;
  numberOfUniqueUses?: number;
};

export type CreateCouponRequestDto = CreateCouponRequestData;

export type UpdateCouponRequestDto = {
  id: string;
  data: CreateCouponRequestData;
};

export type CouponUsedByUser = {
  id: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
};

export type GetCouponsUsedByUsersListResponseDto = {
  items: CouponUsedByUser[];
  totalCount: number;
};
