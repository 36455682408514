// Lib
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
// Api
import { useGetUserQuery } from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification } from "hooks";
// Theme
import { theme } from "theme";
// Constants
import { ADMIN_ROUTES, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { CancelIcon, CheckCircleIcon } from "icons";
// Components
import { ArrowBackLink, Badge, SummaryField } from "components";
import { History } from "../components";
// Styled
import {
  ContentBox,
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";
import { SummaryItemsGrid } from "styled/Box";
import { Wrapper } from "./styled";

const getCustomerActivateValue = (value: boolean) => (
  <FlexContainer $align="center" $gap={4}>
    {value ? (
      <CheckCircleIcon fill={theme.color.text.tertiary} />
    ) : (
      <CancelIcon fill={theme.color.text.tertiary} />
    )}
    <span>{value ? "Active" : "Inactive"}</span>
  </FlexContainer>
);

export const UserDetails: FC = () => {
  const { id } = useParams();

  const { openNotification } = useNotification();

  const { data, isFetching, error } = useGetUserQuery(
    { id },
    { ...rtkQueryParams, skip: !id },
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  return (
    <FlexContainer $fullwidth $column $gap={24}>
      <PageHeadingContainer>
        <PageTitleContainer $column $gap={16}>
          <ArrowBackLink title="Back to Users" path={ADMIN_ROUTES.USERS.path} />

          <Typography.H1>User Detail</Typography.H1>
        </PageTitleContainer>
      </PageHeadingContainer>

      <Wrapper>
        <ContentBox $column $gap={20}>
          <Typography.H2>User Informations</Typography.H2>

          <FlexContainer $fullwidth $column $gap={16}>
            <SummaryField label="ID" value={id} />

            <SummaryItemsGrid>
              <SummaryField
                label="First name"
                isLoading={isFetching}
                value={data?.firstName}
              />
              <SummaryField
                label="Last name"
                isLoading={isFetching}
                value={data?.lastName}
              />
              <SummaryField
                label="Phone number"
                isLoading={isFetching}
                value={data?.phoneNumber}
              />
              <SummaryField
                label="E-mail"
                isLoading={isFetching}
                value={data?.email}
              />
              <SummaryField
                label="Role name"
                isLoading={isFetching}
                value={
                  !data?.role.name ? null : (
                    <Badge capitalize title={data?.role.name} />
                  )
                }
              />
              <SummaryField
                label="Customer activated"
                isLoading={isFetching}
                value={getCustomerActivateValue(data?.customerActivated)}
              />
            </SummaryItemsGrid>
          </FlexContainer>
        </ContentBox>

        <History isLoading={isFetching} userData={data} />
      </Wrapper>
    </FlexContainer>
  );
};
