// Lib
import { FC, useEffect } from "react";
import TileSource from "ol/source/Tile";
import TileLayer from "ol/layer/Tile";
// Hooks
import { useMapInteractionContext } from "hooks";

interface TileLayerWrapperProps {
  source: TileSource;
  zIndex?: number;
}

export const TileLayerWrapper: FC<TileLayerWrapperProps> = ({
  source,
  zIndex = 0,
}) => {
  const { map } = useMapInteractionContext();

  useEffect(() => {
    if (!map) return;

    const tileLayer = new TileLayer({
      source,
      zIndex,
    });

    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);

    return () => {
      map?.removeLayer(tileLayer);
    };
  }, [map, source, zIndex]);

  return null;
};
