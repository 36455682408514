// Lib
import { ReactNode } from "react";
// Types
import {
  CustomerMenuAddonModifier,
  GetCustomerMenuResponseDto,
} from "types/customers";
import {
  CompleteProductData,
  MenuStatuses,
  SubtotalProductData,
  SummaryData,
} from "./types";
// Helpers
import { fixedDigitsValue } from "helpers/dataHelpers";
// Styled
import { Typography } from "styled/Typography";

export const handleMutateOrderItems = (
  prev: CompleteProductData[],
  data: CompleteProductData,
): CompleteProductData[] => {
  const index = prev.findIndex(el => el.uniqId === data.uniqId);

  if (index === undefined) {
    return prev;
  }

  const newArray = [...prev];

  newArray[index] = data;

  return newArray;
};

const getAddonsTotalPrice = (addons: CustomerMenuAddonModifier[]) => {
  return addons.reduce(
    (acc, el) => {
      const quantity = el?.quantity || 1;

      const coins = el?.fmcCentsPrice ? el.fmcCentsPrice * quantity * 1000 : 0;

      const fiat = el?.fiatCentsPrice ? el.fiatCentsPrice * quantity * 1000 : 0;

      return {
        coins: acc.coins + coins,
        fiat: acc.fiat + fiat,
      };
    },
    { coins: 0, fiat: 0 },
  );
};

export const getSubtotal = ({
  product,
  addonsModifiers,
  quantity,
}: CompleteProductData): SubtotalProductData => {
  const productPrice = {
    coins: product?.fmcCentsPrice ? product.fmcCentsPrice * 1000 : 0,
    fiat: product?.fiatCentsPrice ? product.fiatCentsPrice * 1000 : 0,
  };

  const addonsPrice = getAddonsTotalPrice(addonsModifiers.addon);

  const subTotalCoins = fixedDigitsValue(
    ((productPrice.coins + addonsPrice.coins) / 1000) * quantity,
    1,
  );

  const subTotalFiat = fixedDigitsValue(
    ((productPrice.fiat + addonsPrice.fiat) / 1000) * quantity,
    3,
  );

  return { subTotalCoins, subTotalFiat };
};

export const renderSubtotal = (data: CompleteProductData): ReactNode => {
  const { subTotalFiat } = getSubtotal(data);
  return <Typography.Title>{subTotalFiat} KD</Typography.Title>;
};

export const calculateSummary = (
  orderItems: CompleteProductData[],
  deliveryFee: {
    fiatCentsPrice: number;
    totalFmcCentsPrice: number;
  },
): SummaryData => {
  const orderItemsData = orderItems.reduce(
    (acc, el) => {
      const productPrice = {
        coins: el.product?.fmcCentsPrice ? el.product.fmcCentsPrice * 1000 : 0,
        fiat: el.product?.fiatCentsPrice ? el.product.fiatCentsPrice * 1000 : 0,
      };

      const addonsPrice = getAddonsTotalPrice(el.addonsModifiers.addon);

      const subTotalCoins =
        (productPrice.coins + addonsPrice.coins) * el.quantity;
      const subTotalFiat = (productPrice.fiat + addonsPrice.fiat) * el.quantity;

      return {
        subTotal: {
          coins: acc.subTotal.coins + subTotalCoins,
          fiat: acc.subTotal.fiat + subTotalFiat,
        },
        quantity: acc.quantity + el.quantity,
      };
    },
    {
      subTotal: { coins: 0, fiat: 0 },
      quantity: 0,
    },
  );

  const couponsDiscount = {};

  const discountedTotal = {
    coins: orderItemsData.subTotal.coins,
    fiat: orderItemsData.subTotal.fiat,
  };

  const discountedTotalCoins =
    discountedTotal.coins > 0 ? discountedTotal.coins : 0;

  const discountedTotalFiat =
    discountedTotal.fiat > 0 ? discountedTotal.fiat : 0;

  return {
    quantity: orderItemsData.quantity || 0,
    subTotal: {
      coins: discountedTotalCoins / 1000,
      fiat: discountedTotalFiat / 1000,
    },
    deliveryFee: {
      coins: deliveryFee.totalFmcCentsPrice,
      fiat: deliveryFee.fiatCentsPrice,
    },
    total: {
      coins:
        (discountedTotalCoins + deliveryFee.totalFmcCentsPrice * 1000) / 1000,
      fiat: (discountedTotalFiat + deliveryFee.fiatCentsPrice * 1000) / 1000,
    },
    couponsDiscount,
  };
};

export const getMenuStatuses = (
  menu: GetCustomerMenuResponseDto,
): MenuStatuses => {
  if (!menu) {
    return {
      isBusy: false,
      isClosed: true,
      isMenuCashPaymentAvailable: false,
    };
  }

  const isBusy = menu?.locationOperatingStatus === "paused";

  const isClosed = menu.isAvailable === false;

  const isMenuCashPaymentAvailable =
    (menu?.isCashPaymentAvailable && menu?.isCashPayment) || false;

  return {
    isBusy,
    isClosed,
    isMenuCashPaymentAvailable,
  };
};
