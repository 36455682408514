// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetUsersQuery } from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { ETable } from "types/tableFilters";
import { TabKey } from "./types";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Pagination, Tabs } from "components";
import { Locations, Users } from "./components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";

import { getUsersSortBy } from "./components/Users/config";

export const LocationProviders: FC = () => {
  const fullURL = window.location.href;

  // Temporary hide tab on production
  const isProduction = fullURL.includes("admin2.api.forevermore.pro");

  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();

  const [tab, setTab] = useState(TabKey.users);

  const {
    search,
    sortingOrder,
    sortBy,
    page,
    limit,
    debouncedSearch,
    debouncedFiltersQuery,
    setSearch,
    setPage,
    setLimit,
    handleSort,
  } = useTable({ name: ETable.Providers });

  const {
    data: usersData,
    isFetching: isUsersDataFetching,
    error: usersError,
  } = useGetUsersQuery(
    {
      query: {
        ...(debouncedFiltersQuery && debouncedFiltersQuery),
        page,
        limit,
        sortBy: getUsersSortBy(sortBy),
        sortingOrder,
        search: debouncedSearch,
      },
    },
    {
      skip: tab !== TabKey.users,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (usersError) {
      errorHandler({ error: usersError, openNotification });
    }
  }, [usersError]);

  const tabItems = [
    {
      key: TabKey.users,
      label: "Users",
      children: (
        <Users
          isFetching={isUsersDataFetching}
          usersData={usersData}
          search={search}
          setSearch={setSearch}
          handleSort={handleSort}
        />
      ),
    },
    !isProduction && {
      key: TabKey.location,
      label: "Locations",
      children: <Locations />,
    },
  ];

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <FlexContainer
          $padding="0 0 24px"
          $align="center"
          $justify="space-between"
        >
          <Typography.H1>Providers</Typography.H1>
        </FlexContainer>

        <Tabs
          activeKey={tab}
          onChange={(value: TabKey) => setTab(value)}
          items={tabItems}
        />
      </FlexContainer>

      {tab === TabKey.users && (
        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          showSizeChanger={!isDesktop}
          totalItems={usersData?.totalCount}
        />
      )}
    </PageWrapper>
  );
};
