import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";
import {
  UserStatus,
  UserStatusLabel,
  UsersFilterNames,
  UsersFilterTypes,
} from "types/users";
import { RoleResponseDto } from "types/roles";

import { TABLE_DATE } from "consts";
import { dateTransform } from "helpers/dataHelpers";

import { Avatar, Badge } from "components";
import {
  CallBlackIcon,
  CancelIcon,
  CheckCircleIcon,
  TextFieldsIcon,
} from "icons";
import { FlexContainer } from "styled/Box";

import { theme } from "theme";

export const getUsersSortBy = (sortBy: string) => {
  switch (sortBy) {
    case "fullName":
      return "firstName";
    case "role":
      return "roleName";

    default:
      return sortBy;
  }
};

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Full name",
    dataIndex: "fullName",
    key: "fullName",
    sorter: true,
    render: (name: string | null) =>
      name ? (
        <FlexContainer $align="center" $gap={8}>
          <Avatar name={name} /> {name}
        </FlexContainer>
      ) : null,
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    sorter: true,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },

  {
    title: "Role name",
    dataIndex: "role",
    key: "role",
    render: (role: RoleResponseDto) => role && <Badge title={role.name} />,
  },

  {
    title: "Status",
    dataIndex: "customerActivated",
    key: "customerActivated",
    align: "center" as const,
    sorter: true,
    render: (status: boolean) => (
      <FlexContainer $justify="center">
        {status ? <CheckCircleIcon /> : <CancelIcon fill={theme.color.error} />}
      </FlexContainer>
    ),
  },
];

export const usersStatusDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: UserStatus.ACTIVATED,
    label: UserStatusLabel[UserStatus.ACTIVATED],
  },
  {
    type: UserStatus.DEACTIVATED,
    label: UserStatusLabel[UserStatus.DEACTIVATED],
  },
];

export const filtersList: FilterOption[] = [
  {
    key: UsersFilterTypes.email,
    icon: <TextFieldsIcon />,
    label: UsersFilterNames[UsersFilterTypes.email],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: UsersFilterTypes.roleName,
    icon: <TextFieldsIcon />,
    label: UsersFilterNames[UsersFilterTypes.roleName],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: UsersFilterTypes.phoneNumber,
    icon: <CallBlackIcon />,
    label: UsersFilterNames[UsersFilterTypes.phoneNumber],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: UsersFilterTypes.customerActivated,
    label: UsersFilterNames[UsersFilterTypes.customerActivated],
    field: FilterField.SELECT,
    options: usersStatusDropdownFields,
  },
];
