import { Badge } from "components";
import { TABLE_DATE } from "consts";
import { dateTransform } from "helpers/dataHelpers";

import {
  ReviewFilterNames,
  ReviewFilterTypes,
  ReviewStatus,
  ReviewStatusLabels,
} from "types/reviews";
import { RateStars, ReviewText } from "./styled";
import { theme } from "theme";
import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";

export const reviewBadgeColors = {
  [ReviewStatus.REJECTED]: theme.color.pink,
  [ReviewStatus.APPROVED]: theme.color.active,
  [ReviewStatus.REVIEW]: theme.color.yellowLight,
};

export const columns = [
  {
    title: "Rate",
    dataIndex: "rating",
    key: "rating",
    sorter: true,
    render: (rate: number) => <RateStars disabled value={rate} />,
  },
  {
    title: "Review",
    dataIndex: "comment",
    key: "comment",
    render: (text: string) => <ReviewText>{text}</ReviewText>,
  },
  {
    title: "Customer",
    dataIndex: "firstName",
    key: "firstName",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,

    render: (status: ReviewStatus) => (
      <Badge
        bgColor={reviewBadgeColors[status]}
        title={ReviewStatusLabels[status]}
      />
    ),
  },
  {
    title: "User initiated",
    dataIndex: "adminFirstName",
    key: "adminFirstName",
    sorter: true,
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];

const reviewStatusDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: ReviewStatus.REVIEW,
    label: ReviewStatusLabels[ReviewStatus.REVIEW],
  },
  {
    type: ReviewStatus.APPROVED,
    label: ReviewStatusLabels[ReviewStatus.APPROVED],
  },
  {
    type: ReviewStatus.REJECTED,
    label: ReviewStatusLabels[ReviewStatus.REJECTED],
  },
];

export const filtersList: FilterOption[] = [
  {
    key: ReviewFilterTypes.createdAfter,
    label: ReviewFilterNames[ReviewFilterTypes.createdAfter],
    field: FilterField.DATE,
  },
  {
    key: ReviewFilterTypes.updatedAfter,
    label: ReviewFilterNames[ReviewFilterTypes.updatedAfter],
    field: FilterField.DATE,
  },
  {
    key: ReviewFilterTypes.status,
    label: ReviewFilterNames[ReviewFilterTypes.status],
    field: FilterField.SELECT,
    options: reviewStatusDropdownFields,
  },
];
