// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
// Api
import {
  useCreateCouponMutation,
  useGetCouponQuery,
  useUpdateCouponMutation,
} from "rtkQuery/query/couponsAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
// Types
import { CouponAllowedUser, CouponType, DiscountType } from "types/coupons";
import { AllPaymentTypes, OrderPaymentType } from "types/orders";
import {
  OrderCouponForm,
  PurchaseType,
  TabKey,
  UsersType,
  UseType,
} from "../../../types";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, rtkQueryParams } from "consts";
// Helpers
import {
  transformToFormData,
  transformOrderCouponsFormToRequestData,
} from "../../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon } from "icons";
// Components
import { Skeleton } from "antd";
import { ArrowBackLink, Tabs } from "components";
import { TabLabel, UsersTab } from "../../../components";
import { MainTab } from "../components";
// Styled
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
  PageWrapper,
} from "styled/Box";

import { resolver } from "./validation";

export const OrderCouponEdit: FC = () => {
  const navigate = useNavigate();
  const { canCouponsUpdate } = usePermissions();
  const { openNotification } = useNotification();
  const { id } = useParams();

  const isEdit = !!id;

  const [create] = useCreateCouponMutation();
  const [update] = useUpdateCouponMutation();

  const {
    data,
    isFetching: isCouponGetLoading,
    error,
  } = useGetCouponQuery({ id }, { ...rtkQueryParams, skip: !id });

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const [isEditMode, setEditMode] = useState(() => (!isEdit ? true : false));
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.Main);
  const [useType, setUseType] = useState<UseType | null>(() =>
    isEdit ? null : UseType.OneTime,
  );
  const [usersType, setUsersType] = useState<UsersType | null>(() =>
    isEdit ? null : UsersType.All,
  );
  const [purchaseType, setPurchaseType] = useState<PurchaseType | null>(() =>
    isEdit ? null : PurchaseType.WithoutPurchase,
  );
  const [allowedUsers, setAllowedUser] = useState<CouponAllowedUser[]>([]);
  const [isAllowedUsersError, setIsAllowedUsersError] = useState(false);
  const [isCouponNameDisabled, setIsCouponNameDisabled] = useState(false);

  const { handleSubmit, control, reset, setValue, watch } =
    useForm<OrderCouponForm>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
      defaultValues: {
        allowMultipleUsage: false,
        isActive: false,
        numberOfUses: 1,
        minimumOrderFiatCentsPrice: 0,
        minimumOrderFmcCentsPrice: 0,
        numberOfCouponsToGenerate: 1,
        name: "",
      },
    });

  const discountType = watch("discountType");
  const orderPaymentType = watch("orderPaymentType");
  const couponType = watch("couponType");
  const couponName = watch("name");
  const numberOfCouponsToGenerate = watch("numberOfCouponsToGenerate");

  useEffect(() => {
    if (numberOfCouponsToGenerate > 1) {
      if (couponName) {
        setValue("name", "");
        openNotification({
          type: "warning",
          message: "The Сoupon Сodes will be randomly generated",
        });
      }

      setIsCouponNameDisabled(true);
    } else {
      setIsCouponNameDisabled(false);
    }
  }, [numberOfCouponsToGenerate]);

  useEffect(() => {
    if (couponType === CouponType.GiftVoucher) {
      setValue("minimumOrderFiatCentsPrice", 0);
      setValue("minimumOrderFmcCentsPrice", 0);

      if (purchaseType === PurchaseType.MinimumPurchase) {
        openNotification({
          message:
            "Pay attention! Minimum Purchase can not be used for the Gift Voucher",
          type: "warning",
        });

        setPurchaseType(PurchaseType.WithoutPurchase);
      }
    }
  }, [couponType]);

  const handleResetForm = () => {
    if (!data) {
      return;
    }

    if (data.numberOfUses > 1) {
      setUseType(UseType.Multiple);
    } else {
      setUseType(UseType.OneTime);
    }

    if (data?.allowedUsers?.length) {
      setAllowedUser(data?.allowedUsers || []);
      setUsersType(UsersType.List);
    } else {
      setAllowedUser([]);
      setUsersType(UsersType.All);
    }

    if (data?.minimumOrderFiatCentsPrice || data?.minimumOrderFmcCentsPrice) {
      setPurchaseType(PurchaseType.MinimumPurchase);
    } else {
      setPurchaseType(PurchaseType.WithoutPurchase);
    }

    reset(transformToFormData(data));
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    handleResetForm();
  }, [data]);

  const handleAddCustomers = (customers: CouponAllowedUser[]) => {
    if (isAllowedUsersError) {
      setIsAllowedUsersError(false);
    }
    setAllowedUser(customers);
  };

  const handleSetUseType = (value: UseType) => {
    if (value === UseType.OneTime) {
      setValue("numberOfUses", 1);
      setValue("allowMultipleUsage", false);
    } else {
      setValue("numberOfUses", 2);
      setValue("allowMultipleUsage", true);
    }
    setUseType(value);
  };

  const handleSetUsersType = (value: UsersType) => {
    setUsersType(value);

    if (value === UsersType.All) {
      setIsAllowedUsersError(false);
    }
  };

  const handleSetPurchaseType = (value: PurchaseType) => {
    if (value === PurchaseType.WithoutPurchase) {
      setValue("minimumOrderFiatCentsPrice", 0);
      setValue("minimumOrderFmcCentsPrice", 0);
    }
    setPurchaseType(value);
  };

  const handleSetDiscountType = (value: DiscountType) => {
    if (
      couponType === CouponType.DiscountCode &&
      purchaseType === PurchaseType.MinimumPurchase
    ) {
      openNotification({
        message: "Pay attention! The minimum purchase has changed",
        type: "warning",
      });
    }

    switch (value) {
      case DiscountType.FiatCents:
        setValue("discountType", value);
        setValue("orderPaymentType", OrderPaymentType.FiatCents);
        setValue("minimumOrderFmcCentsPrice", 0);
        return;
      case DiscountType.FmcCents:
        setValue("discountType", value);
        setValue("orderPaymentType", OrderPaymentType.FmcCents);
        setValue("minimumOrderFiatCentsPrice", 0);
        return;
      case DiscountType.Percentage:
        setValue("discountType", value);
        setValue("orderPaymentType", AllPaymentTypes.All);
        return;

      default:
        return;
    }
  };
  const handleSetPaymentType = (value: OrderPaymentType | AllPaymentTypes) => {
    switch (value) {
      case OrderPaymentType.FiatCents:
        setValue("orderPaymentType", value);
        setValue("minimumOrderFmcCentsPrice", 0);
        return;
      case OrderPaymentType.FmcCents:
        setValue("orderPaymentType", value);
        setValue("minimumOrderFiatCentsPrice", 0);
        return;
      case AllPaymentTypes.All:
        setValue("orderPaymentType", value);
        return;

      default:
        return;
    }
  };

  const name = isEdit ? data?.couponCode : "New Coupon";

  const isLoading = isCouponGetLoading || isEditLoading;

  const items = [
    {
      key: TabKey.Main,
      label: <TabLabel label={TabKey.Main} />,
      children: (
        <MainTab
          isEdit={isEdit}
          isLoading={isLoading}
          isEditMode={isEditMode}
          isCouponNameDisabled={isCouponNameDisabled}
          control={control}
          useType={useType}
          usersType={usersType}
          purchaseType={purchaseType}
          discountType={discountType}
          couponType={couponType}
          orderPaymentType={orderPaymentType}
          handleSetUseType={handleSetUseType}
          handleSetUsersType={handleSetUsersType}
          handleSetDiscountType={handleSetDiscountType}
          handleSetPurchaseType={handleSetPurchaseType}
          handleSetPaymentType={handleSetPaymentType}
        />
      ),
    },
    usersType === UsersType.List && {
      key: TabKey.Users,
      label: <TabLabel label={TabKey.Users} isError={isAllowedUsersError} />,
      children: (
        <UsersTab
          isEditMode={isEditMode}
          isLoading={isLoading}
          allowedUsers={allowedUsers}
          handleAddCustomers={handleAddCustomers}
        />
      ),
    },
  ];

  const onSubmit: SubmitHandler<OrderCouponForm> = async data => {
    if (usersType === UsersType.List) {
      if (allowedUsers.length === 0) {
        setIsAllowedUsersError(true);
        return;
      }
    }

    try {
      setIsEditLoading(true);
      if (isEdit) {
        await update({
          id,
          data: transformOrderCouponsFormToRequestData(
            data,
            usersType,
            useType,
            allowedUsers,
          ),
        }).unwrap();

        openNotification({ message: NOTIFICATIONS.COUPON_UPDATED });

        setEditMode(false);
      } else {
        const response = await create(
          transformOrderCouponsFormToRequestData(
            data,
            usersType,
            useType,
            allowedUsers,
          ),
        ).unwrap();

        openNotification({ message: NOTIFICATIONS.COUPON_CREATED });

        setEditMode(false);

        if (response?.length > 1) {
          navigate(`${ADMIN_ROUTES.ORDER_COUPONS.path}`);
        } else {
          navigate(`${ADMIN_ROUTES.ORDER_COUPONS.path}/${response[0].id}`);
        }
      }
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsEditLoading(false);
    }
  };

  const onCancel = () => {
    setEditMode(false);
    handleResetForm();
  };

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $gap={24}
        $grow={1}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PageHeadingContainer>
          <PageTitleContainer $column>
            <ArrowBackLink
              title="Back to Purchase Coupons"
              path={ADMIN_ROUTES.ORDER_COUPONS.path}
            />

            <FlexContainer
              $gap={12}
              $align="center"
              $justify="center"
              $margin="20px 0 0 0"
            >
              {isCouponGetLoading ? (
                <>
                  <Skeleton.Input active size={"default"} />
                </>
              ) : name ? (
                <>
                  <Typography.H1>{name}</Typography.H1>
                </>
              ) : null}
            </FlexContainer>
          </PageTitleContainer>

          {canCouponsUpdate && (
            <FlexContainer $gap={8}>
              {!isEditMode ? (
                <Button.Heading
                  icon={<PencilIcon />}
                  onClick={e => {
                    e.preventDefault();
                    setEditMode(true);
                  }}
                >
                  Edit
                </Button.Heading>
              ) : (
                <>
                  <Button.Heading
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Save
                  </Button.Heading>
                  {isEdit && (
                    <Button.Heading onClick={onCancel}>Cancel</Button.Heading>
                  )}
                </>
              )}
            </FlexContainer>
          )}
        </PageHeadingContainer>

        <Tabs
          activeKey={activeTab}
          items={items}
          onChange={(activeKey: TabKey) => setActiveTab(activeKey)}
        />
      </FlexContainer>
    </PageWrapper>
  );
};
