import styled from "styled-components";

import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

export const Wrapper = styled(FlexContainer)`
  position: absolute;
  left: 50%;
  top: 0.5rem;
  transform: translateX(-50%);
  z-index: 100;
`;

export const SwitchButton = styled(Button.Base)<{
  $isFirst: boolean;
  $isLast: boolean;
}>`
  border-radius: 0;

  ${({ $isFirst }) =>
    $isFirst && `border-start-start-radius: 8px; border-end-start-radius: 8px`}

  ${({ $isLast }) =>
    $isLast && `border-end-end-radius: 8px; border-start-end-radius: 8px`}
`;
