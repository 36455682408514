// Lib
import { FC } from "react";
import { Skeleton } from "antd";
// Types
import { CustomerProfileResponseDto } from "types/customers";
// Theme
import { theme } from "theme";
// Helpers
import { dateTransform } from "helpers/dataHelpers";
// Icons
import {
  AccountBalanceWalletIcon,
  AddCircleIcon,
  EditIcon,
  ShoppingCartIcon,
} from "icons";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";
import { HistoryWrapper } from "../styled";
import {
  Description,
  Devider,
  HistoryItem,
  HistoryItemsWrapper,
  IconWrapper,
} from "./styled";

export enum CustomerHistory {
  Created = "created",
  Updated = "updated",
  OrderMade = "orderMade",
  TopUpBalanceMade = "topUpBalanceMade",
}

const icon = {
  [CustomerHistory.Created]: AddCircleIcon,
  [CustomerHistory.Updated]: EditIcon,
  [CustomerHistory.OrderMade]: ShoppingCartIcon,
  [CustomerHistory.TopUpBalanceMade]: AccountBalanceWalletIcon,
};

const title = {
  [CustomerHistory.Created]: "Customer data created",
  [CustomerHistory.Updated]: "Customer update data",
  [CustomerHistory.OrderMade]: "Customer make an order",
  [CustomerHistory.TopUpBalanceMade]: "Customer make an top up balance",
};

interface HistoryProps {
  isCustomerDataLoading: boolean;
  customerData: CustomerProfileResponseDto;
}

export const History: FC<HistoryProps> = ({
  isCustomerDataLoading,
  customerData,
}) => {
  const data = [
    {
      Icon: icon[CustomerHistory.Created],
      title: title[CustomerHistory.Created],
      date: dateTransform({ date: customerData?.createdAt }),
    },
    {
      Icon: icon[CustomerHistory.Updated],
      title: title[CustomerHistory.Updated],
      date: dateTransform({ date: customerData?.updatedAt }),
    },
  ];
  return (
    <HistoryWrapper>
      <FlexContainer $fullwidth $column $gap={20}>
        <Typography.H2>History</Typography.H2>

        <HistoryItemsWrapper>
          {isCustomerDataLoading ? (
            <Skeleton active />
          ) : data?.length ? (
            data.map(({ Icon, title, date }, i) => (
              <HistoryItem key={i}>
                <IconWrapper>
                  {i !== 0 && <Devider />}
                  <Icon fill={theme.color.text.tertiary} />
                </IconWrapper>

                <div>
                  <Typography.Title>{title}</Typography.Title>
                  <Description>{date}</Description>
                </div>
              </HistoryItem>
            ))
          ) : null}
        </HistoryItemsWrapper>
      </FlexContainer>
    </HistoryWrapper>
  );
};
