import { styled } from "styled-components";

import {
  ACTIVE_POLYGON_COLOR,
  DEFAULT_POLYGON_COLOR,
  REMOVED_POLYGON_COLOR,
} from "Layers/config";

export const RemoveButton = styled.button<{
  $isToRemove: boolean;
  $isEditable: boolean;
  $isMounted: boolean;
}>`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  background: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  cursor: pointer;
  opacity: ${({ $isMounted }) => ($isMounted ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  color: ${({ $isToRemove, $isEditable }) =>
    $isToRemove
      ? REMOVED_POLYGON_COLOR
      : $isEditable
      ? ACTIVE_POLYGON_COLOR
      : DEFAULT_POLYGON_COLOR};

  &:disabled {
    cursor: auto;
  }
`;
