import {
  dateTransform,
  fixedDigitsValue,
  getCouponBadgeStatus,
} from "helpers/dataHelpers";

import { CancelIcon, CheckCircleIcon, CurrencyIcon } from "icons";
import {
  Coupon,
  CouponAllowedUser,
  DiscountType,
  DiscountTypeLabel,
} from "types/coupons";
import { theme } from "theme";
import { Badge } from "components";

import { FlexContainer } from "styled/Box";

const getCouponDiscountLabel = (value: number, type: DiscountType) => {
  switch (type) {
    case DiscountType.Percentage:
      return `${value}%`;
    case DiscountType.FiatCents:
      return `${fixedDigitsValue(value, 3)} KWD`;
    case DiscountType.FmcCents:
      return (
        <FlexContainer $align="center" $justify="flex-end" $gap={8}>
          {fixedDigitsValue(value, 3)} <CurrencyIcon />
        </FlexContainer>
      );

    default:
      return value;
  }
};

export const columns = [
  {
    title: "Coupon code",
    dataIndex: "couponCode",
    key: "couponCode",
  },
  {
    title: "Bonus Type",
    dataIndex: "discountType",
    key: "discountType",
    render: (value: DiscountType) => DiscountTypeLabel[value] || value,
  },
  {
    title: "Bonus (%)",
    dataIndex: "discountOff",
    key: "discountOff",
    align: "right" as const,
    render: (value: number, record: Coupon) =>
      record.discountType === DiscountType.Percentage
        ? getCouponDiscountLabel(value, record.discountType)
        : null,
  },
  {
    title: "Bonus (FM Coins)",
    dataIndex: "discountOff",
    key: "discountOff",
    align: "right" as const,
    render: (value: number, record: Coupon) =>
      record.discountType === DiscountType.FmcCents
        ? getCouponDiscountLabel(value, record.discountType)
        : null,
  },
  {
    title: "KWD entered by admin",
    dataIndex: "discountFiatCents",
    key: "discountFiatCents",
    align: "right" as const,
    width: 300,
    render: (value: number | undefined) =>
      value ? `${fixedDigitsValue(value, 3)} KWD` : null,
  },
  {
    title: "Number of Unique Users",
    dataIndex: "numberOfUniqueUses",
    key: "numberOfUniqueUses",
    align: "center" as const,
    width: 300,
  },
  {
    title: "Number Of Uses (per user)",
    dataIndex: "numberOfUses",
    key: "numberOfUses",
    align: "center" as const,
    width: 300,
  },
  {
    title: "Count Of Uses",
    dataIndex: "couponUsageCount",
    key: "couponUsageCount",
    align: "center" as const,
  },
  {
    title: "Allowed users",
    dataIndex: "allowedUsers",
    key: "allowedUsers",
    align: "center" as const,
    render: (users: CouponAllowedUser[]) => users?.length || "All",
  },
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    align: "center" as const,
    render: (isActive: boolean) => (
      <FlexContainer $justify="center">
        {isActive ? (
          <CheckCircleIcon />
        ) : (
          <CancelIcon fill={theme.color.error} />
        )}
      </FlexContainer>
    ),
  },
  {
    title: "Multiple usage",
    dataIndex: "allowMultipleUsage",
    key: "allowMultipleUsage",
    align: "center" as const,
    render: (isActive: boolean) => (
      <FlexContainer $justify="center">
        {isActive ? (
          <CheckCircleIcon />
        ) : (
          <CancelIcon fill={theme.color.error} />
        )}
      </FlexContainer>
    ),
  },
  {
    title: "Status",
    dataIndex: "startDate",
    key: "startDate",
    align: "center" as const,
    render: (startDate: string, record: Coupon) => {
      const { title, bgColor, textColor } = getCouponBadgeStatus(
        startDate,
        record.expiryDate,
      );
      return <Badge title={title} bgColor={bgColor} textColor={textColor} />;
    },
  },

  {
    title: "Start date",
    dataIndex: "startDate",
    key: "startDate",
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
  {
    title: "Expire date",
    dataIndex: "expiryDate",
    key: "expiryDate",
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
];
