import { Settings } from "./types";
import { DeliveryProviders } from "types/deliveryProviders";

export const DEFAULT_SETTINGS: Settings = {
  [DeliveryProviders.loop]: {
    locationId: "",
    location: "",
    isActive: true,
  },
  [DeliveryProviders.deliverect]: {
    locationId: "",
    location: "",
    isActive: false,
  },
};

export const getDefaultSettings = (): Settings => {
  return {
    [DeliveryProviders.loop]: {
      locationId: "",
      location: "",
      isActive: true,
    },
    [DeliveryProviders.deliverect]: {
      locationId: "",
      location: "",
      isActive: false,
    },
  };
};
