import { TABLE_DATE } from "consts";
import { dateTransform, fixedDigitsValue } from "helpers/dataHelpers";
import { CurrencyIcon } from "icons";
import { FlexContainer } from "styled/Box";
import {
  Coupon,
  CouponType,
  CouponTypeLabel,
  DiscountType,
  DiscountTypeLabel,
} from "types/coupons";

const getCouponDiscountLabel = (value: number, type: DiscountType) => {
  switch (type) {
    case DiscountType.Percentage:
      return `${value}%`;
    case DiscountType.FiatCents:
      return `${fixedDigitsValue(value, 3)} KWD`;
    case DiscountType.FmcCents:
      return (
        <FlexContainer $align="center" $justify="flex-end" $gap={8}>
          {fixedDigitsValue(value, 3)} <CurrencyIcon />
        </FlexContainer>
      );

    default:
      return value;
  }
};

export const columns = [
  {
    title: "Coupon code",
    dataIndex: "couponCode",
    key: "couponCode",
  },

  {
    title: "Discount",
    dataIndex: "discountOff",
    key: "discountOff",
    render: (value: number, record: Coupon) =>
      getCouponDiscountLabel(value, record.discountType),
  },
  {
    title: "Discount type",
    dataIndex: "discountType",
    key: "discountType",
    render: (value: DiscountType) => DiscountTypeLabel[value] || value,
  },
  {
    title: "Coupon type",
    dataIndex: "couponType",
    key: "couponType",
    render: (value: CouponType) => CouponTypeLabel[value] || value,
  },
  {
    title: "Order number",
    dataIndex: "orderSid",
    key: "orderSid",
  },
  {
    title: "Coupon used",
    dataIndex: "couponUsed",
    key: "createdAt",
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];
