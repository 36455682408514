import styled from "styled-components";
import { TABLE_DATE } from "consts";
import { dateTransform, fixedDigitsValue } from "helpers/dataHelpers";
import { CurrencyIcon } from "icons";
import { Tooltip } from "components";
import { FlexContainer } from "styled/Box";

const StyledName = styled.div`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  cursor: pointer;
`;

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Price",
    dataIndex: "totalFmcCentsPrice",
    key: "totalFmcCentsPrice",
    align: "right" as const,
    width: 160,
    render: (value: string) => `${fixedDigitsValue(value, 3)} KWD`,
  },
  {
    title: "FM Coin",
    dataIndex: "totalFiatCentsPrice",
    key: "totalFiatCentsPrice",
    align: "right" as const,
    width: 160,
    render: (value: string) => (
      <FlexContainer $align="center" $justify="flex-end" $gap={8}>
        {fixedDigitsValue(value, 3)} <CurrencyIcon />
      </FlexContainer>
    ),
  },
  {
    title: "Recommended items",
    dataIndex: "recommendedItems",
    key: "recommendedItems",
    width: 180,
    align: "right" as const,
    render: (items: []) => items?.length,
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 180,
    render: (date: string) =>
      dateTransform({
        date,
        format: TABLE_DATE,
      }),
  },
];

export const expandedRowColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name: string) => (
      <Tooltip title={name} trigger={"click"}>
        <StyledName>{name}</StyledName>
      </Tooltip>
    ),
  },
  ...columns.slice(1),
];
