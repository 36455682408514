import { styled } from "styled-components";
import { Typography } from "styled/Typography";
import { theme } from "theme";

export const StatusesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  padding: 24px;
`;

export const IdsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
`;

export const Description = styled(Typography.Description)`
  color: ${theme.color.text.tertiary};
`;
