// Lib
import { FC, useEffect } from "react";
//TODO: remove comment if BE is ready
// import { useNavigate } from "react-router-dom";
// Api
import {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
} from "rtkQuery/query/transactionsAPI";
import { useGetPurposeOptionsQuery } from "rtkQuery/query/authSettingsAPI";
// Hooks
import { useExport, useNotification, useTable, useViewport } from "hooks";
// Types
import { TablePaginationConfig } from "antd";
import { FilterValue } from "antd/es/table/interface";
import { AdditionalFilter, ETable } from "types/tableFilters";
import { TransactionFilterTypes } from "types/transactions";
// Constants
import { rtkQueryParams } from "consts";
// Helpers
import { getCsvMappedData } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon, RightBurgerMenuIcon } from "icons";
// Components
import { DropDown, Export, Pagination, Table } from "components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";

import { columns, filtersList } from "./config";
import { Button } from "styled/Buttons";

export const Transactions: FC = () => {
  //TODO: remove comment if BE is ready
  // const navigate = useNavigate();

  const { isDesktop } = useViewport();

  const { openNotification } = useNotification();

  const { downloadCSV } = useExport();

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    //TODO: remove comment if BE is ready
    // search,
    selectedFilters,
    debouncedSearch,
    debouncedFiltersQuery,
    setPage,
    setLimit,
    //TODO: remove comment if BE is ready
    // setSearch,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.Transactions });

  const {
    data: transactionsData,
    isFetching: isLoading,
    error,
  } = useGetTransactionsQuery(
    {
      query: {
        ...(debouncedFiltersQuery && debouncedFiltersQuery),
        page,
        limit,
        sortBy,
        sortingOrder,
        search: debouncedSearch,
      },
    },
    rtkQueryParams,
  );

  const { data: optionsData, error: optionsError } = useGetPurposeOptionsQuery(
    null,
    rtkQueryParams,
  );

  useEffect(() => {
    if (optionsError) {
      errorHandler({ error: optionsError, openNotification });
    }
  }, [optionsError]);

  const [getExportTransactionsData, { isFetching: isExportLoading }] =
    useLazyGetTransactionsQuery();

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      const data = await getExportTransactionsData({
        query: {
          ...(debouncedFiltersQuery && debouncedFiltersQuery),
          limit: transactionsData.totalCount,
          sortBy,
          sortingOrder,
          search: debouncedSearch,
        },
      }).unwrap();

      return getCsvMappedData(data.items);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  //TODO: remove comment if BE is ready
  // const onRow = record => {
  //   return {
  //     onClick: () => {
  //       navigate(`${ADMIN_ROUTES.TRANSACTIONS.path}/${record?.id}`);
  //     },
  //   };
  // };

  const filters = optionsData?.length
    ? filtersList.map(f =>
        f.key === TransactionFilterTypes.purpose
          ? {
              ...f,
              options: optionsData.map(({ name }) => ({
                type: name,
                label: name,
              })),
            }
          : f,
      )
    : filtersList;

  const dropDownMenuItems = [
    {
      key: "1",
      label: "Export",
      onClick: () =>
        downloadCSV({
          fileName: "transactions",
          isDataLoading: false,
          columns: columns,
          getExportData: getExportData,
        }),
    },
  ];

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <FlexContainer
          $padding="0 0 24px"
          $align="center"
          $justify="space-between"
        >
          <Typography.H1>Transactions</Typography.H1>

          {isDesktop ? (
            <Export
              fileName="transactions"
              isLoading={isExportLoading}
              isDataLoading={isLoading}
              isDisabled={!transactionsData?.totalCount}
              columns={columns}
              getExportData={getExportData}
            />
          ) : (
            <DropDown items={dropDownMenuItems} trigger={["click"]}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          )}
        </FlexContainer>

        <Table
          isLoading={isLoading}
          dataSource={transactionsData?.items || []}
          columns={columns}
          empty={{
            icon: DescriptionBlackIcon,
            title: "No transactions to show.",
            description: "Try changing sections or change the filters",
          }}
          header={{
            //TODO: remove comment if BE is ready
            // search: {
            //   placeholder: "Search customer or order ID",
            //   value: search,
            //   setValue: setSearch,
            // },
            totalCount: transactionsData?.totalCount,
            filter: {
              include: [
                AdditionalFilter.Customer,
                AdditionalFilter.UserInitiated,
              ],
              filters: filters,
              selected: selectedFilters,
              setValue: handleSetTableFilterValues,
            },
          }}
          onChange={handleTableChange}
          //TODO: remove comment if BE is ready
          // onRow={onRow}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        showSizeChanger={!isDesktop}
        totalItems={transactionsData?.totalCount}
      />
    </PageWrapper>
  );
};
