import { NOTIFICATIONS } from "consts/notifications";

export enum GeneralField {
  ExchangeRateValue = "exchangeRateValue",
  BalanceExpirationDays = "balanceExpirationDays",
  MinimumTopUpValue = "minimumTopUpValue",
  MinimumOrderValue = "minimumOrderValue",
  InstantOrderCancellationTime = "instantOrderCancellationTime",
  DefaultDeliveryTime = "defaultDeliveryTime",
  DefaultPreparationTime = "defaultPreparationTime",
}

export const notificationMessage = {
  [GeneralField.ExchangeRateValue]: NOTIFICATIONS.EXCHANGE_RATE_UPDATED,
  [GeneralField.BalanceExpirationDays]:
    NOTIFICATIONS.BALANCE_EXPIRATION_DAYS_UPDATED,
  [GeneralField.MinimumTopUpValue]: NOTIFICATIONS.MIN_TOP_UP_UPDATED,
  [GeneralField.MinimumOrderValue]: NOTIFICATIONS.MIN_ORDER_COINS_UPDATED,
  [GeneralField.InstantOrderCancellationTime]: NOTIFICATIONS.ORDER_CANCEL_TIME,
  [GeneralField.DefaultDeliveryTime]: NOTIFICATIONS.DEFAULT_DELIVERY_TIME,
  [GeneralField.DefaultPreparationTime]: NOTIFICATIONS.DEFAULT_PREPARATION_TIME,
};

export type StateValues = {
  [GeneralField.BalanceExpirationDays]: undefined | number;
  [GeneralField.ExchangeRateValue]: undefined | number;
  [GeneralField.MinimumTopUpValue]: undefined | number;
  [GeneralField.MinimumOrderValue]: undefined | number;
  [GeneralField.InstantOrderCancellationTime]: undefined | number;
  [GeneralField.DefaultDeliveryTime]: undefined | number;
  [GeneralField.DefaultPreparationTime]: undefined | number;
};

export const initialState: StateValues = {
  [GeneralField.BalanceExpirationDays]: undefined,
  [GeneralField.ExchangeRateValue]: undefined,
  [GeneralField.MinimumTopUpValue]: undefined,
  [GeneralField.MinimumOrderValue]: undefined,
  [GeneralField.InstantOrderCancellationTime]: undefined,
  [GeneralField.DefaultDeliveryTime]: undefined,
  [GeneralField.DefaultPreparationTime]: undefined,
};

export type LoadingStateValues = Record<keyof StateValues, boolean>;

export const initialLoadingState: LoadingStateValues = {
  [GeneralField.BalanceExpirationDays]: false,
  [GeneralField.ExchangeRateValue]: false,
  [GeneralField.MinimumTopUpValue]: false,
  [GeneralField.MinimumOrderValue]: false,
  [GeneralField.InstantOrderCancellationTime]: false,
  [GeneralField.DefaultDeliveryTime]: false,
  [GeneralField.DefaultPreparationTime]: false,
};

export type CoreSettingsKey =
  | GeneralField.InstantOrderCancellationTime
  | GeneralField.DefaultDeliveryTime
  | GeneralField.DefaultPreparationTime;
