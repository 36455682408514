import { Invitee } from "types/referrals";
import { CurrencyIcon } from "icons";

import {
  dateTransform,
  fixedDigitsValue,
  getFullName,
} from "helpers/dataHelpers";

import { FlexContainer } from "styled/Box";

export const columns = [
  {
    title: "Name",
    dataIndex: "inviteeId",
    key: "name",
    render: (user: Invitee | null) =>
      getFullName({ firstName: user?.firstName, lastName: user?.lastName }),
  },
  {
    title: "Email",
    dataIndex: "inviteeId",
    key: "email",
    render: (user: Invitee | null) => user?.email,
  },
  {
    title: "Phone",
    dataIndex: "inviteeId",
    key: "phoneNumber",
    render: (user: Invitee | null) => user?.phoneNumber,
  },
  {
    title: "Referral Bonus",
    dataIndex: "bonusForInvitee",
    key: "bonusForInvitee",
    align: "right" as const,
    width: 210,
    render: (bonus: number) => (
      <FlexContainer $align="center" $justify="flex-end" $gap={8}>
        {fixedDigitsValue(bonus, 3)} <CurrencyIcon />
      </FlexContainer>
    ),
  },
  {
    title: "Referrer Bonus",
    dataIndex: "bonusForInviter",
    key: "bonusForInviter",
    align: "right" as const,
    width: 210,
    render: (bonus: number) => (
      <FlexContainer $align="center" $justify="flex-end" $gap={8}>
        {fixedDigitsValue(bonus, 3)} <CurrencyIcon />
      </FlexContainer>
    ),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 210,
    render: (date: string) =>
      dateTransform({
        date,
      }),
  },
];
