// Lib
import { FC, useMemo, useState } from "react";
// Api
import { useUpdateUserMutation } from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
// Types
import { FilterValue } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd/lib/table";
import { ETable } from "types/tableFilters";
import { GetUsersResponseDto, UserResponseDto } from "types/users";
// Constants
import { NOTIFICATIONS } from "consts";
import {
  DeliveryProviderLabels,
  DeliveryProviders,
} from "types/deliveryProviders";
// Icons
import { DescriptionBlackIcon } from "icons";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Table } from "components";
import { Select } from "components/Form";
import { ConfirmDialog } from "components/Modals";

import { columns, filtersList } from "./config";

const OPTIONS = [
  {
    label: DeliveryProviderLabels[DeliveryProviders.deliverect],
    value: DeliveryProviders.deliverect,
  },
  {
    label: DeliveryProviderLabels[DeliveryProviders.loop],
    value: DeliveryProviders.loop,
  },
];

interface UsersProps {
  isFetching: boolean;
  usersData: GetUsersResponseDto;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  handleSort: (field: string, direction: string) => void;
}

export const Users: FC<UsersProps> = ({
  search,
  isFetching,
  usersData,
  handleSort,
  setSearch,
}) => {
  const { openNotification } = useNotification();

  const { isDesktop } = useViewport();

  const [updateUser, { isLoading: isUserUpdateLoading }] =
    useUpdateUserMutation();

  const [confirmModal, setConfirmModal] = useState<
    false | (UserResponseDto & { value: DeliveryProviders })
  >(false);

  const { selectedFilters, handleSetTableFilterValues } = useTable({
    name: ETable.Providers,
  });

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const onConfirm = async () => {
    if (!confirmModal) return;

    //req here

    const { id, value } = confirmModal;

    try {
      await updateUser({ id, deliveryProvider: value }).unwrap();

      openNotification({ message: NOTIFICATIONS.USER_UPDATE });

      setConfirmModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const tableColumns = useMemo(() => {
    return columns.map(column => {
      if (column.key === "deliveryProvider") {
        return {
          ...column,
          render: (value, record) => (
            <Select
              style={{ width: 200 }}
              value={value}
              options={OPTIONS}
              onChange={value => setConfirmModal({ ...record, value })}
            />
          ),
        };
      }
      return column;
    });
  }, []);

  return (
    <>
      <Table
        isLoading={isFetching}
        dataSource={usersData?.items || []}
        columns={tableColumns}
        withPagination={!isDesktop}
        empty={{
          icon: DescriptionBlackIcon,
          title: "No users to show.",
          description: "Try changing sections or change the filters",
        }}
        header={{
          search: {
            placeholder: "Search first name or last name",
            value: search,
            setValue: setSearch,
          },
          totalCount: usersData?.totalCount,
          filter: {
            filters: filtersList,
            selected: selectedFilters || [],
            setValue: handleSetTableFilterValues,
          },
        }}
        onChange={handleTableChange}
      />

      <ConfirmDialog
        open={!!confirmModal}
        isLoading={isUserUpdateLoading}
        Icon={DescriptionBlackIcon}
        message={
          confirmModal &&
          `${
            DeliveryProviderLabels[confirmModal.value]
          } Provider will be applied for ${confirmModal?.fullName} `
        }
        description="Are you sure to continue this action?"
        onCancel={() => setConfirmModal(false)}
        firstCTAButton={{
          title: "Confirm",
          type: "primary",
          loading: isUserUpdateLoading,
          onClick: onConfirm,
        }}
      />
    </>
  );
};
