// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  MenuProductResponseDto,
  ProductResponseDto,
  UpdateProductOrderRequestDto,
  UpdateProductRequestDto,
} from "types/recommended";
// Consts
import { endpoints } from "consts";
// Utils
import { coreQuery } from "utils/baseQuery";
import {
  allMenuProductsListMapper,
  recommendedProductsListMapper,
} from "utils/mappers";

export const recommendedAPI = createApi({
  reducerPath: "recommendedAPI",
  baseQuery: coreQuery(),
  endpoints: build => ({
    getRecommendedProductsList: build.query<
      ProductResponseDto[],
      { locationId: string }
    >({
      query: ({ locationId }) => ({
        url: `${endpoints.recommended}/${locationId}`,
        method: "GET",
      }),
      transformResponse: (response: ProductResponseDto[]) =>
        recommendedProductsListMapper(response),
    }),
    getAllMenuProductsList: build.query<
      MenuProductResponseDto[],
      { locationId: string }
    >({
      query: ({ locationId }) => ({
        url: `${endpoints.recommended}/products/${locationId}`,
        method: "GET",
      }),
      transformResponse: (response: MenuProductResponseDto[]) =>
        allMenuProductsListMapper(response),
    }),
    updateRecommendedProducts: build.mutation<void, UpdateProductRequestDto>({
      query: ({ locationId, data }) => ({
        url: `${endpoints.recommended}/${locationId}`,
        method: "POST",
        data,
      }),
    }),
    updateRecommendedProductsOrder: build.mutation<
      void,
      UpdateProductOrderRequestDto
    >({
      query: ({ locationId, data }) => ({
        url: `${endpoints.recommended}/${locationId}`,
        method: "PATCH",
        data,
      }),
    }),
    removeProduct: build.mutation<void, { productId: string }>({
      query: ({ productId }) => ({
        url: `${endpoints.recommended}/${productId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetRecommendedProductsListQuery,
  useGetAllMenuProductsListQuery,
  useLazyGetAllMenuProductsListQuery,
  useUpdateRecommendedProductsMutation,
  useUpdateRecommendedProductsOrderMutation,
  useRemoveProductMutation,
} = recommendedAPI;
