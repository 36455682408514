// Lib
import { FC } from "react";
import { fromLonLat } from "ol/proj";
import { OSM } from "ol/source";
// Context
import { MapInteractionProvider } from "context/MapInteractionContext";
// Types
import { GetLocationResponseDto } from "types/locations";
// Constants
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from "Layers/config";
// Helpers
import { inputDataMapper } from "../../../../helpers";
// Layers
import { PointLayer, PolygonLayer, TileLayerWrapper } from "Layers";
import { useViewport } from "hooks";

interface MapProps {
  locationData: GetLocationResponseDto;
}

export const Map: FC<MapProps> = ({ locationData }) => {
  const { width } = useViewport();
  if (!locationData) {
    return null;
  }
  const mappedData = inputDataMapper([locationData]);

  const { kitchenAddress } = mappedData[0];
  const mapCenter = !kitchenAddress.length
    ? fromLonLat(DEFAULT_MAP_CENTER)
    : kitchenAddress;

  return (
    <div style={{ height: width < 1131 && 300 }}>
      <MapInteractionProvider center={mapCenter} zoom={DEFAULT_MAP_ZOOM}>
        {mappedData?.map(({ zones, kitchenAddress }) => (
          <>
            {zones.map(({ source, zoneId, name }) => (
              <PolygonLayer
                key={zoneId}
                {...{
                  zoneId,
                  source,
                  name,
                }}
              />
            ))}
            <PointLayer coords={kitchenAddress} isActive={false} />
          </>
        ))}
        <TileLayerWrapper source={new OSM()} zIndex={0} />
      </MapInteractionProvider>
    </div>
  );
};
