import styled from "styled-components";
import { Button } from "styled/Buttons";

export const RefreshButton = styled(Button.Base)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 100;
  min-width: auto;
`;
