// Lib
import { FC, useEffect, useRef, useState } from "react";
import { Overlay } from "ol";
import { getCenter } from "ol/extent";
import { Polygon } from "ol/geom";
import { Modify } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import { Vector } from "ol/source";
import { StyleLike } from "ol/style/Style";
import { Coordinate } from "ol/coordinate";
// Hooks
import { useMapInteractionContext } from "hooks";
// Icons
import { TrashIcon } from "icons";
// Styled
import { RemoveButton } from "./styled";

import { ACTIVE_POLYGON_COLOR, REMOVED_POLYGON_COLOR } from "../config";

interface VectorLayerContainerProps {
  source: Vector;
  style: StyleLike;
  zIndex?: number;
  isEditable: boolean;
  isToRemove?: boolean;
  toggleRemoveZone: () => void;
  updateKitchen: (data: Coordinate[][]) => void;
  name: string;
  isPolygonMode: boolean;
}

export const VectorLayerContainer: FC<VectorLayerContainerProps> = ({
  source,
  style,
  zIndex = 0,
  isEditable,
  isToRemove,
  toggleRemoveZone,
  updateKitchen,
  name,
  isPolygonMode,
}) => {
  const { map } = useMapInteractionContext();

  //fix blinking
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const deleteButtonRef = useRef(null);
  const deleteButtonOverlay = new Overlay({
    positioning: "center-center",
    stopEvent: true,
  });
  const vectorLayer = new VectorLayer({
    source,
    style,
    zIndex,
  });
  const modify = new Modify({ source });

  useEffect(() => {
    if (!map) return;

    if (deleteButtonRef.current) {
      deleteButtonOverlay.setElement(deleteButtonRef.current);

      const extent = source.getFeatures()[0].getGeometry()?.getExtent();
      if (!extent) return;

      const centerPos = getCenter(extent);

      deleteButtonOverlay.setPosition(centerPos);
    }

    map.addOverlay(deleteButtonOverlay);

    return () => {
      map.removeOverlay(deleteButtonOverlay);
    };
  }, [isEditable, isToRemove, deleteButtonOverlay, deleteButtonRef.current]);

  useEffect(() => {
    if (!map) return;

    map.addLayer(vectorLayer);

    return () => {
      map.removeLayer(vectorLayer);
    };
  }, [isEditable, map, source, style, zIndex, isPolygonMode, isMounted]);

  useEffect(() => {
    if (isEditable && isPolygonMode) {
      map?.addInteraction(modify);
    }

    modify.on("modifyend", e => {
      e.features.forEach(function (feature) {
        const geometry = feature?.getGeometry() as Polygon;
        const coords = geometry?.getCoordinates();
        updateKitchen(coords);
      });
    });

    return () => {
      map?.removeInteraction(modify);
    };
  }, [map, isEditable, isPolygonMode, modify]);

  return (
    <div>
      <RemoveButton
        id="delete"
        ref={deleteButtonRef}
        disabled={!isEditable}
        $isToRemove={isToRemove}
        $isEditable={isEditable}
        $isMounted={isMounted}
        onClick={toggleRemoveZone}
      >
        {isEditable && isPolygonMode && (
          <TrashIcon
            fill={isToRemove ? REMOVED_POLYGON_COLOR : ACTIVE_POLYGON_COLOR}
          />
        )}
        {name}
      </RemoveButton>
    </div>
  );
};
