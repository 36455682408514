// Lib
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import {
  useCreatePackageRuleMutation,
  useUpdatePackageRuleMutation,
} from "rtkQuery/query/coinPackagesApi";
// Hooks
import { useNotification, useViewport } from "hooks";
// Types
import { PackageRulesResponseDto } from "types/packages";
// Constants
import { NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import { Modal } from "components";
import { InputNumber } from "components/Form";
// Styled
import { FlexContainer, InputsGridContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { ErrorMessage } from "components/Form/styled";

import { resolver } from "./validation";

export type RulesForm = {
  from: number | null;
  to: number | null;
  bonusCoinsPercent: number | null;
};

interface RulesModalProps {
  modalData: boolean | PackageRulesResponseDto;
  rules: PackageRulesResponseDto[];
  onClose: () => void;
}

export const RulesModal: FC<RulesModalProps> = ({
  modalData,
  rules,
  onClose,
}) => {
  const { openNotification } = useNotification();

  const { isMobile } = useViewport();

  const isEdit = !!modalData && typeof modalData !== "boolean";

  const [create, { isLoading: isCreateLoading }] =
    useCreatePackageRuleMutation();
  const [update, { isLoading: isUpdateLoading }] =
    useUpdatePackageRuleMutation();

  const { handleSubmit, control, reset, watch } = useForm<RulesForm>({
    mode: "onChange",
    defaultValues: {
      from: null,
      to: null,
      bonusCoinsPercent: null,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver,
  });

  const [rageError, setRageError] = useState<boolean>(false);

  const from = watch("from");
  const to = watch("to");
  const bonusCoinsPercent = watch("bonusCoinsPercent");

  useEffect(() => {
    if (!modalData) {
      reset({ from: null, to: null, bonusCoinsPercent: null });
      setRageError(false);
    }

    if (isEdit) {
      reset({
        from: modalData.from,
        to: modalData.to,
        bonusCoinsPercent: modalData.bonusCoinsPercent,
      });
    }
  }, [modalData]);

  useEffect(() => {
    setRageError(false);
  }, [from, to]);

  const validateRage = ({ to, from }: RulesForm) => {
    const rulesData = isEdit
      ? rules.filter(row => row.id !== modalData.id)
      : rules;

    const isFromIncludes = rulesData.find(row => {
      return from >= row?.from && from <= row?.to;
    });

    const isToIncludes = rulesData.find(row => {
      return to >= row?.from && to <= row?.to;
    });

    const isHighestRange = rulesData.find(row => {
      return from <= row?.from && to >= row?.to;
    });

    if (isFromIncludes || isToIncludes || isHighestRange) {
      return false;
    }

    return true;
  };

  const onSubmit: SubmitHandler<RulesForm> = async data => {
    const isValidRage = validateRage(data);

    if (!isValidRage) {
      setRageError(true);
      return;
    }

    try {
      if (isEdit) {
        await update({ id: modalData?.id, ...data }).unwrap();

        openNotification({ message: NOTIFICATIONS.PACKAGE_RULE_UPDATED });

        onClose();
      } else {
        await create(data).unwrap();

        openNotification({ message: NOTIFICATIONS.PACKAGE_RULE_CREATED });

        onClose();
      }
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const isDisabled =
    !from?.toString()?.length ||
    !to?.toString()?.length ||
    !bonusCoinsPercent?.toString()?.length;

  const isLoading = isCreateLoading || isUpdateLoading;

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  return (
    <Modal
      title={`${isEdit ? "Edit" : "Add"} Rule`}
      width={560}
      open={!!modalData}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer $column $gap={16}>
          <div>
            <InputsGridContainer>
              <Controller
                name="from"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="From (KD)"
                    required
                    min={0}
                    step={0.001}
                    precision={3}
                    {...field}
                    disabled={isLoading}
                    fieldState={fieldState}
                    fieldError={rageError}
                  />
                )}
              />

              <Controller
                name="to"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="To (KD)"
                    required
                    min={0}
                    step={0.001}
                    precision={3}
                    {...field}
                    disabled={isLoading}
                    fieldState={fieldState}
                    fieldError={rageError}
                  />
                )}
              />
            </InputsGridContainer>

            {rageError && (
              <ErrorMessage>
                This range has been added to the list of rules
              </ErrorMessage>
            )}
          </div>

          <Controller
            name="bonusCoinsPercent"
            control={control}
            render={({ field, fieldState }) => (
              <InputNumber
                label="Bonus (%)"
                required
                min={0}
                precision={0}
                {...field}
                disabled={isLoading}
                fieldState={fieldState}
              />
            )}
          />
        </FlexContainer>

        <FlexContainer
          $fullwidth
          $align="center"
          $justify="flex-end"
          $gap={8}
          $margin="24px 0 0"
        >
          <FlexContainer
            $align="center"
            $justify="center"
            $gap={8}
            $fullwidth={isMobile}
            $column={isMobile}
          >
            <Button.Base
              $fullWidth={isMobile}
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancel
            </Button.Base>

            <Button.Base
              type="primary"
              htmlType="submit"
              $fullWidth={isMobile}
              loading={isLoading}
              disabled={isDisabled}
            >
              Save
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </form>
    </Modal>
  );
};
