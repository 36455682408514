// Lib
import { FC, ReactNode } from "react";
// Types
import { SvgIconConstituentValues } from "types/common";
// Theme
import { theme } from "theme";
// Components
import { Modal } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { IconContainer, Title } from "./styled";

interface WarningDialogProps {
  open: boolean;
  Icon: FC<SvgIconConstituentValues>;
  message: ReactNode;
  onCancel: () => void;
}

export const WarningDialog: FC<WarningDialogProps> = ({
  open,
  message,
  Icon,
  onCancel,
}) => {
  return (
    <Modal closeIcon={false} open={open} width={480}>
      <FlexContainer
        $fullwidth
        $column
        $gap={8}
        $align="center"
        $justify="center"
        $padding="24px 0 0"
      >
        <IconContainer>
          <Icon fill={theme.color.text.tertiary} />
        </IconContainer>

        <Title>{message}</Title>

        <FlexContainer
          $fullwidth
          $align="center"
          $justify="flex-end"
          $gap={8}
          $padding="24px 0 0"
        >
          <Button.Heading onClick={onCancel}>Cancel</Button.Heading>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
