// Lib
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useDeleteCouponMutation,
  useGetCouponsQuery,
} from "rtkQuery/query/couponsAPI";
// Hooks
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
// Types
import { TableAction } from "types/common";
import { ETable } from "types/tableFilters";
import { Coupon, CouponKindType } from "types/coupons";
// Theme
import { theme } from "theme";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS, rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import {
  DescriptionBlackIcon,
  PlusIcon,
  RightBurgerMenuIcon,
  TrashIcon,
} from "icons";
// Components
import { DropDown, Pagination, Table } from "components";
import { ConfirmDialog } from "components/Modals";
import { CouponUsersModal } from "../../components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

import { columns } from "./config";

export const TopUpCoupons: FC = () => {
  const navigate = useNavigate();

  const { isDesktop } = useViewport();

  const { canCouponsCreate, canCouponsDelete } = usePermissions();
  const { openNotification } = useNotification();

  const [deleteCoupon, { isLoading: isCouponDeleteLoading }] =
    useDeleteCouponMutation();

  const [deleteCouponModal, setDeleteCouponModal] = useState<false | Coupon>(
    false,
  );

  const [usedCouponUsers, setUsedCouponUsers] = useState({
    id: "",
    name: "",
  });

  const { page, limit, setPage, setLimit } = useTable({
    name: ETable.Coupons,
  });

  const { data, isFetching, error } = useGetCouponsQuery(
    {
      query: {
        page,
        limit,
        type: CouponKindType.TopUp,
      },
    },
    rtkQueryParams,
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const handleAddCoupon = () => {
    navigate(`${ADMIN_ROUTES.TOP_UP_COUPON_CREATE.path}`);
  };

  const onConfirmDelete = async () => {
    if (!deleteCouponModal) {
      return;
    }

    try {
      await deleteCoupon({ id: deleteCouponModal.id }).unwrap();

      openNotification({
        message: NOTIFICATIONS.COUPON_DELETE,
      });

      setDeleteCouponModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onCounterClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    count: number,
    id: string,
    name: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!count) {
      return;
    }
    setUsedCouponUsers({
      id,
      name,
    });
  };

  const tableColumns = useMemo(() => {
    return columns.map(col => {
      if (col.key === "couponUsageCount") {
        return {
          ...col,
          render: (count: number, record: Coupon) => (
            <Button.Notification
              type="link"
              onClick={e => {
                onCounterClick(e, count, record.id, record?.couponCode);
              }}
            >
              {count}
            </Button.Notification>
          ),
        };
      }

      return col;
    });
  }, []);

  const actions: TableAction[] = [
    {
      title: "",
      Icon: TrashIcon,
      type: "Grey",
      disabled: !canCouponsDelete,
      onClick: (row: Coupon) => setDeleteCouponModal(row),
    },
  ];

  const DropDownMenuItems = [
    {
      key: "1",
      label: "Add Coupon",
      onClick: handleAddCoupon,
    },
  ];

  const onRow = (record: Coupon) => {
    return {
      onClick: () => {
        navigate(`${ADMIN_ROUTES.TOP_UP_COUPONS.path}/${record.id}`);
      },
    };
  };

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $padding="0 0 24px"
            $align="center"
            $justify="space-between"
          >
            <Typography.H1>Top-up Coupons</Typography.H1>

            {canCouponsCreate && isDesktop ? (
              <Button.Heading
                type="primary"
                icon={<PlusIcon fill={theme.color.white} />}
                onClick={handleAddCoupon}
              >
                Add Coupon
              </Button.Heading>
            ) : (
              <DropDown items={DropDownMenuItems} trigger={["click"]}>
                <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
              </DropDown>
            )}
          </FlexContainer>

          <Table
            isLoading={isFetching}
            withPagination={!isDesktop}
            dataSource={data?.items || []}
            columns={tableColumns}
            actions={actions}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No Coupons to show.",
            }}
            header={{
              totalCount: data?.totalCount,
              suffix: <div style={{ height: 40 }} />,
            }}
            onRow={onRow}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          limit={limit}
          page={page}
          showSizeChanger={!isDesktop}
          setLimit={setLimit}
          setPage={setPage}
          totalItems={data?.totalCount}
        />
      </PageWrapper>

      <CouponUsersModal
        usedCouponUsers={usedCouponUsers}
        setUsedCouponUsers={setUsedCouponUsers}
      />

      <ConfirmDialog
        open={!!deleteCouponModal}
        isLoading={isCouponDeleteLoading}
        Icon={TrashIcon}
        message={`Coupon "${
          (deleteCouponModal as Coupon)?.couponCode
        }" will be deleted`}
        description="Are you sure to continue this action?"
        onCancel={() => setDeleteCouponModal(false)}
        firstCTAButton={{
          title: "Delete Coupon",
          status: "danger",
          loading: isCouponDeleteLoading,
          onClick: onConfirmDelete,
        }}
      />
    </>
  );
};
