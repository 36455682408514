// Lib
import { FC } from "react";
import { Radio, RadioChangeEvent } from "antd";
import { Control, Controller } from "react-hook-form";
// Types
import { CouponType, DiscountType } from "types/coupons";
import { AllPaymentTypes, OrderPaymentType } from "types/orders";
import {
  OrderCouponForm,
  PurchaseType,
  UsersType,
  UseType,
} from "../../../../types";
// Constants
import { DAY_MONTH_YEAR_DATE, TABLE_MONTH_DAY_YEAR_TIME_DATE } from "consts";
// Helpers
import {
  getDiscountLabel,
  //temporary removed
  // paymentTypeOptions,
  discountTypeOptions,
  couponTypeOptions,
} from "../../../../helpers";
// Components
import { Tooltip } from "components";
import {
  DatePicker,
  Input,
  InputNumber,
  Select,
  Switch,
} from "components/Form";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";

interface MainTabProps {
  isEdit: boolean;
  isEditMode: boolean;
  isCouponNameDisabled: boolean;
  isLoading: boolean;
  control: Control<OrderCouponForm, any>;
  useType: UseType;
  usersType: UsersType;
  couponType: CouponType;
  discountType: DiscountType;
  purchaseType: PurchaseType;
  orderPaymentType: OrderPaymentType | AllPaymentTypes;
  handleSetUseType: (value: UseType) => void;
  handleSetUsersType: (value: UsersType) => void;
  handleSetDiscountType: (value: DiscountType) => void;
  handleSetPurchaseType: (value: PurchaseType) => void;
  handleSetPaymentType: (value: OrderPaymentType | AllPaymentTypes) => void;
}

export const MainTab: FC<MainTabProps> = ({
  isEdit,
  isEditMode,
  isCouponNameDisabled,
  isLoading,
  control,
  useType,
  usersType,
  couponType,
  discountType,
  purchaseType,
  orderPaymentType,
  handleSetUseType,
  handleSetUsersType,
  handleSetDiscountType,
  handleSetPurchaseType,
  handleSetPaymentType,
}) => {
  const onChangeDiscountType = (value: DiscountType) => {
    handleSetDiscountType(value);
  };
  const onChangePaymentType = (value: OrderPaymentType | AllPaymentTypes) => {
    handleSetPaymentType(value);
  };
  const onChangeUseType = (e: RadioChangeEvent) => {
    handleSetUseType(e.target.value as UseType);
  };
  const onChangeUsersType = (e: RadioChangeEvent) => {
    handleSetUsersType(e.target.value as UsersType);
  };
  const onChangePurchaseType = (e: RadioChangeEvent) => {
    handleSetPurchaseType(e.target.value as PurchaseType);
  };

  const isGift = couponType === CouponType.GiftVoucher;

  const isMinimumOrderFiatCentsPrice =
    orderPaymentType === OrderPaymentType.FiatCents ||
    orderPaymentType === AllPaymentTypes.All;

  const isMinimumOrderFmcCentsPrice =
    orderPaymentType === OrderPaymentType.FmcCents ||
    orderPaymentType === AllPaymentTypes.All;

  const isMinimumPurchaseAvailable =
    !isGift && (isMinimumOrderFiatCentsPrice || isMinimumOrderFmcCentsPrice);

  return (
    <>
      <FlexContainer $column $gap={16} $fullwidth>
        <ContentBox $column $gap={20}>
          <FlexContainer $fullwidth $align="center" $justify="space-between">
            <Typography.H2>Settings</Typography.H2>

            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <Switch
                  label="Activate Coupon"
                  checked={field.value}
                  {...field}
                  disabled={!isEditMode || isLoading}
                />
              )}
            />
          </FlexContainer>

          <InputsGridContainer>
            {!isEdit && (
              <>
                <Tooltip
                  title={
                    isCouponNameDisabled &&
                    "Not available for bulk coupons creating"
                  }
                  trigger={["hover"]}
                >
                  <div>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input
                          label="Coupon Code"
                          placeholder="Coupon Code"
                          {...field}
                          fieldState={fieldState}
                          disabled={isCouponNameDisabled || isEdit || isLoading}
                        />
                      )}
                    />
                  </div>
                </Tooltip>

                <Controller
                  name="numberOfCouponsToGenerate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      label="Quantity"
                      placeholder="Quantity"
                      required
                      min={1}
                      step={1}
                      precision={0}
                      {...field}
                      disabled={isEdit || isLoading}
                      fieldState={fieldState}
                    />
                  )}
                />
              </>
            )}

            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  required
                  showTime
                  label="Start date"
                  placeholder={DAY_MONTH_YEAR_DATE}
                  format={TABLE_MONTH_DAY_YEAR_TIME_DATE}
                  isDisabled={!isEditMode || isLoading}
                  {...field}
                  fieldState={fieldState}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={e => field.onChange(e) as any}
                />
              )}
            />

            <Controller
              name="expiryDate"
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  required
                  showTime
                  label="Expiration date"
                  placeholder={DAY_MONTH_YEAR_DATE}
                  format={TABLE_MONTH_DAY_YEAR_TIME_DATE}
                  isDisabled={!isEditMode || isLoading}
                  {...field}
                  fieldState={fieldState}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={e => field.onChange(e) as any}
                />
              )}
            />

            <Controller
              name="couponType"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  required
                  label="Type"
                  placeholder="Select coupon type"
                  loading={false}
                  {...field}
                  fieldState={fieldState}
                  options={couponTypeOptions}
                  disabled={!isEditMode || isLoading}
                />
              )}
            />

            <Controller
              name="discountType"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  required
                  label="Discount Type"
                  placeholder="Select type"
                  loading={false}
                  {...field}
                  fieldState={fieldState}
                  options={discountTypeOptions}
                  onChange={onChangeDiscountType}
                  disabled={!isEditMode || isLoading}
                />
              )}
            />

            <Controller
              name="discountOff"
              control={control}
              render={({ field, fieldState }) => (
                <InputNumber
                  label={getDiscountLabel(discountType)}
                  placeholder={`Set ${getDiscountLabel(discountType)}`}
                  required
                  min={0}
                  step={1}
                  precision={0}
                  {...field}
                  disabled={!isEditMode || isLoading}
                  fieldState={fieldState}
                />
              )}
            />

            {/* temporary removed */}

            {/* {discountType === DiscountType.Percentage && (
              <Controller
                name="orderPaymentType"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    required
                    label="Payment type"
                    placeholder="Select payment type"
                    loading={false}
                    {...field}
                    fieldState={fieldState}
                    options={paymentTypeOptions}
                    onChange={onChangePaymentType}
                    disabled={!isEditMode || isLoading}
                  />
                )}
              />
            )} */}
          </InputsGridContainer>

          <Controller
            name="allowMultipleUsage"
            control={control}
            render={({ field }) => (
              <Switch
                label="Coupon Stacking"
                checked={field.value}
                {...field}
                disabled={!isEditMode || isLoading}
              />
            )}
          />
        </ContentBox>

        <ContentBox $column $gap={20}>
          <Typography.H2>Users</Typography.H2>

          <Radio.Group onChange={onChangeUsersType} value={usersType}>
            <FlexContainer>
              <Radio value={UsersType.All} disabled={!isEditMode || isLoading}>
                {UsersType.All}
              </Radio>
              <Radio value={UsersType.List} disabled={!isEditMode || isLoading}>
                {UsersType.List}
              </Radio>
            </FlexContainer>
          </Radio.Group>
        </ContentBox>

        <ContentBox $column $gap={20}>
          <Typography.H2>Usage Type</Typography.H2>

          <Radio.Group onChange={onChangeUseType} value={useType}>
            <FlexContainer>
              <Radio
                value={UseType.OneTime}
                disabled={!isEditMode || isLoading}
              >
                {UseType.OneTime}
              </Radio>
              <Radio
                value={UseType.Multiple}
                disabled={!isEditMode || isLoading}
              >
                {UseType.Multiple}
              </Radio>
            </FlexContainer>
          </Radio.Group>

          {useType === UseType.Multiple && (
            <InputsGridContainer>
              <Controller
                name="numberOfUses"
                control={control}
                render={({ field, fieldState }) => (
                  <InputNumber
                    label="Count of uses"
                    required
                    min={2}
                    step={1}
                    precision={0}
                    {...field}
                    disabled={!isEditMode || isLoading}
                    fieldState={fieldState}
                  />
                )}
              />
            </InputsGridContainer>
          )}
        </ContentBox>

        {isMinimumPurchaseAvailable && (
          <ContentBox $column $gap={20}>
            <Typography.H2>Minimum purchase</Typography.H2>

            <Radio.Group onChange={onChangePurchaseType} value={purchaseType}>
              <FlexContainer>
                <Radio
                  value={PurchaseType.WithoutPurchase}
                  disabled={!isEditMode || isLoading}
                >
                  {PurchaseType.WithoutPurchase}
                </Radio>
                <Radio
                  value={PurchaseType.MinimumPurchase}
                  disabled={!isEditMode || isLoading}
                >
                  {PurchaseType.MinimumPurchase}
                </Radio>
              </FlexContainer>
            </Radio.Group>

            {orderPaymentType &&
              purchaseType === PurchaseType.MinimumPurchase && (
                <InputsGridContainer>
                  {isMinimumOrderFiatCentsPrice && (
                    <Controller
                      name="minimumOrderFiatCentsPrice"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          label="Minimum purchase (KWD)"
                          required
                          min={0}
                          step={0.01}
                          precision={2}
                          {...field}
                          disabled={!isEditMode || isLoading}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  )}

                  {isMinimumOrderFmcCentsPrice && (
                    <Controller
                      name="minimumOrderFmcCentsPrice"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputNumber
                          label="Minimum purchase (FM Coins)"
                          required
                          min={0}
                          step={0.01}
                          precision={2}
                          {...field}
                          disabled={!isEditMode || isLoading}
                          fieldState={fieldState}
                        />
                      )}
                    />
                  )}
                </InputsGridContainer>
              )}
          </ContentBox>
        )}
      </FlexContainer>
    </>
  );
};
