import { FlexContainer } from "styled/Box";

const Logo = () => (
  <FlexContainer $column $align="center" $justify="center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="17"
      viewBox="0 0 90 17"
      fill="none"
    >
      <path
        d="M24.7013 13.9328C23.1545 15.4113 20.9717 16.1847 18.7208 16.1847C16.4698 16.1847 14.2861 15.4113 12.7393 13.9328C11.1244 12.386 10.351 10.2724 10.351 8.27079C10.351 6.26918 11.1244 4.1088 12.7393 2.58543C14.2861 1.10784 16.4688 0.334473 18.7208 0.334473C20.9727 0.334473 23.1545 1.10784 24.7013 2.58543C26.3162 4.10977 27.0896 6.24677 27.0896 8.27079C27.0896 10.2948 26.3162 12.387 24.7013 13.9328ZM15.6507 5.35944C14.8325 6.15521 14.4458 7.26949 14.4458 8.33896C14.4458 9.68019 15.0818 10.6123 15.6507 11.1588C16.3783 11.864 17.402 12.3636 18.7208 12.3636C20.0396 12.3636 21.0633 11.8864 21.7675 11.1812C22.4727 10.476 22.9957 9.42987 22.9957 8.26981C22.9957 7.10976 22.4727 6.04125 21.7675 5.35847C20.926 4.56269 19.7659 4.1536 18.7208 4.1536C17.6757 4.1536 16.4688 4.54029 15.6507 5.35847V5.35944Z"
        fill="white"
      />
      <path
        d="M34.8223 0.675293C35.959 0.675293 37.4142 0.857438 38.5294 2.0175C39.3934 2.90385 39.8258 4.22267 39.8258 5.4285C39.8258 6.72493 39.3719 7.86161 38.621 8.58921C38.1632 9.02946 37.5827 9.33336 37.0168 9.5116C36.7869 9.58368 36.7012 9.86226 36.8483 10.0532L40.8505 15.2807C41.0268 15.5116 40.8622 15.8437 40.5719 15.8437H36.5823C36.4635 15.8437 36.3535 15.7833 36.2882 15.684L33.2609 11.0155C33.071 10.7223 32.6171 10.8567 32.6171 11.2064V15.494C32.6171 15.6879 32.4603 15.8447 32.2665 15.8447H29.0327C28.8389 15.8447 28.6821 15.6879 28.6821 15.494V1.02691C28.6821 0.833083 28.8389 0.676268 29.0327 0.676268H34.8233L34.8223 0.675293ZM32.6161 7.47492H33.3671C33.9583 7.47492 34.7989 7.31519 35.2772 6.86032C35.5723 6.56519 35.7778 6.06454 35.7778 5.58727C35.7778 5.11 35.5957 4.63176 35.2548 4.31423C34.8905 3.94995 34.232 3.72301 33.3671 3.72301H32.6161V7.4759V7.47492Z"
        fill="white"
      />
      <path
        d="M50.2995 4.01813H46.3157C46.1219 4.01813 45.9651 4.17494 45.9651 4.36877V6.19215C45.9651 6.38598 46.1219 6.54279 46.3157 6.54279H50.0501C50.2439 6.54279 50.4008 6.69961 50.4008 6.89344V9.53596C50.4008 9.72978 50.2439 9.8866 50.0501 9.8866H46.3157C46.1219 9.8866 45.9651 10.0434 45.9651 10.2372V12.1522C45.9651 12.346 46.1219 12.5028 46.3157 12.5028H50.2995C50.4933 12.5028 50.6501 12.6596 50.6501 12.8535V15.495C50.6501 15.6888 50.4933 15.8457 50.2995 15.8457H42.3807C42.1868 15.8457 42.03 15.6888 42.03 15.495V1.02789C42.03 0.834061 42.1868 0.677246 42.3807 0.677246H50.2995C50.4933 0.677246 50.6501 0.834061 50.6501 1.02789V3.66943C50.6501 3.86326 50.4933 4.02008 50.2995 4.02008V4.01813Z"
        fill="white"
      />
      <path
        d="M55.0352 0.897374L58.2631 9.08693C58.379 9.38206 58.7969 9.38303 58.9147 9.08791L62.1836 0.896399C62.2371 0.762958 62.3657 0.67627 62.5089 0.67627H66.0173C66.2686 0.67627 66.4381 0.932438 66.3397 1.16425L60.179 15.6314C60.1245 15.7609 59.9969 15.8447 59.8566 15.8447H57.2511C57.1099 15.8447 56.9823 15.7599 56.9277 15.6304L50.8537 1.16328C50.7563 0.932437 50.9268 0.677245 51.1771 0.677245H54.7099C54.854 0.677245 54.9826 0.764897 55.0362 0.899312L55.0352 0.897374Z"
        fill="white"
      />
      <path
        d="M75.4974 4.01813H71.5137C71.3198 4.01813 71.163 4.17494 71.163 4.36877V6.19215C71.163 6.38598 71.3198 6.54279 71.5137 6.54279H75.248C75.4419 6.54279 75.5987 6.69961 75.5987 6.89344V9.53596C75.5987 9.72978 75.4419 9.8866 75.248 9.8866H71.5137C71.3198 9.8866 71.163 10.0434 71.163 10.2372V12.1522C71.163 12.346 71.3198 12.5028 71.5137 12.5028H75.4974C75.6912 12.5028 75.848 12.6596 75.848 12.8535V15.495C75.848 15.6888 75.6912 15.8457 75.4974 15.8457H67.5786C67.3848 15.8457 67.228 15.6888 67.228 15.495V1.02789C67.228 0.834061 67.3848 0.677246 67.5786 0.677246H75.4974C75.6912 0.677246 75.848 0.834061 75.848 1.02789V3.66943C75.848 3.86326 75.6912 4.02008 75.4974 4.02008V4.01813Z"
        fill="white"
      />
      <path
        d="M83.5798 0.675293C84.7165 0.675293 86.1717 0.857438 87.2869 2.0175C88.1509 2.90385 88.5833 4.22267 88.5833 5.4285C88.5833 6.72493 88.1295 7.86161 87.3785 8.58921C86.9207 9.02946 86.3402 9.33336 85.7743 9.5116C85.5444 9.58368 85.4587 9.86226 85.6058 10.0532L89.608 15.2807C89.7843 15.5116 89.6197 15.8437 89.3294 15.8437H85.3399C85.221 15.8437 85.111 15.7833 85.0457 15.684L82.0184 11.0155C81.8285 10.7223 81.3746 10.8567 81.3746 11.2064V15.494C81.3746 15.6879 81.2178 15.8447 81.024 15.8447H77.7902C77.5964 15.8447 77.4396 15.6879 77.4396 15.494V1.02691C77.4396 0.833083 77.5964 0.676268 77.7902 0.676268H83.5808L83.5798 0.675293ZM81.3736 7.47492H82.1246C82.7159 7.47492 83.5564 7.31519 84.0347 6.86032C84.3298 6.56519 84.5353 6.06454 84.5353 5.58727C84.5353 5.11 84.3532 4.63176 84.0123 4.31423C83.648 3.94995 82.9896 3.72301 82.1246 3.72301H81.3736V7.4759V7.47492Z"
        fill="white"
      />
      <path
        d="M10.4095 0.631592H2.81209C2.64067 0.631592 2.49359 0.755293 2.46534 0.924773L0.0049669 15.4465C-0.0310718 15.6607 0.133537 15.8565 0.351718 15.8565H3.88839C4.05981 15.8565 4.20689 15.7328 4.23514 15.5633L5.19844 9.87602H8.96108C9.13251 9.87602 9.27861 9.75232 9.30784 9.58284L9.75783 6.93059C9.79387 6.7163 9.62926 6.52053 9.41108 6.52053H5.76727L6.19681 3.9871H9.95946C10.1309 3.9871 10.277 3.8634 10.3062 3.69392L10.7562 1.04166C10.7922 0.827378 10.6276 0.631592 10.4095 0.631592Z"
        fill="white"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="17"
      viewBox="0 0 61 17"
      fill="none"
    >
      <path
        d="M0.64132 15.9153L3.09293 1.44821C3.12118 1.27971 3.26728 1.15601 3.43871 1.15601H6.78934C6.93447 1.15601 7.06499 1.24562 7.11661 1.38199L9.7825 8.41246C9.89549 8.71051 10.3163 8.71538 10.4351 8.41928L13.2636 1.37614C13.3172 1.24367 13.4458 1.15601 13.589 1.15601H16.9854C17.1588 1.15601 17.3058 1.28263 17.3321 1.45406L19.5013 15.9212C19.5334 16.1325 19.3688 16.3234 19.1545 16.3234H15.9364C15.7601 16.3234 15.611 16.192 15.5886 16.0176L14.6789 8.8868C14.6331 8.53129 14.1471 8.46506 14.0078 8.79525L10.9416 16.1082C10.887 16.2387 10.7594 16.3234 10.6182 16.3234H9.51952C9.37634 16.3234 9.24776 16.2368 9.19419 16.1033L6.26239 8.79135C6.12895 8.45921 5.64291 8.51667 5.59032 8.87024L4.52864 16.0235C4.50331 16.1949 4.35526 16.3225 4.18189 16.3225H0.987102C0.769896 16.3225 0.605281 16.1277 0.64132 15.9134V15.9153Z"
        fill="white"
      />
      <path
        d="M34.8206 14.4135C33.2739 15.892 31.0911 16.6654 28.8402 16.6654C26.5892 16.6654 24.4054 15.892 22.8587 14.4135C21.2438 12.8667 20.4704 10.7531 20.4704 8.7515C20.4704 6.74989 21.2438 4.58951 22.8587 3.06614C24.4054 1.58856 26.5882 0.815186 28.8402 0.815186C31.0921 0.815186 33.2739 1.58856 34.8206 3.06614C36.4356 4.59049 37.2089 6.72749 37.2089 8.7515C37.2089 10.7755 36.4356 12.8677 34.8206 14.4135ZM25.77 5.84015C24.9519 6.63593 24.5652 7.75022 24.5652 8.81969C24.5652 10.1609 25.2012 11.093 25.77 11.6395C26.4976 12.3447 27.5213 12.8443 28.8402 12.8443C30.159 12.8443 31.1827 12.3671 31.8869 11.6619C32.5921 10.9567 33.1151 9.91058 33.1151 8.75053C33.1151 7.59047 32.5921 6.52197 31.8869 5.83918C31.0453 5.0434 29.8853 4.63432 28.8402 4.63432C27.795 4.63432 26.5882 5.021 25.77 5.83918V5.84015Z"
        fill="white"
      />
      <path
        d="M44.9408 1.15601C46.0774 1.15601 47.5326 1.33815 48.6479 2.49821C49.5118 3.38457 49.9443 4.70339 49.9443 5.90923C49.9443 7.20565 49.4904 8.34233 48.7394 9.06992C48.2816 9.51018 47.7011 9.81407 47.1352 9.99232C46.9054 10.0644 46.8196 10.343 46.9667 10.5339L50.969 15.7614C51.1453 15.9923 50.9807 16.3244 50.6904 16.3244H46.7008C46.582 16.3244 46.4719 16.264 46.4067 16.1647L43.3794 11.4962C43.1895 11.203 42.7356 11.3374 42.7356 11.6871V15.9748C42.7356 16.1686 42.5788 16.3254 42.3849 16.3254H39.1512C38.9574 16.3254 38.8005 16.1686 38.8005 15.9748V1.50763C38.8005 1.3138 38.9574 1.15698 39.1512 1.15698H44.9417L44.9408 1.15601ZM42.7346 7.95565H43.4856C44.0768 7.95565 44.9174 7.7959 45.3956 7.34103C45.6908 7.04591 45.8963 6.54527 45.8963 6.068C45.8963 5.59073 45.7141 5.11247 45.3732 4.79494C45.0089 4.43066 44.3505 4.20372 43.4856 4.20372H42.7346V7.95662V7.95565Z"
        fill="white"
      />
      <path
        d="M60.4189 4.49885H56.4352C56.2413 4.49885 56.0845 4.65567 56.0845 4.8495V6.67286C56.0845 6.86669 56.2413 7.02351 56.4352 7.02351H60.1696C60.3634 7.02351 60.5202 7.18032 60.5202 7.37415V10.0167C60.5202 10.2105 60.3634 10.3673 60.1696 10.3673H56.4352C56.2413 10.3673 56.0845 10.5241 56.0845 10.718V12.6329C56.0845 12.8267 56.2413 12.9835 56.4352 12.9835H60.4189C60.6127 12.9835 60.7696 13.1404 60.7696 13.3342V15.9757C60.7696 16.1696 60.6127 16.3264 60.4189 16.3264H52.5001C52.3063 16.3264 52.1495 16.1696 52.1495 15.9757V1.5086C52.1495 1.31477 52.3063 1.15796 52.5001 1.15796H60.4189C60.6127 1.15796 60.7696 1.31477 60.7696 1.5086V4.15015C60.7696 4.34398 60.6127 4.50079 60.4189 4.50079V4.49885Z"
        fill="white"
      />
    </svg>
  </FlexContainer>
);
export default Logo;
