// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { UrlQueryParams } from "types/common";
import {
  GetReviewsResponseDto,
  ReviewDetailsResponseDto,
  ReviewResponseDto,
  UpdateReviewRequestDto,
} from "types/reviews";
// Helpers
import { reviewsUrlBuilder } from "./urlBuilder/reviews";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";

export const reviewsApi = createApi({
  reducerPath: "reviewsApi",
  baseQuery: coreQuery(),
  tagTypes: ["reviews"],

  endpoints: build => ({
    getReviews: build.query<GetReviewsResponseDto, { query?: UrlQueryParams }>({
      query: ({ query }) => ({
        url: reviewsUrlBuilder.getReviewsList({ query }),
        method: "GET",
      }),
      providesTags: ["reviews"],
    }),
    getReview: build.query<ReviewDetailsResponseDto, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.reviews}/${id}`,
        method: "GET",
      }),
      providesTags: ["reviews"],
    }),
    updateReview: build.mutation<ReviewResponseDto, UpdateReviewRequestDto>({
      query: ({ id, ...data }) => ({
        url: `${endpoints.reviews}/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["reviews"],
    }),
  }),
});

export const {
  useGetReviewsQuery,
  useGetReviewQuery,
  useUpdateReviewMutation,
} = reviewsApi;
