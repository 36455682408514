// Lib
import { FC, useEffect, useRef } from "react";
import { Overlay } from "ol";
import { transform } from "ol/proj";
import { Coordinate } from "ol/coordinate";
// Hooks
import { useMapInteractionContext } from "hooks";
// Icons
import { LocationsIcon } from "icons";
// Styled
import { Marker } from "./styled";

import { NEW_POLYGON_COLOR } from "../config";

interface DrawPointLayerProps {
  addNewKitchenPoint: (coordinate: Coordinate) => void;
}

export const DrawPointLayer: FC<DrawPointLayerProps> = ({
  addNewKitchenPoint,
}) => {
  const { map } = useMapInteractionContext();

  const markerRef = useRef(null);

  const markerOverlay = new Overlay({
    positioning: "bottom-center",
    stopEvent: false,
  });

  useEffect(() => {
    if (markerRef.current) {
      markerOverlay.setElement(markerRef.current);
    }
  }, []);

  const addMarker = (coordinate: [number, number]) => {
    if (!coordinate) return;

    const formatted = transform(coordinate, "EPSG:3857", "EPSG:4326");
    markerOverlay.setPosition(coordinate);

    addNewKitchenPoint(formatted as Coordinate);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnClick = (e: any) => addMarker(e.coordinate);

  useEffect(() => {
    if (!map) return;

    map.addOverlay(markerOverlay);
    map.on("click", handleOnClick);

    return () => {
      map.un("click", handleOnClick);
      map.removeOverlay(markerOverlay);
    };
  }, [map]);

  return (
    <div>
      <Marker id="new_marker" ref={markerRef}>
        <LocationsIcon fill={NEW_POLYGON_COLOR} width="3rem" height="3rem" />
      </Marker>
    </div>
  );
};
