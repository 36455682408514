// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useLazyGetExchangeRateQuery,
  useUpdateExchangeRateMutation,
} from "rtkQuery/query/exchangeRateAPI";
import {
  useLazyGetBalanceExpirationQuery,
  useUpdateBalanceExpirationMutation,
} from "rtkQuery/query/balanceExpirationAPI";
import {
  useLazyGetSettingsQuery,
  useUpdateSettingsMutation,
} from "rtkQuery/query/settingsAPI";
import { useLazyGetAuthSettingsQuery } from "rtkQuery/query/authSettingsAPI";
import { useUpdateCoreSettingsMutation } from "rtkQuery/query/coreSettingsAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
// Utils
import { errorHandler } from "utils/errorHandler";
// Components
import SettingsItem from "../SettingsItem";
import { Input } from "../Input";
// Styled
import { ContentBox } from "styled/Box";
import { Typography } from "styled/Typography";

import {
  CoreSettingsKey,
  GeneralField,
  initialLoadingState,
  initialState,
  LoadingStateValues,
  notificationMessage,
  StateValues,
} from "./config";

export const General: FC = () => {
  const { openNotification } = useNotification();

  const {
    canSettingsGet,
    canExchangeRateUpdate,
    canSettingsUpdate,
    canBalanceExpirationUpdate,
  } = usePermissions();

  const [fetchBalanceExpiration] = useLazyGetBalanceExpirationQuery();
  const [fetchExchangeRate] = useLazyGetExchangeRateQuery();
  const [fetchSettings] = useLazyGetSettingsQuery();
  const [fetchAuthSettings] = useLazyGetAuthSettingsQuery();
  const [updateExchangeRate] = useUpdateExchangeRateMutation();
  const [updateBalanceExpiration] = useUpdateBalanceExpirationMutation();
  const [updateSettings] = useUpdateSettingsMutation();
  const [updateCoreSettings] = useUpdateCoreSettingsMutation();

  const [settings, setSettings] = useState<StateValues>(initialState);

  const [isLoading, setIsLoading] =
    useState<LoadingStateValues>(initialLoadingState);

  const getBalanceExpiration = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.BalanceExpirationDays]: true,
      }));

      const { days } = await fetchBalanceExpiration().unwrap();

      setSettings(prev => ({ ...prev, balanceExpirationDays: days }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.BalanceExpirationDays]: false,
      }));
    }
  };

  const getExchangeRate = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.ExchangeRateValue]: true,
      }));
      const { value } = await fetchExchangeRate().unwrap();

      setSettings(prev => ({ ...prev, exchangeRateValue: value }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.ExchangeRateValue]: false,
      }));
    }
  };

  const getMinimunSettings = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.MinimumTopUpValue]: true,
        [GeneralField.MinimumOrderValue]: true,
      }));

      const { minimumTopUpValue, minimumOrderValue } =
        await fetchSettings().unwrap();

      setSettings(prev => ({
        ...prev,
        minimumTopUpValue,
        minimumOrderValue,
      }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.MinimumTopUpValue]: false,
        [GeneralField.MinimumOrderValue]: false,
      }));
    }
  };

  const getAuthSettings = async () => {
    try {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.InstantOrderCancellationTime]: true,
        [GeneralField.DefaultDeliveryTime]: true,
        [GeneralField.DefaultPreparationTime]: true,
      }));

      const {
        instantOrderCancellationTime,
        defaultDeliveryTime,
        defaultPreparationTime,
      } = await fetchAuthSettings().unwrap();

      setSettings(prev => ({
        ...prev,
        instantOrderCancellationTime,
        defaultDeliveryTime,
        defaultPreparationTime,
      }));
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        [GeneralField.InstantOrderCancellationTime]: false,
        [GeneralField.DefaultDeliveryTime]: false,
        [GeneralField.DefaultPreparationTime]: false,
      }));
    }
  };

  useEffect(() => {
    if (!canSettingsGet) {
      return;
    }

    getBalanceExpiration();
    getExchangeRate();
    getMinimunSettings();
    getAuthSettings();
  }, []);

  const handleUpdateSettings = async (
    key: GeneralField,
    value: number | boolean,
  ) => {
    const isValueNumber = typeof value === "number";
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      const payload = {
        [GeneralField.MinimumTopUpValue]:
          settings[GeneralField.MinimumTopUpValue],
        [key]: value,
      };

      if (isValueNumber) {
        await updateSettings(payload).unwrap();

        setSettings(prev => ({ ...prev, [key]: value }));
      } else {
        await updateSettings(payload).unwrap();
      }

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      if (!isValueNumber) {
        setSettings(prev => ({ ...prev, [key]: !value }));
      }

      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateExchangeRateValue = async (
    key: GeneralField.ExchangeRateValue,
    value: number,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      await updateExchangeRate({ value }).unwrap();

      setSettings(prev => ({ ...prev, [key]: value }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateBalanceExpiration = async (
    key: GeneralField.BalanceExpirationDays,
    days: number,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      await updateBalanceExpiration({ days }).unwrap();

      setSettings(prev => ({ ...prev, [key]: days }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  const handleUpdateCoreSettings = async (
    key: CoreSettingsKey,
    value: number,
  ) => {
    try {
      setIsLoading(prev => ({ ...prev, [key]: true }));

      await updateCoreSettings({ [key]: value }).unwrap();

      setSettings(prev => ({ ...prev, [key]: value }));

      openNotification({ message: notificationMessage[key] });
    } catch (error) {
      errorHandler({ error, openNotification });
    } finally {
      setIsLoading(prev => ({ ...prev, [key]: false }));
    }
  };

  return (
    <ContentBox $column $gap={12}>
      <Typography.H2>General</Typography.H2>

      <div>
        <SettingsItem
          title="Exchange rate"
          description="Set FM coin exchange rate"
        >
          <Input
            step={0.1}
            precision={1}
            isLoading={isLoading[GeneralField.ExchangeRateValue]}
            canUpdate={canSettingsUpdate && canExchangeRateUpdate}
            data={settings[GeneralField.ExchangeRateValue]}
            onSubmit={value =>
              handleUpdateExchangeRateValue(
                GeneralField.ExchangeRateValue,
                value,
              )
            }
          />
        </SettingsItem>

        <SettingsItem
          title="Virtual currency expiration time"
          description="Set the expiration date when the user topup the balance"
        >
          <Input
            isLoading={isLoading[GeneralField.BalanceExpirationDays]}
            canUpdate={canSettingsUpdate && canBalanceExpirationUpdate}
            data={settings[GeneralField.BalanceExpirationDays]}
            onSubmit={value =>
              handleUpdateBalanceExpiration(
                GeneralField.BalanceExpirationDays,
                value,
              )
            }
          />
        </SettingsItem>

        <SettingsItem
          title="Minimum top up (KWD)"
          description="Set the minimum to top up the balance"
        >
          <Input
            suffix="KWD"
            fixedDigitsPlaceholder={3}
            step={0.001}
            isLoading={isLoading[GeneralField.MinimumTopUpValue]}
            canUpdate={canSettingsUpdate}
            data={settings[GeneralField.MinimumTopUpValue]}
            onSubmit={value =>
              handleUpdateSettings(GeneralField.MinimumTopUpValue, value)
            }
          />
        </SettingsItem>

        <SettingsItem
          title="Minimum order (FM Coins)"
          description="Set the minimum coins required to proceed with the order for users with active coin package"
        >
          <Input
            suffix="FM Coins"
            fixedDigitsPlaceholder={3}
            step={0.001}
            isLoading={isLoading[GeneralField.MinimumOrderValue]}
            canUpdate={canSettingsUpdate}
            data={settings[GeneralField.MinimumOrderValue]}
            onSubmit={value =>
              handleUpdateSettings(GeneralField.MinimumOrderValue, value)
            }
          />
        </SettingsItem>

        <SettingsItem
          title="Minimum instant order rejection time (minutes)"
          description="Set a minimum order rejection time before starting order preparation for instant orders"
        >
          <Input
            suffix="minutes"
            step={1}
            min={1}
            isLoading={isLoading[GeneralField.InstantOrderCancellationTime]}
            canUpdate={canSettingsUpdate}
            data={settings[GeneralField.InstantOrderCancellationTime]}
            onSubmit={value =>
              handleUpdateCoreSettings(
                GeneralField.InstantOrderCancellationTime,
                value,
              )
            }
          />
        </SettingsItem>

        <SettingsItem
          title="Default order preparation time"
          description="The Order Preparation Time will be used to calculate and display the start time of the Estimated Arrival Time range on the mobile app"
        >
          <Input
            suffix="minutes"
            step={1}
            min={1}
            isLoading={isLoading[GeneralField.DefaultPreparationTime]}
            canUpdate={canSettingsUpdate}
            data={settings[GeneralField.DefaultPreparationTime]}
            onSubmit={value =>
              handleUpdateCoreSettings(
                GeneralField.DefaultPreparationTime,
                value,
              )
            }
          />
        </SettingsItem>

        <SettingsItem
          title="Default order delivery time"
          description="Set default order delivery time"
        >
          <Input
            suffix="minutes"
            step={1}
            min={1}
            isLoading={isLoading[GeneralField.DefaultDeliveryTime]}
            canUpdate={canSettingsUpdate}
            data={settings[GeneralField.DefaultDeliveryTime]}
            onSubmit={value =>
              handleUpdateCoreSettings(GeneralField.DefaultDeliveryTime, value)
            }
          />
        </SettingsItem>
      </div>
    </ContentBox>
  );
};
