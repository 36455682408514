// Lib
import { FC, ReactNode } from "react";
// Styled
import { EmptyIconContainer, EmptyStateWrapper, TitleThin } from "./styled";

interface EmptyStateProps {
  Icon: ReactNode;
  title: string;
}

export const EmptyState: FC<EmptyStateProps> = ({ Icon, title }) => {
  return (
    <EmptyStateWrapper>
      <EmptyIconContainer>{Icon}</EmptyIconContainer>
      <TitleThin>{title}</TitleThin>
    </EmptyStateWrapper>
  );
};
