// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useGetTrialPackageQuery,
  useUpdateTrialPackageMutation,
} from "rtkQuery/query/coinPackagesApi";
import {
  useGetAuthSettingsQuery,
  useUpdateAuthSettingsMutation,
} from "rtkQuery/query/authSettingsAPI";
// Hooks
import { useDebounce, useNotification, usePermissions } from "hooks";
// Constants
import { NOTIFICATIONS, rtkQueryParams } from "consts";
// Helpers
import { fixedDigitsValue } from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { CurrencyIcon } from "icons";
// Components
import { InputNumber, Switch } from "components/Form";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";

export const calculateTotalCoinsAmount = ({
  price,
  exchangeRateValue,
}: {
  price?: number;
  exchangeRateValue: number;
}) => {
  if (!exchangeRateValue) {
    return null;
  }

  if (!price) {
    return null;
  }

  return (
    <FlexContainer $align="center" $gap={4}>
      <Typography.Title>
        {fixedDigitsValue(price / exchangeRateValue, 3)}
      </Typography.Title>
      <CurrencyIcon />
    </FlexContainer>
  );
};

type trialPackageValuesState = {
  price: number;
  expiryDays: number;
  active: boolean;
};

interface TrialPackageProps {
  exchangeRateValue: number;
}

export const TrialPackage: FC<TrialPackageProps> = ({ exchangeRateValue }) => {
  const { canPackagesUpdate } = usePermissions();

  const { openNotification } = useNotification();

  const [updateTrialPackage] = useUpdateTrialPackageMutation();
  const [updateSettings, { isLoading: isUpdateSettingsLoading }] =
    useUpdateAuthSettingsMutation();

  const [trialPackageValues, setTrialPackageValues] =
    useState<trialPackageValuesState | null>(null);

  const debouncedTrialPackageValues = useDebounce(trialPackageValues, 1000);

  const {
    data: trialData,
    isLoading: trialLoading,
    error: trialError,
  } = useGetTrialPackageQuery(null, {
    ...rtkQueryParams,
  });

  const {
    data: settingsData,
    isLoading: settingsLoading,
    error: settingsError,
  } = useGetAuthSettingsQuery(null, {
    ...rtkQueryParams,
  });

  useEffect(() => {
    if (trialError) {
      errorHandler({ error: trialError, openNotification });
    }
  }, [trialError]);

  useEffect(() => {
    if (settingsError) {
      errorHandler({ error: settingsError, openNotification });
    }
  }, [settingsError]);

  useEffect(() => {
    if (!trialData) return;

    const { price, expiryDays } = trialData || {};

    setTrialPackageValues(prev => ({ ...prev, price, expiryDays }));
  }, [trialData]);

  useEffect(() => {
    if (!settingsData) return;

    const { isTrialPackageAllowed } = settingsData || {};

    setTrialPackageValues(prev => ({ ...prev, active: isTrialPackageAllowed }));
  }, [trialData]);

  const isChanged =
    debouncedTrialPackageValues?.active !==
      settingsData?.isTrialPackageAllowed ||
    debouncedTrialPackageValues?.price !== trialData?.price ||
    debouncedTrialPackageValues?.expiryDays !== trialData?.expiryDays;

  const handleUpdateTrialPackage = async () => {
    try {
      if (
        debouncedTrialPackageValues?.price !== trialData?.price ||
        debouncedTrialPackageValues?.expiryDays !== trialData?.expiryDays
      ) {
        await updateTrialPackage({
          id: trialData?.id,
          ...debouncedTrialPackageValues,
        });
      }

      if (
        debouncedTrialPackageValues?.active !==
        settingsData?.isTrialPackageAllowed
      ) {
        await updateSettings({
          isTrialPackageAllowed: debouncedTrialPackageValues.active,
        });
      }

      openNotification({ message: NOTIFICATIONS.TRIAL_PACKAGE_UPDATED });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  useEffect(() => {
    if (!debouncedTrialPackageValues) return;
    if (!Object?.entries(debouncedTrialPackageValues)?.length) return;
    if (!isChanged) return;

    handleUpdateTrialPackage();
  }, [debouncedTrialPackageValues]);

  const handlePriceChange = (price: number) => {
    setTrialPackageValues(prev => ({ ...prev, price }));
  };

  const handleExpiryDaysChange = (expiryDays: number) => {
    setTrialPackageValues(prev => ({ ...prev, expiryDays }));
  };

  const handleActiveChange = (active: boolean) => {
    setTrialPackageValues(prev => ({ ...prev, active }));
  };

  const totalCoinsAmount = calculateTotalCoinsAmount({
    price: trialPackageValues?.price,
    exchangeRateValue,
  });

  return (
    <ContentBox $column>
      <FlexContainer $align="center" $justify="space-between">
        <Typography.H2>Trial package</Typography.H2>

        <Switch
          label="Active"
          loading={settingsLoading || isUpdateSettingsLoading}
          disabled={!canPackagesUpdate}
          checked={trialPackageValues?.active}
          onChange={active => handleActiveChange(active)}
        />
      </FlexContainer>

      <InputsGridContainer>
        <InputNumber
          label="KD"
          min={0.1}
          step={0.001}
          precision={3}
          disabled={!canPackagesUpdate || trialLoading}
          value={trialPackageValues?.price}
          onChange={(price: number) => handlePriceChange(price)}
        />

        <InputNumber
          label="Expiry (days)"
          min={0}
          step={1}
          precision={0}
          disabled={!canPackagesUpdate || trialLoading}
          value={trialPackageValues?.expiryDays}
          onChange={(expiryDays: number) => handleExpiryDaysChange(expiryDays)}
        />
      </InputsGridContainer>

      <FlexContainer $align="center" $justify="space-between">
        <Typography.TitleThin>Amount of coins:</Typography.TitleThin>

        {totalCoinsAmount}
      </FlexContainer>
    </ContentBox>
  );
};
