import { generateUniqueId } from "helpers/dataHelpers";
import {
  MenuItem,
  MenuProductResponseDto,
  ProductResponseDto,
  RecommendedMenuItem,
} from "types/recommended";

export const createTableData = ({
  allMenuProducts,
  recommendedProducts,
}: {
  allMenuProducts: MenuProductResponseDto[];
  recommendedProducts: ProductResponseDto[];
}): MenuItem[] => {
  const data: MenuItem[] = allMenuProducts.map(product => {
    const recommended = recommendedProducts.find(
      el => el.productId === product.id,
    );

    if (recommended) {
      const {
        productId,
        name,
        fiatCentsPrice,
        updatedAt,
        recommendedItems,
        totalFmcCentsPrice,
      } = recommended || {};
      return {
        id: productId,
        name,
        totalFmcCentsPrice: totalFmcCentsPrice,
        totalFiatCentsPrice: fiatCentsPrice,
        updatedAt,
        recommendedItems: recommendedItems?.map(children => ({
          id: `dnd-id-${generateUniqueId()}`,
          itemId: children.productId,
          removeId: children.id,
          parentId: productId,
          parentName: name,
          name: children.name,
          order: children.order,
          totalFmcCentsPrice: children.totalFmcCentsPrice,
          totalFiatCentsPrice: children.fiatCentsPrice,
        })),
      };
    }

    return {
      id: product.id,
      removeId: "id,",
      name: product.name,
      totalFmcCentsPrice: product.totalFmcCentsPrice,
      totalFiatCentsPrice: product.fiatCentsPrice,
      updatedAt: null,
      recommendedItems: [],
    };
  });

  return data;
};

export const addChildrenItems = ({
  prev,
  parentId,
  items,
}: {
  prev: MenuItem[];
  parentId: string;
  items: MenuProductResponseDto[];
}): MenuItem[] => {
  const itemIndex = prev.findIndex(el => el.id === parentId);

  prev[itemIndex].recommendedItems = prev[itemIndex].recommendedItems.concat(
    items
      .map(item => ({
        id: `dnd-id-${generateUniqueId()}`,
        removeId: "removeId",
        itemId: item.id,
        parentId: prev[itemIndex].id,
        parentName: prev[itemIndex].name,
        name: item.name,
        order: 0,
        totalFmcCentsPrice: item.fiatCentsPrice,
        totalFiatCentsPrice: item.fiatCentsPrice,
      }))
      .map((el, i) => ({ ...el, order: i })),
  );

  return prev;
};

export const removeChild = ({
  prev,
  item,
}: {
  prev: MenuItem[];
  item: RecommendedMenuItem;
}): MenuItem[] => {
  const index = prev.findIndex(el => el.id === item.parentId);

  prev[index].recommendedItems = prev[index].recommendedItems
    .filter(i => i.itemId !== item.itemId)
    .map((el, i) => ({ ...el, order: i }));

  return prev;
};

export const getFiltered = ({
  page,
  limit,
  search,
  tableData,
}: {
  page: number;
  limit: number;
  search: string;
  tableData: MenuItem[];
}): { itemsToShow: MenuItem[]; totalCount: number } => {
  let data = tableData;

  if (search) {
    data = tableData.filter(p =>
      p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    );
  }

  const indexOfLastItem = page * limit;
  const indexOfFirstItem = indexOfLastItem - limit;
  const itemsToShow = data.slice(indexOfFirstItem, indexOfLastItem);

  return { itemsToShow, totalCount: data.length };
};
