import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";
import { UsersFilterNames, UsersFilterTypes } from "types/users";

import {
  DeliveryProviderLabels,
  DeliveryProviders,
} from "types/deliveryProviders";

import { Avatar } from "components";
import { CallBlackIcon, TextFieldsIcon } from "icons";
import { FlexContainer } from "styled/Box";

export const getUsersSortBy = (sortBy: string) => {
  switch (sortBy) {
    case "fullName":
      return "firstName";
    case "role":
      return "roleName";

    default:
      return sortBy;
  }
};

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Full name",
    dataIndex: "fullName",
    key: "fullName",
    sorter: true,
    render: (name: string | null) =>
      name ? (
        <FlexContainer $align="center" $gap={8}>
          <Avatar name={name} /> {name}
        </FlexContainer>
      ) : null,
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    sorter: true,
  },
  {
    title: "Provider",
    dataIndex: "deliveryProvider",
    key: "deliveryProvider",
    sorter: true,
  },
];

export const providersDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: DeliveryProviders.deliverect,
    label: DeliveryProviderLabels[DeliveryProviders.deliverect],
  },
  {
    type: DeliveryProviders.loop,
    label: DeliveryProviderLabels[DeliveryProviders.loop],
  },
];

export const filtersList: FilterOption[] = [
  {
    key: UsersFilterTypes.email,
    icon: <TextFieldsIcon />,
    label: UsersFilterNames[UsersFilterTypes.email],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: UsersFilterTypes.phoneNumber,
    icon: <CallBlackIcon />,
    label: UsersFilterNames[UsersFilterTypes.phoneNumber],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: UsersFilterTypes.deliveryProvider,
    label: UsersFilterNames[UsersFilterTypes.deliveryProvider],
    field: FilterField.SELECT,
    options: providersDropdownFields,
  },
];
