import { FlexContainer } from "styled/Box";

export const Logo = () => (
  <FlexContainer $column $align="center" $justify="center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97"
      height="18"
      viewBox="0 0 97 18"
      fill="none"
    >
      <path
        d="M26.6792 15.0217C25.0086 16.6186 22.6511 17.4539 20.2199 17.4539C17.7886 17.4539 15.43 16.6186 13.7594 15.0217C12.0152 13.3511 11.1799 11.0682 11.1799 8.9063C11.1799 6.74441 12.0152 4.41104 13.7594 2.76568C15.43 1.16977 17.7876 0.334473 20.2199 0.334473C22.6521 0.334473 25.0086 1.16977 26.6792 2.76568C28.4235 4.41209 29.2588 6.72021 29.2588 8.9063C29.2588 11.0924 28.4235 13.3521 26.6792 15.0217ZM16.9039 5.76182C16.0202 6.62132 15.6026 7.82482 15.6026 8.97994C15.6026 10.4286 16.2895 11.4354 16.9039 12.0255C17.6898 12.7872 18.7954 13.3269 20.2199 13.3269C21.6443 13.3269 22.75 12.8114 23.5106 12.0497C24.2722 11.2881 24.8372 10.1582 24.8372 8.90525C24.8372 7.6523 24.2722 6.49823 23.5106 5.76077C22.6016 4.90127 21.3487 4.45943 20.2199 4.45943C19.091 4.45943 17.7876 4.87708 16.9039 5.76077V5.76182Z"
        fill="#002E07"
      />
      <path
        d="M37.6107 0.702637C38.8384 0.702637 40.4102 0.899367 41.6147 2.15232C42.5479 3.10965 43.0149 4.53407 43.0149 5.83647C43.0149 7.2367 42.5247 8.46442 41.7136 9.25027C41.2192 9.72578 40.5922 10.054 39.9809 10.2465C39.7327 10.3244 39.6401 10.6253 39.7989 10.8315L44.1217 16.4776C44.3121 16.7269 44.1343 17.0857 43.8208 17.0857H39.5117C39.3834 17.0857 39.2645 17.0205 39.194 16.9132L35.9244 11.8708C35.7192 11.5542 35.229 11.6994 35.229 12.077V16.708C35.229 16.9174 35.0596 17.0867 34.8502 17.0867H31.3576C31.1482 17.0867 30.9788 16.9174 30.9788 16.708V1.08241C30.9788 0.873062 31.1482 0.70369 31.3576 0.70369H37.6118L37.6107 0.702637ZM35.2279 8.04676H36.039C36.6776 8.04676 37.5855 7.87423 38.102 7.38294C38.4208 7.06418 38.6428 6.52344 38.6428 6.00795C38.6428 5.49246 38.446 4.97593 38.0778 4.63297C37.6844 4.23952 36.9732 3.9944 36.039 3.9944H35.2279V8.04781V8.04676Z"
        fill="#002E07"
      />
      <path
        d="M54.3273 4.31324H50.0246C49.8152 4.31324 49.6458 4.48262 49.6458 4.69197V6.66135C49.6458 6.8707 49.8152 7.04007 50.0246 7.04007H54.058C54.2673 7.04007 54.4367 7.20945 54.4367 7.4188V10.2729C54.4367 10.4823 54.2673 10.6516 54.058 10.6516H50.0246C49.8152 10.6516 49.6458 10.821 49.6458 11.0304V13.0986C49.6458 13.308 49.8152 13.4774 50.0246 13.4774H54.3273C54.5367 13.4774 54.706 13.6467 54.706 13.8561V16.7092C54.706 16.9185 54.5367 17.0879 54.3273 17.0879H45.7744C45.5651 17.0879 45.3957 16.9185 45.3957 16.7092V1.08356C45.3957 0.874206 45.5651 0.704834 45.7744 0.704834H54.3273C54.5367 0.704834 54.706 0.874206 54.706 1.08356V3.93663C54.706 4.14598 54.5367 4.31535 54.3273 4.31535V4.31324Z"
        fill="#002E07"
      />
      <path
        d="M59.4422 0.942423L62.9286 9.78778C63.0538 10.1065 63.5051 10.1076 63.6324 9.78883L67.163 0.94137C67.2208 0.797244 67.3597 0.703613 67.5144 0.703613H71.3037C71.5751 0.703613 71.7582 0.980295 71.6519 1.23067L64.9979 16.8563C64.939 16.9962 64.8012 17.0867 64.6497 17.0867H61.8356C61.683 17.0867 61.5452 16.9951 61.4863 16.8552L54.9259 1.22962C54.8207 0.980294 55.0048 0.704666 55.2752 0.704666H59.0909C59.2466 0.704666 59.3854 0.799338 59.4433 0.944516L59.4422 0.942423Z"
        fill="#002E07"
      />
      <path
        d="M81.543 4.31324H77.2402C77.0309 4.31324 76.8615 4.48262 76.8615 4.69197V6.66135C76.8615 6.8707 77.0309 7.04007 77.2402 7.04007H81.2736C81.483 7.04007 81.6524 7.20945 81.6524 7.4188V10.2729C81.6524 10.4823 81.483 10.6516 81.2736 10.6516H77.2402C77.0309 10.6516 76.8615 10.821 76.8615 11.0304V13.0986C76.8615 13.308 77.0309 13.4774 77.2402 13.4774H81.543C81.7523 13.4774 81.9217 13.6467 81.9217 13.8561V16.7092C81.9217 16.9185 81.7523 17.0879 81.543 17.0879H72.9901C72.7807 17.0879 72.6113 16.9185 72.6113 16.7092V1.08356C72.6113 0.874206 72.7807 0.704834 72.9901 0.704834H81.543C81.7523 0.704834 81.9217 0.874206 81.9217 1.08356V3.93663C81.9217 4.14598 81.7523 4.31535 81.543 4.31535V4.31324Z"
        fill="#002E07"
      />
      <path
        d="M90.2726 0.702637C91.5003 0.702637 93.072 0.899367 94.2766 2.15232C95.2097 3.10965 95.6768 4.53407 95.6768 5.83647C95.6768 7.2367 95.1866 8.46442 94.3755 9.25027C93.881 9.72578 93.254 10.054 92.6428 10.2465C92.3945 10.3244 92.3019 10.6253 92.4608 10.8315L96.7835 16.4776C96.9739 16.7269 96.7962 17.0857 96.4827 17.0857H92.1736C92.0453 17.0857 91.9264 17.0205 91.8559 16.9132L88.5862 11.8708C88.3811 11.5542 87.8908 11.6994 87.8908 12.077V16.708C87.8908 16.9174 87.7215 17.0867 87.5121 17.0867H84.0194C83.8101 17.0867 83.6407 16.9174 83.6407 16.708V1.08241C83.6407 0.873062 83.8101 0.70369 84.0194 0.70369H90.2737L90.2726 0.702637ZM87.8898 8.04676H88.7009C89.3395 8.04676 90.2474 7.87423 90.7639 7.38294C91.0827 7.06418 91.3046 6.52344 91.3046 6.00795C91.3046 5.49246 91.1079 4.97593 90.7397 4.63297C90.3463 4.23952 89.6351 3.9944 88.7009 3.9944H87.8898V8.04781V8.04676Z"
        fill="#002E07"
      />
      <path
        d="M11.243 0.655273H3.03728C2.85212 0.655273 2.69327 0.788881 2.66276 0.971931L0.00536464 16.6565C-0.03356 16.8879 0.144231 17.0994 0.379882 17.0994H4.19975C4.38491 17.0994 4.54376 16.9657 4.57427 16.7827L5.61472 10.64H9.67866C9.86381 10.64 10.0216 10.5064 10.0532 10.3233L10.5392 7.45867C10.5781 7.22723 10.4003 7.01577 10.1647 7.01577H6.22909L6.69304 4.27948H10.757C10.9421 4.27948 11.0999 4.14587 11.1315 3.96282L11.6175 1.09818C11.6565 0.866737 11.4787 0.655273 11.243 0.655273Z"
        fill="#002E07"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="18"
      viewBox="0 0 66 18"
      fill="none"
    >
      <path
        d="M0.813776 16.5245L3.46171 0.898857C3.49222 0.716858 3.65002 0.583252 3.83517 0.583252H7.45411C7.61086 0.583252 7.75183 0.680045 7.80759 0.827327L10.687 8.42078C10.809 8.74269 11.2635 8.74796 11.3918 8.42815L14.4469 0.821009C14.5047 0.677934 14.6436 0.583252 14.7982 0.583252H18.4666C18.6539 0.583252 18.8127 0.72002 18.8411 0.905175L21.184 16.5308C21.2187 16.7591 21.0409 16.9653 20.8095 16.9653H17.3336C17.1432 16.9653 16.9822 16.8232 16.958 16.6349L15.9754 8.93311C15.926 8.54912 15.401 8.47759 15.2506 8.83422L11.9389 16.7328C11.8799 16.8737 11.7421 16.9653 11.5896 16.9653H10.4029C10.2483 16.9653 10.1094 16.8716 10.0515 16.7275L6.88497 8.83001C6.74084 8.47127 6.21588 8.53334 6.15907 8.91522L5.01238 16.6412C4.98503 16.8264 4.82512 16.9642 4.63786 16.9642H1.18725C0.952647 16.9642 0.774851 16.7538 0.813776 16.5224V16.5245Z"
        fill="#002E07"
      />
      <path
        d="M37.7301 14.9023C36.0595 16.4993 33.7019 17.3346 31.2707 17.3346C28.8395 17.3346 26.4809 16.4993 24.8103 14.9023C23.066 13.2317 22.2307 10.9488 22.2307 8.78692C22.2307 6.62502 23.066 4.29165 24.8103 2.6463C26.4809 1.05039 28.8384 0.215088 31.2707 0.215088C33.703 0.215088 36.0595 1.05039 37.7301 2.6463C39.4743 4.2927 40.3096 6.60083 40.3096 8.78692C40.3096 10.973 39.4743 13.2327 37.7301 14.9023ZM27.9547 5.64244C27.071 6.50194 26.6534 7.70545 26.6534 8.86057C26.6534 10.3092 27.3404 11.316 27.9547 11.9061C28.7406 12.6678 29.8463 13.2075 31.2707 13.2075C32.6951 13.2075 33.8008 12.692 34.5614 11.9303C35.3231 11.1687 35.888 10.0388 35.888 8.78586C35.888 7.53291 35.3231 6.37885 34.5614 5.64139C33.6525 4.78189 32.3995 4.34004 31.2707 4.34004C30.1419 4.34004 28.8384 4.75769 27.9547 5.64139V5.64244Z"
        fill="#002E07"
      />
      <path
        d="M48.6606 0.583252C49.8883 0.583252 51.46 0.779982 52.6646 2.03293C53.5977 2.99027 54.0648 4.4147 54.0648 5.7171C54.0648 7.11733 53.5746 8.34503 52.7635 9.13089C52.269 9.6064 51.642 9.93462 51.0308 10.1271C50.7825 10.205 50.6899 10.5059 50.8488 10.7121L55.1715 16.3582C55.3619 16.6076 55.1842 16.9663 54.8707 16.9663H50.5616C50.4332 16.9663 50.3144 16.9011 50.2439 16.7938L46.9742 11.7515C46.7691 11.4348 46.2788 11.58 46.2788 11.9577V16.5886C46.2788 16.798 46.1095 16.9674 45.9001 16.9674H42.4074C42.1981 16.9674 42.0287 16.798 42.0287 16.5886V0.963028C42.0287 0.753677 42.1981 0.584305 42.4074 0.584305H48.6616L48.6606 0.583252ZM46.2778 7.92739H47.0889C47.7275 7.92739 48.6353 7.75485 49.1519 7.26356C49.4707 6.9448 49.6926 6.40407 49.6926 5.88858C49.6926 5.37309 49.4959 4.85654 49.1277 4.51358C48.7342 4.12013 48.0231 3.87502 47.0889 3.87502H46.2778V7.92844V7.92739Z"
        fill="#002E07"
      />
      <path
        d="M65.3782 4.19387H61.0754C60.8661 4.19387 60.6967 4.36324 60.6967 4.5726V6.54197C60.6967 6.75132 60.8661 6.92069 61.0754 6.92069H65.1088C65.3182 6.92069 65.4876 7.09006 65.4876 7.29941V10.1535C65.4876 10.3629 65.3182 10.5323 65.1088 10.5323H61.0754C60.8661 10.5323 60.6967 10.7016 60.6967 10.911V12.9793C60.6967 13.1886 60.8661 13.358 61.0754 13.358H65.3782C65.5875 13.358 65.7569 13.5274 65.7569 13.7367V16.5898C65.7569 16.7991 65.5875 16.9685 65.3782 16.9685H56.8253C56.6159 16.9685 56.4465 16.7991 56.4465 16.5898V0.964173C56.4465 0.754821 56.6159 0.585449 56.8253 0.585449H65.3782C65.5875 0.585449 65.7569 0.754821 65.7569 0.964173V3.81724C65.7569 4.02659 65.5875 4.19597 65.3782 4.19597V4.19387Z"
        fill="#002E07"
      />
    </svg>
  </FlexContainer>
);
