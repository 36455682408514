import { TextFieldsIcon } from "icons";
import { CallBlackIcon } from "icons/CallBlackIcon";

import {
  EFilterDateLabel,
  EFilterDateValue,
  ELoyaltyLevelLabel,
  ELoyaltyLevelValue,
} from "types/common";
import { CustomersFilterNames, CustomersFilterTypes } from "types/customers";
import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";

export const columns = [
  {
    title: "Customer name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
];

export const dateDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: EFilterDateValue.TODAY,
    label: EFilterDateLabel[EFilterDateValue.TODAY],
  },
  {
    type: EFilterDateValue.YESTERDAY,
    label: EFilterDateLabel[EFilterDateValue.YESTERDAY],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_7_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_7_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_30_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_30_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_90_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_90_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_12_MONTHS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_12_MONTHS],
  },
  {
    type: EFilterDateValue.CUSTOM,
    label: EFilterDateLabel[EFilterDateValue.CUSTOM],
  },
];

export const loyaltyLevelDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: ELoyaltyLevelValue.BRONZE,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.BRONZE],
  },
  {
    type: ELoyaltyLevelValue.SILVER,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.SILVER],
  },
  {
    type: ELoyaltyLevelValue.GOLD,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.GOLD],
  },
  {
    type: ELoyaltyLevelValue.PLATINUM,
    label: ELoyaltyLevelLabel[ELoyaltyLevelValue.PLATINUM],
  },
];

export const filtersList: FilterOption[] = [
  {
    key: CustomersFilterTypes.firstName,
    icon: <TextFieldsIcon />,
    label: CustomersFilterNames[CustomersFilterTypes.firstName],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: CustomersFilterTypes.lastName,
    icon: <TextFieldsIcon />,
    label: CustomersFilterNames[CustomersFilterTypes.lastName],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: CustomersFilterTypes.phoneNumber,
    icon: <CallBlackIcon />,
    label: CustomersFilterNames[CustomersFilterTypes.phoneNumber],
    field: FilterField.INPUT_SELECT,
  },
  {
    key: CustomersFilterTypes.createdAtAfter,
    label: CustomersFilterNames[CustomersFilterTypes.createdAtAfter],
    field: FilterField.MULTISELECT_SINGLE_DATE,
    options: dateDropdownFields,
  },
];
