// Lib
import { FC } from "react";
// Types
import { CustomerProfileResponseDto } from "types/customers";
// Theme
import { theme } from "theme";
// Constants
import { MONTH_DAY_FUll_YEAR_DATE } from "consts";
// Helpers
import { convertStatusToText, dateTransform } from "helpers/dataHelpers";
// Icons
import { CancelIcon, CheckCircleIcon } from "icons";
// Components
import { SummaryField, Badge } from "components";
import { History } from "../History";
// Styled
import { ContentBox, FlexContainer, SummaryItemsGrid } from "styled/Box";
import { Typography } from "styled/Typography";
import { Wrapper, MainInfoWrapper } from "../styled";

const getHealthQuestionnaireValue = (value: boolean) => (
  <FlexContainer $align="center" $gap={4}>
    {value ? (
      <CheckCircleIcon fill={theme.color.text.tertiary} />
    ) : (
      <CancelIcon fill={theme.color.text.tertiary} />
    )}
    <span>{value ? "Yes" : "No"}</span>
  </FlexContainer>
);

interface HealthDataTabProps {
  isCustomerDataLoading: boolean;
  customerData: CustomerProfileResponseDto;
}

export const HealthDataTab: FC<HealthDataTabProps> = ({
  isCustomerDataLoading,
  customerData,
}) => {
  return (
    <>
      <Wrapper>
        <MainInfoWrapper $column $gap={16}>
          <ContentBox $column $gap={20}>
            <Typography.H2>Health Data</Typography.H2>

            <SummaryItemsGrid>
              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Is health data questionnaire skipped"
                value={getHealthQuestionnaireValue(
                  customerData?.isHealthDataQuestionnaireSkipped,
                )}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Activity level"
                value={
                  !customerData?.healthData?.activityLevel ? null : (
                    <Badge
                      capitalize
                      title={convertStatusToText(
                        customerData?.healthData?.activityLevel,
                      )}
                    />
                  )
                }
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Goal"
                value={
                  !customerData?.healthData?.goal ? null : (
                    <Badge
                      capitalize
                      title={convertStatusToText(
                        customerData?.healthData?.goal,
                      )}
                    />
                  )
                }
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Height (cm)"
                value={customerData?.healthData?.height}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Weight (kg)"
                value={customerData?.healthData?.weight}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Kcal deficit per week"
                value={customerData?.healthData?.perWeekWeightChange}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Updated at"
                value={dateTransform({
                  date: customerData?.healthData?.updatedAt,
                  format: MONTH_DAY_FUll_YEAR_DATE,
                })}
              />
            </SummaryItemsGrid>
          </ContentBox>

          <ContentBox $column $gap={20}>
            <Typography.H2>Health Calculations</Typography.H2>

            <SummaryItemsGrid>
              <SummaryField
                isLoading={isCustomerDataLoading}
                label="BMR"
                value={customerData?.healthCalculations?.bmr}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Recommended daily intake"
                value={
                  customerData?.healthCalculations
                    ?.recommendedDailyCaloriesIntake
                }
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="TDEE"
                value={customerData?.healthCalculations?.tdee}
              />

              <SummaryField
                isLoading={isCustomerDataLoading}
                label="Updated at"
                value={dateTransform({
                  date: customerData?.healthCalculations?.updatedAt,
                  format: MONTH_DAY_FUll_YEAR_DATE,
                })}
              />
            </SummaryItemsGrid>
          </ContentBox>
        </MainInfoWrapper>

        <History
          isCustomerDataLoading={isCustomerDataLoading}
          customerData={customerData}
        />
      </Wrapper>
    </>
  );
};
