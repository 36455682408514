import { NOTIFICATIONS } from "consts/notifications";

export enum ScheduleOrder {
  IsScheduleOrders = "isScheduleOrders",
  OrderCancelTime = "orderCancelTime",
  ScheduleOrderTime = "scheduleOrderTime",
  OrderAppearingTime = "orderAppearingTime",
  CancelTimeAfterStart = "cancelTimeAfterStart",
  TimeToNextSlot = "timeToNextSlot",
}

export type StateValues = {
  [ScheduleOrder.IsScheduleOrders]: undefined | boolean;
  [ScheduleOrder.OrderCancelTime]: undefined | number;
  [ScheduleOrder.ScheduleOrderTime]: undefined | number;
  [ScheduleOrder.OrderAppearingTime]: undefined | number;
  [ScheduleOrder.CancelTimeAfterStart]: undefined | number;
  [ScheduleOrder.TimeToNextSlot]: undefined | number;
};

export const initialState: StateValues = {
  [ScheduleOrder.IsScheduleOrders]: undefined,
  [ScheduleOrder.OrderCancelTime]: undefined,
  [ScheduleOrder.ScheduleOrderTime]: undefined,
  [ScheduleOrder.OrderAppearingTime]: undefined,
  [ScheduleOrder.CancelTimeAfterStart]: undefined,
  [ScheduleOrder.TimeToNextSlot]: undefined,
};

export type LoadingStateValues = Record<keyof StateValues, boolean>;

export const initialLoadingState: LoadingStateValues = {
  [ScheduleOrder.IsScheduleOrders]: false,
  [ScheduleOrder.OrderCancelTime]: false,
  [ScheduleOrder.ScheduleOrderTime]: false,
  [ScheduleOrder.OrderAppearingTime]: false,
  [ScheduleOrder.CancelTimeAfterStart]: false,
  [ScheduleOrder.TimeToNextSlot]: false,
};

export type ConfirmReferralModalType = {
  key: ScheduleOrder;
  value: boolean | number;
};

export const notificationMessage = {
  [ScheduleOrder.IsScheduleOrders]: NOTIFICATIONS.IS_SCHEDULE_ORDERS,
  [ScheduleOrder.OrderCancelTime]: NOTIFICATIONS.SCHEDULE_ORDER_CANCEL_TIME,
  [ScheduleOrder.ScheduleOrderTime]: NOTIFICATIONS.SCHEDULE_ORDER_TIME,
  [ScheduleOrder.OrderAppearingTime]: NOTIFICATIONS.SCHEDULE_ORDER_TIME_COONFIG,
  [ScheduleOrder.CancelTimeAfterStart]:
    NOTIFICATIONS.SCHEDULE_ORDER_TIME_COONFIG,
  [ScheduleOrder.TimeToNextSlot]: NOTIFICATIONS.SCHEDULE_ORDER_TIME_COONFIG,
};
