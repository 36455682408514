import { Tooltip } from "components";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "PLU",
    dataIndex: "plu",
    key: "plu",
    align: "right" as const,
    sorter: true,
  },
  {
    title: "Price",
    dataIndex: "totalFmcCentsPrice",
    key: "totalFmcCentsPrice",
    align: "right" as const,
    sorter: true,
    render: (value: string) => `${value} KWD`,
  },
];

export const mobileColumns = columns.map(column =>
  column.key === "name"
    ? {
        ...column,
        render: (name: string) => (
          <Tooltip title={name} trigger={"click"}>
            {name}
          </Tooltip>
        ),
      }
    : column,
);
