export enum TabKey {
  General = "General",
  Scheduled = "Scheduled",
  Talabat = "Talabat",
}

export const pageSizeOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 500,
    label: "500",
  },
];
