export const NOTIFICATIONS = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  BALANCE_UPDATED: "Balance updated successfully",
  BALANCE_EXPIRED_DATE_UPDATED: "Balance expired date updated",
  FRIENDS_CODE_SHARING_UPDATED: "Invite friends code sharing updated",
  IS_WAITING_LIST_UPDATED: "Waiting list configuration updated",
  IS_COUPONS_UPDATED: "Coupons configuration updated",
  IS_MENU_ITEMS_GRID_UPDATED: "Display menu items configuration updated",
  ACCESS_APP_INVITATION_UPDATED: "Invitation to access app updated",
  EXCHANGE_RATE_UPDATED: "Invitation to access app updated",
  BALANCE_EXPIRATION_DAYS_UPDATED: "Virtual currency expiration time updated",
  MIN_ORDER_COINS_UPDATED: "Minimum order (FM coins) updated",
  MIN_TOP_UP_UPDATED: "Minimum top up updated",
  ORDER_CANCEL_TIME:
    "Minimum instant order rejection time successfully updated",
  AVAILABLE_LOCATIONS: "Available Delivery Areas updated",
  TRIAL_PACKAGE_UPDATED: "Trial package successfully updated",
  PACKAGE_RULE_CREATED: "Rule successfully created",
  PACKAGE_RULE_UPDATED: "Rule successfully updated",
  PACKAGE_RULE_DELETED: "Rule successfully deleted",
  PACKAGE_CREATED: "Package successfully created",
  PACKAGE_UPDATED: "Package successfully updated",
  PACKAGE_DELETED: "Package successfully deleted",
  COIN_CONVERSION_UPDATED: "Coin conversion updated",
  ROLE_CREATED: "Role successfully added",
  ROLE_UPDATED: "Role successfully updated",
  ROLE_DELETE: "Role successfully deleted",
  USER_CREATE: "User successfully added",
  USER_UPDATE: "User successfully updated",
  CUSTOMER_UPDATE: "Customer successfully updated",
  USER_DELETE: "User successfully deleted",
  TRANSACTION_REFUND: "Balance has been successfully refunded",
  LOCATION_UPDATE: "Area successfully updated",
  ORDER_UPDATE: "Order successfully updated",
  IS_CASH_PAYMENT: "Cash Payment configuration updated",
  PAYMENT_DISCOUNT: "Discount updated",
  PAYMENT_DELIVERY_FEE: "Delivery Fee updated",
  PAYMENT_MINIMUM_ORDER: "Minimum Order updated",
  IS_REFERRAL_BONUS: "Referral Links configuration updated",
  REFERRAL_BONUS: "Referral Bonus updated",
  REFERRER_BONUS: "Referrer Bonus updated",
  COUPON_CREATED: "Coupon successfully created",
  COUPON_UPDATED: "Coupon successfully updated",
  COUPON_DELETE: "Coupon successfully deleted",
  IS_SCHEDULE_ORDERS: "Order schedule configuration updated",
  SCHEDULE_ORDER_CANCEL_TIME:
    "Minimum scheduled order rejection time successfully updated",
  SCHEDULE_ORDER_TIME: "Maximum available order time successfully updated",
  SCHEDULE_ORDER_TIME_COONFIG: "Order time configuration successfully updated",
  IS_APPLEPAY_UPDATED: "ApplePay payments configuration updated",
  IS_TABBY_UPDATED: "Tabby payments configuration updated",
  IS_TALY_UPDATED: "Taly payments configuration updated",
  IS_TAMARA_UPDATED: "Tamara payments configuration updated",
  IS_APP_CONFIG_UPDATED: "Application configuration updated",
  IS_DELIVERY_FEE_EXPIRED:
    "Delivery Fee (expired FM Coins) configuration updated",
  DEFAULT_DELIVERY_TIME: "Default delivery time successfully updated",
  DEFAULT_PREPARATION_TIME: "Default preparation time successfully updated",
};
