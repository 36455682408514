import { endpoints } from "consts";
import { generateRequestUrl } from "helpers/dataHelpers";
import { UrlQueryParams } from "types/common";

export const reviewsUrlBuilder = {
  getReviewsList: ({ query }: { query?: UrlQueryParams }): string => {
    if (!query) {
      return endpoints.reviews;
    }
    return generateRequestUrl(`${endpoints.reviews}`, query);
  },
};
