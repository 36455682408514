// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  GetLocationResponseDto,
  UpdateLocationRequestDto,
} from "types/locations";
// Utils
import { coreQuery } from "utils/baseQuery";

import { endpoints } from "consts";
import { locationsUpdatePayloadMapper } from "utils/mappers";
import {
  CreateLocationProvidersRequestDto,
  GetLocationProvidersResponseDto,
  UpdateLocationProvidersRequestDto,
} from "types/deliveryProviders";

export const locationsAPI = createApi({
  reducerPath: "locationsAPI",
  baseQuery: coreQuery(),
  tagTypes: ["locations", "location", "location-providers"],

  endpoints: build => ({
    getLocations: build.query<GetLocationResponseDto[], void>({
      query: () => ({
        url: endpoints.locations,
        method: "GET",
      }),

      providesTags: ["locations"],
    }),
    getLocation: build.query<GetLocationResponseDto, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.locations}/${id}`,
        method: "GET",
      }),
    }),
    updateLocation: build.mutation<
      GetLocationResponseDto,
      UpdateLocationRequestDto
    >({
      query: data => ({
        url: `${endpoints.locations}/${data.id}`,
        method: "PATCH",
        data: locationsUpdatePayloadMapper(data),
      }),
      invalidatesTags: ["locations", "location"],
    }),
    updateZoneLocation: build.mutation<
      GetLocationResponseDto,
      UpdateLocationRequestDto
    >({
      query: data => ({
        url: `${endpoints.locations}/${data.id}`,
        method: "PATCH",
        data: locationsUpdatePayloadMapper(data),
      }),
    }),
    getLocationProviders: build.query<GetLocationProvidersResponseDto[], void>({
      query: () => ({
        url: `${endpoints.locations}/providers`,
        method: "GET",
      }),

      providesTags: ["location-providers"],
    }),
    createLocationProviders: build.mutation<
      GetLocationProvidersResponseDto[],
      CreateLocationProvidersRequestDto
    >({
      query: data => ({
        url: `${endpoints.locations}/providers`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["location-providers"],
    }),
    updateLocationProviders: build.mutation<
      GetLocationProvidersResponseDto[],
      UpdateLocationProvidersRequestDto
    >({
      query: ({ id, ...data }) => ({
        url: `${endpoints.locations}/providers/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["location-providers"],
    }),
    deleteLocationProviders: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${endpoints.locations}/providers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["location-providers"],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useGetLocationQuery,
  useUpdateLocationMutation,
  useUpdateZoneLocationMutation,
  useGetLocationProvidersQuery,
  useCreateLocationProvidersMutation,
  useUpdateLocationProvidersMutation,
  useDeleteLocationProvidersMutation,
} = locationsAPI;
