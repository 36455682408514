// Lib
import { FC, useEffect } from "react";
import { fromLonLat } from "ol/proj";
import { OSM } from "ol/source";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification } from "hooks";
// Context
import { MapInteractionProvider } from "context/MapInteractionContext";
// Constants
import { rtkQueryParams } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { LocationsIcon } from "icons";
// Layers
import { TileLayerWrapper } from "Layers";
// Components
import { Loader, NoItemsContent } from "components";
import { InteractiveMap } from "./components";
// Styled
import {
  ContentBox,
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";
import { Typography } from "styled/Typography";
import { MapWrapper } from "./styled";

import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from "Layers/config";

export const DeliveryAreas: FC = () => {
  const { openNotification } = useNotification();

  const {
    data: locationsData,
    isFetching,
    error,
  } = useGetLocationsQuery(null, rtkQueryParams);

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  return (
    <>
      {isFetching && <Loader />}

      <FlexContainer $fullwidth $column $gap={32}>
        <PageHeadingContainer>
          <PageTitleContainer>
            <Typography.H1>Delivery Areas</Typography.H1>
          </PageTitleContainer>
        </PageHeadingContainer>

        {!isFetching && !!locationsData?.length && (
          <MapWrapper>
            <MapInteractionProvider
              isInteractive
              center={fromLonLat(DEFAULT_MAP_CENTER)}
              zoom={DEFAULT_MAP_ZOOM}
              rawKitchens={locationsData}
            >
              <InteractiveMap />

              <TileLayerWrapper source={new OSM()} zIndex={0} />
            </MapInteractionProvider>
          </MapWrapper>
        )}

        {!isFetching && !locationsData?.length && (
          <ContentBox>
            <NoItemsContent
              message="Don`t have any zones to show"
              description="Try to create at least one zone"
              Icon={LocationsIcon}
            />
          </ContentBox>
        )}
      </FlexContainer>
    </>
  );
};
