import { yupResolver } from "@hookform/resolvers/yup";
import { number, object } from "yup";

const schema = object({
  from: number()
    .nullable()
    .required("From is required")
    .test("from-to", "From must be less than To", function (value) {
      const { to } = this.parent;
      return value === null || to === null || value < to;
    }),
  to: number().nullable().required("To is required"),
  bonusCoinsPercent: number().nullable().required("Bonus is required"),
}).required();

export const resolver = yupResolver(schema);
