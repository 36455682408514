// Lib
import { FC } from "react";
import { useNavigate } from "react-router-dom";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks";
// Actions
import { setTableFilters } from "rtkQuery/slices/tableFilters";
// Selectors
import { getCustomerOrdersFilters } from "rtkQuery/selectors/tableFilters";
// Constants
import { ADMIN_ROUTES } from "consts";
// Types
import { FilterValue } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd";
import { ETable, FilterStateItem } from "types/tableFilters";
import { GetOrdersResponseDto } from "types/orders";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Table } from "components";
// Styled

import { columns, filtersList } from "./config";

interface OrdersTabProps {
  isLoading: boolean;
  ordersData: GetOrdersResponseDto;
  //TODO: remove comment if BE is ready
  // search: string;
  // setSearch: React.Dispatch<React.SetStateAction<string>>;
  handleSort: (field: string, direction: string) => void;
}

export const OrdersTab: FC<OrdersTabProps> = ({
  isLoading,
  ordersData,
  //TODO: remove comment if BE is ready
  // search,
  // setSearch,
  handleSort,
}) => {
  const navigate = useNavigate();

  const filters = useAppSelector(getCustomerOrdersFilters);
  const dispatch = useAppDispatch();

  const setTableFilterValues = (filters: FilterStateItem[]) => {
    dispatch(setTableFilters({ name: ETable.CustomerOrders, filters }));
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const onRow = record => {
    return {
      onClick: () => {
        navigate(`${ADMIN_ROUTES.ORDERS.path}/${record.id}`);
      },
    };
  };

  return (
    <Table
      isLoading={isLoading}
      dataSource={ordersData?.items || []}
      columns={columns}
      empty={{
        icon: DescriptionBlackIcon,
        title: "No order to show.",
        description: "Try changing sections or change the filters",
      }}
      header={{
        //TODO: remove comment if BE is ready
        // search: {
        //   placeholder:
        //     "Search Order ID, Type, Payment status, Created at, Exchange rate",
        //   value: search,
        //   setValue: setSearch,
        // },
        totalCount: ordersData?.totalCount,
        filter: {
          filters: filtersList,
          selected: filters || [],
          setValue: setTableFilterValues,
        },
      }}
      onChange={handleTableChange}
      onRow={onRow}
    />
  );
};
