// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetCustomersQuery } from "rtkQuery/query/customersAPI";
// Hooks
import { useDebounce, useNotification, useTable } from "hooks";
// Types
import { TablePaginationConfig } from "antd";
import { ETable } from "types/tableFilters";
import { FilterValue } from "antd/es/table/interface";
import { CouponAllowedUser } from "types/coupons";
// Theme
import { theme } from "theme";
// Constants
import { rtkQueryParams } from "consts";
// Helpers
import { getFiltersQuery } from "helpers/tableFiltersHelpers";
import { transformCustomerToCouponUserData } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon, PlusIcon } from "icons";
// Components
import { Modal, Pagination, Table } from "components";
// Styled
import { Button } from "styled/Buttons";

import { columns, filtersList } from "./config";

interface AddCustomersModalProps {
  open: boolean;
  allowedUsers: CouponAllowedUser[];
  handleAddCustomers: (customers: CouponAllowedUser[]) => void;
  handleClose: () => void;
}

export const AddCustomersModal: FC<AddCustomersModalProps> = ({
  open,
  allowedUsers,
  handleAddCustomers,
  handleClose,
}) => {
  const { openNotification } = useNotification();

  const {
    sortingOrder,
    sortBy,
    page,
    limit,
    debouncedSearch,
    selectedFilters,
    setPage,
    setLimit,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.CouponCustomersAdd, removeQueryParams: true });

  const filtersQuery = getFiltersQuery(selectedFilters);

  const query = useDebounce<Record<string, string | string[]>>(
    filtersQuery,
    500,
  );

  const {
    data: customersData,
    isFetching: isLoading,
    error: customersError,
  } = useGetCustomersQuery(
    {
      query: {
        ...(query && query),
        page,
        limit,
        sortBy,
        sortingOrder,
        search: debouncedSearch,
      },
    },
    rtkQueryParams,
  );

  useEffect(() => {
    if (customersError) {
      errorHandler({ error: customersError, openNotification });
    }
  }, [customersError]);

  const [selected, setSelected] = useState<CouponAllowedUser[]>([]);

  useEffect(() => {
    if (!open) {
      setSelected([]);
    } else {
      setSelected(allowedUsers);
    }
  }, [open]);

  const disabledRows = allowedUsers.map(el => el.id);

  const rowSelection = {
    getCheckboxProps: (record: CouponAllowedUser) => ({
      disabled: disabledRows.includes(record.id),
    }),
    onSelect: (record: CouponAllowedUser, selected: boolean) => {
      if (selected) {
        setSelected(prev => [...prev, record]);
      } else {
        setSelected(prev => prev.filter(p => p.id !== record.id));
      }
    },
    onSelectAll: (
      selected: boolean,
      _selectedRows: CouponAllowedUser[],
      changeRows: CouponAllowedUser[],
    ) => {
      if (selected) {
        setSelected(prev => prev.concat(changeRows));
      } else {
        setSelected(prev =>
          prev.filter(p => !changeRows.find(r => r.id === p.id)),
        );
      }
    },
  };

  const addSelectedItems = () => {
    handleAddCustomers(selected);
    handleClose();
  };

  const onClose = () => {
    handleClose();
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sorter: any,
  ) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const mappedUsersData =
    customersData?.items?.map(user =>
      transformCustomerToCouponUserData(user),
    ) || [];

  return (
    <Modal title="Add Users" open={open} width={1150} onClose={onClose}>
      <Table
        isLoading={isLoading}
        columns={columns}
        dataSource={mappedUsersData}
        scroll={{ y: "55vh" }}
        header={{
          totalCount: customersData?.totalCount,
          filter: {
            filters: filtersList,
            selected: selectedFilters,
            setValue: handleSetTableFilterValues,
          },
          suffix: (
            <Button.Base
              type="primary"
              icon={<PlusIcon fill={theme.color.white} />}
              disabled={!selected?.length}
              onClick={addSelectedItems}
            >
              Add
            </Button.Base>
          ),
        }}
        empty={{
          icon: DescriptionBlackIcon,
          title: "No customer to show.",
          description: "Try changing sections or change the filters",
        }}
        rowSelection={{
          selectedRowKeys: selected.map(el => el.id),
          type: "checkbox",
          ...rowSelection,
        }}
        onChange={handleTableChange}
      />
      <Pagination
        padding="12px 16px"
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        totalItems={customersData?.totalCount}
      />
    </Modal>
  );
};
