// Lib
import { FC, useEffect, useRef } from "react";
import VectorLayer from "ol/layer/Vector";
import { Vector } from "ol/source";
import { StyleLike } from "ol/style/Style";
import { Draw } from "ol/interaction";
import { Polygon } from "ol/geom";
import { Coordinate } from "ol/coordinate";
// Hooks
import { useMapInteractionContext } from "hooks";
// Theme
import { theme } from "theme";
// Icons
import { RefreshIcon } from "icons";
// Styled
import { RefreshButton } from "./styled";

const RIGHT_MOUSE_BUTTON_INDEX = 2;

type DrawLayerProps = {
  style: StyleLike;
  addNewZone: (data: Coordinate[][]) => void;
  removeNewZones: () => void;
  activeKitchenId: string;
};

export const DrawLayer: FC<DrawLayerProps> = ({
  style,
  addNewZone,
  activeKitchenId,
  removeNewZones,
}) => {
  const { map } = useMapInteractionContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sourceRef = useRef<any>(null);

  useEffect(() => {
    sourceRef?.current?.clear();
  }, [activeKitchenId]);

  useEffect(() => {
    if (!map) return;

    sourceRef.current = new Vector({ wrapX: false });

    const layer = new VectorLayer({
      source: sourceRef.current,
      style,
    });
    map.addLayer(layer);

    const draw = new Draw({
      source: sourceRef.current,
      style,
      type: "Polygon",
    });
    map.addInteraction(draw);

    const rightClickHandler = (e: MouseEvent) => {
      if (e.button === RIGHT_MOUSE_BUTTON_INDEX) draw.abortDrawing();
    };

    draw.on("drawstart", () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      addNewZone(null as any);
    });

    draw.on("drawend", e => {
      const geometry = e.feature.getGeometry() as Polygon;
      const coords = geometry?.getCoordinates();
      addNewZone(coords);
    });

    window.addEventListener("mouseup", rightClickHandler);

    return () => {
      sourceRef.current?.clear();
      map.removeInteraction(draw);
      window.removeEventListener("mouseup", rightClickHandler);
    };
  }, []);

  return (
    <RefreshButton
      onClick={() => {
        sourceRef.current?.clear();
        removeNewZones();
      }}
    >
      <RefreshIcon fill={theme.color.text.tertiary} />
    </RefreshButton>
  );
};
