// Lib
import { FC } from "react";
import { Coordinate } from "ol/coordinate";
import { TMappedZone } from "types/locations";
import VectorSource from "ol/source/Vector";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
// Components
import { VectorLayerContainer } from "../VectorLayerContainer";

import { mapStyles } from "../config";

interface VectorLayerWrapperProps {
  editedKitchen: TMappedZone[];
  zoneId: string;
  name: string;
  kitchenId: string;
  activeKitchenId: string;
  source: VectorSource<Feature<Geometry>>;
  editZone: (zoneId: string, data: Coordinate[][]) => void;
  toggleRemoveZone: (zoneId: string) => void;
  isPolygonMode: boolean;
}

export const VectorLayerWrapper: FC<VectorLayerWrapperProps> = ({
  editedKitchen,
  zoneId,
  name,
  kitchenId,
  activeKitchenId,
  source,
  editZone,
  toggleRemoveZone,
  isPolygonMode,
}) => {
  const isEditable = activeKitchenId === kitchenId;

  const isToRemove = !!editedKitchen.find(kitchen => kitchen.zoneId === zoneId)
    ?.toRemove;

  const editableStyle = isToRemove
    ? mapStyles.RemovedPolygon
    : mapStyles.ActivePolygon;

  const vectorLayerStyle = isEditable ? editableStyle : mapStyles.MultiPolygon;

  const handleUpdateKitchen = (data: Coordinate[][]) => editZone(zoneId, data);

  const handleToggleToRemove = () => {
    isPolygonMode && toggleRemoveZone(zoneId);
  };

  return (
    <VectorLayerContainer
      isEditable={isEditable}
      isToRemove={isToRemove}
      updateKitchen={handleUpdateKitchen}
      toggleRemoveZone={handleToggleToRemove}
      style={vectorLayerStyle}
      source={source}
      name={name}
      isPolygonMode={isPolygonMode}
    />
  );
};
