import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const CallBlackIcon: FC<SvgIconConstituentValues> = ({
  fill,
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Placeholder" clipPath="url(#clip0_3936_12277)">
      <path
        id="Vector"
        d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z"
        fill={fill || "#646965"}
      />
    </g>
    <defs>
      <clipPath id="clip0_3936_12277">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
