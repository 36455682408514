import { theme } from "theme";
import styled from "styled-components";

export const Wrapper = styled.div<{ $size?: "xl" | "md" }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px 2px 4px;
  color: ${theme.color.text.primary2};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.lineHeight.base};
  border-radius: 4px;
  border: 1px solid ${theme.color.borderLight};
  background: ${theme.color.white};

  ${({ $size }) =>
    $size === "xl" &&
    `
    line-height: ${theme.lineHeight.md}; 
    font-weight: ${theme.fontWeight.medium}; 
    font-size: ${theme.fontSize.md};
    padding: 4px 12px 4px 4px;

    svg {
      height: 24px;
      width: 24px;
    }
    `}
`;
